import { ErrorPopsService } from './../../../service/error-pops/error-pops.service';
import { DbStorageService } from './../../../service/headers/db-storage/db-storage.service';
import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Router, ActivatedRoute, ParamMap, Routes } from '@angular/router';
import { PropertyDetailsService } from '../../property-details/property-details.service';
import { MapSearchLibService } from './../../../service/map-search/map-search-lib.service';
import { ListPropertyService } from './../../list-properties/list-property/list-property.service';
import { MapsAPILoader } from '@agm/core';

@Component({
  selector: 'user-flow-favourite',
  templateUrl: './favourite.component.html',
  styleUrls: ['./favourite.component.scss', './mq-favourite.component.scss'],
})
export class FavouriteComponent implements OnInit {
  addressSearch: any;
  cordinatesValue: any = 'Locate';
  id: any;
  property: any = [];
  nearBy: any;
  selectedNearby: any;
  userId: any;
  count: Number;
  properties: any = [];
  currentLat;
  currentLng;
  loader: boolean = false;
  propertiesList: any[];
  constructor(
    private propertyDetailsService: PropertyDetailsService,
    private _route: ActivatedRoute,
    private metaService: Meta,
    private router: Router,
    private map: MapSearchLibService,
    private listProp: ListPropertyService,
    private _dbStorage: DbStorageService,
    private mapsApiLoader: MapsAPILoader,
    private errorPops: ErrorPopsService
  ) {}
  zoom = 8;
  icon = {
    url: './assets/images/map/map-pin.png',
    scaledSize: { height: 45, width: 30 },
  };

  ngOnInit(): void {
    this.metaService.getTag("name='viewport'");
    this._route.params.subscribe((data: any) => {
      this.id = data.propId;
      // this.propertyDetail(this.id)
    });
    this.userId = this._dbStorage.getUserId();

    //get Favourite Properties
    this.loader = false;
    this.listProp.getFavourites(this.userId).subscribe(
      async (res: any) => {
        // console.log("get fav list", res.data);
        // let a = res.data
        // console.log("get fav aaa",a);
        this.propertiesList = res.data;
        this.count = res.data.length;
        for (let i = 0; i < res.data.length; i++) {
          res.data[i].isFav = true;
        }
        this.getCoords().then((position: any) => {
          if (position) {
            this.map
              .findAddressByCoordinates(position.latitude, position.longitude)

              .then((d) => {
                // console.log('location data :', d);
              });
            this.currentLat = position.latitude;
            this.currentLng = position.longitude;
            this.asyncLoop();
          } else {
            this.asyncLoop();
          }
        });
      },
      (err: any) => {
        // console.log("get fav error", err)
      },
      () => {
        if (this.properties) {
          this.loader = true;
        }
      }
    );

    if (this.userId == 'null' || this.userId == null) {
      this.router.navigate(['/']);
    }

    //get nearby coordinates
  }

  asyncLoop = async () => {
    for (let i = 0; i < this.propertiesList.length; i++) {
      let propObj = Object.assign({}, this.propertiesList[i]);
      propObj.distance = await this.distanceFromSpecificLocation(
        this.propertiesList[i]
      );
      if (this.distance) {
        propObj.distance = this.distance + 'km';
      } else {
      propObj.distance = ' ';
        
      }
      this.properties.push(propObj);
    }
  };
  // propertyDetail(propId) {
  //   this.propertyDetailsService
  //     .propertyDetails(propId)
  //     .subscribe((propDetail: any) => {
  //       this.property = propDetail.propertyList[propId];
  //       this.nearBy = this.property.nearBy;
  //       this.selectedNearby = this.nearBy[0];
  //       this.metaService.removeTag("name='description'");
  //       this.metaService.addTag({
  //         name: 'description',
  //         content: this.property.overview,
  //       });
  //       this.metaService.addTag({
  //         name: 'keywords',
  //         content: "'Best', 'Isthara', 'Hostel'",
  //       });
  //     });
  // }
  getAddress(place: any) {
    this.addressSearch = place;
    // console.log(place);
  }
  getCordinates(place: any) {
    this.cordinatesValue = place.city;
  }

  nearbyDetails(item, i) {
    // console.log(item, i);
    this.selectedNearby = item;
  }

  fav = {
    headText: 'Favourite Properties',
    img: './../../../../assets/images/Favourites/nofav.png',
    bigText: 'No Favourites',
    subText1: 'Tap on the heart to add to your favourites',
    subText2: 'see them here at a glance.',
    btnText: 'Explore Now',
    path: 'list',
  };
  routeTo(path) {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.map
            .findAddressByCoordinates(
              position.coords.latitude,
              position.coords.longitude
            )
            .then((d) => {
              // console.log('location data :', d);
              this.addressSearch = d;
              if (this.addressSearch.city == 'Hyderabad') {
                this.addressSearch = {
                  city: 'Hyderabad',
                  country: 'India',
                  locality: '',
                  state: 'Telangana',
                };
                this.router.navigate([path], {
                  queryParams: this.addressSearch,
                });
              } else if (this.addressSearch.city == 'Banglore') {
                this.addressSearch = {
                  city: 'Banglore',
                  coutry: 'India',
                  locality: '',
                  state: 'Karnataka',
                };
                this.router.navigate([path], {
                  queryParams: this.addressSearch,
                });
              }
            });
        },
        (err) => {
          // console.log('map error', err);
        }
      );
    }
  }
  detailProperty(property, i) {
    // this.router.navigate(['/detail', i]);
    this.router.navigate([`${property.propertyAddress.city}`,`Pg-hostel-in-${property.propertyAddress.locality}`,property.propertyType.toLowerCase(),property.propertyName.replaceAll(' ', '-')])
  }
  addFav(prpId, flag, event) {
    event.stopPropagation();
    flag = flag ? false : true;
    this.detailProperty[prpId];
    let favData = {
      favourite: flag,
      propertyId: prpId,
      userId: this.userId,
    };
    // console.log(favData);
    this.listProp.addFavourites(favData).subscribe(
      (res: any) => {
        // console.log('success', res);
        for (let i = 0; i < this.properties.length; i++) {
          if (prpId === this.properties[i].propertyId) {
            this.properties[i].isFav = flag;
            window.location.reload();
            break;
          }
        }
        if (flag == true) {
          this.errorPops.openSnackBarMessage('Added to Favourites', 'OK');
        } else {
          this.errorPops.openSnackBarMessage('Removed from Favourites', 'OK');
        }
      },
      (err: any) => {
        // console.log('error', err);
      }
    );
  }
  getMinimum(roomTypes) {
    let a = [];
    roomTypes.forEach((r) => {
      a.push(Number(r.roomPrice.monthly));
    });

    return Math.min.apply(Math, a);
  }
  distance;

  distanceFromSpecificLocation(propLocation): string {
    this.distance = '';
    if (propLocation) {
      this.mapsApiLoader.load().then(() => {
        const center = new google.maps.LatLng(this.currentLat, this.currentLng);
        const place = new google.maps.LatLng(
          propLocation.propertyAddress.geoLocation.latitude,
          propLocation.propertyAddress.geoLocation.longitude
        );
        let distance = google.maps.geometry.spherical.computeDistanceBetween(
          center,
          place
        );
        // console.log('this.distance', distance);
        this.distance = Math.ceil(distance / 1000);
        return this.distance;
        // console.log("this.distance", this.distance);
      });
    } else {
      return '';
    }
  }
  getCoords() {
    return new Promise((resolve, reject) =>
      navigator.permissions
        ? // Permission API is implemented
          navigator.permissions
            .query({
              name: 'geolocation',
            })
            .then((permission) =>
              // is geolocation granted?
              permission.state === 'granted'
                ? navigator.geolocation.getCurrentPosition((pos) =>
                    resolve(pos.coords)
                  )
                : resolve(null)
            )
        : // Permission API was not implemented
        resolve(null)
          // reject(new Error('Permission API is not supported'))
    );
  }
}
