import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'user-flow-refer-a-friend',
  templateUrl: './refer-a-friend.component.html',
  styleUrls: ['./refer-a-friend.component.scss']
})
export class ReferAFriendComponent implements OnInit {
  viewMode:any= 'direct';
  constructor(
    public dialogRef: MatDialogRef<ReferAFriendComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
  }
  closeBtn() {
    this.dialogRef.close();
  }

  tabs = [
    { name: 'Direct Invite', active: true,viewMode:'direct' },
    { name: 'Invite Via Link', active: false ,viewMode:'link'},
    { name: 'Invite Via Social Media', active: false ,viewMode:'socialmedia'},
  ];

  openTab(tab, tabI) {
    this.tabs.forEach((value, index) => {
      if (index === tabI) {
        value.active = true;
        this.viewMode=value.viewMode
      } else {
        value.active = false;
      }
    });

  }
}
