<div class="enq-detail">
  <div class="top-section">
    <div class="enq-heading"><b>Enquiry Details</b></div>
    <div class="close-btn" (click)="onNoClick()">
      <img
        src="./../../../../../assets/images/popup-images/cross-mark.png"
        alt="close"
        class="close"
      />
    </div>
  </div>

  <div class="enquity-temp-form">
    <div class="name">
      <mat-form-field class="input-width" appearance="outline">
        <mat-label>Name</mat-label>
        <input
          matInput
          [(ngModel)]="enqForm.Name"
          #nameCheck="ngModel"
          pattern="[a-zA-Z\s]+"
          required
        />
      </mat-form-field>
      <mat-error
        *ngIf="!nameCheck.valid && (nameCheck.dirty || nameCheck.touched)"
      >
        Please enter your name
      </mat-error>
    </div>
    <div class="name">
      <mat-form-field class="input-width" appearance="outline">
        <mat-label>Mobile Number</mat-label>
        <input
          matInput
          [(ngModel)]="enqForm.PhoneNumber"
          #numberCheck="ngModel"
          pattern="^[6-9]\d{9}$|^[1-9]\d{9}$"
          required
          maxlength="10"
        />
      </mat-form-field>
      <mat-error
        *ngIf="!numberCheck.valid && (numberCheck.dirty || numberCheck.touched)"
      >
        please provide your contact number
      </mat-error>
    </div>
    <div class="name">
      <mat-form-field class="input-width" appearance="outline">
        <mat-label>Email id</mat-label>
        <input
          matInput
          [(ngModel)]="enqForm.EmailId"
          #emailCheck="ngModel"
          pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
          required
        />
      </mat-form-field>
      <mat-error
        *ngIf="!emailCheck.valid && (emailCheck.dirty || emailCheck.touched)"
      >
        please provide your emailID
      </mat-error>
    </div>
    <div class="name" *ngIf="data.data.dataFrom === 'topnavbar'">
      <mat-form-field class="input-width" appearance="outline">
        <mat-label>City</mat-label>
        <mat-select
          name="property"
          [(ngModel)]="enqForm.CityMaster.CityMaster_Id"
          #propertyTypeCheck="ngModel"
          disableOptionCentering
          panelClass="panelClass"
          required
        >
          <mat-option
            *ngFor="let property of cityList"
            [value]="property.value"
          >
            {{ property.name | titlecase }}
          </mat-option>
        </mat-select>
        <mat-error
          *ngIf="
            !propertyTypeCheck.valid &&
            (propertyTypeCheck.dirty || propertyTypeCheck.touched)
          "
        >
          please select a property type
        </mat-error>
      </mat-form-field>
    </div>
    <button
      class="enquire-btn-new"
      (click)="submitEnquiry()"
      [disabled]="!numberCheck.valid && !emailCheck.valid && submitbtn"
      *ngIf="submitbtn ; else loading"
    >
      Submit
    </button>
    <ng-template #loading>
      <button class="enquire-btn-new">
        <i class="fa fa-spinner fa-spin"></i> &nbsp;Loading
      </button>
    </ng-template>
  </div>
</div>
