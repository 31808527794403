import { environment } from './../../../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class ContatUsService {

  constructor(
    private http: HttpClient
  ) { }

  contactUs(data) {
    return this.http.post(
      environment.authenticationUrl + 'user/userdetails/ist/contactus', data
    )
  }

}
