<section>
  <div class="abmain-section">
    <div class="top-nav">
      <user-flow-top-navbar></user-flow-top-navbar>
    </div>

    <div class="goup-image">
      <img
        src="./../../../../assets/images/about-us/group-isthara.svg"
        class="group-one"
        alt="group-one"
        loading = "lazy"
      />
      <p class="heading-text">Revolutionizing <span>Urban Living</span></p>
    </div>

    <div class="main-section">
      <div class="inter-sub-text">
        <p>
          Isthara is a premium residential venture offering high-quality
          executive / student living with the best facilities and amenities
          across many cities of India. Its premium features include a
          well-planned layout, stylish interior design, beautiful landscaping,
          and architecture along with various other amenities.
        </p>
        <p>
          Placing tech at the forefront of the business, Isthara aims to
          revolutionize the living space segment with operational excellence.
          The company enjoys great alignment in culture across verticals and
          business functions.
        </p>
        <p>
          Our team has spent many years in designing products and services which
          create memorable experience for Students and Executives.
        </p>
      </div>
      <div class="group-two">
        <img
          src="./../../../../assets/images/about-us/group-2-isthara.svg"
          alt="group"
          class="group-two-img"
          loading = "lazy"
        />
      </div>
    </div>
    <div class="co-living">
      <div class="coliving-img">
        <img
          src="./../../../../assets/images/about-us/coliving.svg"
          alt="coliv"
          class="coliv-img"
          loading = "lazy"
        />
      </div>
      <div class="coliv-details">
        <p class="heading-sub">Isthara Co-Living</p>
        <p class="para">
          Our Isthara properties enjoy strategic locations in each city around
          important clusters. Each one offers guests, a true home away from
          home. Comfortable living spaces, dedicated housekeeping and security
          services, and more than you'd expect from your average hostel (group
          activities, communal lounge spaces, central and cluster led kitchens,
          etc.). All without breaking the bank —our aim here is to create homes
          that guests adore.
        </p>
        <p class="know-text" (click)="changePath('/aboutus')">Know More</p>
      </div>
    </div>
    <div class="student-living">
      <div class="student-img">
        <img
          src="./../../../../assets/images/about-us/student-living.svg"
          alt="stu"
          class="stu-img"
          loading = "lazy"
        />
      </div>
      <div class="stu-details">
        <p class="heading-sub">Isthara Student Living</p>
        <p class="para">
          Isthara is a pioneer in offering a holistic housing solution to help
          students make the best of their college years. The company leverages
          technology and expertise to create synergies at multiple levels for
          colleges, students, and parents. By combining off-campus experience
          with on-campus living, Isthara offers great returns with a focus on
          safety, quality, and well-being for students.
        </p>
        <p class="know-text" (click)="changePath('/student-living')">
          Know More
        </p>
      </div>
    </div>
    <div class="co-living">
      <div class="coliving-img">
        <img
          src="./../../../../assets/images/about-us/food-court.svg"
          alt="coliv"
          class="coliv-img"
          loading = "lazy"
        />
      </div>
      <div class="coliv-details">
        <p class="heading-sub">Isthara Food Court</p>
        <p class="para">
          A first-of-its-kind offering, Isthara brings technology into campuses
          and allows the consumer to discover various cuisines - from Indian
          street food to Chinese, Continental, and South Indian. By having
          options for a different cuisine every day, menu fatigue is avoided.
          With state of art technology Isthara helps students and the colleges
          with No Contact ordering, Online / Wallet payment option, QR code
          based pickup scheduling, and food court congestion management.
        </p>
        <p class="know-text" (click)="changePath('/food-court')">Know More</p>
      </div>
    </div>
  </div>
  <div class="footer">
    <app-footer></app-footer>
  </div>
</section>
