import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { PopupService } from '../../popup-components/popup.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SearchBarComponent } from '../../popup-components/popups/search-bar/search-bar.component';

@Component({
  selector: 'user-flow-locality-auto-complete',
  templateUrl: './locality-auto-complete.component.html',
  styleUrls: [
    './locality-auto-complete.component.scss',
    './mq-locality-auto-complete.component.scss',
  ],
})
export class LocalityAutoCompleteComponent implements OnInit {
  @Output() newItemEvent = new EventEmitter<any>();
  addressSearch: any;
  constructor(private popService: PopupService, private router: Router) {}

  ngOnInit(): void {
    this.popService.getLocalites().subscribe(
      (res: any) => {
        this.localites = res.data;
        // console.log('suggested locaties done', this.localites);
      },
      (err: any) => {
        // console.log('error from suggested properties', err);
      }
    );
  }

  // closeBtn() {
  //   this.dialogRef.close();
  // }
  keyword = 'name';
  localites: any = [];

  selectEvent(item) {
    // // do something with selected item
    // this.popService.getSuggest(item.name).subscribe(
    //   (res: any) => {
    //     this.suggest=res.data;
    //     console.log("pro",res.data);
    //     this.suggest.forEach((item,index) => {
    //       if(item.name==item.name){
    //         console.log('jhjkjh',item.responseType)
    //if search by locality
    // console.log("seachclick",item);
    
    if (item.responseType == 'locality') {
      let data = {
        locality: item.name,
      };
      this.popService.searchUpdate(data).subscribe(
        (res: any) => {
          // this.suggest=res.data;
          // console.log('pro', res.data);
          this.addressSearch = {
            locality: item.name,
            city: item.city,
          };
          // this.router.navigate(['/list']
          // ,
          //  {
          //   queryParams: this.addressSearch,
          // }
          this.router.navigate([`${item.city}`,`pg-hostel-in-${item.name}`]
          );
          this.closePopup();
          // this.closeBtn()
        },
        (err: any) => {
          // console.log('error from suggested properties', err);
        }
      );
    }
    //if search by city
    if (item.responseType == 'city') {
      let data = {
        locality: item.name,
      };
      this.popService.searchUpdate(data).subscribe(
        (res: any) => {
          // this.suggest=res.data;
          // console.log('pro', res.data);
          this.addressSearch = {
            city: item.name,
          };
          this.router.navigate([`${item.name}`]
          // , {
          //   queryParams: this.addressSearch,
          // }
          );
          // this.closeBtn()
          this.closePopup();
        },
        (err: any) => {
          // console.log('error from suggested properties', err);
        }
      );
    }
    if (item.responseType == 'landmark') {
      let data = {
        lat_long: {
          latitude: item.lat_long.latitude,
          longitude: item.lat_long.longitude,
        },
      };
      this.popService.searchUpdate(data).subscribe(
        (res: any) => {
          this.addressSearch = {
            lat: Number(item.lat_long.latitude),
            lng: Number(item.lat_long.longitude),
          };
          this.router.navigate(['/list'], {
            queryParams: this.addressSearch,
          });
          this.closePopup();
        },
        (err: any) => {
          // console.log('error from suggested properties', err);
        }
      );
    }
    if (item.responseType == 'property') {
      this.router.navigate([`${item.city}`,`Pg-hostel-in-${item.locality}`,item.name.replaceAll(' ', '-')])
      // this.router.navigate(['/detail', item.propertyId]);
      this.closePopup();
    }
    // }
    // });
    // },
    // (err: any) => {
    //   console.log("error from suggested properties", err);
    // }
    // );
  }

  suggest: any = [];
  onChangeSearch(search: string) {
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.

    this.popService.getSuggest(search).subscribe(
      (res: any) => {
        this.suggest = res.data;
        // console.log('pro', res.data);
      },
      (err: any) => {
        // console.log('error from suggested properties', err);
      }
    );
    // this.suggest.forEach((item,index) => {
    //   // this.localites=[];
    //   this.localites.push(item.name)
    //   this.localites=[...new Set(this.localites)]
    //   console.log('vbhjnhb',item.name)
    // });
    // this.localites = arrayA.concat(arrayB);
  }

  onFocused(e) {
    // do something
  }

  closePopup() {
    this.newItemEvent.emit({ close: true });
  }
}
