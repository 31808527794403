import { Component, OnInit ,Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'user-flow-change-mobile-number',
  templateUrl: './change-mobile-number.component.html',
  styleUrls: ['./change-mobile-number.component.scss','./mq-change-mobile-number.component.scss']
})
export class ChangeMobileNumberComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ChangeMobileNumberComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any) { 
      {
        dialogRef.disableClose = true;
      }
    }

  ngOnInit(): void {
  }
  closeBtn(){
    this.dialogRef.close();
  }
}
