<div class="privacy-section">
    <div class="community-details">
        <user-flow-top-navbar></user-flow-top-navbar>
      </div>
    <div class="main-section">
        <h1>Privacy Policy</h1>
        <p>Last updated on April 1, 2022.</p>
    </div>
    <div class="sub-heading">
       <p class="privacy-paragraph">Isthara Parks Pvt Ltd and/or its affiliates ("Isthara," the "Company," "we," "us," and
        "our,") respect your privacy and are committed to protecting it through its compliance
        with its privacy policies. This policy describes:
        <br>
        ● the types of information that Isthara may collect from you when you access or
        use its websites, applications and other online services (collectively, referred as
        "Services"); and
        <br>
        ● its practices for collecting, using, maintaining, protecting and disclosing that
        information.
        <br>
        This policy applies only to the information Isthara collects through its Services, in email,
        text and other electronic communications sent through or in connection with its
        Services.
        <br>
        This policy DOES NOT apply to information that you provide to, or that is collected by,
        any third-party, such as a food-court partner restaurant at which you make order and/or
        pay through Isthara Services and social networks that you use in connection with its
        Services. Isthara encourages you to consult directly with such third-parties about their
        privacy practices.
        <br>
        Please read this policy carefully to understand Isthara policies and practices regarding
        your information and how Isthara will treat it. By accessing or using its Services and/or
        registering for an account with Isthara, you agree to this privacy policy and you are
        consenting to Isthara's collection, use, disclosure, retention, and protection of your
        personal information as described here. If you do not provide the information Isthara
        requires, Isthara may not be able to provide all of its Services to you.
        <br>
        Isthara Parks Pvt Limited, located at Third Floor, Ayyappa Arcade, Janardana Hills,
        Lumbini Avenue, Gachibowli, Hyderabad - 500032, Telangana, India will be the
        controller of your personal data provided to, or collected by or for, or processed in
        connection with our Services.
        <br>
        Your data controller is responsible for the collection, use, disclosure, retention, and
        protection of your personal information in accordance with its privacy standards as well
        as any applicable national laws. Your data controller may transfer data to other
        members of Isthara as described in this Privacy Policy. Isthara may process and retain
        your personal information on its servers in India where its data centers are located,
        and/or on the servers of its third parties (in or outside India), having contractual
        relationships with Isthara.
        <br>
        This policy may change from time to time, your continued use of Isthara's Services after
        it makes any change is deemed to be acceptance of those changes, so please check
        the policy periodically for updates.
    </p> 
    <h1 class="privacy-heading">The information we collect and how we use it</h1>
    <p class="privacy-paragraph">
        Isthara Limited ("Isthara," the "Company," "we," "us," and "our”) collects several types of
        information from and about users of our Services, including:
        <br>
        ● Your Personal Information("PI") - Personal Information is the information that can
        be associated with a specific person and could be used to identify that specific
        person whether from that data, or from the data and other information that we
        have, or is likely to have access to. We do not consider personal information to
        include information that has been made anonymous or aggregated so that it can
        no longer be used to identify a specific person, whether in combination with other
        information or otherwise.
        <br>
        ● Information about your internet connection, the equipment you use to access our
        Services and your usage details.
        <br>
        We collect this information:
        <br>
        ● directly from you when you provide it to us; and/or
        <br>
        ● automatically as you navigate through our Services (information collected
        automatically may include usage details, IP addresses and information collected
        through cookies, web beacons and other tracking technologies).
        <br>
    </p>
    <h1 class="privacy-heading">Information You Provide to Us</h1>
    <p class="privacy-paragraph">
        The information we collect on or through our Services may include:
        <br>
        ● Your account information: Your full name, email address, postal code, password
        and other information you may provide with your account, such as your gender,
        mobile phone number and website. Your profile picture that will be publicly
        displayed as part of your account profile.
        <br>
        ● Your preferences: Your preferences and settings such as time zone and
        language.
        <br>
        ● Your content: Information you provide through our Services, including your
        reviews, photographs, comments, lists, followers, the users you follow, current
        and prior stay details and history, favorite property, food ordering details and
        history, favorite restaurants, special restaurant requests, contact information of
        people you add to, or notify of, your food delivery orders through our Services,
        names, and other information you provide on our Services, and other information
        in your account profile.
        <br>
        ● Your searches and other activities: The search terms you have looked up and
        results you selected.
        <br>
        ● Your browsing information: How long you used our Services and which features
        you used; the ads you clicked on.
        <br>
        ● Your communications: Communications between you and other users or
        merchants through our Services; your participation in a survey, poll,
        sweepstakes, contest or promotion scheme; your request for certain features
        (e.g., newsletters, updates or other products); your communication with us about
        employment opportunities posted to the services.
        <br>
        ● Your transactional information: If you make reservations or purchases through
        our Services, we may collect and store information about you to process your
        requests and automatically complete forms for future transactions, including (but
        not limited to) your phone number, address, email, billing information. This
        information may be shared with third-parties which assist in processing and
        fulfilling your requests, including PCI compliant payment gateway processors. If
        you write reviews about businesses with which you conduct transactions through
        our Services, we may publicly display information that you transacted with those
        businesses.
        <br>
        ● Your Public Posts: You also may provide information (such as ratings, reviews,
        tips, photos, comments, likes, bookmarks, friends, lists, etc.) to be published or
        displayed (hereinafter, "posted") on publicly accessible areas of our Services, or
        transmitted to other users of our Services or third-parties (collectively, "User
        Contributions"). Your User Contributions are posted on and transmitted to others
        at your own risk. Although we limit access to certain pages, you may set certain
        privacy settings for such information by logging into your account profile. Please
        be aware that no security measures are perfect or impenetrable (see "Security"
        section below). Additionally, we cannot control the actions of other users of our
        Services with whom you may choose to share your User Contributions.
        Therefore, we cannot and do not guarantee that your User Contributions will not
        be viewed by unauthorized persons. We may display this information on the
        Services, share it with businesses, and further distribute it to a wider audience
        through third party sites and services. You should be careful about revealing any
        sensitive details about yourself in such postings.
        We use the information you provide to us to enhance the functionality and improve the
        quality of our Services, and to personalize your experience while using our Services.
        We also use this information to display relevant advertising, provide support to you,
        communicate with you, and comply with our legal obligations.
    </p>
    <h1 class="privacy-heading">Information about Your Friends</h1>
    <p class="privacy-paragraph">
        You have the option to request your friends to join the Services by providing their
        contact information. If you request a friend to join and connect with you on the Services,
        we will only use your friend's contact information to process your request.
    </p>
    <h1 class="privacy-heading">Information about Your Messages</h1>
    <p class="privacy-paragraph">
        If you exchange messages with others through the Services, we may store them in
        order to process and deliver them, allow you to manage them, and investigate possible
        violations of our Terms of Service and wrongdoing in connection with the Services. If
        you send information from the Services to your mobile device via SMS text message,
        we may log your phone number, phone carrier, and the date and time that the message
        was processed. Carriers may charge recipients for texts that they receive.
    </p>
    <h1 class="privacy-heading">Information We Collect Through Automatic Data Collection
        Technologies</h1>
            <p class="privacy-paragraph">
                We may automatically collect certain information about the computer or devices
        (including mobile devices) you use to access the Services, and about your use of the
        Services, even if you use the Services without registering or logging in.
        <br>
        ● Usage information: Details of your use of our Services, including traffic data,
        location data, logs and other communication data and the resources that you
        access and use on or through our Services.
        <br>
        ● Computer and device information: Information about your computer, Internet
        connection and mobile device, including your IP address, operating systems,
        platforms, browser type, other browsing information (connection, speed,
        connection type etc.), device type, device's unique device identifier, mobile
        network information and the device's telephone number.
        <br>
        ● Stored information and files: Our applications also may access metadata and
        other information associated with other files stored on your mobile device. This
        may include, for example, photographs, audio and video clips, personal contacts
        and address book information.
        <br>
        ● Location information: Our applications collect real-time information about the
        location of your device, as permitted by you.
        <br>
        ● Last URL visited: The URL of the last web page you visited before visiting our
        websites.
        <br>
        ● Mobile device IDs: Unique mobile device identifier (e.g. IDFA or other device IDs
        on Apple devices like the iPhone and iPad), if you're using our Services on a
        mobile device, we may use mobile device IDs (the unique identifier assigned to a
        device by the manufacturer), instead of cookies, to recognize you. We may do
        this to store your preferences and track your use of our applications. Unlike
        cookies, mobile device IDs cannot be deleted. Advertising companies may use
        device IDs to track your use of our applications, track the number of
        advertisements displayed, measure advertising performance and display
        advertisements that are more relevant to you. Analytics companies may use
        mobile device IDs to track your usage of our applications.
        <br>
        ● Your preferences: Your preferences and settings such as time zone and
        language.
        <br>
        ● Your activity on the Services: Information about your activity on the Services,
        such as your search queries, comments, domain names, search results selected,
        number of clicks, pages viewed and the order of those pages, how long you
        visited our Services, the date and time you used the Services, error logs, and
        other similar information.
        <br>
        ● Mobile status: For mobile application users, the online or offline status of your
        application.
        <br>
        ● Applications: If you use the Isthara application, Isthara may collect information
        about the presence and/ or absence and/ or details pertaining to other
        applications on your mobile phone. The applications we gather information for,
        may vary across categories including, without limitation, shopping, fashion, food
        and travel. This will help us understand you and your preferences better and
        enable Isthara to provide you with a personalized experience.
        <br>
    </p>
    <h1 class="privacy-heading">Precise Location Information and How to Opt Out</h1>
    <p class="privacy-paragraph">
        When you use one of our location-enabled services (for example, when you access
        Services from a mobile device), we may collect and process information about your
        mobile device's GPS location (including the latitude, longitude or altitude of your mobile
        device) and the time the location information is recorded to customize the Services with
        location-based information and features (for example, to inform you about restaurants in
        your area or applicable promotions). Some of these services require your personal data
        for the feature to work and we may associate location data with your device ID and
        other information we hold about you. We keep this data for no longer than is reasonably
        necessary for providing services to you. If you wish to use the particular feature, you will
        be asked to consent to your data being used for this purpose. You can withdraw your
        consent at any time by disabling the GPS or other location-tracking functions on your
        device, provided your device allows you to do this. See your device manufacturer's
        instructions for further details.

    </p>
    <h1 class="privacy-heading">Cookies and Other Electronic Tools</h1>
     <p class="privacy-paragraph">
        We, and third parties with whom we partner, may use cookies, pixel tags, web beacons,
        mobile device IDs, "flash cookies" and similar files or technologies to collect and store
        information in respect to your use of the Services and third party websites. A cookie is a
        small text file that is stored on your computer that enables us to recognize you (for
        example, as a registered user) when you visit our website, store your preferences and
        settings, enhance your experience by delivering content and advertising specific to your
        interests, perform research and analytics, track your use of our Services, and assist with
        security and administrative functions. Cookies may be persistent or stored only during
        an individual session. To understand more about cookies, click here
        <a href="https://www.aboutcookies.org."></a> A pixel tag (also called a web beacon or clear GIF) is a
        tiny graphic with a unique identifier, embedded invisibly on a webpage (or an online ad
        or email), and is used to count or track things like activity on a webpage or ad
        impressions or clicks, as well as to access cookies stored on users' computers. Isthara
        uses pixel tags to measure the popularity of our various pages, features and services.
        We also may include web beacons in e-mail messages or newsletters to determine
        whether the message has been opened and for other analytics.
        <br>
        Most browsers are set to automatically allow cookies. Please note it may be possible to
        disable some (but not all) cookies through your device or browser settings, but doing so
        may interfere with certain functionality on the Services. Major browsers provide users
        with various options when it comes to cookies. Users can usually set their browsers to
        block all third-party cookies (which are those set by third-party companies collecting
        information on websites operated by other companies), block all cookies (including
        first-party cookies such as the ones Isthara uses to collect search activity information
        about its users), or block specific cookies. To modify your cookie settings, please visit
        your browser's help settings. You will need to opt out on each browser and each device
        you use to access the Services. Flash cookies operate differently than browser cookies
        and cannot be removed or blocked via web browser settings. By using our Services with
        your browser set to accept cookies you are consenting to our use of cookies in the
        manner described in this section. For more information please read our Cookie Policy.
        <br>
        Third parties whose products or services are accessible or advertised through the
        Services, including social media services, may also use cookies or similar tools, and we
        advise you to check their privacy policies for information about their cookies and other
        practices. We do not control the practices of such partners and their privacy policies
        govern their interactions with you.
        <br>
    </p>
    <h1 class="privacy-heading">Information from Third Parties</h1>
    <p class="privacy-paragraph">
        We may also obtain information about you from third parties such as partners,
        marketers, third-party websites, and researchers, and combine that information with
        information which we collect from or about you.
        <br>
    </p>
    <h1 class="privacy-heading">Anonymous or De-Identified Data</h1>
    <p class="privacy-paragraph">
        We may anonymize and/or de-identify information collected from you through the
        Services or via other means, including via the use of third-party web analytic tools as
        described below. As a result, our use and disclosure of aggregated and/or de-identified
        information is not restricted by this Privacy Policy, and it may be used and disclosed to
        others without limitation.
        <br>
    </p>
    <h1 class="privacy-heading">How we use the information we collect</h1>
    <p class="privacy-paragraph">
        ● We use the information we collect from and about you for a variety of purposes,
        including to:
        <br>
        ● Process and respond to your queries
        <br>
        ● Understand our users (what they do on our Services, what features they like,
        how they use them, etc.), improve the content and features of our Services (such
        as by personalizing content to your interests), process and complete your
        transactions, and make special offers.
        <br>
        ● Administer our Services and diagnose technical problems.
        <br>
        ● Send you communications that you have requested or that may be of interest to
        you by way of emails, or courier, or registered post, or telephone calls, or any
        other mode of communication. We may also share your preferences or the
        Services availed by you with your network followers on Isthara for marketing and
        other promotional activities of our Services.
        <br>
        ● Send you questions from other users that you may be able to answer if you have
        registered with Isthara.
        <br>
        ● Enable us to show you ads that are relevant to you.
        <br>
        ● Generate and review reports and data about, and to conduct research on, our
        user base and Service usage patterns.
        <br>
        ● Administer contests and sweepstakes.
        <br>
        ● Provide you with customer support.
        <br>
        ● Provide you with policies about your account.
        <br>
        ● Carry out our obligations and enforce our rights arising from any contracts
        entered into between you and us, including for billing and collection.
        <br>
        ● Notify you about changes to our Services.
        <br>
        ● Allow you to participate in interactive features offered through our Services.
        <br>
        ● In any other way we may describe when you provide the information.
        <br>
        ● For any other purpose with your consent.
        <br>
        We may also use your information to contact you about our own and third-party goods
        and services that may be of interest to you. If you do not want us to use your
        information in this way, please check the relevant box located on the form on which we
        collect your data and/or adjust your user preferences in your account profile.
        <br>
        We may use the information we have collected from you to enable us to display
        advertisements to our advertisers'/service providers' target audiences. Even though we
        do not disclose your personal information for these purposes without your consent, if
        you click on or otherwise interact with an advertisement, the advertiser may assume
        that you meet its target criteria.

        <br>
    </p>
    <h1 class="privacy-heading">How we share the information we collect</h1>
    <p class="privacy-paragraph">
        We may disclose personal information that we collect or you provide, as described in
        this privacy policy, in the following ways:
        <br>
    </p>
    <h1 class="privacy-heading">General Information Disclosures</h1>
    <p class="privacy-paragraph">
        ● To our subsidiaries and affiliates, which are entities under common ownership or
        control of our ultimate parent company Isthara Limited.
        <br>
        ● To contractors, advertisers/service providers and other third-parties whom we
        use to support our business (e.g. dinner reservations and food delivery) and who
        are bound by contractual obligations to keep personal information confidential
        and use it only for the purposes for which we disclose it to them.
        <br>
        ● To a buyer or other successor in the event of a merger, divestiture, restructuring,
        reorganization, dissolution or other sale or transfer of some or all of Isthara's
        assets, whether as a going concern or as part of bankruptcy, liquidation or similar
        proceeding, in which personal information held by Isthara about the users of our
        Services are among the assets transferred.
        <br>
        ● To third-parties to market their products or services to you if you have consented
        to receive the promotional updates. We contractually require these third-parties
        to keep personal information confidential and use it only for the purposes for
        which we disclose it to them.
        <br>
        ● To fulfill the purpose for which you provide it.
        <br>
        ● For any other purpose disclosed by us when you provide the information.
        <br>
        ● Service Providers. We may share your information with outside vendors that we
        use for a variety of purposes, such as to send you communications via emails,
        messages or tele-call to inform you about our products that may be of interest to
        you, push notifications to your mobile device on our behalf, provide voice
        recognition services to process your spoken queries and questions, help us
        analyze use of our Services, and process and collect payments. Some of our
        products, services and databases are hosted by third party hosting services
        providers. We also may use vendors for other projects, such as conducting
        surveys or organizing sweepstakes for us. We may share information about you
        with these vendors only to enable them to perform their services.
        <br>
        ● Legal Purposes. We may share your information when we believe in good faith
        that such sharing is reasonably necessary in order to investigate, prevent, or take
        action regarding possible illegal activities or to comply with legal process. We
        may also share your information to investigate and address threats or potential
        threats to the physical safety of any person, to investigate and address violations
        of this Privacy Policy or the Terms of Service, or to investigate and address
        violations of the rights of third parties and/or to protect the rights, property and
        safety of Isthara, our employees, users, or the public. This may involve the
        sharing of your information with law enforcement, government agencies, courts,
        and/or other organizations on account of legal request such as subpoena, court
        order or government demand to comply with the law.
        <br>
        ● Social Networks. If you interact with social media features on our Services, such
        as the Facebook Like button, or post content, these features may collect
        information about your use of the Services, as well as post information about
        your activities on the social media service. Your interactions with social media
        companies are governed by their privacy policies.
        <br>
        ● To enforce or apply our Terms of Use and other agreements, including for billing
        and collection purposes.
        <br>
        ● If we believe disclosure is necessary or appropriate to protect the rights, property,
        or safety of Isthara, our customers or others. This includes exchanging
        information with other companies and organizations for the purposes of fraud
        protection and credit risk reduction.
        <br>
        ● Consent. We may share your information in any other circumstances where we
        have your consent.
        <br>
    </p>
    <h1 class="privacy-heading">Information Shared with Coliving & Restaurants Partners</h1>
    <p class="privacy-paragraph">
        When you make a co-living reservation or execute an online food ordering transaction
        through our Services, your information is provided to us , to the coliving partner where
        you choose to stay and to the restaurants with whom you choose to reserve. In order to
        facilitate your reservation and online food order processing, we provide your information
        to that restaurant in a similar manner as if you had made a reservation or food order
        directly with the restaurant. If you provide a mobile phone number, restaurants or
        Isthara may send you text messages regarding your reservation or order's delivery
        status.
        <br>
        Information you choose to share with a restaurant when you make a reservation and/or
        pay a restaurant through our Services may be used by the restaurant for its own
        purposes. We do not control the privacy practices of restaurants. Please contact the
        restaurant directly if you want to learn about its privacy practices.        
        <br>
    </p>
    <h1 class="privacy-heading">Analytics and tailored advertising</h1>
    <p class="privacy-paragraph">
        To help us better understand your use of the Services, we may use third-party web
        analytics on our Services, such as Google Analytics. These service providers use the
        sort of technology described in the "Automatically-Collected Information" Section above.
        The information collected by this technology will be disclosed to or collected directly by
        these service providers, who use the information to evaluate our users' use of the
        Services. We also use Google Analytics as described in the following section. To
        prevent Google Analytics from collecting or using your information, you may install the
        Google Analytics Opt-Out Browser add-on.
        <br>
    </p>
    <h1 class="privacy-heading">Tailored Advertising</h1>
    <p class="privacy-paragraph">
        Third parties whose products or services are accessible or advertised via the Services
        may also use cookies or similar technologies to collect information about your use of the
        Services. This is done in order to help them-
        <br>
        ● Inform, optimize, and serve ads based on past visits to our website and other
        sites and
        <br>
        ● Report how our ad impressions, other uses of ad services, and interactions with
        these ad impressions and ad services are related to visits to our website. We
        also allow other third parties (e.g., ad networks and ad servers such as Google
        Analytics, OpenX, Pubmatic, DoubleClick and others) to serve tailored ads to you
        on the Services, and to access their own cookies or similar technologies on your
        computer, mobile phone, or other device you use to access the Services. We
        neither have access to, nor does this Privacy Policy govern, the use of cookies or
        other tracking technologies that may be placed by such third parties. These
        parties may permit you to opt out of ad targeting. If you are interested in more
        information about tailored browser advertising and how you can generally control
        cookies from being put on your computer to deliver tailored advertising (i.e., not
        just for the Services), you may visit the Network Advertising Initiative's Consumer
        Opt-Out Link, and/or the Digital Advertising Alliance's Consumer Opt-Out Link to
        opt-out of receiving tailored advertising from companies that participate in those
        programs. To opt out of Google Analytics for Display Advertising or customize
        Google Display Network ads, you can visit the Google Ads Settings page. Please
        note that to the extent advertising technology is integrated into the Services, you
        may still receive ads even if you opt-out of tailored advertising. In that case, the
        ads will just not be tailored to your interests. Also, we do not control any of the
        above opt-out links and are not responsible for any choices you make using
        these mechanisms or the continued availability or accuracy of these
        mechanisms.
        <br>
        When accessing the Services from a mobile application you may also receive tailored
        in-application advertisements. Each operating system: iOS, Android and Windows
        Phone provides its own instructions on how to prevent the delivery of tailored
        in-application advertisements. You may review the support materials and/or the privacy
        settings for the respective operating systems in order to opt-out of tailored in-application
        advertisements. For any other devices and/or operating systems, please visit the
        privacy settings for the applicable device or operating system or contact the applicable
        platform operator.
        <br>
    </p>
    <h1 class="privacy-heading">Choices about how we use and disclose your information</h1>
    <p class="privacy-paragraph">
        We strive to provide you with choices regarding the personal information you provide to
        us. You can set your browser or mobile device to refuse all or some browser cookies, or
        to alert you when cookies are being sent. To learn how you can manage your Flash
        cookie settings, visit the Flash player settings page on Adobe's website. If you disable
        or refuse cookies, please note that some parts of our Services may then be inaccessible
        or not function properly. We do not share your personal information with any advertising
        agency.
        <br>
    </p>
    <h1 class="privacy-heading">Communication choices</h1>
    <p class="privacy-paragraph">
        When you sign up for an account, you are opting in to receive emails from other Isthara
        users, businesses, and Isthara itself. You can log in to manage your email preferences
        and follow the "unsubscribe" instructions in commercial email messages, but note that
        you cannot opt out of receiving certain administrative policy, service policy, or legal
        policy from Isthara.
        <br>
    </p>
    <h1 class="privacy-heading">Reviewing, changing or deleting information</h1>
    <p class="privacy-paragraph">
        If you would like to review, change or delete personal information we have collected
        from you, or permanently delete your account, please use the "Contact Us" link at the
        bottom of every page (also located here), or contact the Isthara Data Protection Officer
        (DPO).
        <br>
        If you delete your User Contributions from our websites, copies of your User
        Contributions may remain viewable in cached and archived pages, or might have been
        copied or stored by other users of our websites. Proper access and use of information
        provided on our websites, including User Contributions, is governed by our Terms of
        Use        
        <br>
    </p>
    <h1 class="privacy-heading">Accessing & correcting your personal information</h1>
    <p class="privacy-paragraph">
        We will take reasonable steps to accurately record the personal information that you
        provide to us and any subsequent updates.
        <br>
        We encourage you to review, update, and correct the personal information that we
        maintain about you, and you may request that we delete personal information about you
        that is inaccurate, incomplete, or irrelevant for legitimate purposes, or are being
        processed in a way which infringes any applicable legal requirement.
        Your right to review, update, correct, and delete your personal information may be
        limited, subject to the law of your jurisdiction:
        <br>
        ● If your requests are abusive or unreasonably excessive,
        <br>
        ● Where the rights or safety of another person or persons would be encroached
        upon, or
        <br>
        ● If the information or material you request relates to existing or anticipated legal
        proceedings between you and us, or providing access to you would prejudice
        negotiations between us or an investigation of possible unlawful activity. Your
        right to review, update, correct, and delete your information is subject to our
        records retention policies and applicable law, including any statutory retention
        requirements.
        <br>
    </p>
    <h1 class="privacy-heading">Security: How we protect your information</h1>
    <p class="privacy-paragraph">
        We have implemented appropriate physical, electronic, and managerial procedures to
        safeguard and help prevent unauthorized access to your information and to maintain
        data security. These safeguards take into account the sensitivity of the information that
        we collect, process and store and the current state of technology. We follow generally
        accepted industry standards to protect the personal information submitted to us, both
        during transmission and once we receive it. The third party service providers with
        respect to payment gateway and payment processing are all validated as compliant with
        the payment card industry standard (generally referred to as PCI compliant service
        providers).
        <br>
        We assume no liability or responsibility for disclosure of your information due to errors in
        transmission, unauthorized third-party access, or other causes beyond our control. You
        play an important role in keeping your personal information secure. You should not
        share your user name, password, or other security information for your Isthara account
        with anyone. If we receive instructions using your user name and password, we will
        consider that you have authorized the instructions.
        <br>
    </p>
    <h1 class="privacy-heading">Permissible Age</h1>
    <p class="privacy-paragraph">
        The Services are not intended for users under the age of 16, unless permitted under
        applicable local laws (Permissible Age). We do not knowingly collect any personal
        information from users or market to or solicit information from anyone under the
        Permissible Age. If we become aware that a person submitting personal information is
        under the Permissible Age, we will delete the account and any related information as
        soon as possible. If you believe we might have any information from or about a user
        under the Permissible Age, please contact us at privacy@Isthara.com.        
        <br>
    </p>
    <h1 class="privacy-heading">Third party links and services</h1>
    <p class="privacy-paragraph">
        The Services may contain links to third-party websites. Your use of these features may
        result in the collection, processing or sharing of information about you, depending on
        the feature. Please be aware that we are not responsible for the content or privacy
        practices of other websites or services which may be linked on our services. We do not
        endorse or make any representations about third-party websites or services. Our
        Privacy Policy does not cover the information you choose to provide to or that is
        collected by these third parties. We strongly encourage you to read such third parties'
        privacy policies.
        <br>
    </p>
    <h1 class="privacy-heading">Data retention and account termination</h1>
    <p class="privacy-paragraph">
        You can close your account by sending email to us on privacy@Isthara.com. We will
        remove your public posts from view and/or dissociate them from your account profile,
        but we may retain information about you for the purposes authorized under this Privacy
        Policy unless prohibited by law. Thereafter, we will either delete your personal
        information or de-identify it so that it is anonymous and not attributed to your identity.
        For example, we may retain information to prevent, investigate, or identify possible
        wrongdoing in connection with the Service or to comply with legal obligations.
        <br>
    </p>
    <h1 class="privacy-heading">Job applicants</h1>
    <p class="privacy-paragraph">
        If your information is submitted to us through our Service when applying for a position
        with our company, the information will be used to consider your application. We may
        retain your information for any period of time. This information may be shared with other
        companies for the purpose of evaluating your qualifications for the particular position or
        other available positions, as well as with third-party service providers retained by us to
        collect, maintain and analyze candidate submissions for job postings. For more details
        please refer to Privacy Policy at our careers page.
        <br>
    </p>
    <h1 class="privacy-heading">Changes to this privacy policy</h1>
    <p class="privacy-paragraph">
        We reserve the right to amend this Privacy Policy from time to time to reflect changes in
        the law, our data collection and use practices, the features of our services, or advances
        in technology. Please check this page periodically for changes. Use of information we
        collect is subject to the Privacy Policy in effect at the time such information is used. If we
        make any material changes to this Privacy Policy, we will post the changes here. Please
        review the changes carefully. Your continued use of the Services following the posting of
        changes to this Privacy Policy will constitute your consent and acceptance of those
        changes.
        <br>
    </p>
    <h1 class="privacy-heading">Contact Us</h1>
    <p class="privacy-paragraph">
        If you have any queries relating to the processing/ usage of information provided by you
        or Isthara's Privacy Policy, you may email the Data Protection Officer (DPO) at
        privacy@Isthara.com or write to us at the following address.
        <br>
    </p>
    <h1 class="privacy-heading">For Users residing in any other part of the world:</h1>
    <p class="privacy-paragraph">
        Isthara Parks Private Limited
        <br>
        Third Floor, Ayyappa Arcade, Janardana Hills,
        <br>
        Lumbini Avenue, Gachibowli, Hyderabad - 500032, Telangana, India
        <br>
    </p>
    </div>
    <app-footer></app-footer>
</div>
