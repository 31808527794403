<section>
  <div class="main-section">
    <div class="main-img">
      <user-flow-top-navbar></user-flow-top-navbar>
    </div>
    <div class="search-div">
      <div class="search-text-heading" align="center">
        <p class="sub-heading">Coliving in</p>
        <p class="city" *ngIf="addCity != ''">{{ addCity | titlecase}}</p>
        <p class="city" *ngIf="addCity == ''">
          Please Search By City / Locality
        </p>
      </div>
      <div class="search-box">
        <div class="search">
          <user-flow-locality-auto-complete
            class="search-box-auto"
          ></user-flow-locality-auto-complete>
          <!-- <div class="search-img" (click)="search()">
            <img src="./../../../../assets/images/popup-images/Search.png" />
          </div> -->
          <div class="btn-box">
            <div class="theme-btn" (click)="nearBy()">
              <span class="search-icon">
                <img
                  src="./../../../../assets/images/home/nearby.svg"
                  alt="nearby"
                />
              </span>
              <!-- <div class="nearby-text">Near By</div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="list-property mt-5">
      <user-flow-list-property></user-flow-list-property>
    </div>
  </div>
  <div class="footer">
    <app-footer></app-footer>
  </div>
</section>
