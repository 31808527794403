import { CareersComponent } from './components/shared/careers/careers.component';
import { ReferAndEarnComponent } from './components/shared/refer-and-earn/refer-and-earn.component';
import { FaqsComponent } from './components/static/faqs/faqs.component';
import { SavedPlaceComponent } from './components/shared/saved-place/saved-place.component';
import { ContactUsComponent } from './components/shared/contactus/contact-us/contact-us.component';
import { ProfileUserComponent } from './components/shared/profile/profile-user/profile-user.component';
import { AboutUsComponent } from './components/static/about-us/about-us.component';
import { CommunityComponent } from './components/static/community/community.component';
import { PropDetailsComponent } from './components/property-details/prop-details/prop-details.component';
import { MainPageComponent } from './components/home-page/main-page/main-page.component';
import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SearchPropertyBoardComponent } from './components/list-properties/search-property-board/search-property-board.component';
import { PromisesTabsComponent } from './components/home-page/promises-tabs-details/promises-tabs/promises-tabs.component';
import { KycComponent } from './components/payments/kyc/kyc.component';
import { FavouriteComponent } from './components/favourite/favourite/favourite.component';
import { PrivacyPolicyComponent } from './components/static/privacy-policy/privacy-policy.component';
import { SigninSignupFlowComponent } from './components/authentication/signin-signup-flow/signin-signup-flow.component';
import { MyVisitsComponent } from './components/visit/my-visits/my-visits.component';
import { LoftComponent } from './components/static/loft/loft.component';
import { MyBookingsComponent } from './components/booking/my-bookings/my-bookings.component';
import { BookingDetailsComponent } from './components/booking/booking-details/booking-details.component';
import { TermsAndConditionsComponent } from './components/static/terms-and-conditions/terms-and-conditions.component';
import { ListYourPropertyComponent } from './components/shared/Partnership/list-your-property/list-your-property.component';
import { RegisterYourServiceComponent } from './components/shared/Partnership/register-your-service/register-your-service.component';
import { ShowcaseYourTalentComponent } from './components/shared/Partnership/showcase-your-talent/showcase-your-talent.component';
import { CorporateConnectComponent } from './components/shared/Partnership/corporate-connect/corporate-connect.component';
import { FoodCourtComponent } from './components/static/food-court/food-court.component';
import { CarouselUserComponent } from './components/shared/carousel/carousel-user/carousel-user.component';
import { UserGuard } from './components/interceptor-guard/user.guard';
import { AllJobsComponent } from './components/shared/careers/all-jobs/all-jobs.component';
import { JobDetailsComponent } from './components/shared/careers/job-details/job-details.component';
import { PropDetailsFromErpComponent } from './components/property-details/prop-details-from-erp/prop-details-from-erp.component';
import { PrivacyPoliciesComponent } from './components/static/privacy-policies/privacy-policies.component';
const routes: Routes = [
  { path: '', component: MainPageComponent },
  // { path: 'list', redirectTo:'listcheck',pathMatch:'full'},
  {path:'list',component:SearchPropertyBoardComponent},
  
  // {path:':city/:locality',component:SearchPropertyBoardComponent},
  { path: 'detail/:propId', component: PropDetailsComponent },
  { path: 'fromErp/details/:propId', component:PropDetailsFromErpComponent},
  { path: 'promises-tabs/:type', component: PromisesTabsComponent },
  { path: 'community', component: CommunityComponent },
  { path: 'aboutus', component: AboutUsComponent },
  { path: 'kyc-documentation', component: KycComponent },
  { path: 'favourite', component: FavouriteComponent },
  { path: 'profile', component: ProfileUserComponent },
  { path: 'contactus', component: ContactUsComponent },
  // { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'privacy-policy', component: PrivacyPoliciesComponent },
  { path: 'signin', component: SigninSignupFlowComponent },
  { path: 'myVisits', component: MyVisitsComponent },
  { path: 'student-living', component: LoftComponent },
  { path: 'savedPlaces', component: SavedPlaceComponent },
  {
    path: 'myBookings',
    canActivate: [UserGuard],
    component: MyBookingsComponent,
  },
  { path: 'success', component: BookingDetailsComponent },
  { path: 'termsandconditions', component: TermsAndConditionsComponent },
  { path: 'listyourProperty', component: ListYourPropertyComponent },
  { path: 'registerYourService', component: RegisterYourServiceComponent },
  { path: 'showcaseTalent', component: ShowcaseYourTalentComponent },
  { path: 'faq', component: FaqsComponent },
  { path: 'corporate-connect', component: CorporateConnectComponent },
  { path: 'ReferNearn', component: ReferAndEarnComponent },
  { path: 'careers', component: CareersComponent },
  { path: 'food-court', component: FoodCourtComponent },
  { path: 'test', component: CarouselUserComponent },
  { path: 'allJobs', component: AllJobsComponent},
  { path: 'details',component:JobDetailsComponent},
  {path:'NearBy/:lat/:long',component:SearchPropertyBoardComponent},
  {path:':city',component:SearchPropertyBoardComponent},
  {path:':city/:locality',component:SearchPropertyBoardComponent},
  {path:':city/Gender/:gender',component:SearchPropertyBoardComponent},
  { path: ':city/:locality/:propertyType/:propertyName', component: PropDetailsComponent },
  { path: ':city/:locality/:propertyName', component: PropDetailsComponent },
  { path: 'fromErp/:city/:locality/:propertyType/:propertyName', component:PropDetailsFromErpComponent},
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
