import { MapSearchLibService } from './../../../service/map-search/map-search-lib.service';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { HostListener } from '@angular/core';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { PropertyDetailsService } from './../../property-details/property-details.service';
import { PopupService } from './../../popup-components/popup.service';
import { SearchBarComponent } from '../../popup-components/popups/search-bar/search-bar.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { isPlatformBrowser } from '@angular/common';

declare let $: any;
@Component({
  selector: 'user-flow-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.scss', './mq-main-page.component.scss'],
})
export class MainPageComponent implements OnInit {
  addressSearch: any;
  loader: boolean = false;
  propertyList: any[];
  constructor(
    private metaService: Meta,
    private _route: ActivatedRoute,
    private propService: PropertyDetailsService,
    private popupService: PopupService,
    private map: MapSearchLibService,
    private router: Router,
    private titleService: Title,
    private _snackBar: MatSnackBar,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.getScreenSize();
  }
  offeringList = [
    {
      couponImageUrl: './../../../../../assets/images/offers/check.png',
      couponCode: 'ISTH007',
      couponName: 'WINTER OFFER',
      activeLocations: ['hyderabad', 'bangaluru'],
      validFrom: new Date(),
      couponType: 'special offer',
      description: {
        bookingType: 'flat',
        bookingDiscount: '90',
        monthlyType: 'upto',
        monthlyDiscount: '50',
      },
    },
    {
      couponImageUrl: './../../../../../assets/images/offers/check.png',
      couponCode: 'ISTH001',
      couponName: 'SUMMER OFFER',
      activeLocations: ['hyderabad', 'bangaluru'],
      validFrom: new Date(),
      couponType: 'special offer',
      description: {
        bookingType: 'flat',
        bookingDiscount: '90',
        monthlyType: 'upto',
        monthlyDiscount: '50',
      },
    },
  ];
  offeringWidth = '35';
  scrHeight: any;
  scrWidth: any;

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    this.offeringWidth = String(Number(this.scrWidth) * 0.31);
    // console.log(this.scrHeight, this.scrWidth);
  }
  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.getHome();

      //hard restart website
      this.router.routeReuseStrategy.shouldReuseRoute = function () {
        return false;
      };
      this.router.events.subscribe((evt) => {
        if (evt instanceof NavigationEnd) {
          // trick the Router into believing it's last link wasn't previously loaded
          this.router.navigated = false;
          // if you need to scroll back to top, here is the right place
          window.scrollTo(0, 0);
        }
      });

      window.scroll(0, 0);
    }
  }
  home: any;
  carosouelSection: any;
  locality: any[];
  getHome() {
    this.loader = false;
    this.propService.home().subscribe(
      (homeData: any) => {
        this.home = homeData.data;
        this.carosouelSection = this.home?.carosouelSection;
        this.locality = this.home?.locationsSection.locations[0].locality;
        this.metaService.removeTag("name='description'");
        this.propertyList =
          this.home?.premiumPropertySection.premiumPropertyList;
        this.titleService.setTitle(
          "Isthara Coliving - India's leading urban living platform"
        );
        this.metaService.addTag({
          name: 'description',
          content:
            'Your home away from awaits you with modern amenities & safety living | Student Living',
        });
        this.metaService.addTag({
          name: 'keywords',
          content:
            'Student living, Coliving Space in Hyderabad, Coliving pg in hyderabad, Best mens pg, Girls pg near me, PG near me, Boys pg near me, Gents pg near me, Coliving, Shared apartments, Food courts near me, Food court near me',
        });
        // console.log("title",this.carosouelSection)
        // console.log("title-case",this.carosouelSection.title)
        // console.log("home Data", homeData)
      },
      (err: any) => {},
      () => {
        if (this.home) {
          this.loader = true;
        }
      }
    );
  }
  getAddress(place: any) {
    this.addressSearch = place;
    // console.log(place);
  }

  openVisit5() {
    if (this.scrWidth < 476) {
      let dialog = this.popupService.openDialog(SearchBarComponent, {
        data: 'data',
        width: '320px',
        height: '350px',
      });
    } else {
      let dialog = this.popupService.openDialog(SearchBarComponent, {
        data: 'data',
        width: '75%',
        height: '90%',
      });
    }
  }
  lat;
  lng;
  nearBy() {
    if ('geolocation' in navigator) {
      this.getCoords().then((position: any) => {
        if (position) {
          this.map.findAddressByCoordinates(
            (this.lat = position.latitude),
            (this.lng = position.longitude)
          );
          this.addressSearch = {
            lat: Number(this.lat),
            lng: Number(this.lng),
          };
          this.router.navigate([`/NearBy`,`${Number(this.lat)}`,`${Number(this.lng)}`]);
        } else {
          this._snackBar.open('Turn the location on in your browser!', 'Close');
        }
      });
    } else {
      console.log("location not supported!");
      
    }
  }

  getCoords() {
    return new Promise((resolve, reject) =>
      navigator.permissions
        ? // Permission API is implemented
          navigator.permissions
            .query({
              name: 'geolocation',
            })
            .then((permission) =>
              // is geolocation granted?
              permission.state === 'granted'
                ? navigator.geolocation.getCurrentPosition((pos) =>
                    resolve(pos.coords)
                  )
                : resolve(null)
            )
        : // Permission API was not implemented
        resolve(null)
          // reject(new Error('Permission API is not supported'))
    );
  }
}
