import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {environment} from './../../../environments/environment';
import { DbStorageService } from '../../service/headers/db-storage/db-storage.service';
@Injectable({
  providedIn: 'root'
})
export class PaymentServiceService {

  constructor(
    private http : HttpClient,
    private _dbStorage: DbStorageService
  ) { }

  userDetails(userDetailsdata){
    const token = this._dbStorage.getToken()
    var headers_object = new HttpHeaders().set("Authorization", "Bearer " + token);
    return this.http.post(
      environment.authenticationUrl+'user/userdetails/updateUserDetails',userDetailsdata,
      {headers: headers_object}
    );
  }

  getUser(id){
    return this.http.get(
      environment.authenticationUrl+`user/userdetails/${id}`
    )
  }
}   
