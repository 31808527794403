import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'user-flow-smart-stay-application',
  templateUrl: './smart-stay-application.component.html',
  styleUrls: ['./smart-stay-application.component.scss','./mq-smart-stay-application.component.scss'],
})
export class SmartStayApplicationComponent implements OnInit {
  @Input() smartStay : any
  serviceBox = {
    name: 'find your',
    subText: 'smart stay now',
    description:
      'Life made easier at Isthara. Get on the app and get everything done, right from payments to updates from Isthara. Stay connected on the go!',
  };
  constructor() {}

  ngOnInit(): void {
    this.serviceBox = this.serviceBox;
  }
}
