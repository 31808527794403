import { Component, OnInit ,Inject,Output,  
  EventEmitter,HostListener} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PopupService } from '../../popup-components/popup.service';
import { SearchBarComponent } from '../../popup-components/popups/search-bar/search-bar.component';
// import { PropertyDetailsService } from '../../property-details/property-details.service';
import { BookingPopUserComponent } from '../booking-pop-user/booking-pop-user.component';
import { Router } from '@angular/router';

@Component({
  selector: 'user-flow-suggest-booking',
  templateUrl: './suggest-booking.component.html',
  styleUrls: ['./suggest-booking.component.scss','./mq-suggest-booking.component.scss']
})
export class SuggestBookingComponent implements OnInit {
 property=[];
 suggestproperty=[];
 selectsuggestproperty:any;
//  suggestedPropertyId:any;
//  @Output() suggestedPropertyId = new EventEmitter <any>();
  constructor(
    private popupService: PopupService,
    @Inject(MAT_DIALOG_DATA) public data,
    // private propertyDetailsService: PropertyDetailsService,
    public dialog: MatDialog,
    private router: Router,
    public dialogRef: MatDialogRef<SuggestBookingComponent >,
    // private dialogRef1 : MatDialogRef<BookingPopUserComponent>,
  ) { }
  offeringWidth = '35';
  scrHeight: any;
  scrWidth: any;

  nextLevel;
  ngOnInit(): void {
    // console.log(this.dialogRef);
    // console.log(this.dialogRef1)
    // console.log("data",this.data);
    // console.log("data",this.data)
    // console.log("ref",this.data.type);
    
    if(this.data.data.property.propertyType == 'MENS'){
      this.nextLevel = 'WOMENS'
    }
    else{
      this.nextLevel = 'MENS'
    }
  }
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    this.offeringWidth = String(Number(this.scrWidth) * 0.31);
    // console.log(this.scrHeight, this.scrWidth);
  }
  closeBtn() {
    this.dialogRef.close();
  }
  
  searchBar(){
    this.router.navigate(['']);
    this.closeBtn();
    this.data.type.close();
      if (this.scrWidth < 476) {
        let dialog = this.popupService.openDialog(SearchBarComponent, {
          data: 'data',
          width: '320px',
          height: '350px',
        });
      } else {
        let dialog = this.popupService.openDialog(SearchBarComponent, {
          data: 'data',
          width: '70%',
          height: '700px',
        });
      }
  }
}

