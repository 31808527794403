<section>
  <div class="loft-details">
    <user-flow-top-navbar></user-flow-top-navbar>
  </div>
  <div class="main">
    <div class="first-section" *ngFor="let ss of studentSection">
      <div class="student-living">
        <span class="first-heading">
          {{ ss.heading }}
        </span>
        <br />

        <span class="second-heading">{{ ss.headingTwo }}</span>
        <p class="student-text">{{ ss.desc }}</p>
        <div class="video" (click)="showvideo()">
          <div class="video" (click)="showvideo()"></div>
          <img src="{{ ss.icon }}" alt="" width="41px" height="41px" loading="lazy"/>
          <p class="v-text">{{ ss.desc1 }}</p>
        </div>
      </div>
      <img src="{{ ss.img }}" alt="" width="50%" loading="lazy"/>
    </div>
    <div class="second-section" *ngFor="let ss of transformedLiving">
      <div class="transformed-living">
        {{ ss.text }}
        <p class="text">{{ ss.heading }}</p>

        <p class="sub-text1">{{ ss.desc }}</p>
        <p class="sub-text1">{{ ss.desc1 }}</p>
        <p class="sub-text1">{{ ss.desc2 }}</p>
      </div>
      <img src="{{ ss.img }}" alt="" width="100%" loading="lazy"/>
    </div>
    <div class="third-section" *ngFor="let q of question">
      <div class="isthara">
        {{ q.text }}
        <p class="tag">{{ q.heading }}</p>
        <div class="isthara-text">
          <b>{{ q.desc }}</b>
        </div>
      </div>
      <div class="right-secimg">
        <ng-container
          *ngFor="let firstRow of amenities[0].firstRowImages.images"
        >
          <div class="ist-icon">
            <img
              class="icon-img"
              id="{{ firstRow.img }}"
              [src]="firstRow.img"
              loading="lazy"
            />
            <p class="img-text">{{ firstRow.name }}</p>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="isthara-tech">
      <div class="text-section">
        <div class="bigText">Isthara Tech</div>
        <div class="smallText">We digitise student living by automating  everyday tasks and  communication within universities, 
          schools and student housing communities, in one workspace.</div>
        <div class="icon-section">
          <a href="https://play.google.com/store/apps/details?id=com.isthara.loft" target="_blank" class="playstore">
            <img src="./../../../../assets/images/loft/playstore.svg" alt="">
          </a>
          <a href="https://apps.apple.com/in/app/loft-by-isthara/id1543870712" target="_blank" class="appstore">
            <img src="./../../../../assets/images/loft/appstore.svg" alt="">
          </a>
        </div>
      </div>
      <div class="image-section">
        <img src="./../../../../assets/images/loft/istharatech.svg" alt="" loading="lazy">
      </div>
    </div>
    <div class="screenIcon-section">
      <div class="icons">
          <img src="./../../../../assets/images/loft/groupIcons.svg" alt="" loading="lazy">
      </div>
      <div class="screen-shot">
        <img src="./../../../../assets/images/loft/screenicon.svg" alt="" loading="lazy">
      </div>
    </div>

    <div class="fifth-section">
      <div>
        <p class="live">Live</p>
        <p class="easy">Comfortably And Stay Happy</p>
      </div>
      <div class="blog">
        <ng-container *ngFor="let b of blog; let i = index">
          <div *ngIf="i == 0" class="eat-box firstbox">
            <div>
              <img src="{{ b.img }}" alt="" loading="lazy"/>
            </div>
            <div class="v-eat">
              <p class="eat">{{ b.subHead }}</p>
              <p>{{ b.desc }}</p>
              <p>{{ b.desc1 }}</p>
              <p>{{ b.desc2 }}</p>
            </div>
          </div>
          <div *ngIf="i == 1" class="eat-box secondBox">
            <div>
              <img src="{{ b.img }}" alt="" loading="lazy"/>
            </div>
            <div class="v-eat">
              <p class="eat">{{ b.subHead }}</p>
              <p>{{ b.desc }}</p>
              <p>{{ b.desc1 }}</p>
              <p>{{ b.desc2 }}</p>
            </div>
          </div>
          <div *ngIf="i == 2" class="eat-box thirdBox">
            <div>
              <img src="{{ b.img }}" alt="" loading="lazy"/>
            </div>
            <div class="v-eat">
              <p class="eat">{{ b.subHead }}</p>
              <p>{{ b.desc }}</p>
              <p>{{ b.desc1 }}</p>
              <p>{{ b.desc2 }}</p>
            </div>
          </div>
          <div *ngIf="i == 3" class="eat-box fourthBox">
            <div>
              <img src="{{ b.img }}" alt="" loading="lazy"/>
            </div>
            <div class="v-eat">
              <p class="eat">{{ b.subHead }}</p>
              <p>{{ b.desc }}</p>
              <p>{{ b.desc1 }}</p>
              <p>{{ b.desc2 }}</p>
            </div>
          </div>
        </ng-container>
      </div>
    </div>

    <div class="sixth-section" *ngFor="let e of experience">
      <div class="create-text">
        <p class="live">{{ e.text }}</p>
        <p class="easy">{{ e.heading }}</p>
        <div class="memo-text">{{ e.desc }}</div>
      </div>
      <img src="{{ e.img }}" alt="" width="50%" loading="lazy"/>
    </div>
    <div class="seventh-section">
      <div>
        <p class="idea">Would like to implement this in your college?</p>
        <p class="help">Let us help you</p>
        <div class="add-box">
          <button class="theme-btn" (click)="changepath('/contactus')">
            Contact Us
          </button>
          <!-- <div class="add-search">
            <div class="text-box">
              <input placeholder="Add Contact Details" />
            </div>
            <div class="btn-box">
              <button class="theme-btn">Send</button>
            </div>
          </div> -->
        </div>
      </div>
      <div class="add-box">
        <div class="event" align="right">
          <img src="./../../../../assets/images/loft/enjoy.png" alt="" loading="lazy"/>
        </div>
      </div>
      <!-- <div class="event" align="right">
                <img src="./../../../../assets/images/loft/enjoy.png" alt="">
            </div> -->
    </div>
  </div>
  <div>
    <app-footer></app-footer>
  </div>

  <div *ngIf="showMe" id="div22" class="video-popup">
    <div (click)="closeBtn()" class="closebtn">X</div>
    <iframe
      width="860"
      height="515"
      src="https://www.youtube.com/embed/77b_5vfletY"
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
  </div>
</section>
