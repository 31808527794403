<section>
    <div class="promisesTab-page">
        <div class="promises-section">
            <div class="top-navbar">
                <user-flow-top-navbar></user-flow-top-navbar>
            </div>
            <div  class="promises-heading-section" *ngFor="let pthSection of headingSection">
                <div class="promises-heading-image" *ngIf="pthSection.type == path">
                    <img src="{{pthSection.img}}" alt="head-image" class="head-image">
                </div>
                <div class="promises-heading" *ngIf="pthSection.type == path">
                    <p class="heading">{{pthSection.heading1}}</p>
                    <p class="heading">{{pthSection.heading2}}</p>
                    <p class="heading">{{pthSection.heading3}}</p>
                </div>
            </div>
            <div  *ngFor="let bb of promisesTabs.bigBox let i = index;">
            <div class="promises-big-box" *ngIf="bb.type == path" [ngStyle]="bb.style">
                <div class="big-box-heading">
                    <p class="big-text">{{bb.title}}</p>
                    <p class="small-text">{{bb.sub}}</p>
                </div>
                <div class="img_desc-section" *ngFor="let item of bb?.content">   
                    <div *ngIf="item.type == path">
                        <span><img  src="{{item.img}}" alt="image"></span>
                        <span class="description">{{item.desc}}</span> 
                    </div>
                </div>
            </div>
            </div>
            <div *ngFor="let smb of promisesTabs.smBox let i = index">
            <div  class="promises-big-box" [ngStyle]="smb.style" *ngIf="smb.type == path">
                <div class="big-box-heading" >
                    <p class="big-text">{{smb.title}}</p>
                </div>
                <div class="smbox-desc-section" *ngFor="let content of smb?.content" align="center">
                    <div *ngIf="content.type == path">
                        <p><img [src]="content.img" alt=""></p>
                        <p class="description">{{content.desc}}</p>
                    </div>
                </div>
            </div>
            </div>
            <div *ngFor="let fb of promisesTabs.finalBox">
            <div class="promises-big-box" *ngIf="fb.type == path" [ngStyle]="fb.style">
                <div class="big-box-heading">
                    <p class="big-text">{{fb.title}}</p>
                </div>
                <div class="final-desc-section" *ngIf="fb.type == path">
                    <p style="flex-basis:20%" align="center"><img src="{{fb.img}}" alt=""></p>
                    <div class="description" style="flex-basis: 80%;">
                       <p>{{fb.desc1}}</p>
                       <p> {{fb.desc2}}</p>
                       <p> {{fb.desc3}}</p>
                       <p> {{fb.desc4}}</p>
                       <p> {{fb.desc5}}</p>
                    </div>
                </div>
            </div>
            </div>
            <div *ngFor="let vv of promisesTabs.videovisit let i = index">
                <div class="promises-big-box vvideo-box" *ngIf="vv.type == path" [ngStyle]="vv.style">
                    <div class="big-box-heading" align="center">
                        <p class="big-text">{{vv.title}}</p>
                    </div>
                    <div class="vvideo" *ngFor="let some of vv?.content">
                        <div class="final-desc-section" *ngIf="vv.type == path">
                            <span class="vimage"><img src="{{some.img}}" alt=""></span>
                            <span class="description">{{some.desc}}</span>
                        </div>
                    </div>
                    <div class="button">
                        <button  class="visit-button" (click)="openVisit()">Schedule A Visit</button>
                    </div>
                </div>
            </div>
            <div class="container final-text" *ngFor="let fData of finalData"> 
                <div *ngIf="fData.type == path">
                {{fData.fd}}
                </div>
            </div>
        </div>
    </div>
    <div class="footer">
        <app-footer></app-footer>
    </div>
</section>