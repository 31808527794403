<!-- <section> -->
<div class="close-x" (click)="closeBtn()">
  <img src="./../../../../assets/images/popup-images/cross-mark.png" />
</div>
<div
  class="section"
  *ngIf="suggestedResponseData == false; else suggestedBooking"
>
  <br />
  <mat-accordion class="mt-5">
    <!-- <mat-expansion-panel
      class="background-heading"
      [expanded]="step === 0"
      (opened)="setStep(0)"
    >
      <mat-expansion-panel-header>
        <mat-panel-title> Login Details </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="logging-section">
        <div *ngIf="data.user.mobile; else notLoggedIn">
          <p class="logged-msg">You have been logged in with</p>
          <p class="logged-data">
            {{ data?.user?.mobile }} <span class="log-btn">Logout</span>
          </p>
        </div>
        <ng-template #notLoggedIn>
          <p class="logged-msg">
            You Not a Logged in User <span class="log-btn">Login</span>
          </p>
        </ng-template>
      </div>
      <mat-action-row>
        <button class="continue-btn" (click)="nextStep()">Continue</button>
      </mat-action-row>
    </mat-expansion-panel> -->

    <mat-expansion-panel
      class="background-heading"
      [expanded]="step === 0"
      (opened)="setStep(0)"
    >
      <mat-expansion-panel-header class="mat-header-text">
        <mat-panel-title> Select Room Category </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="room-sections">
        <user-flow-room-list
          [roomList]="data.property.roomType"
          [selection]="true"
          (roomSelected)="selectedRoom($event)"
          (click)="clicked()"
        ></user-flow-room-list>
      </div>
      <mat-action-row>
        <button class="continue-btn" (click)="nextStep()">Continue</button>
      </mat-action-row>
    </mat-expansion-panel>

    <mat-expansion-panel
      class="background-heading"
      [disabled]="this.selectRoom == null || this.selectRoom == ''"
      [expanded]="step === 1"
      (opened)="setStep(1)"
    >
      <mat-expansion-panel-header class="mat-header-text">
        <mat-panel-title> Select Move In Date </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="movein">
        <p class="logged-msg">
          Your rent will start from this date and you can move in any day after
          this. You can not postpone your move-in once booking is done
        </p>
        <div class="date-section">
          <mat-form-field class="form-fields" appearance="outline">
            <mat-label>Choose a date</mat-label>
            <input
              matInput
              [matDatepicker]="picker"
              [(ngModel)]="chooseDate"
              #chooseDateCheck="ngModel"
              [min]="minDate"
              [max]="maxDate"
              required
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-error
              *ngIf="
                !chooseDateCheck.valid &&
                (chooseDateCheck.dirty || chooseDateCheck.touched)
              "
            >
              Please Choose Date
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <mat-action-row>
        <button class="continue-btn" (click)="nextStep1()">
          <span *ngIf="!nextstep1">Continue</span>
          <span *ngIf="nextstep1"><i class="fa fa-spinner fa-spin"></i> &nbsp;Loading</span>
        </button>
      </mat-action-row>
    </mat-expansion-panel>

    <mat-expansion-panel
      class="background-heading"
      [disabled]="this.chooseDate == null || this.chooseDate == ''"
      [expanded]="step === 2"
      (opened)="setStep(2)"
    >
      <mat-expansion-panel-header class="mat-header-text">
        <mat-panel-title> Personal Information </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="info-section">
        <div class="two-sections">
          <div class="two-radio-btns">
            <input
              name0="options"
              type="radio"
              [value]="0"
              [(ngModel)]="fifthSector.in_stock"
            /><span>Booking For Your-self</span>
          </div>
          <div class="two-radio-btns">
            <input
              name1="options"
              type="radio"
              [value]="1"
              [(ngModel)]="fifthSector.in_stock"
            /><span>Booking For Someone Else</span>
          </div>
        </div>
      </div>
      <div class="personal-info" *ngIf="fifthSector.in_stock == 0">
        <mat-form-field appearance="legacy">
          <mat-label>Full Name</mat-label>
          <input
            matInput
            [(ngModel)]="fullName"
            required
            #nameCheck="ngModel"
          />
          <mat-error
            *ngIf="!nameCheck.valid && (nameCheck.dirty || nameCheck.touched)"
          >
            Please enter a valid Full Name
          </mat-error>
        </mat-form-field>
        <br />
        <mat-form-field appearance="legacy">
          <mat-label>Email</mat-label>
          <input
            type="text"
            placeholder="pat@example.com"
            name="email"
            matInput
            pattern="^([_a-z0-9]+(.[_a-z0-9]+)*@[a-z0-9-]+(.[a-z0-9-]+)*(.[a-z]{2,5}))|(d+$)$"
            [(ngModel)]="email"
            #emailCheck="ngModel"
            required
          />
          <mat-error
            *ngIf="
              !emailCheck.valid && (emailCheck.dirty || emailCheck.touched)
            "
          >
            Please enter a valid email address
          </mat-error>
        </mat-form-field>
        <br />
        <mat-form-field appearance="legacy">
          <mat-label>Mobile Number</mat-label>
          <input
            type="text"
            placeholder="Mobile"
            name="mbnumber"
            matInput
            [(ngModel)]="mbnumber"
            #mbnumberCheck="ngModel"
            required
          />
          <mat-error
            *ngIf="
              !mbnumberCheck.valid &&
              (mbnumberCheck.dirty || mbnumberCheck.touched)
            "
          >
            Please enter a valid mobile number
          </mat-error>
        </mat-form-field>
        <br />
        <div>Gender:</div>
        <div class="gender-boxes">
          <ng-container *ngFor="let item of genders; let i = index">
            <mat-radio-button
              class="gender-value"
              (click)="genderSelect(item, i)"
              name="gender"
              [value]="item.value"
              ngDefaultControl
              >{{ item.name }}</mat-radio-button
            >
          </ng-container>
        </div>
      </div>
      <div class="personal-info" *ngIf="fifthSector.in_stock == 1">
        <mat-form-field appearance="legacy">
          <mat-label>Full Name</mat-label>
          <input
            matInput
            [(ngModel)]="other.ofName"
            required
            #fullnameCheck="ngModel"
          />
          <mat-error
            *ngIf="
              !fullnameCheck.valid &&
              (fullnameCheck.dirty || fullnameCheck.touched)
            "
            >Please enter a valid Full Name
          </mat-error>
        </mat-form-field>
        <br />
        <mat-form-field appearance="legacy">
          <mat-label>Email</mat-label>
          <input
            type="text"
            placeholder="pat@example.com"
            name="email"
            matInput
            pattern="^([_a-z0-9]+(.[_a-z0-9]+)*@[a-z0-9-]+(.[a-z0-9-]+)*(.[a-z]{2,5}))|(d+$)$"
            [(ngModel)]="other.ofEmail"
            #emailCheck="ngModel"
            required
          />
          <mat-error
            *ngIf="
              !emailCheck.valid && (emailCheck.dirty || emailCheck.touched)
            "
          >
            Please enter a valid email address
          </mat-error>
        </mat-form-field>
        <br />
        <mat-form-field appearance="legacy">
          <mat-label>Mobiler Number</mat-label>
          <input
            type="text"
            placeholder="Mobile"
            name="mbnumber"
            matInput
            [(ngModel)]="other.ofMobile"
            #mbnumberCheck="ngModel"
            required
          />
          <mat-error
            *ngIf="
              !mbnumberCheck.valid &&
              (mbnumberCheck.dirty || mbnumberCheck.touched)
            "
          >
            Please enter a valid mobile number
          </mat-error>
        </mat-form-field>
        <br />
        <div>Gender:</div>
        <div class="gender-boxes">
          <ng-container *ngFor="let item of othersgender; let i = index">
            <mat-radio-button
              class="gender-value"
              (click)="genderSelect(item, i)"
              name="gender"
              [value]="item.value"
              ngDefaultControl
              >{{ item.name }}</mat-radio-button
            >
          </ng-container>
        </div>
      </div>
      <mat-action-row>
        <button
          class="continue-btn"
          (click)="details()"
          [disabled]="btnDisabled"
          [ngStyle]="{ color: btnDisabled == false ? 'gray' : 'black' }"
          [ngStyle]="{
            'background-color': btnDisabled == false ? '#FF1872' : 'gray'
          }"
        >
          Continue
        </button>
      </mat-action-row>
    </mat-expansion-panel>

    <mat-expansion-panel
      class="background-heading"
      [expanded]="step === 3"
      (opened)="setStep(3)"
      [disabled]="
        this.other.ofEmail == '' ||
        this.other.ofEmail == null ||
        this.other.ofMobile == '' ||
        this.other.ofMobile == null ||
        this.other.ofName == '' ||
        this.other.ofName == null ||
        this.gender == '' ||
        this.gender == null
      "
    >
      <mat-expansion-panel-header class="mat-header-text">
        <mat-panel-title> Payment Information </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="payment-info">
        <p class="logged-msg">
          Choose the payment suit you and save as much you want.
        </p>
        <!-- <div class="info">
  <button class="blocks" *ngFor="let dt of blocks" >
    <div class="rent">{{dt.rent}}</div>
  <div class="price"> &#x20B9;{{dt.price}}</div>
  <div class="block-security">
    {{dt.SecurityDeposit}}
  </div>
  <div class="content">
    {{dt.time}}
  </div>
  <div class="block-discount">
   {{dt.discount}}
  </div>
  </button>
</div> -->
        <div class="check-box" *ngFor="let check of check; let i = index">
          <mat-checkbox
            class="example-margin"
            (change)="isChecked(check, i)"
            [(ngModel)]="check.isActive"
            [disabled]="check.uId == '1'"
          ></mat-checkbox>
          <div class="tokenName">
            {{ check.tokenName }}
          </div>
          <span class="check-price">&#x20B9;{{ check.price }}</span>
        </div>
        <hr />
        <div class="total-amout">
          <div class="total-text">Total Amount to be Paid:</div>
          <div class="total-price">&#x20B9; {{ total }}</div>
        </div>
        <hr />
        <!-- <div>
          <div class="apply-coupon">Apply Coupon</div>
          <mat-form-field appearance="fill">
            <input matInput placeholder="Enter Coupon Code" />
          </mat-form-field>
          <span class="Appl">Apply</span>
        </div> -->
      </div>
      <mat-action-row>
        <button class="continue-btn" (click)="nextStep2()">Continue</button>
      </mat-action-row>
    </mat-expansion-panel>
  </mat-accordion>

  <div *ngIf="showMe">
    <div class="terms-condition">
      <div class="term-head">Terms and Conditions</div>
      <ul class="list-data" *ngFor="let lit of termsList">
        <li>
          <div class="terms">{{ lit.text }}</div>
        </li>
      </ul>
      <div class="terms-line"><hr /></div>
      <div class="accepting">
        <mat-checkbox [(ngModel)]="termsnconditions"
          >I accept all terms and conditions</mat-checkbox
        >
      </div>
    </div>
    <button class="first-bt">Total Amount Rs{{ total }}</button>
    <button class="bt" (click)="pay()">
      <span *ngIf="!payLoader">Pay</span>
      <span *ngIf="payLoader"><i class="fa fa-spinner fa-spin"></i> &nbsp;Loading</span>
    </button>
  </div>
  <!-- </section> -->
</div>

<form id="redirectForm" method="post" [action]="cashFree">
  <!-- test===> https://test.cashfree.com/billpay/checkout/post/submit
  
  production ===> https://www.cashfree.com/checkout/post/submit-->
  <input id="appId" type="hidden" name="appId" [value]="formDataView.appId" />
  <input type="hidden" name="orderId" [value]="formDataView.orderId" />
  <input type="hidden" name="orderAmount" [value]="formDataView.orderAmount" />
  <input type="hidden" name="orderCurrency" [value]="'INR'" />
  <input type="hidden" name="orderNote" [value]="'Booking'" />
  <input
    type="hidden"
    name="customerName"
    [value]="formDataView.customerName"
  />
  <input
    type="hidden"
    name="customerEmail"
    [value]="formDataView.customerEmail"
  />
  <input
    type="hidden"
    name="customerPhone"
    [value]="formDataView.customerPhone"
  />
  <input type="hidden" name="returnUrl" [value]="formDataView.returnUrl" />
  <input type="hidden" name="notifyUrl" [value]="formDataView.notifyUrl" />
  <input type="hidden" name="signature" [value]="formDataView.signature" />
</form>

<!-- suggest properties -->
<section>
  <ng-template #suggestedBooking>
    <div class="suggest-section">
      <div class="pop-header">
        <div class="pop-heading">Book A Property</div>
      </div>
      <div class="property-filled">
        This property is filled but you can book from other property
      </div>
      <div class="card-line">
        <div class="card-section" *ngFor="let prop of property; index as i">
          <div class="total-imagestop">
            <div class="imagestop">
              <img [src]="prop.images[0].imageUrl" alt="" />
              <!-- <user-flow-single-image-carousel
  [imageSet]="prop.images"
  [classId]="'image' + (i + 1)"
>
</user-flow-single-image-carousel> -->
              <!-- <img [src]="prop.images[0].imageUrl"> -->
            </div>
          </div>
          <div class="descrpition">
            <div class="prop-heading">
              {{ prop.propertyName }}
            </div>
            <!-- <p class="prop-heading">{{ prop.propertyName }}</p> -->
            <div class="location-distance">
              <div class="location">
                <img
                  src="./../../../assets/images/property-list/location.png"
                  alt="location"
                />
                {{ prop.propertyAddress.locality }},
                {{ prop.propertyAddress.city }}
              </div>

              <div class="distance">
                <span *ngIf="distance != NaN">{{ prop.distance }} </span>
              </div>
            </div>
            <p class="starting-from">starting from</p>
            <div class="price-fav">
              <div class="price">
                &#x20B9; {{ getMinimum(prop?.roomType) }}/M
              </div>
              <div
                class="distance"
                *ngIf="userId !== 'null' && userId !== null"
              >
                <!-- <img
        class="fav"
        src=" {{
          prop.isFav
            ? './assets/images/property-list/fav.png'
            : './assets/images/property-list/unfav.png'
        }} "
        type="button"
        (click)="addFav(prop.propertyId, prop.isFav, $event)"
        alt="fav"
      /> -->
              </div>
            </div>
          </div>
        </div>
        <div class="line-symbol"></div>
      </div>
      <div class="recomened-prop">Recomended Properties</div>
      <div class="main-listing-box" *ngIf="suggestproperty?.length > 0; else noProperties">
        <ng-container *ngFor="let prop of suggestproperty; index as i">
          <ng-container>
            <div class="prop-box">
              <div class="images">
                <!-- <user-flow-single-image-carousel
              [imageSet]="prop.images"
              [classId]="'image' + (i + 1)"
            >
            </user-flow-single-image-carousel> -->
                <img [src]="prop.images[0].imageUrl" alt="" />
                <!-- <div class="gender-box">
              <img
                [src]="
                  './../../../assets/images/property-list/' +
                  prop.propertyType +
                  '.png'
                "
                alt="gender"
                loading="lazy"
              />
              {{ prop.propertyType.replace("_", " ") }}
            </div> -->
              </div>
              <div class="container-text">
                <div class="prop-heading">
                  {{ prop.propertyName }}
                </div>
                <div class="location-distance">
                  <div class="location">
                    <img
                      src="./../../../assets/images/property-list/location.png"
                      alt="location"
                    />
                    {{ prop.propertyAddress.locality }},
                    {{ prop.propertyAddress.city }}
                  </div>

                  <div class="distance">
                    <span *ngIf="distance != NaN">{{ prop.distance }} Km</span>
                  </div>
                </div>
                <p class="starting-from">starting from</p>
                <div class="price-fav">
                  <div class="price">
                    &#x20B9; {{ getMinimum(prop?.roomType) }}/M
                  </div>
                  <div
                    class="distance"
                    *ngIf="userId !== 'null' && userId !== null"
                  >
                    <!-- <img
                  class="fav"
                  src=" {{
                    prop.isFav
                      ? './assets/images/property-list/fav.png'
                      : './assets/images/property-list/unfav.png'
                  }} "
                  type="button"
                  
                  alt="fav"
                /> -->
                  </div>
                </div>
                <div class="select-property">
                  <button
                    [ngClass]="{ 'selct-btactive': prop.active == true }"
                    class="selct-bt"
                    (click)="selectProperty(prop.propertyId, i)"
                  >
                    Select
                  </button>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
      <ng-template #noProperties>
        <div class="no-suggested-properties">
          <div>
              <img src="././../../../../assets/images/myBookings/nosuggested.svg" alt="">
          </div>
        </div>
        <div class="no-properties-text">
          Suggested properties are not available
        </div>
      </ng-template>
      <div class="continue-booking">
        <button (click)="continueSuggest()" *ngIf="suggestproperty?.length > 0">Continue Booking</button>
      </div>
    </div>
  </ng-template>
</section>
