<div >
    <div class="big-text">OTP Verification</div>
    <div class="sub-text">
        OTP has sent to Mobile Number
    </div>
    <div class="mobile-block">
        <div class="mobile-no">
            9640587505
        </div>
        <div>
            <button>
                Change
            </button>
        </div>
    </div>
    <div class="otp-field">
        <input id="codeBox1" #num1 maxlength="1" (keyup)="move(num1,num2)" [(ngModel)]="verifyotp.otp1">
        <input id="codeBox2" #num2 maxlength="1" (keyup)="move(num2,num3)" [(ngModel)]="verifyotp.otp2">
        <input id="codeBox3" #num3 maxlength="1" (keyup)="move(num3,num4)" [(ngModel)]="verifyotp.otp3">
        <input id="codeBox4" #num4 maxlength="1" (keyup)="move(num4,num5)" [(ngModel)]="verifyotp.otp4">
        <input id="codeBox5" #num5 maxlength="1" (keyup)="move(num5,num6)" [(ngModel)]="verifyotp.otp5">
        <input id="codeBox6" #num6 maxlength="1"  [(ngModel)]="verifyotp.otp6">
    </div>
    <!-- <div class="timer pull-right">
        <countdown #countdown [config]="{leftTime: 10}" (event)="handleCounter($event)">$!s!</countdown>
      </div>                     -->
    <div class="resend-otp">
        <div class="sub-text"></div><button class="resendBtn">
            Resend OTP</button>
    </div>
    <div class="v-btn">
        <button class="verify-btn">Verify Now</button>
    </div>
</div>