import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'user-flow-loft',
  templateUrl: './loft.component.html',
  styleUrls: ['./loft.component.scss', './mq-loft.component.scss'],
})
export class LoftComponent implements OnInit {
  constructor(private router: Router,
    private metaService: Meta,
    private titleService: Title) {}
  cordinatesValue: any = 'Locate';
  addressSearch: any;
  showMe: boolean = false;

  studentSection = [
    {
      heading: 'Experience Student Living',
      headingTwo: 'The Isthara Way',
      desc: 'Introducing a transformed student living experience that brings together comfort and luxury, with reliability and a deep sense of responsibility for the wellbeing of all the students. At Isthara, we are committed to creating a sanctuary, a personal space that feels like a second home.',
      icon: './../../../../assets/images/loft/video.png',
      desc1: 'watch video',
      img: './../../../../assets/images/loft/studentliving.png',
    },
  ];
  transformedLiving = [
    {
      text: "Here's",
      heading: 'Transformed Student Living',
      desc: 'Colleges and Universities have transformed in their curriculum, Teaching methodologies, tools used and even the evaluation process. This Transformation is needed in the Student Living space as well and Isthara is a pioneer in providing this.',
      desc1:
        'Isthara is a platform for a convenient shared community living experience. It is professionally managed and efficient student living facility, empowered by technology and backed by a commitment to provide the best hygiene and hospitality services. We understand that these are the most impactful years of your life that shape your future and we are determined to make them memorable.',
      desc2: '',
      img: './../../../../assets/images/loft/transformedliving.png',
    },
  ];
  question = [
    {
      text: 'The Isthara',
      heading: 'Amenities',
      desc: 'The needs of students for a well-rounded academic, professional, physical and mental growth are huge. The state of the art amenities offered at Isthara are designed with these growing needs in mind.',
      img: '',
      img1: '',
      img2: '',
      img3: '',
    },
  ];
  amenities = [
    {
      text: 'Isthara',
      heading: 'Amenities',
      desc: 'Today the needs of the students for their physical, professional and academic and mental growth are huge. Keeping these in mind the amenities in Isthara are designed around these factors.',
      firstRowImages: {
        name: '1stRow',
        images: [
          {
            img: './../../../../assets/images/loft/food.png',
            name: 'Food',
          },
          {
            img: './../../../../assets/images/loft/hot-water.png',
            name: 'Hot Water',
          },
          {
            img: './../../../../assets/images/loft/cloth.png',
            name: 'Laundry',
          },
          {
            img: './../../../../assets/images/loft/clean.png',
            name: 'House Keeping',
          },
          {
            img: './../../../../assets/images/loft/ro-water.png',
            name: 'Ro Water Supply',
          },

          {
            img: './../../../../assets/images/loft/tv.png',
            name: 'Entertainment',
          },
          {
            img: './../../../../assets/images/loft/wifi.png',
            name: 'Wifi',
          },
          {
            img: './../../../../assets/images/loft/gym.png',
            name: 'Gym',
          },
        ],
      },
      secondRowImages: {
        name: '2nd Row',
        images: [
          {
            img: './../../../../assets/images/loft/ro-water.png',
            activeimg: './assets/images/loft/activero-water.png',
            src: '',
          },

          {
            img: './../../../../assets/images/loft/coffee.png',
            activeimg: './assets/images/loft/active-coffee.png',
            src: '',
          },
          {
            img: './../../../../assets/images/loft/tv.png',
            activeimg: './assets/images/loft/activetv.png',
            src: '',
          },
          {
            img: './../../../../assets/images/loft/wifi.png',
            activeimg: './assets/images/loft/activewifi.png',
            src: '',
          },
        ],
      },

      thirdRowImages: {
        name: '3rd Row',
        images: [
          {
            img: './../../../assets/images/loft/ac.png',
            activeimg: './assets/images/loft/activeac.png',
            src: '',
          },
          {
            img: './../../../assets/images/loft/clean.png',
            activeimg: './assets/images/loft/activecleaning.png',
            src: '',
          },
          {
            img: './../../../assets/images/loft/gym.png',
            activeimg: './assets/images/loft/activegym.png',
            src: '',
          },
          {
            img: './../../../assets/images/loft/fridge.png',
            activeimg: './assets/images/loft/activefridge.png',
            src: '',
          },
        ],
      },
    },
  ];
  loftData = [
    {
      img: './../../../../assets/images/loft/nutricious-food.png',
      heading: 'Nutricious Food',
      subText:
        'Menu designed as per nutriotn requirements, FFSAI standards and Third party quality checks',
    },
    {
      img: './../../../../assets/images/loft/international-standard.png',
      heading: 'International Standards',
      subText:
        'Processes, chemicals, staff training and audit as per International Standards for House Keeping, Maintenance and Operations ',
    },
    {
      img: './../../../../assets/images/loft/technology.png',
      heading: 'Cutting Edge Technology',
      subText:
        'Technology at your hand, Mobile app driven approach for ticketing, Events, and any requests to college.',
    },
    {
      img: './../../../../assets/images/loft/development.png',
      heading: 'Community Development',
      subText:
        'What is better than friends and community. Events planned for you and planned by you. ',
    },
  ];

  blog = [
    {
      img: './../../../../assets/images/loft/veat.png',
      subHead: 'Isthara Eats',
      desc: 'We know nothing comes close to the comfort of a mom-made meal, but we’ve tailored our menu to not only be yummy,    but to also fulfil the nutritive requirements of students',
      desc1:
        'Our menu is designed to meet the FSSAI standards, and monthly third-party quality checks in place ensure the quality and safety of the food served to the residents of Isthara',
      desc2: '',
    },
    {
      img: './../../../../assets/images/loft/vclean.png',
      subHead: 'Isthara Hygiene',
      desc: 'At Isthara, we believe that only a clean space is a happy space. We have trained staff and streamlined processes in place to meet the International Standards for House Keeping, Maintenance and Operations.',
      desc1:
        'We have also partnered with the industry best hospitality agencies that maintain a gold standard in cleanliness and hygiene. Frequent quality audits help effective implementation of the adopted practices.',
      desc2: '',
    },
    {
      img: './../../../../assets/images/loft/vtech.png',
      subHead: 'Isthara Tech',
      desc: 'Technologically driven Complaint Management and Service Request system for all residents is simply a tap away.',
      desc1:
        'Technological empowerment enables the admin staff to manage and meet the needs of the students effectively.',
      desc2: '',
    },
    {
      img: './../../../../assets/images/loft/vsocial.png',
      subHead: 'Isthara Community',
      desc: 'Community organized festive celebrations, gaming nights, movie screenings and other fun events foster a sense of community and belongingness.',
      desc1:
        'These community events nurture the emotional well-being of the students while also providing them a safe space for immense personal growth.',
      desc2: '',
    },
  ];
  experience = [
    {
      text: 'Creating',
      heading: 'Memorable Experience',
      desc: 'Convert your existing hostel infrastructure to a state-of-the-art students co-living space or develop a new dedicated premise.',
      img: './../../../../assets/images/loft/hostel-img.png',
    },
  ];
  ngOnInit(): void {
    this.metaService.updateTag({
      name:'description',
      content:'Student living experience that provides safety, comfort, luxury at the best locations | Student Living in Hyderabad | Student Living in Chennai'});
    this.titleService.setTitle('A sanctuary of good living for students | PG near me')
  }
  showvideo() {
    this.showMe = !this.showMe;
  }
  closeBtn() {
    // this.router.navigate(['/community'])
    this.showMe = !this.showMe;
  }
  changepath(path) {
    this.router.navigate([path]);
  }
}
