import { environment } from './../../../../environments/environment';
import { BookingService } from './../booking.service';
import { RightSlidingNavbarService } from './../../shared/navbars/right-sliding-navbar/right-sliding-navbar.service';
import { DbStorageService } from './../../../service/headers/db-storage/db-storage.service';
import { ErrorPopsService } from './../../../service/error-pops/error-pops.service';
import { Component, Inject, OnInit, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SuggestBookingComponent } from '../suggest-booking/suggest-booking.component';
import { getMaxListeners } from 'node:process';
declare let CashFree: any;
declare let $: any;

@Component({
  selector: 'user-flow-booking-pop-user',
  templateUrl: './booking-pop-user.component.html',
  styleUrls: [
    './booking-pop-user.component.scss',
    './mq-booking-pop-user.component.scss',
  ],
})
export class BookingPopUserComponent implements OnInit {
  fifthSector: any = { in_stock: 0 };
  property = [];
  suggestproperty = [];
  selectsuggestproperty: any;
  cashFree = environment.cashFree;
  // suggestedPropertyId=this._dbStorage.getSuggestPropId();
  panelOpenState: boolean = false;
  showMe: boolean = false;
  checked = false;
  constructor(
    public dialogRef: MatDialogRef<BookingPopUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private errorPops: ErrorPopsService,
    private _dbStorage: DbStorageService,
    private rightService: RightSlidingNavbarService,
    private bookingService: BookingService
  ) {
    dialogRef.disableClose = true;
  }
  selectRoom;
  securityDeposite;
  advanceAmount;
  step = 0;
  total = 0;
  email;
  fullName;
  mbnumber;
  chooseDate;
  termsnconditions;
  tokenAmount;
  userid;
  userDetails;
  btnDisabled: boolean;
  other = {
    ofName: '',
    ofMobile: '',
    ofEmail: '',
  };
  // today: Date = new Date();
  // minDate: Object = new Date(this.today);
  // maxDate: Object = new Date(new Date().getDate() + 30);
  // maxDate: Object = new Date(this.today.getDate() + 30);
  minDate = new Date();
  maxDate = new Date(new Date().setDate(new Date().getDate() + 30));
  genders = [
    { name: 'Male', value: 'MALE', status: false },
    { name: 'Female', value: 'FEMALE', status: false },
    // { name: 'other', value: 'Others',status:false },
  ];
  othersgender = [
    { name: 'Male', value: 'MALE', status: false },
    { name: 'Female', value: 'FEMALE', status: false },
    // { name: 'other', value: 'Others',status:false },
  ];
  gender;
  genderactive;
  bookingDetails;
  setStep(index: number) {
    this.step = index;
  }
  genderSelect(genderitem, i) {
    this.genders.forEach((item, index) => {
      // if(event.checked){
      this.genderactive = true;
      if (index == i) {
        item.status = true;
        this.gender = genderitem.value;
        // console.log("gendervalue",this.gender);
        if (this.gender == 'MALE') {
          if (
            this.data.property.propertyType == 'MENS' ||
            this.data.property.propertyType == 'COLIVING'
          ) {
            // console.log('alterproertytype procced')
            this.btnDisabled = false;
          } else {
            // this.errorPops.openSnackBarMessage("Gender doesnot matching property type", "OK");
            // console.log("this.popup opens")
            this.bookingService.genderDialog(SuggestBookingComponent, {
              data: this.data,
              type: this.dialogRef,
            });
            this.btnDisabled = true;
          }
        }
        if (this.gender == 'FEMALE') {
          if (
            this.data.property.propertyType == 'WOMENS' ||
            this.data.property.propertyType == 'COLIVING'
          ) {
            // console.log('alterproertytype procced');
            this.btnDisabled = false;
          } else {
            // this.errorPops.openSnackBarMessage("Gender doesnot matching property type", "OK");
            // console.log("this.popup oopens senkladfsj")
            this.bookingService.genderDialog(SuggestBookingComponent, {
              data: this.data,
              type: this.dialogRef,
            });
            this.btnDisabled = true;
          }
        }
      } else {
        item.status = false;
        // this.gender='';
      }
    });
  }
  nextStep() {
    if (this.selectRoom == null || this.selectRoom == '') {
      this.errorPops.openSnackBarMessage('Please Select Room Category', 'OK');
    } else {
      this.step++;
    }
  }
  suggestedResponseData = false;
  nextstep1 : boolean = false
  nextStep1() {
    // console.log("selected",this.data.property)
    // console.log('setpropid',this.suggestproperty)
    if (this.chooseDate == '' || this.chooseDate == null) {
      this.errorPops.openSnackBarMessage('Please Select MoveIn Date', 'OK');
    } else {
      this.nextstep1 = true;
      // this.step++;
      // console.log("move in date", this.chooseDate)
      let details = {
        checkInDate: this.chooseDate,
        istharaPropertyId: this.data.property.istharaPropertyId,
        propertyId: this.data.property.propertyId,
        sharingType: Number(this.selectRoom.sharingCount),
      };
      this.bookingService.getAvailabilityByProperty(details).subscribe(
        (res: any) => {
          this.suggestproperty = res.data.suggestedproperty;
          // console.log('rty',this.suggestproperty);
          // console.log('availablebooking',res.data.availedforbooking);
          if (res.data.availedforbooking == false) {
            this.suggestedResponseData = !this.suggestedResponseData;
            // let dialog = this.bookingService.openDialog(
            //   SuggestBookingComponent,
            //   {
            //     // user: { name: 'Prateek', mobile: '9618474297' },
            //     propertyData: res.data.suggestedproperty,
            //     info:this.data
            //   }
            // );
            // this.closeBtn()
          } else {
            this.step++;
          }
          this.nextstep1 = false;
        },
        (err) => {
          // console.log('gbv',err)
        }
      );
    }
  }
  details() {
    // if (((this.other.ofEmail == '' || this.other.ofEmail == null) &&(this.email==null || this.email=='') ) || ((this.other.ofMobile == '' || this.other.ofMobile == null )&&(this.mbnumber==null || this.mbnumber=='')) || ((this.other.ofName == '' || this.other.ofName == null) &&(this.fullName=='' || this.fullName== null)) || (this.gender == '' || this.gender == null)) {
    //   console.log("please selecte mandatory fields")
    //   this.errorPops.openSnackBarMessage("Please fill mandatory fields", "OK");
    // }
    // else {
    //   this.step++;
    //   // console.log('mail.id', this.email, " gender", this.gender)
    // }
    if (this.fifthSector.in_stock == 0) {
      if (
        this.email == null ||
        this.email == '' ||
        this.mbnumber == null ||
        this.mbnumber == '' ||
        this.fullName == '' ||
        this.fullName == null ||
        this.gender == '' ||
        this.gender == null
      ) {
        this.errorPops.openSnackBarMessage(
          'Please fill mandatory fields',
          'OK'
        );
      } else {
        this.step++;
      }
    } else {
      if (
        this.other.ofEmail == '' ||
        this.other.ofEmail == null ||
        this.other.ofMobile == '' ||
        this.other.ofMobile == null ||
        this.gender == '' ||
        this.gender == null ||
        this.other.ofName == '' ||
        this.other.ofName == null
      ) {
        this.errorPops.openSnackBarMessage(
          'Please fill mandatory fields',
          'OK'
        );
      } else {
        this.step++;
      }
    }
  }
  payLoader : boolean = false;
  pay() {
    if (this.selectRoom == null || this.selectRoom == '') {
      this.errorPops.openSnackBarMessage('Please Select Room Category', 'OK');
      this.setStep(0);
    } else if (this.chooseDate == '' || this.chooseDate == null) {
      this.errorPops.openSnackBarMessage('Please Select MoveIn Date', 'OK');
      this.setStep(1);
    } else if (this.termsnconditions == '' || this.termsnconditions == null) {
      this.errorPops.openSnackBarMessage('Accept Terms and Conditions', 'OK');
    } else {
      if (this.fifthSector.in_stock == 1) {
        (this.fullName = this.other.ofName),
          (this.mbnumber = this.other.ofMobile),
          (this.email = this.other.ofEmail);
      }
      this.payLoader = true;
      // console.log("selected",this.data.property)
      let bookingData = {
        agreedRent: this.agreedRent,
        istharaPropertyId: this.data.property.istharaPropertyId,
        rentDiscount: this.rentDiscount,
        agreedDeposit: this.agreedDeposit,
        bookedById: this.userid,
        bookingAmount: this.total,
        bookingType: 'PROPERTY_BOOKING',
        countryCode: '91',
        discountAmount: 1.0,
        expectedCheckInDate: this.chooseDate,
        offerApplied: true,
        propertyId: this.data.property.propertyId,
        // propertyName: this.data.property.propertyName,
        roomSharing: this.roomSharing,
        roomType: this.roomType,
        totalAmount: this.total,
        userEmail: this.email,
        userGender: this.gender,
        userMobile: this.mbnumber,
        userName: this.fullName,
      };
      // console.log("booking data",bookingData);
      this.bookingService.createBooking(bookingData).subscribe(
        (res: any) => {
          // console.log("bookingdata",bookingData)
          // console.log("this.is the responce from booking service", res)
          this.bookingDetails = res.data;
          this.paymentMethod();
          this.payLoader = false;
        },
        (err: any) => {
          // console.log("this is the error from booking service", err)
          this.payLoader =  false;
        }
      );
    }
  }
  paymentObj: any = {};
  formDataView: any = {};
  paymentMethod() {
    this.paymentObj = {
      bookingId: this.bookingDetails.bookingId,
      orderAmount: this.bookingDetails.totalAmount.toFixed(2),
    };
    this.paymentCall();
  }
  cfInitialized = false;
  paymentCall() {
    this.bookingService.merchantToken(this.paymentObj).subscribe(
      (pay: any) => {
        this.formDataView.orderId = pay.data.orderId;
        this.formDataView.orderAmount = pay.data.orderAmount.toFixed(1);
        this.formDataView.customerName = pay.data.payeeName;
        this.formDataView.customerPhone = pay.data.payeeMobile;
        this.formDataView.customerEmail = pay.data.payeeEmail;
        this.formDataView.returnUrl = pay.data.returnUrl; //http://localhost:4200/#/amount/${this.paymentObj.bookingId}
        this.formDataView.notifyUrl = pay.data.notifyUrl;
        this.formDataView.appId = pay.data.appId;
        this.formDataView.signature = pay.data.orderSignature; //paymentToken
        this.formDataView.orderCurrency = pay.data.paymentCurrency;
        this.formDataView.orderNote = 'Booking';

        setTimeout(() => {
          $('#redirectForm').submit();
        }, 100);
      },
      (err: any) => {
        // console.log("error from cashfree payment gateway", err)
      }
    );
  }

  // cashFreemerchanttoken(){
  //   let merchantData = {
  //     bookingId : this.bookingDetails.bookingId,
  //     orderAmount: this.bookingDetails.totalAmount
  //   }
  //   this.bookingService.merchantToken(merchantData).subscribe(
  //     (res:any) =>{
  //       console.log("cash free merchant token",res.data)
  //     },
  //     (err:any) => {
  //       console.log("error for cashe free merchant token",err)
  //     }
  //   )
  // }

  prevStep() {
    this.step--;
  }
  ngOnInit(): void {
    this.suggestproperty.forEach((item) => {
      item.active = false;
    });

    if (this.check[0].isActive == true) {
      this.total = this.total + Number(this.check[0].price);
    }

    this.userid = this._dbStorage.getUserId();
    // console.log("propertyName", this.data.property.propertyName)

    this.rightService.getUser(this.userid).subscribe(
      (res: any) => {
        this.userDetails = res.data;
        //console.log("User details", this.userDetails)
        //console.log("lfajsdk",this.fifthSector.in_stock)
        if (this.fifthSector.in_stock === 0) {
          this.fullName = res.data.fullName;
          this.email = res.data.email;
          this.mbnumber = res.data.mobile;
        }
      },
      (err: any) => {
        // console.log("error for propfile", err)
      }
    );
    //console.log('bbbb',this.data)
    this.property.push(this.data.property);
    //console.log("User details", this.userDetails)
    // this.suggestproperty=this.data.propertyData
  }
  closeBooking() {
    this.dialogRef.close(this.data);
  }

  selectedRoom(event) {
    this.selectRoom = event;
    // console.log(event);
  }
  roomSharing;
  roomType;
  agreedRent;
  agreedDeposit;
  rentDiscount;
  clicked() {
    if (this.selectRoom.isSharingCount == true) {
      this.securityDeposite = Number(this.selectRoom.roomPrice.monthly) - 500;
      // console.log("token amoutn",this.securityDeposite);
      this.agreedRent = Number(this.selectRoom.roomPrice.monthly);
      this.agreedDeposit = Number(this.selectRoom.roomPrice.monthly);
      this.rentDiscount = 0;
      // console.log('jakldsjfklsdfa', this.agreedRentdiscount
      // console.log("rentDiscount", this.rentDiscount)
      this.advanceAmount = this.selectRoom.roomPrice.monthly;
      this.check[1].price = this.securityDeposite;
      this.check[2].price = this.advanceAmount;
      this.roomSharing = this.selectRoom.sharingCount;
      this.roomType = this.selectRoom.name;
      if (this.roomSharing == 1) {
        this.roomSharing = 'SINGLE_SHARING';
      }
      if (this.roomSharing == 2) {
        this.roomSharing = 'DOUBLE_SHARING';
      }
      if (this.roomSharing == 3) {
        this.roomSharing = 'TRIPLE_SHARING';
      }
      if (this.roomSharing == 4) {
        this.roomSharing = 'FOUR_SHARING';
      }
      if (this.roomSharing == 5) {
        this.roomSharing = 'FOUR_SHARING';
      }
      // console.log("room price", this.roomSharing);
      // console.log('agreeddepositnormalroom',this.agreedDeposit)
    }
    if (this.selectRoom.isPrivateRoom == true) {
      var normalPrice =
        this.selectRoom.roomPrice.monthly * this.selectRoom.sharingCount;
      this.agreedRent = Number(normalPrice);
      this.agreedDeposit = Number(
        this.selectRoom.roomPrice.monthly * this.selectRoom.sharingCount -
          this.selectRoom.roomPrice.discount
      );
      // this.agreedDeposit=this.selectRoom.roomPrice.monthly*
      this.rentDiscount = this.selectRoom.roomPrice.discount;
      // console.log("rent Discount", this.rentDiscount);
      // console.log("private room", this.agreedRent)
      var offerPrice = normalPrice - this.selectRoom.roomPrice.discount;
      this.securityDeposite = Number(offerPrice) - 500;
      this.advanceAmount = offerPrice;
      this.check[1].price = this.securityDeposite;
      this.check[2].price = this.advanceAmount;
      this.roomSharing = this.selectRoom.sharingCount;
      // this.roomSharing = this.selectRoom.roomTypeValues[0]
      if (this.roomSharing == 1) {
        this.roomSharing = 'SINGLE_SHARING';
      }
      if (this.roomSharing == 2) {
        this.roomSharing = 'DOUBLE_SHARING';
      }
      if (this.roomSharing == 3) {
        this.roomSharing = 'TRIPLE_SHARING';
      }
      if (this.roomSharing == 4) {
        this.roomSharing = 'FOUR_SHARING';
      }
      if (this.roomSharing == 5) {
        this.roomSharing = 'FOUR_SHARING';
      }
      // console.log("normalPrice", normalPrice);
      // console.log('agreedDepositprivateroom',this.agreedDeposit)
      this.roomType = this.selectRoom.name;
      // console.log("room price for private room ", this.roomSharing);
    }
  }
  closeBtn() {
    this.dialogRef.close();
  }
  blocks = [
    {
      rent: 'Rent',
      price: '4500/M',
      SecurityDeposit: 'Security Deposit',
      time: '3 Month Deposit',
      discount: 'Save 10%',
    },
    {
      rent: 'Rent',
      price: '4500/M',
      SecurityDeposit: 'Security Deposit',
      time: '3 Month Deposit',
      discount: 'Save 10%',
    },
    {
      rent: 'Rent',
      price: '4500/M',
      SecurityDeposit: 'Security Deposit',
      time: '3 Month Deposit',
      discount: 'Save 10%',
    },
    {
      rent: 'Rent',
      price: '4500/M',
      SecurityDeposit: 'Security Deposit',
      time: '3 Month Deposit',
      discount: 'Save 10%',
    },
  ];
  check = [
    {
      uId: '1',
      tokenName: 'Token Amount',
      price: '500',
      isActive: true,
      paymentInfoChecked: '',
    },
    // {
    //   uId: '2',
    //   tokenName: "Move in Charges",
    //   price: " 1599",
    //   isActive: false,
    //   paymentInfoChecked: ''
    // },
    {
      uId: '2',
      tokenName: 'Security Deposit',
      price: '',
      isActive: false,
      paymentInfoChecked: '',
    },
    {
      uId: '3',
      tokenName: 'Advance Rent Amount ( for 30 Days )',
      price: '',
      isActive: false,
      paymentInfoChecked: '',
    },
  ];
  termsList = [
    {
      text: 'Parking is Not Guaranteed and subject to availability.',
    },
    {
      text: 'Rent is payable on or before the 8* of every month. Delays would result in a charge of Rs. 200/- per day and in recurring delays 2 times or more ) Isthara reserves the right to terminate the lease, without prior information.',
    },
    {
      text: 'Housekeeping is provided thrice a week. Any misuse / unhygienic use of property or linen will be charged a fee of Rs.1000 /- per occurrence along with the cost of the material. Isthara Premier will offer Housekeeping 5 times a week.',
    },
    {
      text: 'Isthara App is available on Android Play Store and IOS App store. Please use this as your tool of engagement with us. Kindly refrain from contacting the caretaker for any requests except in times of emergency.',
    },
    {
      text: 'Visitors will be entertained only in common areas and will NOT be allowed into personal rooms. Visiting timings will be till 11 p.m.',
    },
    {
      text: 'Laundry damage to be intimated within 24 hours and the same will be reimbursed @ Rs.650/- or 10 time the cost of doing the laundry- whichever is lesser.',
    },
    {
      text: 'Residents are requested to store valuables & belongings inside the cupboards provided and lock them up. Islhara management will not be responsible for any loss of belongings.',
    },
    {
      text: '30 Day notice period is mandatory for any departure. Notice period will be calculated from the date of submission of the exit',
    },
    {
      text: 'Exit / Checkout request needs to be raised from the Isthara App - Profiles section. No verbal information to any Isthara Personnel will be considered as submission of the exit notice.',
    },
    {
      text: 'If the necessary notice period is not complete, then the remaining day’s charges will be deducted from the deposit.',
    },
    {
      text: 'Remal refund will not be done. Please ensure you plan your departure on the last day of the month.',
    },
    {
      text: 'Isthara reserves the  "Right of admission".',
    },
    {
      text: 'Surcharges may be applicable as and when situation warrants. The amount and duration will be announced 30 days prior & will be mandatory in nature with no exceptions.',
    },
    {
      text: 'Smoking is strimly prohibited in1he rooms. A freshening fee of Rs.2000 will be levied if any resident is found smoking in the room.',
    },
    {
      text: 'Illegal activity such as Drug, Verbal / Physical Voilence. Sexual Harrasment or Carrying of Any Weapon lead to immediate eviction from the property & can register a legal case.',
    },
    {
      text: 'Residents are expected to behave politely with all the co-residents & Isthara staff.',
    },
    {
      text: 'Residents should not tamper with any House Material snd Electrical Equipment.',
    },
    {
      text: '2 Devices per resident are allowed to access the wiFi.',
    },
    {
      text: 'Advance deposit will be refunded within 14 working days after your physical check out from the rooms. Any damages or a due that the resident owes to Isthara will be deducted from the deposit.',
    },
    {
      text: "Isthara seryes Vegetarian food only and Non-Vegetarian food at a cost will be provided by an authorized vendor. Days of this being served and the surcharge for this will be displayed on the Building's notice board. This will be on a monthly package and should be prepaid. There will be no refund of unused days in this package.",
    },
    {
      text: 'Isthara reserves the right to Change / Modify the policies without any prior notice to residents.',
    },
    {
      text: 'Isthara may store details provided by lessee from time to time and may use for marketing & operational purpose.',
    },
    {
      text: 'Please note that once you checkin the regular rent will apply every month irrespective of stay in room or travel.',
    },
  ];

  nextStep2() {
    if (
      this.check[0].isActive == false &&
      this.check[1].isActive == false &&
      this.check[2].isActive == false &&
      this.check[3].isActive == false
    ) {
      this.errorPops.openSnackBarMessage(
        'Minimum You have to pay TOKEN AMOUNT',
        'OK'
      );
    } else {
      this.step++;
      this.showMe = !this.showMe;
    }
  }
  isChecked(check, i) {
    if (check.uId == 1) {
      check.isActive == true;
      // console.log("not possible")
    } else {
      if (check.isActive == false) {
        this.total = this.total - Number(this.check[i].price);
      } else {
        for (let i = 0; i < this.check.length; i++) {
          if (this.check[i].uId == check.uId) {
            this.check[i].isActive = true;
            // console.log("new payment Info", this.check)
            if (this.check[i].isActive == true) {
              this.total = this.total + Number(this.check[i].price);
              // console.log("Number", this.total);
            }
          }
        }
      }
    }
  }

  // closeBtn() {
  //   this.dialogRef.close();
  // }
  getMinimum(roomTypes) {
    let a = [];
    roomTypes.forEach((r) => {
      a.push(Number(r.roomPrice.monthly));
    });

    return Math.min.apply(Math, a);
  }

  selectProperty(properId, i) {
    this.suggestproperty.forEach((item, index) => {
      if (i === index) {
        item.active = true;
        // console.log("suggestpropertyid",item);
        // this.suggestedPropertyId=properId
        this.selectsuggestproperty = item;
      } else {
        item.active = false;
      }
    });
  }

  continueSuggest() {
    if (
      this.selectsuggestproperty == '' ||
      this.selectsuggestproperty == null
    ) {
      this.errorPops.openSnackBarMessage(
        'Please Select any Suggest Property',
        'OK'
      );
    } else {
      this.data.property = this.selectsuggestproperty;
      this.suggestedResponseData = !this.suggestedResponseData;
      this.step = 0;
    }
    // console.log('gfghvvnbv',this.selectsuggestproperty)
    // console.log('gfhjhbvnnmnbvbjhg',this.data.property)
  }
}
