<div class="close-x" (click)="closeBtn()"><img src="./../.././../../././../assets/images/popup-images/cross-mark.png"/></div>
<section>
    <div class="main-section" align="center">
        <div class="bigText">Change Mobile</div>
        <div class="sub-heading">Enter your new mobile number</div>
        <div class="field-section">
            <mat-form-field appearance="outline" class="mobileField">
                <mat-label>
                    Mobile Number
                </mat-label>
                <input matInput class="input-field"/>
            </mat-form-field>
        </div>
    </div>
    <button class="bt" >Submit</button>
</section>

<!-- <div class="main-sec">
<div class="heading">
    Change Mobile
</div>
<div class="sub-heading">
    Enter your new mobile number
</div>
<div> -->
    <!-- <mat-form-field appearance="outline" class="mobileField"> -->
        <!-- <mat-label>Moblie Number</mat-label> -->
        <!-- <input matInput  placeholder="Moblie Number"/> -->
        <!-- <mat-icon matSuffix>sentiment_very_satisfied</mat-icon> -->
      <!-- </mat-form-field> -->
      <!-- <mat-form-field appearance="outline">
        <mat-label>Full Name</mat-label>
        <input matInput/>
    </mat-form-field> -->
<!-- </div>
<button class="bt" >Submit</button>
</div> -->