<div class="section">
  <div class="top-nav">
    <user-flow-top-navbar></user-flow-top-navbar>
  </div>
  <div class="total">
    <div class="left">
      <div class="left-bg">
        <div class="left-heading">
          Join The Isthara Family - Register Your Products & Services
        </div>
        <div class="sub-text">
          Scale your business with a fast-growing brand. As a growing company,
          we’re always on a lookout for people who can take care of our daily,
          weekly, monthly and yearly supply needs. If you think you have
          something that we might need, we're all ears. Just contact us and see
          your business have a pan India presence in no time.
        </div>
        <div class="images-heading">How To Get Started In Few Steps?</div>
        <div class="total-top-images">
          <div
            class="top-images"
            [ngStyle]="{ border: ct.border }"
            *ngFor="let ct of cards"
          >
            <div class="background-number">
              <img src="{{ ct.bigImg }}" alt="" />
            </div>
            <div class="note">
              <img src="{{ ct.img }}" />
            </div>
            <div class="desc">{{ ct.text }}</div>
          </div>
        </div>
      </div>
      <div class="benifits">The Isthara</div>
      <div class="isthara-promise">Promise</div>
      <div>
        <div class="total-bottom-images">
          <div class="bottom-images" *ngFor="let im of about">
            <img src="{{ im.img }}" />

            <div class="bottom-images-text">{{ im.text }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="right">
      <div class="right-form">
        <div class="form-heading">Register Your Services / Products</div>
        <div class="share-detail">Share your details with us</div>
        <div class="fields">
          <div class="name">
            <mat-form-field class="input-width" appearance="outline">
              <mat-label>Name</mat-label>
              <input
                matInput
                [(ngModel)]="Form.details.name"
                #nameCheck="ngModel"
                pattern="[a-zA-Z\s]+"
                required
              />
            </mat-form-field>
            <mat-error
              *ngIf="!nameCheck.valid && (nameCheck.dirty || nameCheck.touched)"
            >
              Please enter your name
            </mat-error>
          </div>
          <div class="name">
            <mat-form-field class="input-width" appearance="outline">
              <mat-label>Mobile Number</mat-label>
              <input
                matInput
                [(ngModel)]="Form.details.mobileNo"
                #numberCheck="ngModel"
                pattern="^[6-9]\d{9}$|^[1-9]\d{9}$"
                required
              />
            </mat-form-field>
            <mat-error
              *ngIf="
                !numberCheck.valid && (numberCheck.dirty || numberCheck.touched)
              "
            >
              please provide your contact number
            </mat-error>
          </div>
          <div class="name">
            <mat-form-field class="input-width" appearance="outline">
              <mat-label>Email id</mat-label>
              <input
                matInput
                [(ngModel)]="Form.details.emailId"
                #emailCheck="ngModel"
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                required
              />
            </mat-form-field>
            <mat-error
              *ngIf="
                !emailCheck.valid && (emailCheck.dirty || emailCheck.touched)
              "
            >
              please provide your emailID
            </mat-error>
          </div>
          <div class="name">
            <mat-form-field class="input-width" appearance="outline">
              <mat-label>Services/Products Name</mat-label>
              <input
                matInput
                [(ngModel)]="Form.details.productName"
                #productCheck="ngModel"
                required
              />
            </mat-form-field>
            <mat-error
              *ngIf="
                !productCheck.valid &&
                (productCheck.dirty || productCheck.touched)
              "
            >
              please select a Service/provide
            </mat-error>
          </div>

          <div class="prefer-method">
            <p>Preferred Contact Method</p>
            <mat-radio-group
              aria-label="Select an option"
              [(ngModel)]="Form.details.contactMethod"
            >
              <mat-radio-button [value]="'email'">Email</mat-radio-button>
              <mat-radio-button [value]="'mobile'">Mobile</mat-radio-button>
            </mat-radio-group>
          </div>

          <div class="message">
            <mat-form-field class="input-width" appearance="outline">
              <mat-label>Message</mat-label>
              <textarea
                matInput
                [(ngModel)]="Form.details.descrpition"
                #descrpitionCheck="ngModel"
              ></textarea>
            </mat-form-field>
          </div>
        </div>
        <div class="sec-div">
          <button class="second-bt" (click)="submitdetails()">Submit</button>
        </div>
        <div class="or">OR</div>
        <div class="contact-number">Call us on 8790858581</div>
      </div>
    </div>
  </div>
  <div class="right-bg">
    <div>
      <div class="our">Our</div>
      <div class="success-stories">Success Stories</div>
      <div class="our-text">See what other partners have to say!</div>
      <div class="carsouel-sectionpart">
        <user-flow-customer-carousel
          [customerCards]="listComments"
        ></user-flow-customer-carousel>
      </div>
    </div>
    <div class="gap"></div>
  </div>
</div>
<div class="footer">
  <app-footer></app-footer>
</div>
