import { ErrorPopsService } from './../../../service/error-pops/error-pops.service';
import { SearchPropertyBoardService } from './../search-property-board/search-property-board.service';
import { DbStorageService } from './../../../service/headers/db-storage/db-storage.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ListPropertyService } from './list-property.service';
import {
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { MapSearchLibService } from '../../../service/map-search/map-search-lib.service';
import { MapsAPILoader } from '@agm/core';
import { PopupService } from '../../popup-components/popup.service';
import { Meta, Title } from '@angular/platform-browser';
@Component({
  selector: 'user-flow-list-property',
  templateUrl: './list-property.component.html',
  styleUrls: [
    './list-property.component.scss',
    './mq-list-property.component.scss',
  ],
})
export class ListPropertyComponent implements OnInit, OnChanges {
  totalProperties: number;
  // @Input() favProps: any[];
  ngOnChanges(changes: SimpleChanges): void {
    // this.loader = true
    // this.properties = changes.favProps.currentValue;
    if (changes.favProps.currentValue) {
      // this._route.queryParams.subscribe((params) => {
      //   if (params.city || params.locality || params.propertyType) {
      //     this.locationBased({
      //       city: params.city,
      //       locality: params.locality,
      //       propertyType: params.propertyType,
      //       businessId: 'bdc21f16-1e81-413d-b4f8-e937c1431982',
      //     });
      //   } else {
      //     // this.getAllProperties();
      //   }
      // }
      // );
     
      this.userId = this._dbStorage.getUserId();
    }
  }

  showVar = true;
  newProps: any = [];
  constructor(
    private listPropertyService: ListPropertyService,
    private router: Router,
    private popService: PopupService,
    private _dbStorage: DbStorageService,
    private _route: ActivatedRoute,
    private searchList: SearchPropertyBoardService,
    private map: MapSearchLibService,
    private mapsApiLoader: MapsAPILoader,
    private errorPops: ErrorPopsService,
    private metaService: Meta,
    private titleService: Title
  ) {}

  properties: any[];
  searchLocation = { city: '', locality: '', propertyType: '' };
  loader: boolean = false;
  propertyListView: boolean = true;
  userId: any;
  getFavList: any = [];
  currentLat;
  currentLng;
  totalDisplayedAdds = 0;
  check=false;
  metaTitle: string = null;
  metaDesc: string = null;

  addBlock = [
    {
      img: 'https://isthara2.s3.amazonaws.com/assets/vsafe.svg',
      heading: '100% Hygine Assured',
      subText: 'Your Safety Our Responsibility',
      description:
        'Isthara is taking all reasonable efforts to comply with the safety & health standards / guidelines issued by the Government of India amid covid-19. ',
      buttonLink: '/promises-tabs/vsafe',
      color: '#FFEDF4',
    },
    {
      img: 'https://isthara2.s3.amazonaws.com/assets/veat.svg',
      heading: 'Nutricious Food Assured',
      subText: 'Healthy and delicious food',
      description:
        'Best chef from the idustry cooks the food suggested by experts to keep perfect balance of taste and nutrition.',
      buttonLink: '/promises-tabs/veat',
      color: '#DBDFF2',
    },
  ];

  ngOnInit(): void {
    let city;
    let locality;
    let gender;
    this._route.params.subscribe(
      (params)=>{
      //  lengthcheck= params.detailsdata.split("-").length
      //  if(lengthcheck===3){
      //   if(params.detailsdata){
      //     city=params.detailsdata.split("-")[3];
      //   }
      //  }
      //  else{
          if(params.city){
            city=params.city;
            if(params.city=='Ncr'){
              city=''
            }
          }
          if(params.locality){
            if(params.locality.split("-").length>4){
              let checklocality=[];
              checklocality.push(params.locality.split("-")[3])
              checklocality.push(params.locality.split("-")[4])
              locality=checklocality.join(" ")
            }
            else{
              locality=params.locality.split("-")[3]

            }
          }
          if(params.gender){
            gender=params.gender
          }
          let near = window.location.href.split('/');
          let checksNear = near.includes('NearBy');
          if(!checksNear){
              this.locationBased({
                city: city,
                locality: locality,
                propertyType: gender,
                businessId: 'bdc21f16-1e81-413d-b4f8-e937c1431982',
              });  
            }
          }
    )
    this.currentLocation();
    //Get Favourites
  }

  currentLocation() {
    this.getCoords().then((position: any) => {
      if (position) {
        this.map
          .findAddressByCoordinates(position.latitude, position.longitude)

          .then((d) => {
            // console.log('location data :', d);
          });
        this.currentLat = position.latitude;
        this.currentLng = position.longitude;
        this.routerChecksProperty();
        // console.log("lat", this.currentLat);
        // console.log("lng", this.currentLng);
      } else {
        this.routerChecksProperty();
      }
    });
  }

  getCoords() {
    return new Promise((resolve, reject) =>
      navigator.permissions
        ? // Permission API is implemented
          navigator.permissions
            .query({
              name: 'geolocation',
            })
            .then((permission) =>
              // is geolocation granted?
              permission.state === 'granted'
                ? navigator.geolocation.getCurrentPosition((pos) =>
                    resolve(pos.coords)
                  )
                : resolve(null)
            )
        : // Permission API was not implemented
        resolve(null)
          // reject(new Error('Permission API is not supported'))
    );
  }

  routerChecksProperty() {
    // this._route.queryParams.subscribe((params) => {
    //   if (params.city || params.locality || params.propertyType) {
    //     this.locationBased({
    //       city: params.city,
    //       locality: params.locality,
    //       propertyType: params.propertyType,
    //       businessId: 'bdc21f16-1e81-413d-b4f8-e937c1431982',
    //     });
    //   } else {
    //     // this.getAllProperties();
    //     this.neabysearch({
    //       lat: params.lat,
    //       lng: params.lng,
    //     });
    //   }
    // });
    this._route.params.subscribe((params) => {
      let param = Object.values(params);
        this.neabysearch({
          lat:param[0],
          lng:param[1]
        })
    })
    this.userId = this._dbStorage.getUserId();
  }

  detailProperty(property, i) {
    // this.router.navigate(['/detail', i]);
    this.router.navigate([`${property.propertyAddress.city}`,`Pg-hostel-in-${property.propertyAddress.locality.replace(" ","-")}`,property.propertyType.toLowerCase(),property.propertyName.replaceAll(' ', '-')])
  }
  addFav(prpId, flag, event) {
    event.stopPropagation();
    flag = flag ? false : true;
    this.detailProperty[prpId];
    let favData = {
      favourite: flag,
      propertyId: prpId,
      userId: this.userId,
    };
    // console.log(favData);
    this.listPropertyService.addFavourites(favData).subscribe(
      (res: any) => {
        // console.log('success', res);
        for (let i = 0; i < this.properties.length; i++) {
          if (prpId === this.properties[i].propertyId) {
            this.properties[i].isFav = flag;
            break;
          }
        }
        if (flag == true) {
          this.errorPops.openSnackBarMessage('Added to favourite List', 'OK');
        } else {
          this.errorPops.openSnackBarMessage(
            'Remove from Favourite List',
            'OK'
          );
        }
      },
      (err: any) => {
        // console.log('error', err);
      }
    );
  }
  locationBased(locationData) {
    this.loader = false;
    this.propertyListView = true;
    this.searchList.getListLocationNew(locationData).subscribe(
      (res: any) => {
        var metaTitle = "Isthara Coliving - India's leading urban living platform";
        var metaDesc = "Your home away from awaits you with modern amenities & safety living | Student Living";
        if(res.data.metaTitle) {
          metaTitle = res.data.metaTitle;
          metaDesc = res.data.metaDesc;
        }
          this.metaService.updateTag({
            name:'description',
            content:metaDesc
          });
          this.titleService.setTitle(metaTitle)

        if (res.data.properties?.length !== 0) {
          // console.log('properties: ', res);
          if (this.userId !== 'null') {
            this.properties = res.data.properties;
            this.properties.forEach((element,i) => {
              if(!element.active){
                this.properties.splice(i,1);
              }
            });
            this.getFavUid(this.userId);
          } else {
            this.properties = res.data.properties;
          }
        } else {
          this.properties = res.data.properties;
        }
      },
      (err: any) => {
        // console.log('error', err);
      },
      () => {
        if (this.properties) {
          this.loader = true;
        }
        if (this.properties?.length === 0) {
          this.propertyListView = false;
        } else {
          this.propertyListView = true;
          this.asyncLoop();
        }
      }
    );
  }

  async asyncLoop() {
    let actualProperty = this.properties;
    this.properties = [];
    let addlist = 0;
    for (let i = 0; i < actualProperty.length; i++) {
      let addObj = Object.assign(
        {},
        {
          add: true,
          advertise: {},
        }
      );
      let propObj = Object.assign({}, actualProperty[i]);
      propObj.distance = await this.distanceFromSpecificLocation(
        actualProperty[i]
      );
      propObj.distance = this.distance || '-';
      if (i % 4 === 0) {
        if (addlist > 1) {
          addlist = 0;
        }
        addObj.add = true;
        addObj.advertise = this.addBlock[addlist];
        propObj.add = false;
        if (i !== 0) {
          this.properties.push(addObj);
        }

        this.properties.push(propObj);
        addlist++;
        this.totalDisplayedAdds++;
      } else {
        propObj.add = false;
        this.properties.push(propObj);
      }
    }
    // console.log('PROPERTIES: ', this.properties);
    this.totalProperties = this.properties.length - this.totalDisplayedAdds + 1;
  }
  getFavUid(userId) {
    this.listPropertyService.getFavourites(userId).subscribe(
      (res: any) => {
        // console.log('get fav list', res.data);
        this.getFavList = res.data;
        this.properties.forEach((propProp, propIndex) => {
          this.properties[propIndex].isFav = false;
          this.getFavList.forEach((favProp) => {
            if (favProp.propertyId === propProp.propertyId) {
              this.properties[propIndex].isFav = true;
            }
          });
        });
      },
      (err: any) => {
        // console.log('get fav error', err);
      },
      () => {
        // console.log('list of property: ', this.properties);
      }
    );
  }
  // getUpdatedProps(properties) {
  //   if (this.userId == 'null') {
  //     // this.locationBased();
  //   } else {
  //     properties.forEach((prop) => {
  //       if (this.getFavList.length == 0) {
  //         // this.locationBased();
  //       } else {
  //         for (let i = 0; i < this.getFavList.length; i++) {
  //           if (prop.propertyId === this.getFavList[i].propertyId) {
  //             prop.isFav = true;
  //             // break;
  //             this.getFavList.splice(i, 1);
  //           } else {
  //             prop.isFav = false;
  //             // break;
  //             // this.getFavList.splice(i,1);
  //           }
  //         }
  //       }
  //     });
  //   }
  //   return properties;
  // }

  getMinimum(roomTypes) {
    let a = [];
    roomTypes.forEach((r) => {
      a.push(Number(r.roomPrice.monthly));
    });

    return Math.min.apply(Math, a);
  }
  distance;
  distanceFromSpecificLocation(propLocation) {
    this.distance = '';
    if (propLocation) {
      this.mapsApiLoader.load().then(() => {
        const center = new google.maps.LatLng(this.currentLat, this.currentLng);
        const place = new google.maps.LatLng(
          propLocation.propertyAddress.geoLocation.latitude,
          propLocation.propertyAddress.geoLocation.longitude
        );
        let distance = google.maps.geometry.spherical.computeDistanceBetween(
          center,
          place
        );
        this.distance = Math.ceil(distance / 1000);
        if (this.distance) {
          this.distance = this.distance + 'Km';
          return this.distance;
        } else {
          return ''
        }
        
        // console.log("this.distance", this.distance);
      });
    } else {
      return '';
      // this.ErrorPopsService.openSnackBarMessage(
      //   "No Coordinates for Property !!",
      //   "Close"
      // );
      // console.log("welcome to isthara");
    }
  }

  changePath(path) {
    this.router.navigate([path]);
  }

  neabysearch(neardata) {
    this.popService.nearestProperties(neardata.lat, neardata.lng).subscribe(
      (res: any) => {
        this.properties = res.data;
        // if (res.data.length !== 0) {
        //   // console.log('properties: ', res);
        //   if (this.userId !== 'null') {
        //     this.properties = res.data;
        //     this.getFavUid(this.userId);
        //   } else {
        //     this.properties = res.data;
        //   }
        // } else {
        //   this.properties = res.data;
        // }
        // console.log('fghg', this.properties);
      },
      (err: any) => {
        // console.log('err', err);
      },
      () => {
        if (this.properties) {
          this.loader = true;
        }
        if (this.properties?.length === 0) {
          this.propertyListView = false;
        } else {
          this.propertyListView = true;
          this.asyncLoop();
        }
      }
    );
  }
}
