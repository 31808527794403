<section>
  <div id="mySidepanel" class="sidepanel" #mySidePanel>
    <a href="javascript:void(0)" class="closebtn" (click)="closeNav()">
      <img
        width="20"
        height="20"
        src="./../../../../../assets/images/right-sliding-nav/cross.png"
        alt="cross"
      />
    </a>
    <div class="profile-img-section">
      <div class="img-section">
        <div class="img-section" *ngIf="user.profileImg != null && user.profileImg !== 'null'; else noImg">
          <img
            *ngIf="userid !== 'null' && userid !== null"
            [src]="user.profileImg"
            [alt]="user.name"
            loading="lazy"
          />
        </div>
        <ng-template class="img-section" #noImg >
          <img
          src="./../../../../../assets/images/right-sliding-nav/defaultUser.png"
          alt="user"
          loading="lazy"
          *ngIf="userid !== 'null' && userid !== null"
          />    
        </ng-template>
        <img
          *ngIf="userid == 'null' || userid == null"
          src="./../../../../../assets/images/right-sliding-nav/defaultUser.png"
          alt="user"
          loading="lazy"
        />
      </div>
      <div class="name-section" *ngIf="userid !== 'null' && userid !== null">
        <p class="user-name">{{ user?.name | titlecase }}</p>
        <div class="profile-btn" (click)="routeTo('profile')">My Profile</div>
      </div>
      <div class="name-section" *ngIf="userid == 'null' || userid == null">
        <p class="user-name">Guest</p>
        <div class="profile-btn" (click)="routeTo('signin')">Login</div>
      </div>
    </div>  
    <div *ngIf="userid !== 'null' && userid !== null">
      <hr />
      <div class="list-view">
        <div *ngIf="srcWidth < 480 ">
          <ng-container *ngFor="let item of navbarArray">
            <div class="list-value cursor-pointer">
              <div class="img-side">
                <img
                *ngIf="item?.redirectUrl=='/food-court'"
                  src="./../../../../../assets/images/about-us/food-court.png"
                  [alt]="item.imgName"
                  loading="lazy"
                  width="100%"
                />
                <img
                *ngIf="item?.redirectUrl=='/student-living'"
                  src="./../../../../../assets/images/about-us/bed.png"
                  [alt]="item.imgName"
                  loading="lazy"
                  width="100%"
                />
              </div>
              <div class="name-side"(click)="nonLogrouteTo(item?.redirectUrl)">{{item?.title |titlecase}}</div>
            </div>
          </ng-container>
          <ng-container *ngFor="let item of sideNav; index as i">
            <div class="list-value cursor-pointer" *ngIf="item?.visibilityCondition == 'ALWAYS'">
              <div class="img-side">
                <img
  
                  [src]="item?.navBar.iconUrl"
                  [alt]="item.imgName"
                  loading="lazy"
                  width="100%"
                />
              </div>
              <div class="name-side" (click)="nonLogrouteTo(item?.navBar.redirectUrl)">{{ item?.navBar.title }}</div>
            </div>
          </ng-container>
        </div>
        <ng-container *ngFor="let item of sideNav; index as i">
          <div class="list-value cursor-pointer" *ngIf="item?.visibilityCondition == 'LOGIN_ONLY'">
            <div class="img-side">
              <img
                [src]="item?.navBar.iconUrl"
                [alt]="item.imgName"
                loading="lazy"
                width="100%"
              />
            </div>
            <div class="name-side" (click)="routeTo(item?.navBar.redirectUrl)">{{ item?.navBar.title }}</div>
          </div>
        </ng-container>
      </div>
    </div>
    <div *ngIf="userid == 'null' || userid == null">
      <hr/>
      <div class="list-view">
        <div *ngIf="srcWidth < 480 ">
          <ng-container *ngFor="let item of navbarArray">
            <div class="list-value cursor-pointer">
              <div class="img-side">
                <img
                *ngIf="item?.redirectUrl=='/food-court'"
                  src="./../../../../../assets/images/about-us/food-court.png"
                  [alt]="item.imgName"
                  loading="lazy"
                  width="100%"
                />
                <img
                *ngIf="item?.redirectUrl=='/student-living'"
                  src="./../../../../../assets/images/about-us/bed.png"
                  [alt]="item.imgName"
                  loading="lazy"
                  width="100%"
                />
              </div>
              <div class="name-side"(click)="nonLogrouteTo(item?.redirectUrl)">{{item?.title |titlecase}}</div>
            </div>
          </ng-container>
        </div>
        <ng-container *ngFor="let item of sideNav; index as i">
          <div class="list-value cursor-pointer" *ngIf="item?.visibilityCondition == 'ALWAYS'">
            <div class="img-side">
              <img

                [src]="item?.navBar.iconUrl"
                [alt]="item.imgName"
                loading="lazy"
                width="100%"
              />
            </div>
            <div class="name-side" (click)="nonLogrouteTo(item?.navBar.redirectUrl)">{{ item?.navBar.title }}</div>
          </div>
        </ng-container>
      </div>
    </div>
    <div *ngIf="userid !== 'null' && userid !== null">
      <hr>
      <div class="list-view list-value cursor-pointer" >
        <div class="img-side">
          <img
            src="./../../../../../assets/images/right-sliding-nav/logout.png"
            alt="item.imgName"
            loading="lazy"
            width="100%"
          />
        </div>
        <div class="name-side" (click)="logout()">Logout</div>
      </div>
    </div>
  </div>
  <div class="openbtn" (click)="openNav()">
    <img
      src="../../../../../assets/images/right-sliding-nav/hamburger.png"
      width="25"
      alt="btn"
    />
  </div>
</section>
