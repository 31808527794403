import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'user-flow-otp-verification',
  templateUrl: './otp-verification.component.html',
  styleUrls: ['./otp-verification.component.scss','./mq-otp-verification.component.scss']
})
export class OtpVerificationComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<OtpVerificationComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any) { 
    {
      dialogRef.disableClose = true;
    }
  }

  ngOnInit(): void {
  }
  closeBtn(){
    this.dialogRef.close();
  }

  verifyotp={
      otp1:'',
      otp2:'',
      otp3:'',
      otp4:'',
      otp5:'',
      otp6:''
    }
    move(fromnum, tonum){
      var length = fromnum.length;
      var maxlength = fromnum.getAttribute(maxlength);
      if(length == maxlength){
        tonum.focus()
      }
    }
}
