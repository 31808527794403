import { MapsAPILoader } from '@agm/core';
import { EventEmitter, Input, NgZone, Output, ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MapSearchLibService } from './../../../../service/map-search/map-search-lib.service';
@Component({
  selector: 'user-flow-map-search',
  templateUrl: './map-search.component.html',
  styleUrls: ['./map-search.component.scss'],
})
export class MapSearchComponent implements OnInit {
  @Input() autocompleteInput: string = null;
  @Input() placeHolderName: string;
  @Input() addressType: string;
  @ViewChild('addresstext') addresstext: any;
  // @ViewChild('message', { static: false }) messageElement: ElementRef;

  @Output() setAddress: EventEmitter<any> = new EventEmitter();

  constructor(
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private _router: Router,
    private _geoLocation: MapSearchLibService, // private _storageService: DbStorageService,
    private _map: MapSearchLibService
  ) {}

  ngOnInit() {
    //console.log("init on google place", this.address);
  }
  address = {
    city: '',
    locality: '',
    country: '',
    state: '',
    pincode: '',
    vicinity: '',
    gender: 'MALE',
  };
  ngAfterViewInit() {
    this.getPlaceAutocomplete();
  }

  geoLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((p) => {
        const geodata = this._geoLocation
          .reverseGeocodding(p.coords.latitude, p.coords.longitude)
          .then((data: any) => {
            const add = data.formatted_address;
            const value = add.split(',');
            const count = value.length;
            this.autocompleteInput = value[count - 4]; // ? value[count - 3]
            this.invokeEvent(this.autocompleteInput);
          })
          .catch((err) => {
            //console.log(err);
          });
        //console.log(geodata);
      });
    } else {
      // console.log('Geo Location Not Supported for browser');
    }
  }

  // private getPlaceAutocomplete() {
  //   this._geoLocation
  //     .autoComplete(this.addresstext.nativeElement, [
  //       'establishment',
  //       'geocode',
  //     ])
  //     .then((data: any) => {
  //       this.invokeEvent(data);
  //     });
  // }
  private getPlaceAutocomplete() {
    this.mapsAPILoader.load().then(() => {
      const autocomplete = new google.maps.places.Autocomplete(
        this.addresstext.nativeElement,
        {
          componentRestrictions: { country: 'IN' },
          types: [this.addressType], // 'establishment' / 'address' / 'geocode' this.addressType
        }
      );
      google.maps.event.addListener(autocomplete, 'place_changed', () => {
        const place = autocomplete.getPlace();
        //console.log("Places From Autocomplete: ", place);
        let placeData = this._map.decomposeAddressComponents([
          { address_components: place.address_components },
        ]);
        this.invokeEvent(placeData);
      });
    });
  }

  invokeEvent(place: Object) {
    this.setAddress.emit(place);
  }
}
