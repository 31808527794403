import { DbStorageService } from './../../../../service/headers/db-storage/db-storage.service';
import { ContatUsService } from './contat-us.service';
import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { PropertyDetailsService } from '../../../property-details/property-details.service';
import { ErrorPopsService } from './../../../../service/error-pops/error-pops.service';

@Component({
  selector: 'user-flow-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss', './mq-contact-us.component.scss'],
})
export class ContactUsComponent implements OnInit {
  addressSearch: any;
  cordinatesValue: any = 'Locate';
  id: any;
  property: any;
  nearBy: any;
  selectedNearby: any;

  constructor(
    private propertyDetailsService: PropertyDetailsService,
    private _route: ActivatedRoute,
    private metaService: Meta,
    private errorPops: ErrorPopsService,
    private contactService: ContatUsService,
    private router: Router,
    private dbStorage: DbStorageService
  ) {}

  zoom = 8;
  userId;
  icon = {
    url: './assets/images/map/map-pin.png',
    scaledSize: { height: 45, width: 30 },
  };

  ngOnInit(): void {
    this.userId = this.dbStorage.getUserId();
    // if (this.userId == 'null' || this.userId == null) {
    // }
    // else {
    //   this.router.navigate(['/'])
    // }
  }
  getAddress(place: any) {
    this.addressSearch = place;
    // console.log(place);
  }
  getCordinates(place: any) {
    this.cordinatesValue = place.city;
  }

  //icon-details section

  iconDet = [
    {
      img: './../../../../../assets/images/contactus/location.png',
      det1: 'ISTHARA Parks Private Limited',
      det2: '3rd Floor, Ayyappa Arcade, Plot no 22,',
      det3: 'Janardhana Hills, Lumbini Avenue,',
      det4: 'Gachibowli, Hyderabad-500032.',
      alt: 'pin-icon',
      href: 'https://goo.gl/maps/VwZC6ugXLS543bc79',
    },
    {
      img: './../../../../../assets/images/contactus/call.png',
      det1: '8790858581',
      alt: 'phone-icon',
      href: 'tel:+91-8790858581',
    },
    {
      img: './../../../../../assets/images/contactus/message.png',
      det1: 'Hello@isthara.com',
      alt: 'mail-icon',
      href: 'mailto:hello@isthara.com',
    },
  ];
  socialMedia = [
    {
      img: './../../../../../assets/images/contactus/facebook.png',
      alt: 'facebook',
      href: 'https://www.facebook.com/IstharaColiving/',
    },
    {
      img: './../../../../../assets/images/contactus/twitter.png',
      alt: 'twitter',
      href: 'https://twitter.com/istharaliving?lang=en',
    },
    {
      img: './../../../../../assets/images/contactus/instagram.png',
      alt: 'instagram',
      href: 'https://www.instagram.com/istharacoliving/?hl=en',
    },
    {
      img: './../../../../../assets/images/contactus/youtube.png',
      alt: 'youtube',
      href: 'https://www.youtube.com/watch?v=zqy7gpj4Xkk',
    },
  ];

  //Contact-Form Validation

  contactForm = {
    name: '',
    mobile:'',
    email: '',
    message: '',
  };
  submit() {
    let data = {
      email: this.contactForm.email,
      message: this.contactForm.message,
      mobile: {
        countryCode: "91",
        mobile: this.contactForm.mobile
      },
      name: this.contactForm.name,
    };
    if (
      this.contactForm.name == null ||
      this.contactForm.name == '' ||
      this.contactForm.email == null ||
      this.contactForm.email == '' ||
      this.contactForm.mobile === '' ||
      this.contactForm.mobile === null
    ) {
      // console.log("error", this.contactForm)
      this.errorPops.openSnackBarMessage('Fill all Mandatory fields!', 'Close');
    } else {
      this.contactService.contactUs(data).subscribe(
        (data: any) => {
          if (data.error) {
            this.errorPops.openSnackBarMessage(data.message, 'ok');
            // console.log("data.error", data.message);
            // console.log("main data", data)
          } else {
            this.errorPops.openSnackBarMessage(
              'Our Customer Executive contact you Soon',
              'OK'
            );
            // console.log("else", data);
            // this.router.navigate(['']);
            window.location.reload();
          }
        },
        (err) => {
          this.errorPops.openSnackBarMessage(err.error.message, 'Cancel');
          // console.log("err", err.error.message);
        }
      );
    }
  }
}
