<section>
  <div class="main-container">
    <ng-container *ngFor="let item of data; let c = index">
      <div class="carousel-box">
        <div class="inverted-coma">
          <img
            src="./../../../../../assets/images/carousel/comma.png"
            alt="comma"
          />
        </div>
        <div class="text-section">
          <p class="description">
            {{ item.describe }}
          </p>
          <p class="title">
            {{ item.title | titlecase }}
          </p>
          <p class="desg">{{ item.designation | titlecase }}</p>
        </div>
      </div>
    </ng-container>
  </div>
</section>
