import {
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'user-flow-common-amenities',
  templateUrl: './common-amenities.component.html',
  styleUrls: ['./common-amenities.component.scss'],
})
export class CommonAmenitiesComponent implements OnInit, OnChanges {
  @Input() commonAmenitiesList: any[];
  @Input() property: any;
  @Input() diamentions: any;
  constructor() { }
  ngOnChanges(changes: SimpleChanges): void {
    // console.log(changes.commonAmenitiesList.currentValue);
  }

  ngOnInit(): void { }
}
