<section *ngIf="updatedPropList.length > 0">
  <div class="main-block">
    <div class="text-section">
      <p class="company-name">{{ premiumProperty.title | titlecase }}</p>
      <p class="description">
        {{ premiumProperty.subtitle }}
      </p>
    </div>
  </div>
  <div class="arrows-main">
    <a class="arrow-left">
      <img
        src="./../../../../assets/images/visit/left.png"
        alt="left"
        (click)="previosScroll()"
        #prev
      />
    </a>
    <a class="arrow-right">
      <img
        src="./../../../../assets/images/visit/right.png"
        alt="right"
        (click)="nextScroll()"
        #next
      />
    </a>
  </div>
  <div class="property-block fixed-zone-section" #scroll>
    <div class="zone-list-section">
    <ng-container *ngFor="let prop of updatedPropList">
      <div class="property-box" (click)="detailProperty(prop.propertyId,prop)">
        <div class="img-box">
          <img [src]="prop?.images?.imageUrl" [alt]="prop.altText" class="prop-img" />
          <div class="gender-box">
            <img
              [src]="
                './../../../assets/images/property-list/' +
                prop.propertyType +
                '.png'
              "
              alt="gender"
              loading="lazy"
            />
            {{ prop.propertyType.replace("_", " ") | titlecase }}
          </div>
        </div>
        <div class="prop-detail">
          <p class="prop-name">{{ prop.propertyName | titlecase }}</p>
          <div class="add-distance">
            <div class="address">
              <span
                ><img
                  src="./../../../../assets/images/pin.png"
                  alt="pin"
                  class="pin-loc"
                />{{ prop.propertyAddress.locality | titlecase
                }}<span *ngIf="prop.propertyAddress.city">,&nbsp;</span
                >{{ prop.propertyAddress.city | titlecase }}</span
              >
            </div>
            <div class="distance">{{ prop.distance }}</div>
          </div>
          <div class="start">Starting from</div>
          <div class="amount">₹ {{ prop.startingFrom }}/M</div>
        </div>
      </div>
    </ng-container>
  </div>
  </div>
</section>
