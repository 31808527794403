<section>
  <div class="header">
    <user-flow-top-navbar></user-flow-top-navbar>
  </div>
  <!-- <div class="search-bar">
    <div class="main-search">
      <div class="location-drop-down">
        <span class="icon">
          <user-flow-map-spot-cordinates
            (setAddress)="getCordinates($event)"
          ></user-flow-map-spot-cordinates>
        </span>
        <span class="location">{{ cordinatesValue | titlecase }}</span>
      </div>
      <div class="search-box">
        <user-flow-map-search
          #place
          (setAddress)="getAddress($event)"
          [placeHolderName]="'Search by locality or city'"
        >
        </user-flow-map-search>
      </div>
    </div>
  </div> -->
  <div class="main-section">
    <p class="frequently">Frequently Asked Questions</p>
    <p class="sub-text">We are here to help you what you need.</p>
    <div class="mat-mainsection">
      <div
        class="mat-section"
        *ngFor="let mt of faq; let i = index"
        (opened)="setOpened(i)"
        (closed)="setClosed(i)"
      >
        <mat-expansion-panel class="background-heading">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="question">
                {{ mt.question }}
              </div></mat-panel-title
            >
          </mat-expansion-panel-header>
          <div class="answer">
            {{ mt.answer }}
          </div>
        </mat-expansion-panel>
      </div>
    </div>
  </div>
  <div>
    <app-footer></app-footer>
  </div>
</section>
