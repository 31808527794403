import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'user-flow-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.scss', 'mq-faqs.component.scss'],
})
export class FaqsComponent implements OnInit {
  addressSearch: any;
  cordinatesValue: any = 'Locate';
  selectedNearby: any;
  currentlyOpenedItemIndex = -1;
  faq = [
    {
      question: 'What is Isthara?',
      answer:
        'In Isthara it is our DNA to provide the best services and amenities to our residents and even in these challenging times, we have taken extra measures to deliver the best for our Residents.',
    },
    {
      question: 'What properties are there at Isthara?',
      answer:
        'In Isthara it is our DNA to provide the best services and amenities to our residents and even in these challenging times, we have taken extra measures to deliver the best for our Residents.',
    },
    {
      question: 'What are the room varients their in Isthara?',
      answer:
        'In Isthara it is our DNA to provide the best services and amenities to our residents and even in these challenging times, we have taken extra measures to deliver the best for our Residents.',
    },
    {
      question: 'What properties are there at Isthara?',
      answer:
        'In Isthara it is our DNA to provide the best services and amenities to our residents and even in these challenging times, we have taken extra measures to deliver the best for our Residents.',
    },
    {
      question: 'What are the room varients their in Isthara?',
      answer:
        'In Isthara it is our DNA to provide the best services and amenities to our residents and even in these challenging times, we have taken extra measures to deliver the best for our Residents.',
    },
    {
      question: 'What properties are there at Isthara?',
      answer:
        'In Isthara it is our DNA to provide the best services and amenities to our residents and even in these challenging times, we have taken extra measures to deliver the best for our Residents.',
    },
    {
      question: 'What are the room varients their in Isthara?',
      answer:
        'In Isthara it is our DNA to provide the best services and amenities to our residents and even in these challenging times, we have taken extra measures to deliver the best for our Residents.',
    },
  ];

  constructor() {}
  icon = {
    url: './assets/images/map/map-pin.png',
    scaledSize: { height: 45, width: 30 },
  };

  ngOnInit(): void {}

  getAddress(place: any) {
    this.addressSearch = place;
    // console.log(place);
  }
  getCordinates(place: any) {
    this.cordinatesValue = place.city;
  }

  setOpened(itemIndex) {
    this.currentlyOpenedItemIndex = itemIndex;
  }

  setClosed(itemIndex) {
    if (this.currentlyOpenedItemIndex === itemIndex) {
      this.currentlyOpenedItemIndex = -1;
    }
  }
}
