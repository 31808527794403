import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PopupService } from '../../../popup-components/popup.service';
import { CareersService } from '../careers.service';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'user-flow-all-jobs',
  templateUrl: './all-jobs.component.html',
  styleUrls: ['./all-jobs.component.scss','./mq-all-jobs.component.scss']
})
export class AllJobsComponent implements OnInit {
  cityGroup
  departmentGroup

  constructor(
    private popupService: PopupService,
    private carrerService: CareersService,
    private router : Router,
    private fb : FormBuilder
  ) { 
  this.cityGroup = fb.group({
    city:'null'
  });
  this.departmentGroup = fb.group({
    department:'null'
  })
  }
  department=null;
  llocation=null;
  type;
  submitLoading:boolean = false;
  resetLoading:boolean = false;
  showMore = false;

  ngOnInit(): void {
    this.getallJobs();
  }
  location=[
    {name:'Hyderabad',value:'HYDERABAD'},
    {name:'Benguluru',value:'BENGALURU'},
    {name:'Gurugram',value:'GURUGRAM'},
  ]
  Department = [
    {name:'IT',value:'IT'},
    {name:'Sales',value:'SALES'},
    {name:'Operations',value:'OPERATIONS'},
    {name:'Corporate',value:'CORPORATE'},
    {name:'Kitchen',value:'KITCHEN'}
  ]
  jobType = [
    {name:'FullTime', value:'FULLTIME',status:false},
    {name:'PartTime', value:'PARTTIME',status:false},
    {name:'Contract', value:'CONTRACT',status:false}
  ]
  jobtypeArr = [];
  selectlocation(value){
    this.llocation = value;
  }
  selectDepartment(value){
    this.department = value;
  }
  selectjobType(value,flag){
    flag = flag ? false : true;
    this.jobType.forEach((item) => {
      if(value === item.value){
        item.status = flag;
      }
    });
    if(flag === true){
      // this.type = value;
      this.jobtypeArr.push(value);
    }
    else{
      // this.type = '';
      this.jobtypeArr.forEach((pid) => {
        if(pid === value){
          let index = this.jobtypeArr.indexOf(pid);
          if(index > -1){
            this.jobtypeArr.splice(index,1);
          }
          return this.jobtypeArr;
        }
      })
      // console.log(this.jobtypeArr);
    }
    // console.log(this.jobType);
  }
  submitSearch(){
    if((this.llocation === '' || this.llocation === null) && (this.department === '' || this.department === null) && 
    (this.jobtypeArr === [])){
      let data ={
        city:null,
        jobName:null,
        jobType:[]
      }
      this.submitLoading = true;
      this.carrerService.filter(data).subscribe(
        (res:any) =>{
          this.careers = res.data.reverse();
          this.submitLoading = false
        },
        (err) => {
          console.log(err);
        }
      )
    }
    else{
      let data = {
        city:this.llocation,
        department:this.department,
        jobType:this.jobtypeArr
      }
      this.submitLoading = true;
      this.carrerService.filter(data).subscribe(
        (res:any) =>{
          this.careers = res.data.reverse();
          this.submitLoading = false;
        },
        (err) => {
          console.log(err);
        }
      )
    }
  }
  resetSearch(){
    this.cityGroup.reset();
    this.departmentGroup.reset();
    this.llocation= null;
    this.department= null;
    this.jobType.forEach(element => {
      element.status = false
    });
    this.jobtypeArr = [];
    let data ={
      city:null,
      jobName:null,
      jobType:[]
    }
    console.log(data);
    this.resetLoading = true;
    this.carrerService.filter(data).subscribe(
      (res:any) =>{
        this.careers = res.data.reverse();
        this.resetLoading = false
      },
      (err) => {
        console.log(err);
      }
    )
  }
  careers = [];
  getallJobs(){
    this.carrerService.getAllJobs(true).subscribe(
      (res:any) => {
        this.careers = res.data.reverse();
      },
      (err) => {
        console.log(err);
      }
    )
  }
  jobDetails:any;
  jobDesc : any[] = [];
  viewMore(jobId){
    this.router.navigate(['/details'],
    {queryParams : {id:jobId}})
    
  }
  mailTo(name,code){
    let mailText = `mailto:hr@isthara.com?cc=paul@isthara.com&subject=Job%20Application%20:%20${name}(${code})`
    window.location.href = mailText;
  }

}
