import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PopupService } from '../../../popup-components/popup.service';
import { CareersService } from '../careers.service';


@Component({
  selector: 'user-flow-job-details',
  templateUrl: './job-details.component.html',
  styleUrls: ['./job-details.component.scss','./mq-job-details.component.scss']
})
export class JobDetailsComponent implements OnInit {

  constructor(
    private carrerService: CareersService,
    private route : ActivatedRoute,
    private router : Router
  ) { }

  jobDetails:any;
  jobDesc : any[] = [];
  jobId;
  loading : boolean = false;
  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      // console.log("params",params);
      this.jobId = params.id
    });
    this.loading = false
    this.carrerService.jobBydetails(this.jobId).subscribe(
      (res:any) => {
        // console.log("res",res.data);
        this.jobDetails = res.data;
        this.jobDesc = this.jobDetails.jobDescription.replace(/(\.+|\:|\!|\?)(\"*|\'*|\)*|}*|]*)(\s|\n|\r|\r\n)/gm, "$1$2|").split("|")
        this.loading = true
        // console.log(this.jobDesc);
      },
      (err) => {
        console.log(err);
      }
    )
  }
  closeBtn(){
    // this.showMore = false;
  this.router.navigate(['/allJobs'])    
  }

}
