<section>
  <div class="room-list-box">
    <ng-container *ngFor="let room of rooms; index as j">
      <div class="room-details">
        <!-- <div class="image-carousel" *ngIf="room?.images;else noImg">
          <user-flow-single-image-carousel
            [imageSet]="room.images"
            [classId]="'propDetails' + (j + 1)"
          >
          </user-flow-single-image-carousel>
        </div> -->
        <ng-template #noImg>
          <img src="./../../../../assets/images/property-list/no-img.svg" 
          width="100%"
          height="144">
        </ng-template>
        <div
          class="sharing-pricing"
          [ngClass]="{ selection: selection == false }"
        >
          <div class="sharing-bed">
            <p class="sharing-type">{{ room.sharingCount }} Sharing Bed</p>
            <p class="monthly-price">
              <b>Rs {{ room.roomPrice.monthly }}/M</b>
            </p>
            <p
              *ngIf="selection"
              class="selectRoom"
              (click)="selectedRoom(room)"
              [ngStyle]="
                room.isSharingCount == true
                  ? { 'background-color': '#ff1872', color: 'white' }
                  : {
                      'background-color': 'white',
                      color: 'black',
                      border: '1px solid'
                    }
              "
            >
              {{ selectroom1.name }}
            </p>
          </div>
          <!-- <div
            class="verticle-line"
            [ngClass]="{ convertable: room?.convertable == false }"
          ></div> -->
          <!-- <div class="verticle-line"></div> -->
          <!-- <div
            class="room-type"
            [ngClass]="{ convertable: room?.convertable == false }"
          > -->
          <!-- <div class="room-type">
            <p class="sharing-type">{{ room.roomTypeValues }} room</p>
            <p class="sharing-type">PRIVATE room</p>
            <p class="monthly-price">
              Rs
              {{
                room.roomPrice.monthly * room.sharingCount -
                  room.roomPrice.discount
              }}/M
            </p>
            <p *ngIf="room.roomPrice.discount && room.roomPrice.discount != 0" class="discount">
              <del> Rs {{ room.roomPrice.monthly * room.sharingCount }}/M </del>
            </p>
            <p *ngIf="room.roomPrice.discount" class="discount-box">
              Rs {{ room.roomPrice.discount }} OFF
            </p>
            <p
              *ngIf="selection"
              class="selectOfferRoom"
              (click)="selectOfferRoom(room)"
              [ngStyle]="
                room.isPrivateRoom == true
                  ? { 'background-color': '#ff1872', color: 'white' }
                  : {
                      'background-color': 'white',
                      color: 'black',
                      border: '1px solid'
                    }
              "
            >
              {{ selectroom2.name }}
            </p>
          </div> -->
        </div>
        <!-- <div class="amenities">
          <div class="room-heading">Room Amenities</div>
          <div class="list-am" *ngIf="room.roomAmenities; else noIcon">
            <ng-container *ngFor="let item of room.roomAmenities">
              <div class="roomAmenities">
                <img
                  [src]="item?.icon"
                  alt="amenities"
                  width="18%"
                />
                <span class="amenities-name">
                  {{ item?.name }}
                </span>
              </div>
            </ng-container>
        </div>
        <ng-template #noIcon>
          <div class="no-am">
            No Amenities 
          </div>            
        </ng-template>
          <div class="list-am">
            <div class="df">
              <img src="./../../../../assets/images/amenities/hk.png" />
              <p class="room-text">House Keeping</p>
            </div>
          </div>
        </div> -->
      </div>
    </ng-container>
  </div>
</section>
