<section>
  <div class="top-nav">
    <user-flow-top-navbar></user-flow-top-navbar>
  </div>
  <div class="main-section">
    <!-- <div class="search-bar">
            <div class="main-search">
              <div class="location-drop-down">
                <span class="icon">
                  <user-flow-map-spot-cordinates
                    (setAddress)="getCordinates($event)"
                  ></user-flow-map-spot-cordinates>
                </span>
                <span class="location">{{ cordinatesValue | titlecase }}</span>
              </div>
              <div class="search-box">
                <user-flow-map-search
                  #place
                  (setAddress)="getAddress($event)"
                  [placeHolderName]="'Search by locality or city'"
                >
                </user-flow-map-search>
              </div>
            </div>
        </div> -->

    <div class="contact-section">
      <div class="details-section">
        <div class="big-text">Contact Us</div>
        <div class="icon-det" *ngFor="let id of iconDet">
          <div class="icon">
            <img src="{{ id.img }}" alt="{{ id.alt }}" />
          </div>
          <div class="det">
            <a href="{{ id.href }}">
              <p>{{ id.det1 }}</p>
              <p>{{ id.det2 }}</p>
              <p>{{ id.det3 }}</p>
              <p>{{ id.det4 }}</p>
            </a>
          </div>
        </div>
        <div class="social-media">
          <div class="sub-text">
            You can also contact through our social networks
          </div>
          <div class="social-icon">
            <div class="fa-icon" *ngFor="let sm of socialMedia">
              <a href="{{ sm.href }}" target="_blank"
                ><img src="{{ sm.img }}" alt="{{ sm.alt }}"
              /></a>
            </div>
          </div>
        </div>
      </div>
      <div class="contact-form">
        <div class="form-heading" align="center">Send us an Email</div>
        <div class="fields">
          <div class="name">
            <mat-form-field class="input-width" appearance="outline">
              <mat-label>Name</mat-label>
              <input
                matInput
                [(ngModel)]="contactForm.name"
                #nameCheck="ngModel"
                pattern="[a-zA-Z\s]+"
                required
              />
              <mat-error
                *ngIf="
                  !nameCheck.valid && (nameCheck.dirty || nameCheck.touched)
                "
              >
                Please enter name
              </mat-error>
            </mat-form-field>
          </div>
          <div class="mobile">
            <mat-form-field class="input-width" appearance="outline">
              <mat-label>Mobile Number</mat-label>
              <input
                matInput
                [(ngModel)]="contactForm.mobile"
                #mobileCheck="ngModel"
                pattern="^[6-9]\d{9}$|^[1-9]\d{9}$"
                maxlength="10"
                type="number"
                required
              />
              <mat-error
                *ngIf="
                  !mobileCheck.valid && (mobileCheck.dirty || mobileCheck.touched)
                "
              >
                Please enter Mobile number
              </mat-error>
            </mat-form-field>
          </div>
          <div class="email">
            <mat-form-field class="input-width" appearance="outline">
              <mat-label>Email</mat-label>
              <input
                matInput
                [(ngModel)]="contactForm.email"
                #emailCheck="ngModel"
                placeholder="abc@example.com"
                pattern="^([_a-z0-9]+(.[_a-z0-9]+)*@[a-z0-9-]+(.[a-z0-9-]+)*(.[a-z]{2,5}))|(d+$)$"
                required
              />
              <mat-error> Please Enter Valid mail address </mat-error>
            </mat-form-field>
          </div>
          <div class="message">
            <mat-form-field class="input-width" appearance="outline">
              <mat-label>Message</mat-label>
              <textarea
                matInput
                [(ngModel)]="contactForm.message"
                rows="4"
                cols="50"
              ></textarea>
            </mat-form-field>
          </div>
          <div class="btn">
            <button class="submit-btn" (click)="submit()">Submit</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footer">
    <app-footer></app-footer>
  </div>
</section>
