import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from './../../../../environments/environment';
import { DbStorageService } from './../../../service/headers/db-storage/db-storage.service';

@Injectable({
  providedIn: 'root',
})
export class ListPropertyService {
  constructor(private http: HttpClient,
    private _dbStorage : DbStorageService) {}

  // propertyList() {
  //   return this.http.get('./../../../assets/json/propertyList.json');
  // }
  getPropertyList(){
    
    return this.http.get(
      environment.authenticationUrl+'property/property/ist/list'
    )
  }
  addFavourites(favData){
    const token = this._dbStorage.getToken()
    var headers_object = new HttpHeaders().set("Authorization", "Bearer " + token);
    return this.http.post(
      environment.authenticationUrl+'property/property/ist/markfavouriteproperty',favData,
      {headers: headers_object}
    )
  }
  getFavourites(id){
    return this.http.get(
      environment.authenticationUrl+`property/property/ist/favouriteproperties/${id}`
    )
  }
}
