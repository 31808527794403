import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { environment } from './../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PropertyDetailsService {
  constructor(private http: HttpClient, public dialog: MatDialog) {}
  // propertyDetails(propId) {
  //   // return this.http.get('./../../../assets/json/propertyList.json');
  //   return this.http.get(
  //     environment.authenticationUrl+'property/property/list',propId
  //   )
  // }
  propertyDetails(id) {
    return this.http.get(
      environment.authenticationUrl+`property/property/ist/${id}`
    );
  }
  home() {
    // return this.http.get('./../../../assets/json/home.json')

    
    return this.http.get(environment.authenticationUrl + 'property/ist/home/bdc21f16-1e81-413d-b4f8-e937c1431982');

  }

  openDialog(component, data) {
    return this.dialog.open(component, {
      width: data.width,
      height: data.height,
      maxWidth:data.maxWidth,
      data: data,
    });
  }

  enquiryDialog(component, data) {
    return this.dialog.open(component, {
      width: '350px',
      height: 'auto',
      data: data,
    });
  }
  checkFromErp(isErp,propId){
    return this.http.get(
      environment.authenticationUrl+`property/property/ist/${propId}?isFromErp=${isErp}`
    )
  }
  propertydetails(data){
    return this.http.post(
      environment.authenticationUrl+`property/property/ist/search`,data
    )
  }
}
