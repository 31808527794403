import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpEvent,
  HttpResponse,
  HttpRequest,
  HttpHandler,
  HttpErrorResponse,
  HttpClient,
} from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { DbStorageService } from '../../service/headers/db-storage/db-storage.service';
import { catchError } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class UserInterceptor implements HttpInterceptor {
  constructor(
    private dbStorageService: DbStorageService,
    private _snackBar: MatSnackBar,
    private http: HttpClient
  ) {}
  intercept(
    httpRequest: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const API_KEY = this.dbStorageService.getToken();
    if (API_KEY) {
      return next
        .handle(
          httpRequest.clone({
            setHeaders: { Authorization: `${API_KEY}` },
          })
        )
        .pipe(
          catchError((error: HttpErrorResponse) => {
            let errorMsg = '';
            if (error.error instanceof ErrorEvent) {
              console.log('this is client side error');
              errorMsg = `Error: ${error.error.message}`;
              console.log(
                `Error: ${error.error.message}, request: ${httpRequest}`
              );
            } else {
              console.log('this is server side error');
              errorMsg = `Error Code: ${error.status},  Message: ${error.message}, request: ${httpRequest}`;
              console.log(
                `Error Code: ${error.status},  Message: ${error.message}, request: ${httpRequest}`
              );
              if (error.status === 401) {
                this.handle401Error(httpRequest, next);
              }
            }
            console.log('this is server side error');
            // alert('Inernal Server Error!');
            // this._snackBar.open(
            //   'Something Went Wrong, Please Try Again!',
            //   'Close',
            //   {
            //     duration: 3000,
            //   }
            // );
            return throwError(errorMsg);
          })
        );
    } else {
      return next.handle(httpRequest).pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMsg = '';
          if (error.error instanceof ErrorEvent) {
            console.log('this is client side error');
            errorMsg = `Error: ${error.error.message}`;
          } else {
            console.log('this is server side error');
            errorMsg = `Error Code: ${error.status},  Message: ${error.message}`;
            this._snackBar.open(
              error.error.message,
              'Close',
              {
                duration: 3000,
              }
            );
          }
          console.log('this is server side error');

          // console.log(errorMsg);
          // alert('Inernal Server Error!');
          // this._snackBar.open(
          //   'Something Went Wrong, Please Try Again!',
          //   'Close',
          //   {
          //     duration: 3000,
          //   }
          // );
          return throwError(errorMsg);
        })
      );
    }
  }
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.refreshToken().subscribe((token: any) => {
        console.log('refreshed token: ', token);
        this.dbStorageService.setToken(token);
        window.location.reload();
        // information on token as header has to come from chaithanya he is checking it.
        // this.isRefreshing = false;
        // this.refreshTokenSubject.next(token.jwt);
        // return next.handle(this.addToken(request, token.jwt));
      });
    } else {
      return this.refreshTokenSubject.pipe();
    }
  }

  refreshToken() {
    return this.http.post<any>(
      environment.authenticationUrl + `gateway/token`,
      null
    );
  }
}
