<section>
  <div class="top-nav">
    <user-flow-top-navbar></user-flow-top-navbar>
  </div>
  <div class="main-section" #intial>
    <div class="main-content">
      <div class="main-heading">Refer And Earn</div>
      <div class="main-subtext">
        You get Rs750 and your friend get 750 off on booking fee and also get
        upto 6months free stay
      </div>
      <hr />
      <div class="service-content">
        <div class="image-part">
          <img src="./../../../../assets/images/refer-earn/referandearn.png" />
        </div>
        <div class="right-total">
          <div class="right-side">
            <div class="btn">
              <button>Refer a Tenant</button>
            </div>
            <div class="price-discount">750 Off</div>
            <div class="discount-desc">
              Refer a friend and win Rs 750 once he create a account on Isthara.
            </div>
            <div class="refer-now" (click)="referfriend()">
              <button>Refer Now</button>
            </div>
          </div>
          <div class="right-side">
            <div class="btn-bottom">
              <button>Refer a Property Owner</button>
            </div>
            <div class="price-discount">950 Off</div>
            <div class="discount-desc">
              Refer a property and win Rs 950 once he create a account on
              Isthara.
            </div>
            <div class="refer-now" (click)="referfriend()">
              <button>Refer Now</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="main-totalcontainer" *ngIf="list > 0;">
    <div class="main-continer">
      <div class="main-total">
        <div class="main-heading">Refer And Earn</div>
        <div class="main-subtext">Earn Reward by invite a friend to livet.</div>
      </div>
      <div class="main-sub">
        <div class="points">Points Earned This Month</div>
        <div class="scored-points">2,500</div>
      </div>
    </div>
    <div class="horizontal"><hr /></div>

    <div class="total-points">
      <div class="total-reedem" *ngFor="let point of points">
        <div class="total-points">{{ point.score }}</div>
        <div class="total-subscore">{{ point.total }}</div>
      </div>
      <div class="pink-button">
        <button>REDEEM NOW</button>
      </div>
    </div>
    <div class="total-search">
      <div class="referatenant">
        <div class="refer-search">
          <input type="text" placeholder="Refer a Tenant" />
        </div>
        <div class="refer-btn">
          <button>Get 750 Off</button>
        </div>
      </div>

      <div class="referatenant">
        <div class="refer-search">
          <input type="text" placeholder="Refer a Property Owner" />
        </div>
        <div class="refer-btn">
          <button>Get 750 Off</button>
        </div>
      </div>
    </div>
    <div class="info-table">
      <table>
        <tr>
          <th>Name</th>
          <th>Referal Type</th>
          <th>Contact</th>
          <th>Status</th>
          <th>Action</th>
        </tr>
        <tr *ngFor="let data of tableData">
          <td>{{ data.name }}</td>
          <td>{{ data.refral }}</td>
          <td>{{ data.contact }}</td>
          <td>{{ data.status }}</td>
          <td>
            <div class="actions">
              <button class="remind-btn" (click)="reminder()">Remind |</button>
              <button class="cancel-btn">Cancel Invite</button>
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div> -->
  <div class="footer">
    <app-footer></app-footer>
  </div>
</section>
