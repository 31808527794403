import { DbStorageService } from './../../../../service/headers/db-storage/db-storage.service';
import { Router } from '@angular/router';
import {
  Component,
  OnInit,
  Inject,
  NgZone,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { PopupService } from './../../popup.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MapsAPILoader } from '@agm/core';

@Component({
  selector: 'user-flow-add-place',
  templateUrl: './add-place.component.html',
  styleUrls: ['./add-place.component.scss'],
})
export class AddPlaceComponent implements OnInit {
  btnSection = [
    { name: 'Home', type: 'Home' },
    { name: 'College', type: 'College' },
    { name: 'Office', type: 'Office' },
    { name: 'Near', type: 'Near' },
  ];
  // zoom = 8;
  icon = {
    url: './assets/images/map/map-pin.png',
    scaledSize: { height: 45, width: 30 },
  };
  btnType;
  userId;
  title: string = 'AGM project';
  latitude: number;
  longitude: number;
  zoom: number;
  address: string;
  private geoCoder;

  @ViewChild('search')
  public searchElementRef: ElementRef;

  constructor(
    public dialogRef: MatDialogRef<AddPlaceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private popService: PopupService,
    private router: Router,
    private _dbStorage: DbStorageService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone
  ) {}

  spaceInfoData = {
    locationDetail: {
      city: '',
      addressLine1: '',
      addressLine2: '',
      locality: '',
      country: '',
      state: '',
      zipCode: Number,
      geoLocation: {
        lat: 19.673858,
        lng: 80.815982,
        draggable: true,
        label: '',
      },
    },
    propertyImageUrls: null,
    address_level_1: '',
    address_level_2: '',
    address_state: '',
    address_country: '',
    address_zip: '',
  };

  ngOnInit(): void {
    this.userId = this._dbStorage.getUserId();
    //load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder();

      // let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      // autocomplete.addListener("place_changed", () => {
      //   this.ngZone.run(() => {
      //     //get the place result
      //     let place: google.maps.places.PlaceResult = autocomplete.getPlace();

      //     //verify result
      //     if (place.geometry === undefined || place.geometry === null) {
      //       return;
      //     }

      //     //set latitude, longitude and zoom
      //     this.latitude = place.geometry.location.lat();
      //     this.longitude = place.geometry.location.lng();
      //     this.zoom = 12;
      //   });
      // });
    });
  }

  closeBtn() {
    this.dialogRef.close();
  }

  setAddress(place: any) {
    this.spaceInfoData.locationDetail = place;
    // console.log(place);
    this.getAddress(this.latitude, this.longitude);
  }
  // getLocationService():Promise<any>{
  //   return new Promise((resolve,reject) =>{
  //     navigator.geolocation.getCurrentPosition( resp =>{
  //       resolve({lng:resp.coords.longitude, lat:resp.coords.latitude})
  //     })
  //   }
  //   )
  // }
  // getLocation(l){
  //   this.getLocationService().then(
  //     (resp) =>{
  //       console.log(resp.lat)
  //       console.log(resp.lng)
  //     }
  //   )
  // }

  btnFunction(i) {
    this.btnType = this.btnSection[i].type;
  }

  // API INTEGRATION FOR CREATE LOCATION
  setDetails() {
    let locationData = {
      addressDetails: {
        addressLine1: 'null',
        addressLine2: 'null',
        city: this.spaceInfoData.locationDetail.city,
        country: this.spaceInfoData.locationDetail.country,
        geoLocation: {
          latitude: 0,
          longitude: 0,
        },
        locality: this.spaceInfoData.locationDetail.locality,
        state: this.spaceInfoData.locationDetail.state,
      },
      addressType: this.btnType,
      userId: this.userId,
    };
    this.popService.createLocation(locationData).subscribe(
      (res: any) => {
        // console.log(res)
        this.closeBtn();
        this.router.navigate(['/']);
      },
      (err: any) => {
        // console.log(err)
      }
    );
  }

  // Get Current Location Coordinates
  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 8;
        this.getAddress(this.latitude, this.longitude);
      });
    }
  }
  fullAddress: any;
  markerDragEnd(m: any) {
    // console.log($event);
    this.latitude = m.coords.lat;
    this.longitude = m.coords.lng;
    this.spaceInfoData.locationDetail.geoLocation.lat = m.coords.lat;
    this.spaceInfoData.locationDetail.geoLocation.lng = m.coords.lng;
    this.getAddress(this.latitude, this.longitude);
    this.fullAddress = this.address.split(',');
    // console.log("full address",this.fullAddress)
    this.spaceInfoData.locationDetail.addressLine1 = this.fullAddress[0];
    this.spaceInfoData.locationDetail.addressLine2 = this.fullAddress[1];
    this.spaceInfoData.locationDetail.city = this.fullAddress[5];
    this.spaceInfoData.locationDetail.country = this.fullAddress[7];
    this.spaceInfoData.locationDetail.geoLocation.lat = this.latitude;
    this.spaceInfoData.locationDetail.geoLocation.lng = this.longitude;
    this.spaceInfoData.locationDetail.locality = this.fullAddress[4];
    this.spaceInfoData.locationDetail.state = this.fullAddress[6];
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode(
      { location: { lat: latitude, lng: longitude } },
      (results, status) => {
        // console.log(results);
        // console.log(status);
        if (status === 'OK') {
          if (results[0]) {
            this.zoom = 12;
            this.address = results[0].formatted_address;
          } else {
            window.alert('No results found');
          }
        } else {
          window.alert('Geocoder failed due to: ' + status);
        }
      }
    );
  }
}
