<section>
  <div class="main-block">
    <div class="align-block">
      <div class="text-section">
        <p class="company-name" [innerHTML]="smartStay?.title">
          {{ serviceBox.name | titlecase }}
        </p>
        <!-- <p class="subtext">
        {{ serviceBox.subText | titlecase }}
      </p> -->
        <p class="description">
          {{ smartStay?.description }}
        </p>
        <p class="download">Download the App</p>
        <div class="download-btn">
          <div class="android btn-img">
            <a href="http://bit.ly/3eaOtf7">
              <img
                src="./../../../../assets/images/home/playstore.png"
                alt="android"
              />
            </a>
          </div>
          <div class="apple btn-img">
            <a href="http://apple.co/3c3KvSR">
              <img
                src="./../../../../assets/images/home/appstore.png"
                alt="apple-store"
              />
            </a>
          </div>
        </div>
      </div>
      <div class="service-section">
        <div class="services-box">
          <img
            class="application-img"
            [src]="smartStay?.imageUrl"
            alt="application"
          />
        </div>
      </div>
    </div>
  </div>
</section>
