// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  authenticationUrl: 'https://devapi.isthara.com/',
  cashFree: 'https://test.cashfree.com/billpay/checkout/post/submit',
  image: {
    Version: 1.0,
    CredentialsProvider: {
      CognitoIdentity: {
        Default: {
          PoolId: 'us-east-1:a50ade6f-3713-48a8-a8c4-15ce1237ac6d',
          Region: 'us-east-1',
        },
      },
    },
    IdentityManager: {
      Default: {},
    },
    S3TransferUtility: {
      Default: {
        Bucket: 'isthara2',
        Region: 'us-east-1',
      },
    },
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
