<section>
  <div class="loft-details">
    <user-flow-top-navbar></user-flow-top-navbar>
  </div>
  <div class="main">
    <div class="first-section" *ngFor="let ss of studentSection">
      <div class="student-living">
        <span class="first-heading">
          {{ ss.heading }}
        </span>
        <br />

        <span class="second-heading">{{ ss.headingTwo }}</span>
        <p class="student-text">{{ ss.desc }}.</p>
        <!-- <div class="video" (click)="showvideo()">
          <div class="video" (click)="showvideo()"></div>
          <img src="{{ ss.icon }}" alt="" width="41px" height="41px" />
          <p class="v-text">{{ ss.desc1 }}</p>
        </div> -->
      </div>
      <img src="{{ ss.img }}" alt="" width="50%" loading="lazy"/>
    </div>

    <div class="second-section" *ngFor="let ss of transformedLiving">
      <div class="transformed-living">
        {{ ss.text }}
        <p class="text">{{ ss.heading }}</p>

        <p class="sub-text1">{{ ss.desc }}</p>
        <p class="sub-text1">{{ ss.desc1 }}</p>
        <p class="sub-text1">{{ ss.desc2 }}</p>
      </div>
      <img src="{{ ss.img }}" alt=""  loading="lazy" class="transformed-image"/>
    </div>

    <div class="first-half-section">
      <ng-container *ngFor="let item of imageFood">
        <img class="food-list" [src]="item.name" alt="name" loading="lazy"/>
      </ng-container>
    </div>

    <div class="fifth-section">
      <div>
        <p class="live">Isthara Food Court Transformation</p>
        <p class="easy">How We Do it.</p>
      </div>
      <div class="blog">
        <ng-container *ngFor="let b of blog; let i = index">
          <div *ngIf="i == 0" class="eat-box firstbox">
            <div>
              <img src="{{ b.img }}" alt="" loading="lazy"/>
            </div>
            <div class="v-eat">
              <p class="eat">{{ b.subHead }}</p>
              <p>{{ b.desc }}</p>
              <p>{{ b.desc1 }}</p>
              <p>{{ b.desc2 }}</p>
            </div>
          </div>
          <div *ngIf="i == 1" class="eat-box secondBox">
            <div>
              <img src="{{ b.img }}" alt="" />
            </div>
            <div class="v-eat">
              <p class="eat">{{ b.subHead }}</p>
              <p>{{ b.desc }}</p>
              <p>{{ b.desc1 }}</p>
              <p>{{ b.desc2 }}</p>
            </div>
          </div>
          <div *ngIf="i == 2" class="eat-box thirdBox">
            <div>
              <img src="{{ b.img }}" alt="" />
            </div>
            <div class="v-eat">
              <p class="eat">{{ b.subHead }}</p>
              <p>{{ b.desc }}</p>
              <p>{{ b.desc1 }}</p>
              <p>{{ b.desc2 }}</p>
            </div>
          </div>
          <div *ngIf="i == 3" class="eat-box fourthBox">
            <div>
              <img src="{{ b.img }}" alt=""  loading="lazy"/>
            </div>
            <div class="v-eat">
              <p class="eat">{{ b.subHead }}</p>
              <p>{{ b.desc }}</p>
              <p>{{ b.desc1 }}</p>
              <p>{{ b.desc2 }}</p>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="seventh-section">
      <div>
        <p class="idea">Impressed? There’s more where that came from.</p>
        <p class="help">
          To know more about how the Isthara Food Court can benefit your campus,
          contact us today
        </p>
        <p class="know-more" (click)="changePath('/contactus')">Know More</p>
      </div>
      <div class="add-box">
        <div class="event" align="right">
          <img src="./../../../../assets/images/loft/enjoy.png" alt="" loading="lazy"/>
        </div>
      </div>
      <!-- <div class="event" align="right">
                <img src="./../../../../assets/images/loft/enjoy.png" alt="">
            </div> -->
    </div>
    <div class="sixth-section" *ngFor="let e of experience">
      <div class="create-text">
        <p class="live">{{ e.text }}</p>
        <p class="easy">{{ e.heading }}</p>
        <div class="memo-text">{{ e.desc }}</div>
        <p class="know-more" (click)="changePath('/contactus')">Know More</p>
      </div>
      <img src="{{ e.img }}" alt="" width="50%" loading="lazy"/>
    </div>
  </div>
  <div>
    <app-footer></app-footer>
  </div>

  <div *ngIf="showMe" id="div22" class="video-popup">
    <div (click)="closeBtn()" class="closebtn">X</div>
    <iframe
      width="860"
      height="515"
      src="https://www.youtube.com/embed/77b_5vfletY"
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
  </div>
</section>
