<div class="main-sec" >
    <div class="heading">
        LogOut
    </div>
    <!-- <div class="sub-heading">
        Are you Sure to cancel your Visit?
    </div> -->
    <!-- <div class="sub">
        If you are busy you can reschedule the visit.
    </div> -->
    <div class="button-sec">
        <div class="button-sec">
        <button class="first-bt">No,Dont logout</button></div>
        <div class="sec-div"> <button class="second-bt"  (click)="closeBtn()">Yes,Logout</button></div>
    </div>
</div>