<section>
    <div class="detail-section">
      <div class="top-nav">
        PREVIEW
      </div>
      <!-- <div class="search-bar">
        <div class="main-search">
          <div class="location-drop-down">
            <span class="icon">
              <user-flow-map-spot-cordinates
                (setAddress)="getCordinates($event)"
              ></user-flow-map-spot-cordinates>
            </span>
            <span class="location">{{ cordinatesValue | titlecase }}</span>
          </div>
          <div class="search-box">
            <user-flow-map-search
              #place
              (setAddress)="getAddress($event)"
              [placeHolderName]="'Search by locality or city'"
            >
            </user-flow-map-search>
          </div>
        </div>
      </div> -->
  
      <section class="property-detail-section" *ngIf="loader; else loadingImg">
        <div class="major-section">
          <div class="image-carousel-section">
            <user-flow-image-carousel-prop-detail
              [imageList]="property?.images"
              [srcWidth]="scrWidth"
            ></user-flow-image-carousel-prop-detail>
          </div>
          <div class="over-view-section">
            <p class="overview">Overview</p>
            <p class="overview-details">
              {{ property?.description }}
            </p>
            <!-- <div class="webLink">
              Hey! Checkout Isthara Viva, an amazing fully furnished accommodation
              by Isthara <a href="https://www.isthara.com">//https:isthara.com</a>
            </div> -->
            <!-- <div class="add-statement">
              {{ property?.addStatement.text }}
              <span class="add-colored">
                {{ property?.addStatement.link }}
              </span>
            </div> -->
            <div class="room-list" *ngIf="property?.roomType">
              <p class="room-section">Room Types & Amenities</p>
              <user-flow-room-list
                [roomList]="property?.roomType"
                [selection]="false"
              ></user-flow-room-list>
            </div>
            <div class="common-amenities">
              <p class="room-section">Common Amenities</p>
              <user-flow-common-amenities
                [commonAmenitiesList]="property?.commonAmenties"
                [property]="property"
                [diamentions]="{
                  width: '60px',
                  height: '60px',
                  fontSize: '6px'
                }"
              ></user-flow-common-amenities>
            </div>
            <div class="common-amenities">
              <p class="room-section">Common Services</p>
              <user-flow-common-amenities
                [commonAmenitiesList]="property?.commonServices"
                [property]="property"
                [diamentions]="{
                  width: '60px',
                  height: '60px',
                  fontSize: '6px'
                }"
              ></user-flow-common-amenities>
            </div>
            <div class="common-amenities">
              <p class="room-section">Food Options</p>
              <p class="overview-details">Food prices are included in rent.</p>
              <div *ngIf="property?.foodService?.weekdaysMeals; noweekdays">
                  <user-flow-common-amenities
                  [commonAmenitiesList]="property?.foodService?.weekdaysMeals"
                  [property]="property"
                  [diamentions]="{
                    width: '100px',
                    height: '100px',
                    fontSize: '14px'
                  }"
                ></user-flow-common-amenities>
              </div>
              <ng-template #noweekdays>
                No Data Found
              </ng-template>
              <p class="room-section">Weekend</p>
              <div *ngIf="property?.foodService?.weekendsMeals; else noweekend">
                <user-flow-common-amenities
                [commonAmenitiesList]="property?.foodService?.weekendsMeals"
                [property]="property"
                [diamentions]="{
                  width: '100px',
                  height: '100px',
                  fontSize: '14px'
                }"
                ></user-flow-common-amenities>
              </div>
              <ng-template #noweekend>
                 No Data Found
              </ng-template>
            </div>
            <div class="food-timings">
              <div class="two-sections">
                <div class="two-radio-btns">
                  <input
                    name="options"
                    type="radio"
                    [value]="0"
                    [(ngModel)]="fifthSector.in_stock"
                  /><span> Weekend Meals</span>
                </div>
                <div class="two-radio-btns">
                  <input
                    name="options"
                    type="radio"
                    [value]="1"
                    [(ngModel)]="fifthSector.in_stock"
                  /><span>Weekdays Meals</span>
                </div>
              </div>
              <p class="room-section">Food Timing</p>
              <p class="overview-details">Food Timings as follows</p>
                <div class="timing-section" *ngIf="fifthSector.in_stock == 0">
                  <div *ngIf="property?.foodService?.weekendMeals; else noFood1">
                    <ng-container
                      *ngFor="let time of property?.foodService?.weekendsMeals"
                    >
                      <div class="timings">
                        <div class="for-timing">
                          <img [src]="time.mealIcon" alt="food" class="time-img" />
                          {{ time.mealName }}
                        </div>
                        <div class="food-timing">
                          {{ time.mealStartTime }} - {{ time.mealEndTime }}
                        </div>
                      </div>
                    </ng-container>
                  </div>
                  <ng-template #noFood1>
                    <div class="no-food">
                      No Data Available
                    </div>
                 </ng-template>
                </div>
                <div class="timing-section" *ngIf="fifthSector.in_stock == 1">
                  <div *ngIf="property?.foodService?.weekdaysMeals; else noFood2">
                    <ng-container
                    *ngFor="let time of property?.foodService?.weekdaysMeals"
                    >
                      <div class="timings">
                        <div class="for-timing">
                          <img [src]="time.mealIcon" alt="food" class="time-img" />
                          {{ time.mealName }}
                        </div>
                        <div class="food-timing">
                          {{ time.mealStartTime }} - {{ time.mealEndTime }}
                        </div>
                      </div>
                    </ng-container>
                  </div>
                  <ng-template #noFood2>
                    <div class="no-food">
                      No Data Available
                    </div>
                  </ng-template>
                </div>
            </div>
            <div class="monthly-rent-breakup">
              <p class="room-section">Monthly Rent Breakup</p>
              <p class="overview-details">
                Things which are included in monthly payment
              </p>
              <div class="foodcharges" *ngFor="let item of property?.rentBreakUp">
                <p class="month-rent-text">
                  <!-- Food Charges : {{ property?.others?.foodCharges?.amount }} -->
                  {{ item.title }}
                </p>
                <p class="overview-details">
                  <!-- {{ property?.others?.foodCharges?.condition }} -->
                  {{ item.description }}
                </p>
                <hr />
              </div>
              <!-- <div class="foodcharges">
                <p class="month-rent-text">
                  Electric Charges : {{ property?.others?.elecrticity?.amount }}
                </p>
                <p class="overview-details">
                  {{ property?.others?.elecrticity?.condition }}
                </p>
                <hr />
              </div> -->
              <!-- <div class="foodcharges">
                <p class="month-rent-text">
                  Food Charges : {{ property?.others?.parking?.amount }}
                </p>
                <p class="overview-details">
                  {{ property?.others?.parking?.condition }}
                </p>
                <hr />
              </div> -->
              <!-- <div class="foodcharges">
                <p class="month-rent-text">
                  Food Charges : {{ property?.others?.Maintainance?.amount }}
                </p>
                <p class="overview-details">
                  {{ property?.others?.Maintainance?.condition }}
                </p>
              </div> -->
            </div>
          </div>
        </div>
        <div class="minor-section">
          <div class="right-section">
            <div class="prop-name">
              <div class="prop-title">
                {{ property?.propertyName | titlecase }}
              </div>
              <div class="share-fav">
                <!-- <div class="share-icon">
                  <img
                    src="./../../../../assets/images/property-list/sharing.png"
                    alt=""
                    width="25"
                    (click)="share()"
                  />
                </div> -->
                <div
                  class="fav-icon"
                  *ngIf="userid !== 'null' && userid !== null"
                >
                  <img
                    class="fav"
                    [src]="
                      property.isFav
                        ? './../../../../assets/images/property-list/fav.png'
                        : './../../../../assets/images/property-list/unfav.png'
                    "
                    type="button"
                    (click)="addFav(property.propertyId, property.isFav, $event)"
                    alt="fav"
                    width="25"
                  />
                </div>
              </div>
            </div>
            <div class="map">
              <div class="address-section" *ngIf="property?.propertyAddress">
                <img
                  src="./../../../../assets/images/pin.png"
                  alt="pin"
                  width="14px"
                />
                {{ property?.propertyAddress.locality | titlecase }},
                {{ property?.propertyAddress.city | titlecase }}
              </div>
              <div class="distance">{{ distance }}</div>
            </div>
            <div class="visit-booking">
              <div class="security-starting-price">
                <div class="security-deposit">
                  <p class="sec-dep-text">Security Deposit</p>
                  <p class="sec-dep-price">
                    {{ property?.securityDepositTitle }}
                  </p>
                </div>
                <div>
                  <p class="sec-dep-text">Starting From</p>
                  <p class="sec-dep-price">&#x20B9; {{ minPriceMonthly }}/M</p>
                </div>
              </div>
              <div class="schedule-visit">
                <p class="visit-heading">Enquire now or Book now</p>
  
                <!-- <p class="available-video">
                  Available video visit
                  <span
                    ><a
                      href="https://www.youtube.com/watch?v=zqy7gpj4Xkk"
                      target="_blank"
                      >(new)</a
                    ></span
                  >
                </p> -->
                <button class="visit-btn" (click)="openVisit()" disabled>
                  Enquire Now
                </button>
                <!-- <div class="under-text">It's assisted & Free</div> -->
  
                <button class="book-btn" (click)="openBooking()" disabled>Book Now</button>
                <p class="available-video">Pay token amount & book</p>
              </div>
            </div>
            <!-- <button class="book-btn" (click)="openBooking()">
              Book Now
            </button>  -->
            <div class="geo-view" *ngIf="property?.propertyAddress">
              <p class="add-heading">Address On Map</p>
              <p class="address">
                <span>{{ property?.propertyAddress.addressLine1 }}</span
                >, <span>{{ property?.propertyAddress.locality }}</span
                >, <span>{{ property?.propertyAddress.city }}</span
                >, <span>{{ property?.propertyAddress.state }}</span>
              </p>
              <agm-map
                [latitude]="property?.propertyAddress.geoLocation.latitude"
                [longitude]="property?.propertyAddress.geoLocation.longitude"
                [zoom]="zoom"
              >
                <agm-marker
                  [latitude]="property?.propertyAddress.geoLocation.latitude"
                  [longitude]="property?.propertyAddress.geoLocation.longitude"
                  [iconUrl]="icon"
                >
                </agm-marker>
              </agm-map>
            </div>
            <div class="near-by">
              <p class="add-heading">Near By</p>
              <div class="list-nearby">
                <ng-container *ngFor="let nearby of nearbyList; index as i">
                  <div
                    class="list-box"
                    align="center"
                    (click)="nearbyDetails(nearby, i)"
                    [ngClass]="{ 'list-nearby-active': nearby.active }"
                  >
                    <img [src]="nearby.icon" alt="list" class="nearby-icon-img" />
                    <p class="nearby-text">
                      {{ nearby.categoryName | titlecase }}
                    </p>
                  </div>
                </ng-container>
              </div>
              <div class="details-nearby">
                <div class="shift" *ngIf="selectedNearby?.placeList; else nonearBy">
                  <button
                    class="bt"
                    *ngFor="let item of selectedNearby?.placeList; let nb = index"
                    (click)="details(item, nb)"
                  >
                    <!--  -->
                    <span [ngClass]="item.active === true ? 'btnActive' : 'bt'">
                      {{ item.subcategoryName }}
                    </span>
                  </button>
                </div>
                <ng-template #nonearBy>
                  <div class="noData">
                    <div>No Data Found</div>
                  </div>
                </ng-template>
                <p></p>
                <!-- <div class="nearby-heading">
                  {{ placeInfoList?.placeInfoList.length }}
                  {{ placeInfoList?.subcategoryName | titlecase }} Near By
                  <img [src]="placeInfoList.icon" />
                </div> -->
                <!-- <ng-container *ngFor="let item of placeInfoList?.placeInfoList">
                  <div class="list-shop">
                    <div class="name">{{ item?.placeName | titlecase }}</div>
                  
                  </div>
                </ng-container> -->
              </div>
            </div>
  
            <!-- <button class="enquire-btn-new" (click)="EnquiryTemp()">
              Enquire Now
            </button> -->
          </div>
        </div>
      </section>
      <ng-template #loadingImg>
        <img
          src="./../../../../assets/images/loader.gif"
          alt=""
          class="loadingImg"
        />
      </ng-template>
    </div>
    <div class="footer">
      <app-footer></app-footer>
    </div>
  </section>
  