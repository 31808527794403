import { DbStorageService } from './../../service/headers/db-storage/db-storage.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';



@Injectable({
  providedIn: 'root'
})
export class BookingService {
 
  constructor(
    private _http: HttpClient,
    private _dbStorage : DbStorageService,
    public dialog: MatDialog
    
  ) { }
  createBooking(bookingData){
    return this._http.post(
      environment.authenticationUrl+'finance/Booking/createBooking',bookingData
    )
  }
  merchantToken(merchantData){
    return this._http.post(
      environment.authenticationUrl+'finance/Booking/cashfreeMerchanttoken',merchantData
    )
  }
  paymentStatus(paymentData){
    return this._http.post(
      environment.authenticationUrl+'finance/Booking/paymentstatus',paymentData
    )
  }

  bookingData(bookingdata){
    return this._http.post(
      environment.authenticationUrl+`finance/Booking/bookingsearchByuserId`,bookingdata
    )
  }
  cancelBooking(data){
    return this._http.post(
      environment.authenticationUrl+`finance/Booking/cancelbooking`,data
    )
  }
  updateBooking(data){
    const token = this._dbStorage.getToken();
    var headers_object = new HttpHeaders().set("Authorization", "Bearer " + token);
    return this._http.post(
      environment.authenticationUrl+'finance/Booking/changeCheckInDate',data
    )
  }

  
getAvailabilityByProperty(data){
return this._http.post(
  environment.authenticationUrl+`finance/Booking/getavailability`,data)
}

openDialog(component, data) {
  return this.dialog.open(component, {
    width: '738px',
    height: '90vh',
    data: data,
  });
}

genderDialog(component,data) {
  return this.dialog.open(component, {
    width: '444px',
    height: '230px',
    data:data
  });

}

}
