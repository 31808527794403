import { ImageUploadService } from './../../../../service/aws-image-upload/image-upload.service';
// import { PopupService } from './../../../../../../../website-upliv/src/app/components/popups/popup.service';

import { RightSlidingNavbarService } from './../../navbars/right-sliding-navbar/right-sliding-navbar.service';
import { DbStorageService } from './../../../../service/headers/db-storage/db-storage.service';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Meta, DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { PropertyDetailsService } from '../../../property-details/property-details.service';
import { ErrorPopsService } from './../../../../service/error-pops/error-pops.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'user-flow-profile-user',
  templateUrl: './profile-user.component.html',
  styleUrls: [
    './profile-user.component.scss',
    './mq-profile-user.component.scss',
  ],
})
export class ProfileUserComponent implements OnInit {
  addressSearch: any;
  cordinatesValue: any = 'Locate';
  id: any;
  property: any;
  nearBy: any;
  selectedNearby: any;
  show: boolean = false;
  updateLoader: boolean = true;
  loader: boolean = false;
  buttonName = 'Show';
  imgsrc = './../../../../../assets/images/profile/Mask Group.png';
  userid;
  token: '';
  data;

  constructor(
    private propertyDetailsService: PropertyDetailsService,
    private _route: ActivatedRoute,
    private metaService: Meta,
    private ds: DomSanitizer,
    private _dbStorage: DbStorageService,
    private apiService: RightSlidingNavbarService,
    // private popupService: PopupService,
    private popupService: ErrorPopsService,
    private imageUpload: ImageUploadService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  zoom = 8;
  icon = {
    url: './assets/images/map/map-pin.png',
    scaledSize: { height: 45, width: 30 },
  };

  user = {
    name: '',
    mobile: '',
    profileImg: null,
    mailId: '',
    noImg: './../../../../../assets/images/right-sliding-nav/defaultUser.png',
    gender: ' ',
  };
  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo(0, 0);
    }

    this.userid = this._dbStorage.getUserId();
    this.token = this._dbStorage.getToken();
    this.getDetails();

    if (this.userid == 'null' || this.userid == null) {
      this.router.navigate(['']);
    } else {
    }
  }
  propertyDetail(propId) {
    this.propertyDetailsService
      .propertyDetails(propId)
      .subscribe((propDetail: any) => {
        this.property = propDetail.propertyList[propId];
        this.nearBy = this.property.nearBy;
        this.selectedNearby = this.nearBy[0];
        this.metaService.removeTag("name='description'");
        this.metaService.addTag({
          name: 'description',
          content: this.property.overview,
        });
        this.metaService.addTag({
          name: 'keywords',
          content: "'Best', 'Isthara', 'Hostel'",
        });
      });
  }
  getAddress(place: any) {
    this.addressSearch = place;
    // console.log(place);
  }
  getCordinates(place: any) {
    this.cordinatesValue = place.city;
  }

  nearbyDetails(item, i) {
    // console.log(item, i);
    this.selectedNearby = item;
  }

  toggle() {
    this.show = !this.show;

    if (this.show) {
      this.show = true;
    } else {
      this.show = false;
    }
  }

  getDetails() {
    this.loader = false;
    let data = {
      id: this.userid,
    };
    this.apiService.getUser(data.id).subscribe(
      (id: any) => {
        if (id.error) {
          // console.log('error', id.message);
        } else {
          this.data = id.data;
          this.user.name = id.data.fullName;
          this.user.profileImg = id.data.profilePicture;
          this.user.mobile = id.data.mobile;
          this.user.mailId = id.data.email;
          this.user.gender = id.data.gender;
        }
      },
      (err) => {
        // console.log('error', err);
      },
      () => {
        if (this.data) {
          this.loader = true;
        }
      }
    );
  }

  async updateDetails() {
    if (this.file == '' || this.file == null) {
      this.user.profileImg = null;
    } else {
      this.updateLoader = false;
      this.user.profileImg = await this.imageUpload.uploadProfileImage(
        this.file
      );
      this.user.profileImg = this.user.profileImg.Location;
      this.updateLoader = true;
    }
    let data = {
      fullName: this.user.name,
      countryCode: '91',
      email: this.user.mailId,
      mobile: this.user.mobile,
      roles: ['USER'],
      gender: this.user.gender,
      userId: this.userid,
      profilePicture: this.user.profileImg,
    };
    this.apiService.updateUser(data).subscribe(
      (data: any) => {
        if (data.error) {
          // console.log('error', data.message);
        } else {
          // console.log('successfull updated profile', data.data);
          this.popupService.openSnackBarMessage(
            'Sucessfully Profile Updated',
            'Ok'
          );
          this.show = !this.show;
        }
      },
      (err) => {
        // console.log('error occurs', err.error);
        this.popupService.openSnackBarMessage('Please Select Gender', 'Cancel');
      }
    );
  }

  // image display
  file;
  fileChange(e) {
    // console.log(e);

    const file = e.srcElement.files[0];
    this.file = file;
    // this.user.noImg = window.URL.createObjectURL(file);
    this.user.profileImg = window.URL.createObjectURL(file);
    // this.user.profileImg = await this.imageUpload.uploadProfileImage(file);
    // this.user.profileImg = this.user.profileImg.Location;
  }
}
