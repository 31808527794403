import { AddPlaceComponent } from './../add-place/add-place.component';
import { ErrorPopsService } from './../../../../service/error-pops/error-pops.service';
import { DbStorageService } from './../../../../service/headers/db-storage/db-storage.service';
import { PopupService } from './../../popup.service';
import {
  Component,
  OnInit,
  Inject,
  NgZone,
  SimpleChanges,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MapSearchLibService } from './../../../../service/map-search/map-search-lib.service';
import { data } from 'jquery';

@Component({
  selector: 'user-flow-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss', './mq-search-bar.component.scss'],
})
export class SearchBarComponent implements OnInit {
  btnSection = [
    { name: 'Home', type: 'Home' },
    { name: 'College', type: 'College' },
    { name: 'Office', type: 'Office' },
    { name: 'Near', type: 'Near' },
  ];
  addressSearch: any;
  viewMode = 'searchBar';
  zoom = 8;
  previousSearch: any = [];
  icon = {
    url: './assets/images/map/map-pin.png',
    scaledSize: { height: 45, width: 30 },
  };
  userId: any;
  svPlaceCount: Number;
  svPlaces: any;

  constructor(
    public dialogRef: MatDialogRef<SearchBarComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private map: MapSearchLibService,
    private ngZone: NgZone,
    private popService: PopupService,
    private _dbStorage: DbStorageService,
    private errorPops: ErrorPopsService
  ) {
    {
      dialogRef.disableClose = true;
    }
  }

  ngOnInit(): void {
    this.userId = this._dbStorage.getUserId();

    //get Saved Places
    this.popService.getsavedPlaces(this.userId).subscribe(
      (res: any) => {
        // console.log("res",res)
        this.svPlaces = res.data.addressDetails;
        // console.log('res', this.svPlaces.addressDetails);
        this.svPlaceCount = res.data.addressDetails.length;
      },
      (err: any) => {
        // console.log('err', err);
      }
    );
    this.previousSearch = this._dbStorage.getlocality();
  }

  // myVal:any='';
  // ngOnChanges(changes: SimpleChanges){
  //   this.myVal = changes;

  // }
  getAddress(place: any) {
    this.addressSearch = place;
    // console.log(place);
    this.search();
    // console.log(
    //   'city',
    //   this.addressSearch.city,
    //   'Locality',
    //   this.addressSearch.locality
    // );
    this.closeBtn();

    if (this._dbStorage.getlocality() == null) {
      this._dbStorage.setlocality([this.addressSearch.locality]);
    } else {
      var localities = this._dbStorage.getlocality();
      if (!localities.includes(this.addressSearch.locality)) {
        localities.push(this.addressSearch.locality);
        // console.log("dsjaklfdjlasdf", localities)
        this._dbStorage.setlocality(localities);
      }
    }
  }
  closeBtn() {
    this.dialogRef.close();
  }
  closePopup(e) {
    if (e.close) {
      this.closeBtn();
    }
  }
  search() {
    if (this.addressSearch) {
      this.router.navigate(['/list'], {
        queryParams: this.addressSearch,
      });
    }

    // else {
    //   this.addressSearch = {
    //     city: "Hyderabad",
    //     locality: "",
    //     country: "",
    //     state: "",
    //   };
    //     this.router.navigate(["/list"], {
    //       queryParams: this.addressSearch,
    //     })
    //     this.closeBtn()
    // }
  }

  lat;
  lng;
  nearBy() {
    // if ('geolocation' in navigator) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        this.map.findAddressByCoordinates(
          (this.lat = position.coords.latitude),
          (this.lng = position.coords.longitude)
        );
        this.addressSearch = {
          lat: Number(this.lat),
          lng: Number(this.lng),
        };
        this.router.navigate(['/list'], {
          queryParams: this.addressSearch,
        });
        this.closeBtn();
      },
      (err) => {
        // console.log('map error', err);
      }
    );
    // }
  }
  addPlace() {
    this.closeBtn();
    this.popService.openDialog(AddPlaceComponent, {
      width: '95%',
      height: '95%',
      maxWidth: '100%',
    });
  }

  searchBySvp(addressDetails, i) {
    this.router.navigate(['/list', addressDetails], {
      queryParams: addressDetails,
    });
    this.closeBtn();
  }
}
