import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PopupService } from '../../popup-components/popup.service';
import { ApplyComponent } from '../../popup-components/popups/apply/apply.component';
import { CareersService } from './careers.service';

@Component({
  selector: 'user-flow-careers',
  templateUrl: './careers.component.html',
  styleUrls: ['./careers.component.scss','mq-careers.component.scss'],
})
export class CareersComponent implements OnInit {
  show : boolean = false;
  constructor(
    private popupService: PopupService,
    private carrerService: CareersService,
    private router : Router
  ) {}

  ngOnInit(): void {}
  

  office = [
    {
      img:'./../../../../assets/images/career/office-chair.svg',
      bigText:'Large Beautiful Office',
      smallText:'Enjoy a comfortable office environment with most modern and stylish furnishings.'
    },
    {
      img:'./../../../../assets/images/career/map-location.svg',
      bigText:'Easy Location',
      smallText:'Commute easily to work at your convinience and enjoy compensation for transport costs.'
    },
    {
      img:'./../../../../assets/images/career/coffee-cup.svg',
      bigText:'Free Tea & Snacks',
      smallText:'Enjoy free tea, coffee and snacks at any time prepared by our most trusted kitchen team.'
    }
  ]
  job = [
    {
      img:'./../../../../assets/images/career/rating.svg',
      bigText:'Great Co-workers',
      smallText:'Work with some of the most talent in the industry and build strong networks with them. '
    },
    {
      img:'./../../../../assets/images/career/exam.svg',
      bigText:'Training Opportunities',
      smallText:'Get training for developing your skills and knowledge to kickstart your career.'
    },
    {
      img:'./../../../../assets/images/career/medal.svg',
      bigText:'Performance Award',
      smallText:'Get awarded for better performance every 3months and be recognised for your work.'
    }
  ]

  openJobs(){
    // this.show =! this.show;
    // this.getallJobs();
    this.router.navigate(['/allJobs'])
  }
  jobtypeArr = [];
  
}




