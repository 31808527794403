import { AddPlaceComponent } from './../../popup-components/popups/add-place/add-place.component';
import { DbStorageService } from 'projects/user-flow-property/src/app/service/headers/db-storage/db-storage.service';
import { PopupService } from './../../popup-components/popup.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'user-flow-saved-place',
  templateUrl: './saved-place.component.html',
  styleUrls: [
    './saved-place.component.scss',
    './mq-saved-place.component.scss',
  ],
})
export class SavedPlaceComponent implements OnInit {
  zoom = 8;
  icon = {
    url: './assets/images/map/map-pin.png',
    scaledSize: { height: 45, width: 30 },
  };

  constructor(
    private savedService: PopupService,
    private _dbstorage: DbStorageService
  ) {}
  svPlaces: any;
  svPlaceCount;
  userId: any;
  id: any;
  show: boolean = false;

  ngOnInit(): void {
    this.userId = this._dbstorage.getUserId();
    this.savedService.getsavedPlaces(this.userId).subscribe(
      (res: any) => {
        // console.log("res",res)
        this.svPlaces = res.data.addressDetails;
        // this.id = this.svPlaces.locationAddress_Id
        // console.log(this.id)
        // console.log("res",this.svPlaces)
        this.svPlaceCount = res.data.addressDetails.length;
      },
      (err: any) => {
        // console.log("err",err);
      }
    );
  }
  addPlace() {
    this.savedService.openDialog(AddPlaceComponent, {
      width: '97%',
      height: '97%',
      maxWidth: '150%',
      buttonType: 'fromSavedPlaces',
    });
  }
  edit() {
    this.show = !this.show;
  }

  delete(id) {
    this.id = id;
    // console.log(this.id)
    this.savedService.deleteSavedPlace(this.id, this.userId).subscribe(
      (res: any) => {
        // console.log("response",res)
        window.location.reload();
      },
      (err: any) => {
        // console.log("err",err)
      }
    );
  }
}
