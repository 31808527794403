import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { Meta, DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute, ParamMap, Routes, Router } from '@angular/router';
import { KycDocsService } from '../../../service/aws-image-upload/kyc-docs/kyc-docs.service';
import { DbStorageService } from '../../../service/headers/db-storage/db-storage.service';
import { PropertyDetailsService } from '../../property-details/property-details.service';
import { PaymentServiceService } from '../payment-service.service';
import { ErrorPopsService } from './../../../service/error-pops/error-pops.service';

interface Gender {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'user-flow-kyc',
  templateUrl: './kyc.component.html',
  styleUrls: ['./kyc.component.scss', './mq-kyc.component.scss'],
})
export class KycComponent implements OnInit {
  addressSearch: any;
  cordinatesValue: any = 'Locate';
  step = 0;
  userid;
  showMe: boolean = false;
  professional: boolean = false;
  Emergency: boolean = false;
  Bank: boolean = false;
  loader: boolean = true;

  constructor(
    private apiService: PaymentServiceService,
    private propertyDetailsService: PropertyDetailsService,
    private _route: ActivatedRoute,
    private _dbStorage: DbStorageService,
    private metaService: Meta,
    private errorPops: ErrorPopsService,
    private router: Router,
    private imageUpload: KycDocsService,
    private ds: DomSanitizer
  ) {}
  zoom = 8;
  icon = {
    url: './assets/images/map/map-pin.png',
    scaledSize: { height: 45, width: 30 },
  };
  ngOnInit(): void {
    this.userid = this._dbStorage.getUserId();
    this.getDetails();
    if (this.userid == null || this.userid == '') {
      this.router.navigate(['']);
    } else {
    }
  }

  maxDate = new Date(2002, 0, 1);

  getAddress(place: any) {
    this.addressSearch = place;
    // console.log(place);
  }
  getCordinates(place: any) {
    this.cordinatesValue = place.city;
  }

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    if (
      this.kycDetails.personalDetails.fullname == null ||
      this.kycDetails.personalDetails.fullname == '' ||
      this.kycDetails.personalDetails.dob == null ||
      this.kycDetails.personalDetails.dob == null ||
      this.kycDetails.personalDetails.gender == null ||
      this.kycDetails.personalDetails.gender == null ||
      this.kycDetails.personalDetails.paddress == null ||
      this.kycDetails.personalDetails.paddress == ' ' ||
      this.kycDetails.personalDetails.state == null ||
      this.kycDetails.personalDetails.state == '' ||
      this.kycDetails.personalDetails.city == null ||
      this.kycDetails.personalDetails.city == ''
    ) {
      // console.log('error', this.kycDetails.personalDetails);
      this.errorPops.openSnackBarMessage('Fill all Mandatory fields!', 'Close');
    } else {
      // this.showMe=!this.showMe;
      this.step++;
    }
  }

  nextStep2() {
    if (
      this.kycDetails.professionalDetails.cName == null ||
      this.kycDetails.professionalDetails.cName == '' ||
      this.kycDetails.professionalDetails.designation == null ||
      this.kycDetails.professionalDetails.designation == '' ||
      this.kycDetails.professionalDetails.caddress == null ||
      this.kycDetails.professionalDetails.caddress == '' ||
      this.kycDetails.professionalDetails.prcity == null ||
      this.kycDetails.professionalDetails.prcity == '' ||
      this.kycDetails.professionalDetails.prstate == null ||
      this.kycDetails.professionalDetails.prstate == ''
    ) {
      // console.log('error', this.kycDetails.professionalDetails);
      this.errorPops.openSnackBarMessage('Fill all Mandatory fields!', 'Close');
    } else {
      this.professional = !this.professional;
      this.step++;
    }
  }

  nextStep3() {
    if (
      this.kycDetails.emergencyDetails.cpName == null ||
      this.kycDetails.emergencyDetails.cprel == '' ||
      this.kycDetails.emergencyDetails.cpnumber == null ||
      this.kycDetails.emergencyDetails.cpnumber == '' ||
      this.kycDetails.emergencyDetails.cpaddress == null ||
      this.kycDetails.emergencyDetails.cpaddress == '' ||
      this.kycDetails.emergencyDetails.cpcity == null ||
      this.kycDetails.emergencyDetails.cpcity == '' ||
      this.kycDetails.emergencyDetails.cpstate == null ||
      this.kycDetails.emergencyDetails.cpstate == ''
    ) {
      // console.log('error', this.kycDetails.emergencyDetails);
      this.errorPops.openSnackBarMessage('Fill all Mandatory fields!', 'Close');
    } else {
      this.Emergency = !this.Emergency;
      this.step++;
    }
  }
  nextStep4() {
    if (
      this.kycDetails.bankDetails.bName == null ||
      this.kycDetails.bankDetails.bName == '' ||
      this.kycDetails.bankDetails.bAccountNumber == null ||
      this.kycDetails.bankDetails.bAccountNumber == '' ||
      this.kycDetails.bankDetails.bAccountHolder == null ||
      this.kycDetails.bankDetails.bAccountHolder == '' ||
      this.kycDetails.bankDetails.ifscCode == null ||
      this.kycDetails.bankDetails.ifscCode == ''
    ) {
      // console.log('error', this.kycDetails.bankDetails);
      this.errorPops.openSnackBarMessage('Fill all Mandatory fields!', 'Close');
    } else {
      this.Bank = !this.Bank;
      this.step++;
    }
  }
  nextStep5() {
    if (
      this.kycDetails.bankDetails.bName == null ||
      this.kycDetails.bankDetails.bName == '' ||
      this.kycDetails.bankDetails.bAccountNumber == null ||
      this.kycDetails.bankDetails.bAccountNumber == '' ||
      this.kycDetails.bankDetails.bAccountHolder == null ||
      this.kycDetails.bankDetails.bAccountHolder == '' ||
      this.kycDetails.bankDetails.ifscCode == null ||
      this.kycDetails.bankDetails.ifscCode == '' ||
      this.kycDetails.emergencyDetails.cpName == null ||
      this.kycDetails.emergencyDetails.cprel == '' ||
      this.kycDetails.emergencyDetails.cpnumber == null ||
      this.kycDetails.emergencyDetails.cpnumber == '' ||
      this.kycDetails.emergencyDetails.cpaddress == null ||
      this.kycDetails.emergencyDetails.cpaddress == '' ||
      this.kycDetails.emergencyDetails.cpcity == null ||
      this.kycDetails.emergencyDetails.cpcity == '' ||
      this.kycDetails.emergencyDetails.cpstate == null ||
      this.kycDetails.emergencyDetails.cpstate == '' ||
      this.kycDetails.professionalDetails.cName == null ||
      this.kycDetails.professionalDetails.cName == '' ||
      this.kycDetails.professionalDetails.designation == null ||
      this.kycDetails.professionalDetails.designation == '' ||
      this.kycDetails.professionalDetails.caddress == null ||
      this.kycDetails.professionalDetails.caddress == '' ||
      this.kycDetails.professionalDetails.prcity == null ||
      this.kycDetails.professionalDetails.prcity == '' ||
      this.kycDetails.professionalDetails.prstate == null ||
      this.kycDetails.professionalDetails.prstate == '' ||
      this.kycDetails.personalDetails.fullname == null ||
      this.kycDetails.personalDetails.fullname == '' ||
      this.kycDetails.personalDetails.dob == null ||
      this.kycDetails.personalDetails.dob == null ||
      this.kycDetails.personalDetails.gender == null ||
      this.kycDetails.personalDetails.gender == null ||
      this.kycDetails.personalDetails.paddress == null ||
      this.kycDetails.personalDetails.paddress == ' ' ||
      this.kycDetails.personalDetails.state == null ||
      this.kycDetails.personalDetails.state == '' ||
      this.kycDetails.personalDetails.city == null ||
      this.kycDetails.personalDetails.city == ''
    ) {
      this.errorPops.openSnackBarMessage('Fill all Mandatory fields!', 'Close');
    } else {
      let userDetailsdata = {
        user: {
          city: this.kycDetails.personalDetails.city,
          dateOfBirth: this.kycDetails.personalDetails.dob,
          countryCode: '91',
          country: 'INDIA',
          fullName: this.kycDetails.personalDetails.fullname,
          gender: this.kycDetails.personalDetails.gender,
          permanentAddress: this.kycDetails.personalDetails.paddress,
          roles: ['USER'],
          // userId: this.userid,
          state: this.kycDetails.personalDetails.state,
        },
        userDetails: {
          professionalDetails: {
            city: this.kycDetails.professionalDetails.prcity,
            companyAddress: this.kycDetails.professionalDetails.caddress,
            companyName: this.kycDetails.professionalDetails.cName,
            country: this.kycDetails.professionalDetails.prcountry,
            designation: this.kycDetails.professionalDetails.designation,
            state: this.kycDetails.professionalDetails.prstate,
          },
          emergencyDetails: {
            city: this.kycDetails.emergencyDetails.cpcity,
            contactAddress: this.kycDetails.emergencyDetails.cpaddress,
            contactPhoneNo: this.kycDetails.emergencyDetails.cpnumber,
            country: this.kycDetails.emergencyDetails.cpcountry,
            emergencyContactPersonName: this.kycDetails.emergencyDetails.cpName,
            relationship: this.kycDetails.emergencyDetails.cprel,
            state: this.kycDetails.emergencyDetails.cpstate,
          },
          bankDetails: {
            bankAccountHolderName: this.kycDetails.bankDetails.bAccountHolder,
            bankAccountNumber: this.kycDetails.bankDetails.bAccountNumber,
            bankName: this.kycDetails.bankDetails.bName,
            ifsccode: this.kycDetails.bankDetails.ifscCode,
          },
          kycDocumentDetails: [
            {
              documentURL: this.kycDetails.documents.identity,
              type: 'IDENTITY_PROOF',
            },
            {
              documentURL: this.kycDetails.documents.addresss,
              type: 'ADDRESS_PROOF',
            },
            {
              documentURL: this.kycDetails.documents.photo,
              type: 'PASSPORT_SIZE_PHOTO',
            },
          ],
          // userId: this.userid
        },
      };
      // console.log('ggggggg', userDetailsdata);
      // console.log('token', this._dbStorage.getToken());

      this.apiService.userDetails(userDetailsdata).subscribe(
        (kycDetails: any) => {
          if (kycDetails.error) {
            // console.log(kycDetails.message, 'error occurs');
          } else {
            // console.log(
            //   'successfully Registered',
            //   kycDetails.data.user.fullName
            // );
            this.errorPops.openSnackBarMessage(
              ` userdetails post success`,
              'close'
            );
          }
        },
        (err) => {
          // console.log('gettting error', err);
          // this.errorPops.openSnackBarMessage(err.error.message, 'Cancel');
        }
      );

      this.step++;
    }
  }

  prevStep() {
    this.step--;
  }

  array_range(start, len) {
    let arr = new Array(len);
    for (var i = 0; i < len; i++, start++) {
      arr[i] = start;
    }
    return arr;
  }

  //gender
  selectedValue: string;
  genders: Gender[] = [
    { value: 'MALE', viewValue: 'MALE' },
    { value: 'FEMALE', viewValue: 'FEMALE' },
    { value: 'OTHERS', viewValue: 'OTHERS' },
  ];

  //browse image
  @ViewChild('fileUpload')
  fileUpload: ElementRef;
  onClick($event) {
    if (this.fileUpload) this.fileUpload.nativeElement.click();
  }

  //Form validation and API Integration
  kycDetails = {
    personalDetails: {
      fullname: '',
      dob: '',
      gender: '',
      paddress: '',
      city: '',
      state: '',
      country: '',
    },
    professionalDetails: {
      cName: '',
      designation: '',
      caddress: '',
      prcity: '',
      prstate: '',
      prcountry: '',
    },
    emergencyDetails: {
      cpName: '',
      cprel: '',
      cpnumber: '',
      cpaddress: '',
      cpcity: '',
      cpstate: '',
      cpcountry: '',
    },
    bankDetails: {
      bName: '',
      bAccountNumber: '',
      bAccountHolder: '',
      ifscCode: '',
    },
    documents: {
      identity: null,
      addresss: null,
      photo: null,
    },
  };

  something;
  getDetails() {
    let data = {
      id: this.userid,
    };
    this.apiService.getUser(data.id).subscribe(
      (id: any) => {
        if (id.error) {
          // console.log('error', id.message);
        } else {
          // console.log('get user data', id);
          this.something = id.data.user;
          this.kycDetails.personalDetails.fullname = id.data.user.fullName;
          this.kycDetails.personalDetails.gender = id.data.user.gender;
          this.kycDetails.personalDetails.dob = id.data.user.dateOfBirth;
          this.kycDetails.personalDetails.city = id.data.user.city;
          this.kycDetails.personalDetails.paddress =
            id.data.user.permanentAddress;
          this.kycDetails.personalDetails.country = id.data.user.country;
          this.kycDetails.personalDetails.state = id.data.user.state;
          this.kycDetails.bankDetails.bAccountHolder =
            id.data?.userDetails?.bankDetails.bankAccountHolderName;
          this.kycDetails.bankDetails.bAccountNumber =
            id.data?.userDetails?.bankDetails?.bankAccountNumber;
          this.kycDetails.bankDetails.bName =
            id.data?.userDetails?.bankDetails?.bankName;
          this.kycDetails.bankDetails.ifscCode =
            id.data?.userDetails?.bankDetails?.ifsccode;
          this.kycDetails.professionalDetails.cName =
            id.data?.userDetails?.professionalDetails?.companyName;
          this.kycDetails.professionalDetails.caddress =
            id.data?.userDetails?.professionalDetails?.companyAddress;
          this.kycDetails.professionalDetails.designation =
            id.data?.userDetails?.professionalDetails?.designation;
          this.kycDetails.professionalDetails.prcity =
            id.data?.userDetails?.professionalDetails?.city;
          this.kycDetails.professionalDetails.prcountry =
            id.data?.userDetails?.professionalDetails?.country;
          this.kycDetails.professionalDetails.prstate =
            id.data?.userDetails?.professionalDetails?.state;
          this.kycDetails.emergencyDetails.cpName =
            id.data?.userDetails?.emergencyDetails?.emergencyContactPersonName;
          this.kycDetails.emergencyDetails.cpaddress =
            id.data?.userDetails?.emergencyDetails?.contactAddress;
          this.kycDetails.emergencyDetails.cpcity =
            id.data?.userDetails?.emergencyDetails?.city;
          this.kycDetails.emergencyDetails.cpcountry =
            id.data?.userDetails?.emergencyDetails?.country;
          this.kycDetails.emergencyDetails.cpnumber =
            id.data?.userDetails?.emergencyDetails?.contactPhoneNo;
          this.kycDetails.emergencyDetails.cprel =
            id.data?.userDetails?.emergencyDetails?.relationship;
          this.kycDetails.emergencyDetails.cpstate =
            id.data?.userDetails?.emergencyDetails?.state;
          this.kycDetails.documents.identity =
            id.data?.userDetails?.kycDocumentDetails[0]?.documentURL;
          this.kycDetails.documents.addresss =
            id.data?.userDetails?.kycDocumentDetails[1]?.documentURL;
          this.kycDetails.documents.photo =
            id.data?.userDetails?.kycDocumentDetails[2]?.documentURL;
          // console.log("get name",this.user.name)
          // console.log("get profile pic",this.user.profileImg)
        }
      },
      (err) => {
        // console.log('error', err);
      }
    );
  }
  overLay = false;
  showImg() {
    this.overLay = true;
  }
  closeOverlay() {
    this.overLay = false;
  }
  async fileChange(e) {
    const file = e.srcElement.files[0];
    this.kycDetails.documents.identity = window.URL.createObjectURL(file);
    this.kycDetails.documents.identity =
      await this.imageUpload.uploadProfileImage(file);
    this.kycDetails.documents.identity =
      this.kycDetails.documents.identity.Location;
  }
  async addressChange(e) {
    const file = e.srcElement.files[0];
    this.kycDetails.documents.addresss = window.URL.createObjectURL(file);
    this.kycDetails.documents.addresss =
      await this.imageUpload.uploadProfileImage(file);
    this.kycDetails.documents.addresss =
      this.kycDetails.documents.addresss.Location;
  }
  async photoChange(e) {
    const file = e.srcElement.files[0];
    this.kycDetails.documents.photo = window.URL.createObjectURL(file);
    this.kycDetails.documents.photo = await this.imageUpload.uploadProfileImage(
      file
    );
    this.kycDetails.documents.photo = this.kycDetails.documents.photo.Location;
  }
}
