import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DbStorageService {
  constructor() { }

  clearLoginType() {
    localStorage.removeItem("isLogin");
  }

  // * Set user opted Filter information
  setUserId(body: any) {
    localStorage.setItem("userId", JSON.stringify(body));
  }

  //* Set Mobile Number
  setMobileNumber(body: any) {
    localStorage.setItem("m", JSON.stringify(body));
  }

  // * Get user opted Filter information
  getUserId() {
    const userId = JSON.parse(localStorage.getItem("userId"));
    if (userId) {
      return userId;
    }
    return null;
  }

  //set token
  setToken(body: any) {
    localStorage.setItem("token", JSON.stringify(body));
  }

  // * Get user opted Filter information
  getToken() {
    // const token = "token";
    // return token;
    const token = JSON.parse(localStorage.getItem("token"));
    if (token) {
      return token;
    }
    return null;
  }

  // * Set PropertyId
  setPropertyId(body: any) {
    localStorage.setItem("property-id", JSON.stringify(body));
  }
  // * Get PropertyId information
  getPropertyId() {
    const propertyId = JSON.parse(localStorage.getItem("property-id"));
    if (propertyId) {
      return propertyId;
    } else {
      return null;
    }
  }

  // * Set Property
  setProperty(body: any) {
    localStorage.setItem("property-detail", JSON.stringify(body));
  }

  //*get Property
  getProperty() {
    const propertyDetail = JSON.parse(localStorage.getItem("property-detail"));
    if (propertyDetail) {
      return propertyDetail;
    }
    return null;
  }
  
  //set visit id
  setvisitId(body: any) {
    localStorage.setItem("visitId", JSON.stringify(body));
  }

  //get visit Id
  getvisitId() {
    const visitId = JSON.parse(localStorage.getItem("visitId"));
    if (visitId) {
      return visitId;
    }
    return null;
  }


  //savePlaces
  setlocality(localities:any) {
    localStorage.setItem("localities", JSON.stringify(localities));
  }

  //getlocalities
  getlocality() {
    const localities = JSON.parse(localStorage.getItem("localities"));
    if (localities) {
      return localities;
    }
    return null;
  }

}


