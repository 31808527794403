import {
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Router, ActivatedRoute, ParamMap, Routes } from '@angular/router';

@Component({
  selector: 'user-flow-our-services-home-page',
  templateUrl: './our-services-home-page.component.html',
  styleUrls: [
    './our-services-home-page.component.scss',
    './mq-our-services-home-page.component.scss',
  ],
})
export class OurServicesHomePageComponent implements OnInit {
  @Input() home: any;
  @Input() cardList: any[];

  serviceBox = {
    name: 'Isthara',
    subText: 'Promise',
    description:
      " Isthara's Industry standard safety and hygiene measure to make your stay safe",
    services: [
      {
        imgType: './../../../../assets/images/services/vsafe.png',
        name: 'v safe',
        path: 'promises-tabs',
        subPath: 'vsafe',
        description: 'Your Safety Our Responsibility',
        bgColor: '#FFEDF4',
      },
      {
        imgType: './../../../../assets/images/services/veat.png',
        name: 'v eat',
        path: 'promises-tabs',
        subPath: 'veat',
        description: ' Healthiest and Nutricious food for you',
        bgColor: '#DBDFF2',
      },
      {
        imgType: './../../../../assets/images/services/vwell.png',
        name: 'v well',
        path: 'promises-tabs',
        subPath: 'vwell',
        description: 'Wellness program',
        bgColor: '#CEF9E8',
      },
      // {
      //   imgType: './../../../../assets/images/services/vvisit.png',
      //   name: 'Video Visit',
      //   path:'promises-tabs',
      //   subPath:'videovisit',
      //   description: 'Whatsapp visit tour',
      //   bgColor: '#FAF0BF',
      // },
    ],
  };
  constructor(private router: Router) {}

  ngOnInit(): void {
    this.serviceBox = this.serviceBox;
  }
  routeTo(nav_url) {
    this.router.navigate([nav_url]);
  }
  getColor(title) {
    switch (title) {
      case 'V Safe':
        return '#FFEDF4';
      case 'V Eat':
        return '#DBDFF2';
      case 'V Well':
        return '#CEF9E8';
      case 'Video Visit':
        return '#FAF0BF';
    }
  }
}
