<section>
    <div class="top-navbar">
        <user-flow-top-navbar></user-flow-top-navbar>
    </div>
    <div class="main-section">
        <div class="sub-textt" *ngIf="userId !== 'null'">
            <!-- <p *ngIf="svPlaceCount == 0">Saved Places(0)</p> -->
            <p>Saved Places({{svPlaceCount}})</p>
            <div class="savePlace" *ngFor="let item of svPlaces;index as i">
              <div class="placeCard">
                <div><img src="./../../../../../assets/images/popup-images/homeIcon.png" width="100%" alt=""></div>
                <div class="addressType">{{item?.addressType}}</div>
                <div class="btns">
                  <img src="./../../../../assets/images/popup-images/edit-button.png" alt="" width="7%" class="edit-btn" (click)="edit()">
                  <img src="./../../../../assets/images/popup-images/trash.png" alt="" width="7%"  class="delete-btn" (click)="delete(item?.locationAddress_Id,i)">
                </div>
              </div>
              <div class="locationDetails">{{item?.addressDetails.locality}},{{item?.addressDetails.city}},{{item?.addressDetails.state}},{{item?.addressDetails.country}}</div>
            </div>
            <div class="savedPlace" (click)="addPlace()">+Add a Place</div>
        </div>
        <div class="map-view" *ngIf="show">
          <div class="geo-view">
            <agm-map
              [latitude]=17.3
              [longitude]=18.25
              [zoom]="zoom"
            >
              <agm-marker
                [latitude]=17.3
                [longitude]=18.25
                [iconUrl]="icon">
              >
              </agm-marker>
            </agm-map>
          </div>
        </div>
    </div>
</section>