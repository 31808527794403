import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';

@Component({
  selector: 'user-flow-carousel-user',
  templateUrl: './carousel-user.component.html',
  styleUrls: [
    './carousel-user.component.scss',
    'mq-carousel-user.component.scss',
  ],
})
export class CarouselUserComponent implements OnInit {
  data: any[] = [
    {
      title: 'Yash Mehta',
      designation: 'Technical Analyst, Wise Energy',
      describe:
        'Its been a long jouney in Isthara for about a year. when i moved to hyderabad for my very first job it was hard to find co-living space with spacious room. Fortunatly I found Isthara who match all my needs. I love how every problem get solved in hour or two that shows how dedicated the team is in making isthara a better place',
      url: './../../../../../assets/images/carousel/carousel.png',
    }
    // {
    //   title: 'Yash Mehta',
    //   designation: 'Technical Analyst, Wise Energy',
    //   describe:
    //     'Its been a long jouney in Isthara for abount a year. when i moved to hyderabad for my very first job it was hard to find co-living space with spacious room. Fortunatly I found Isthara who match all my needs. I love how every problem get solved in hour or two that shows how dedicated the team is in making isthara a better place',

    //   url: './../../../../../assets/images/carousel/carousel.png',
    // },
    // {
    //   title: 'Yash Mehta',
    //   designation: 'Technical Analyst, Wise Energy',
    //   describe:
    //     'Its been a long jouney in Isthara for abount a year. when i moved to hyderabad for my very first job it was hard to find co-living space with spacious room. Fortunatly I found Isthara who match all my needs. I love how every problem get solved in hour or two that shows how dedicated the team is in making isthara a better place',

    //   url: './../../../../../assets/images/carousel/carousel.png',
    // },
    // {
    //   title: 'Yash Mehta',
    //   designation: 'Technical Analyst, Wise Energy',
    //   describe:
    //     'Its been a long jouney in Isthara for abount a year. when i moved to hyderabad for my very first job it was hard to find co-living space with spacious room. Fortunatly I found Isthara who match all my needs. I love how every problem get solved in hour or two that shows how dedicated the team is in making isthara a better place',

    //   url: './../../../../../assets/images/carousel/carousel.png',
    // },
    // {
    //   title: 'Yash Mehta',
    //   designation: 'Technical Analyst, Wise Energy',
    //   describe:
    //     'Its been a long jouney in Isthara for abount a year. when i moved to hyderabad for my very first job it was hard to find co-living space with spacious room. Fortunatly I found Isthara who match all my needs. I love how every problem get solved in hour or two that shows how dedicated the team is in making isthara a better place',

    //   url: './../../../../../assets/images/carousel/carousel.png',
    // },
    // {
    //   title: 'Yash Mehta',
    //   designation: 'Technical Analyst, Wise Energy',
    //   describe:
    //     'Its been a long jouney in Isthara for abount a year. when i moved to hyderabad for my very first job it was hard to find co-living space with spacious room. Fortunatly I found Isthara who match all my needs. I love how every problem get solved in hour or two that shows how dedicated the team is in making isthara a better place',

    //   url: './../../../../../assets/images/carousel/carousel.png',
    // },
    // {
    //   title: 'Yash Mehta',
    //   designation: 'Technical Analyst, Wise Energy',
    //   describe:
    //     'Its been a long jouney in Isthara for abount a year. when i moved to hyderabad for my very first job it was hard to find co-living space with spacious room. Fortunatly I found Isthara who match all my needs. I love how every problem get solved in hour or two that shows how dedicated the team is in making isthara a better place',

    //   url: './../../../../../assets/images/carousel/carousel.png',
    // },
  ];

  constructor() {}

  ngOnInit(): void {}
}
