<section>
  <div class="main-block">
    <div class="align-block">
      <div class="text-section">
        <p class="company-name" [innerHTML]="community?.title">
          <!-- {{ community?.title_1 | titlecase }} -->
        </p>
        <p class="sub-com-name">Isthara Living</p>
        <!-- <p class="subtext">
        {{ community?.title_2 | titlecase }}
      </p> -->
        <p class="description">
          {{ community?.description }}
        </p>
        <div class="know-btn">
          <a href="/community"><button>Know More</button></a>
        </div>
      </div>

      <div class="service-section">
        <div class="services-box">
          <div *ngFor="let tile of communityCard">
            <img
              class="community-img"
              [src]="tile.iconUrl"
              [alt]="tile.description"
              (click)="showvideo()"
            />
          </div>
        </div>
      </div>

      <div *ngIf="showMe" id="div22" class="video-popup">
        <div (click)="closeBtn()" class="closebtn">X</div>
        <iframe
          width="860"
          height="515"
          src="https://www.youtube.com/embed/77b_5vfletY"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  </div>
</section>
