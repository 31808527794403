<section>
  <div class="main-section">
    <div class="top-nav">
      <user-flow-top-navbar></user-flow-top-navbar>
    </div>
    <!-- <div class="search-bar">
            <div class="main-search">
              <div class="location-drop-down">
                <span class="icon">
                  <user-flow-map-spot-cordinates
                    (setAddress)="getCordinates($event)"
                  ></user-flow-map-spot-cordinates>
                </span>
                <span class="location">{{ cordinatesValue | titlecase }}</span>
              </div>
              <div class="search-box">
                <user-flow-map-search
                  #place
                  (setAddress)="getAddress($event)"
                  [placeHolderName]="'Search by locality or city'"
                >
                </user-flow-map-search>
              </div>
            </div>
        </div> -->
    <div class="favourite-section">
      <div class="heading-text">
        {{ fav.headText }}
      </div>
      <div class="no-fav" *ngIf="count == 0">
        <img src="{{ fav.img }}" alt="" />
        <p class="big-text">{{ fav.bigText }}</p>
        <p class="sub-text" align="center">{{ fav.subText1 }}</p>
        <p class="sub-text" align="center">{{ fav.subText2 }}</p>
        <div class="btn-section">
          <button class="explore-btn" (click)="routeTo(fav.path)">
            {{ fav.btnText }}
          </button>
        </div>
      </div>
      <!-- <div class="no-fav"> -->
      <!-- <user-flow-list-property
              [favProps]="getFavList"></user-flow-list-property> -->
      <section *ngIf="loader; else loadingImg">
        <div class="main-listing-box">
          <ng-container *ngFor="let prop of properties; index as i">
            <div
              class="prop-box"
              (click)="detailProperty(prop, prop.propertyId)"
            >
              <div class="images">
                <user-flow-single-image-carousel
                  [imageSet]="prop.images"
                  [classId]="'image' + (i + 1)"
                >
                </user-flow-single-image-carousel>
                <div class="gender-box">
                  <img
                    [src]="
                      './../../../assets/images/property-list/' +
                      prop.propertyType +
                      '.png'
                    "
                    alt="gender"
                    loading="lazy"
                  />
                  {{ prop.propertyType.replace("_", " ") | uppercase }}
                </div>
              </div>
              <div class="container-text">
                <p class="prop-heading">{{ prop.propertyName }}</p>
                <div class="location-distance">
                  <div class="location">
                    <img
                      src="./../../../assets/images/property-list/location.png"
                      alt="location"
                    />
                    {{ prop.propertyAddress.locality }},
                    {{ prop.propertyAddress.city }}
                  </div>
                  <div class="distance">{{ prop.distance }} </div>
                </div>
                <p class="starting-from">starting from</p>
                <div class="price-fav">
                  <div class="price">
                    &#x20B9; {{ getMinimum(prop?.roomType) }}/M
                  </div>
                  <div
                    class="distance"
                    *ngIf="userId !== 'null' && userId !== null"
                  >
                    <img
                      class="fav"
                      src=" {{
                        prop.isFav
                          ? './../../../../assets/images/property-list/fav.png'
                          : './../../../../assets/images/property-list/unfav.png'
                      }} "
                      type="button"
                      (click)="addFav(prop.propertyId, prop.isFav, $event)"
                      alt="fav"
                    />
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </section>
      <ng-template #loadingImg>
        <img
          src="./../../../../assets/images/loader.gif"
          alt=""
          class="loadingImg"
        />
      </ng-template>
      <!-- </div> -->
    </div>
  </div>
  <div class="footer">
    <app-footer></app-footer>
  </div>
</section>
