import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'user-flow-blog-preview-section',
  templateUrl: './blog-preview-section.component.html',
  styleUrls: ['./blog-preview-section.component.scss','./mq-blog-preview-section.component.scss'],
})
export class BlogPreviewSectionComponent implements OnInit {
  @Input() blog:any
  @Input() blogCards:any[]

  serviceBox = {
    name: 'the',
    subText: 'isthara blog',
    description:
      "New in the city? Don't panic! We help you in finding the best of everything, right from best places to live to the best morning chai. Let us be your first friend in this new city!",
    services: [
      {
        imgType: './../../../../assets/images/blog/blog1.png',
        description:
          'Challenges of moving to a new city, away from the parental nest care and security.',
      },
      {
        imgType: './../../../../assets/images/blog/blog2.png',
        description:
          ' Challenges of moving to a new city, away from the parental nest care and security.',
      },
      {
        imgType: './../../../../assets/images/blog/blog3.png',
        description: 'Budget friendly tips to survive in a metro city.',
      },
    ],
  };
  constructor() {}

  ngOnInit(): void {
    this.serviceBox = this.serviceBox;
  }
}
