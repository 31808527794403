import { HttpClient } from '@angular/common/http';
import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
@Injectable({
  providedIn: 'root',
})
export class PopupService {
  constructor(public dialog: MatDialog, private http: HttpClient) {}
  openDialog(component, data) {
    return this.dialog.open(component, {
      width: data.width,
      height: data.height,
      maxWidth: data.maxWidth,
      maxHeight: data.maxHeight,
      data: data,
    });
  }
  createLocation(locationData) {
    return this.http.post(
      environment.authenticationUrl + 'property/property/createlocation',
      locationData
    );
  }
  getsavedPlaces(userId) {
    return this.http.get(
      environment.authenticationUrl +
        `property/property/ist/savedlocations/${userId}`
    );
  }
  deleteSavedPlace(id, userId) {
    return this.http.delete(
      environment.authenticationUrl +
        `property/property/ist/deletelocation/${id}/${userId}`
    );
  }

  enqueryOld(data) {
    return this.http.post(
      'https://erp.isthara.com/api/MobileAPI/SaveOrUpdateEnquiryDetails',
      data
    );
  }

getLocalites(){
  return this.http.get(
    environment.authenticationUrl+'property/master/ist/property/localities'
  );
}

getSuggest(data){
  return this.http.get(
    environment.authenticationUrl+`property/master/ist/suggest?text=${data}`
  );
}

getLocalityProperty(locality){
 return this.http.get(environment.authenticationUrl+`master/property/locality?location=${locality}`);
}

getPropertyByCity(city){
  return this.http.get(environment.authenticationUrl+`property/master/city?city=${city}`)
}

nearestProperties(lat,longi){
  return this.http.get(environment.authenticationUrl+`property/master/ist/nearestproperties?lat=${lat}&longi=${longi}`)
}

searchUpdate(data){
  return this.http.post(environment.authenticationUrl+`property/property/ist/searchupdate`,data)
}
enqueryNew(data){
  return this.http.post(
    environment.authenticationUrl+`enquiry/enquiry/tenant/ist/create`,data
  );
}
}
