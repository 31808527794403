import { Component, OnInit } from '@angular/core';
import { ErrorPopsService } from 'projects/user-flow-property/src/app/service/error-pops/error-pops.service';
import { PartnershipService } from '../partnership.service';
enum CheckBoxType {
  owner,
  agent,
  NONE,
}
interface Property {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'user-flow-list-your-property',
  templateUrl: './list-your-property.component.html',
  styleUrls: [
    './list-your-property.component.scss',
    './mq-list-your-property.component.scss',
  ],
})
export class ListYourPropertyComponent implements OnInit {
  check_box_type = CheckBoxType;

  currentlyChecked: CheckBoxType;

  selected = -1;
  array = [
    {
      name: 'Agent',
      isActive: false,
    },
    {
      name: 'Owner',
      isActive: false,
    },
  ];
  selectedUser: any = this.array.filter((ch) => {
    if (ch.isActive) {
      return ch.name;
    }
  })[0];
  selectedUseractive: boolean;
  constructor(
    private apiService: PartnershipService,
    private errorPops: ErrorPopsService
  ) {}

  ngOnInit(): void {}

  cards = [
    {
      img: './../../../../../assets/images/list-your-property/note.png',
      text: 'Submit The Details of your property',
      border: '3px solid #FFEDF4',
      bigImg: './../../../../../assets/images/list-your-property/onedigit.png',
    },
    {
      img: './../../../../../assets/images/list-your-property/person.png',
      text: 'Our Account Manager will get in touch with you',
      border: '3px solid #CEF9E8',
      bigImg:
        './../../../../../assets/images/list-your-property/seconddigit.png',
    },
    {
      img: './../../../../../assets/images/list-your-property/writing.png',
      text: 'Iron out the finer details and sign the agreement',
      border: '3px solid #DBDFF2',
      bigImg:
        './../../../../../assets/images/list-your-property/threedigit.png',
    },
    {
      img: './../../../../../assets/images/list-your-property/startup.png',
      text: 'Start earning right away!',
      border: '3px solid #FAF0BF',
      bigImg:
        './../../../../../assets/images/list-your-property/fourthdigit.png',
    },
  ];

  about = [
    {
      img: './../../../../../assets/images/list-your-property/paymentmethod.png',
      text: 'Timely Payments',
    },
    {
      img: './../../../../../assets/images/list-your-property/tool.png',
      text: 'Well Maintained Properties',
    },
    {
      img: './../../../../../assets/images/list-your-property/house.png',
      text: 'Competitive Rental Yield',
    },
    {
      img: './../../../../../assets/images/list-your-property/calander.png',
      text: 'Long Term Partnership',
    },
  ];
  Form = {
    details: {
      name: '',
      mobileNo: '',
      emailId: '',
      propertyType: '',
      city: '',
      location: '',
      listingAgent: '',
    },
  };
  selectedValue: string;
  property: Property[] = [
    { value: 'Hostel', viewValue: 'Hostel' },
    { value: 'PG', viewValue: 'PG' },
    { value: 'Hotel', viewValue: 'Hotel' },
    { value: 'Apartment', viewValue: 'Apartment' },
    { value: 'IndividualHouse', viewValue: 'IndividualHouse' },
  ];
  listComments = [
    {
      img: './../../../../../assets/images/carousel/random.gif',
      comment:
        ' I own a property in the heart of the city and was looking to rent it out . That’s when I learnt about Isthara and decided to get in touch with them immediately. I am so happy I did! They are managing my property really well and the payments are prompt, competitive and transparent. I have given my another property in Hyderabad also bought a new property in Bangalore and gave to isthara.',
      name: 'Lasya Reddy',
      designation: 'Hyderabad | With us since 3 Years',
    },
    // {
    //   img: './../../../../../assets/images/carousel/random.gif',
    //   comment:
    //     ' I own a property in the heart of the city and was looking to rent it out for some easy side income. That’s when I learnt about Isthara and decided to get in touch with them immediately. I am so happy I did! They are managing my property really well and the payments are prompt, competitive and transparent.',
    //   name: 'Amali kamalini',
    //   designation: 'Lecturer, Hyderabad',
    // },
  ];
  selectCheckBox(targetType: CheckBoxType) {
    // If the checkbox was already checked, clear the currentlyChecked variable
    if (this.currentlyChecked === targetType) {
      this.currentlyChecked = CheckBoxType.NONE;
      return;
    }

    this.currentlyChecked = targetType;
  }

  submitdetails() {
    if (this.selectedUseractive) {
      let listdata = {
        city: this.Form.details.city,
        details: {
          emailId: this.Form.details.emailId,
          mobileNumber: this.Form.details.mobileNo,
          name: this.Form.details.name,
        },
        location: this.Form.details.location,
        propertyType: this.Form.details.propertyType,
        listingAgent: this.selectedUser,
      };
      if((listdata.city =='' || listdata.city == null) || (listdata.details.emailId == '' || listdata.details.emailId == null) ||
      (listdata.details.mobileNumber == '' || listdata.details.mobileNumber == null) || (listdata.details.name == '' || listdata.details.name == null)){
        this.errorPops.openSnackBarMessage(`please fill all mandatory fields`, 'close');
      }
      else{
        // console.log('gggg',listdata)
      this.apiService.listyourproperty(listdata).subscribe(
        (res: any) => {
          this.errorPops.openSnackBarMessage('Submitted Successfully', 'OK');
          window.location.reload();
        },
        (err) => {
          // console.log("gettting error", err)
          // this.errorPops.openSnackBarMessage(err.error.message, 'Cancel');
        }
      );
      }
    } else {
      this.errorPops.openSnackBarMessage(` select the type of user`, 'close');
    }
  }

  asignUserType(user, i, ev) {
    // console.log(ev);

    this.selectedUser = user.name;
    this.array.forEach((user, index) => {
      if (ev.checked) {
        this.selectedUseractive = true;
        if (i === index) {
          user.isActive = true;
        } else {
          user.isActive = false;
        }
      } else {
        this.selectedUseractive = false;
        this.errorPops.openSnackBarMessage(` select the type of user`, 'close');
      }
    });
    // console.log(this.array);
  }
}
