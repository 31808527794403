import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
declare let $: any;
@Component({
  selector: 'user-flow-single-image-carousel',
  templateUrl: './single-image-carousel.component.html',
  styleUrls: ['./single-image-carousel.component.scss'],
})
export class SingleImageCarouselComponent implements OnInit {
  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}
  @Input() imageSet: any[] = [];
  @Input() classId: string;
  images = [];
  ngOnInit(): void {}
  ngOnChanges(changes: import('@angular/core').SimpleChanges) {
    if (changes.imageSet.currentValue) {
      this.carousel(
        changes.imageSet.currentValue,
        changes.classId.currentValue
      );
    }
  }

  carousel(imageSet, classId) {
    if (imageSet) {
      this.images.push(...imageSet);
    } else {
      this.images.push(...imageSet);
    }

    setTimeout(() => {
      this.images = this.images.splice(0, 5);
      this.newMethod(classId);
    }, 100);
  }
  newMethod(classId) {
    if (isPlatformBrowser(this.platformId)) {
      $('.' + classId)
        .not('.slick-initialized')
        .slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
          prevArrow: $('.prevtrendprop'),
          nextArrow: $('.nexttrendprop'),
          fade: true,
          dots: false,
          autoplay: false,
          autoplaySpeed: 500,
          responsive: [
            {
              breakpoint: 700,
              settings: {
                dots: false,
              },
            },
          ],
        });

      $('.' + classId).mouseover(function () {
        $('.' + classId).slick('slickPlay');
      });
      $('.' + classId).mouseout(function () {
        $('.' + classId).slick('slickPause');
      });
    }
  }
}
