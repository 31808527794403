<section>
    <div class="top-nav">
        <user-flow-top-navbar></user-flow-top-navbar>
    </div>
    <div class="main-section" *ngIf="loading; else noloading">
        <div class="details-card">
              <div class="heading-part">
                  <div class="job-head">{{jobDetails?.jobName}}</div>
                  <div class="close-btn" (click)="closeBtn()">X</div>
              </div>
              <div class="det-loc-exp">
                  <div class="location">
                    <img src="./../../../../assets/images/career/location.svg" alt="">
                    <div class="loc" *ngFor="let loc of jobDetails?.locationDetails">{{loc.city}}</div>
                  </div>
                  <div class="experience">
                    <img src="./../../../../assets/images/career/exp.svg" alt="" class="exp-img">
                    <div class="exp">{{jobDetails?.experience}}</div>
                  </div>
                <div class="ctc-sec">
                    <span class="ctc">CTC</span>&nbsp;:&nbsp;
                    <span class="compensation" *ngIf="jobDetails?.compensation != '' || jobDetails?.compensation != null">{{jobDetails?.compensation}}</span>
                    <span class="compensation" *ngIf="jobDetails?.compensation === '' || jobDetails?.compensation === null">Not Disclosed</span>
                </div>
            </div>
            <div class="det-type">
                <div class="det-name" *ngFor="let item of jobDetails?.jobType">
                    {{item}}
                </div>
            </div>
            <hr class="hr">
            <div class="details">
                <div class="details-heading">Description</div>
                <div class="details-sub">
                    <ul>
                        <li *ngFor="let desc of jobDesc">
                            <span>{{desc}}</span>
                        </li>
                    </ul>
                </div>
                <div class="details-heading" *ngIf="jobDetails?.basicRequirements != null && jobDetails?.basicRequirements != []">
                    Basic Qualifications</div>
                <div class="details-sub">
                    <div *ngFor="let item of jobDetails?.basicRequirements">
                        <ul>
                            <li>{{item}}</li>
                        </ul>
                    </div>
                </div>
                <div class="details-heading">
                    Roles and Responsibilities
                </div>
                <div class="details-sub">
                    <div *ngFor="let item of jobDetails?.rolesResponsibilities">
                        <ul>
                            <li>{{item}}</li>
                        </ul>
                    </div>
                </div>
                <div class="details-heading">Required Skills</div>
                <div class="details-sub">
                    <div *ngFor="let item of jobDetails?.skills">
                        <ul>
                            <li>
                                {{item}}
                            </li>
                        </ul>
                    </div>
                </div>
                <!-- <div class="details-heading" *ngIf="jobDetails?.tags != null && jobDetails?.tags.length > 0">
                    Tags
                </div>
                <div class="deatils-sub">
                    <div class="tags">
                        <div *ngFor="let tags of jobDetails?.tags; let isLast = last">
                            {{tags}}{{isLast ? '' : ',&nbsp;'}}
                        </div>
                    </div>
                </div> -->
            </div>
            <div class="app-btn">
                <a href="mailto:hr@isthara.com?cc=paul@isthara.com&subject=Job%20Application%20:%20{{jobDetails?.jobCode}}">
                    <button>Apply</button>
                </a>
            </div>
        </div>
    </div>
    <ng-template #noloading>
        <div class="loader">
            <img src="./../../../../../assets/images/loader.gif" alt="">
        </div>
    </ng-template>
    <div class="footer">
        <app-footer></app-footer>
    </div>
</section>