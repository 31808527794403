import { DbStorageService } from './../../../../service/headers/db-storage/db-storage.service';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from './../../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RightSlidingNavbarService {
  constructor(
    private http: HttpClient,
    private _dbStorage: DbStorageService) {}

  checkProfile(userId) {
    return this.http.get('./../../../../../assets/json/user.json');
  }

  getUser(id){
    const token = this._dbStorage.getToken()
    var headers_object = new HttpHeaders().set("Authorization", "Bearer " + token);
    return this.http.get(
      environment.authenticationUrl+`user/user/${id}`,{headers: headers_object}
    )
  }
  updateUser(data){
    const token = this._dbStorage.getToken()
    var headers_object = new HttpHeaders().set("Authorization", "Bearer " + token);
    return this.http.put(
      environment.authenticationUrl+'user/user/',data,{headers: headers_object}
    )
  }
}
