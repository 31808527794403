import { Router } from '@angular/router';
import {
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { MapsAPILoader } from '@agm/core';
@Component({
  selector: 'user-flow-premiumproperty-list',
  templateUrl: './premiumproperty-list.component.html',
  styleUrls: [
    './premiumproperty-list.component.scss',
    './mq-premiumproperty-list.component.scss',
  ],
})
export class PremiumpropertyListComponent implements OnInit, OnChanges {
  @Input() premiumProperty: any;
  @ViewChild('scroll') scroll: ElementRef;
  @ViewChild('prev') prev: ElementRef;
  @ViewChild('next') next: ElementRef;
  propertyList: any[];
  distance: any;
  constructor(private mapsApiLoader: MapsAPILoader, private router: Router) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.premiumProperty.currentValue) {
      this.propertyList =
        changes.premiumProperty.currentValue.premiumPropertyList;
    }
  }
  currentLat;
  currentLng;
  async ngOnInit() {
    this.currentLocation();
  }
  nextScroll() {
    this.sideScroll(this.scroll.nativeElement, 'right', 25, 98, 6);
  }
  previosScroll() {
    this.sideScroll(this.scroll.nativeElement, 'left', 25, 98, 6);
  }
  sideScroll(element, direction, speed, distance, step) {
    let scrollAmount = 0;
    var slideTimer = setInterval(function () {
      if (direction == 'left') {
        element.scrollLeft -= step;
      } else {
        element.scrollLeft += step;
      }
      scrollAmount += step;
      if (scrollAmount >= distance) {
        window.clearInterval(slideTimer);
      }
    }, speed);
  }
  updatedPropList: any[] = [];
  currentLocation() {
    if ('geolocation' in navigator) {

    this.getCoords().then((position: any) => {
      if (position) {
        this.currentLat = position.latitude;
        this.currentLng = position.longitude;
        //   // console.log(this.updatedPropList);
        // };
        this.asyncLoop();
      } else {
        this.asyncLoop();
      }
    });
    } else {
      console.log('Navigations not working');
    }
  }

  asyncLoop = async () => {
    for (let i = 0; i < this.propertyList.length; i++) {
      let propObj = Object.assign({}, this.propertyList[i]);
      propObj.distance = await this.distanceFromSpecificLocation(
        this.propertyList[i]
      );
      if (this.distance) {
        propObj.distance = (this.distance + 'km');
      } else {
        propObj.distance = '';
      }
      
      this.updatedPropList.push(propObj);
    }
    // console.log(this.updatedPropList);
  };

  distanceFromSpecificLocation(propLocation): string {
    this.distance = '';
    if (propLocation) {
      this.mapsApiLoader.load().then(() => {
        const center = new google.maps.LatLng(this.currentLat, this.currentLng);
        const place = new google.maps.LatLng(
          propLocation.propertyAddress.geoLocation.latitude,
          propLocation.propertyAddress.geoLocation.longitude
        );
        let distance = google.maps.geometry.spherical.computeDistanceBetween(
          center,
          place
        );
        // console.log('this.distance', distance);
        this.distance = Math.ceil(distance / 1000);
        return this.distance;
        // console.log("this.distance", this.distance);
      });
    } else {
      return '';
    }
  }
  detailProperty(id,property) {
    // this.router.navigate(['/detail', id]);
    this.router.navigate([`${property.propertyAddress.city}`,`Pg-hostel-in-${property.propertyAddress.locality.replaceAll(" ","-")}`,property.propertyType.toLowerCase(),property.propertyName.replaceAll(' ', '-')])
    console.log("permiumproperty",property);
    
  }

  getCoords() {
    return new Promise((resolve, reject) =>
      navigator.permissions
        ? // Permission API is implemented
          navigator.permissions
            .query({
              name: 'geolocation',
            })
            .then((permission) =>
              // is geolocation granted?
              permission.state === 'granted'
                ? navigator.geolocation.getCurrentPosition((pos) =>
                    resolve(pos.coords)
                  )
                : resolve(null)
            )
        : // Permission API was not implemented
        resolve(null)
          // resolve(new Error('Permission API is not supported'))
    );
  }
}
