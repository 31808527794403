import { Component, OnInit } from '@angular/core';
import { PopupService } from '../../popup-components/popup.service';
import { ReferAFriendComponent } from '../../popup-components/popups/refer-a-friend/refer-a-friend.component';
import { RemainderComponent } from '../../popup-components/popups/remainder/remainder.component';

@Component({
  selector: 'user-flow-refer-and-earn',
  templateUrl: './refer-and-earn.component.html',
  styleUrls: ['./refer-and-earn.component.scss','./mq-refer-and-earn.component.scss']
})
export class ReferAndEarnComponent implements OnInit {
  list =1 ;

  constructor(
    private popupService: PopupService,
  ) { }

  ngOnInit(): void {
  }
  points = [
    {
      score: '5,000',
      total:'Total Points Earned',
    },
    {
      score: '2,500',
      total:'Points Redeemed',
    },
    {
      score: '2,500',
      total:'Balance Points',
    }
  ]

  tableData = [
    {
      name: 'Alfreds Futterkiste',
      refral: 'Resident',
      contact: '9059169238',
      status: 'Reward In Progress',
    },
    {
      name: 'Alfreds Futterkiste',
      refral: 'Resident',
      contact: '9059169238',
      status: 'Reward In Progress',
    },
    {
      name: 'Alfreds Futterkiste',
      refral: 'Resident',
      contact: '9059169238',
      status: 'Reward In Progress',
    },
  ]


  referfriend() {
    let dialog = this.popupService.openDialog(
      ReferAFriendComponent, {
      // data: "data",
      width: '978px',
        height: '264px',
    }
    );
  }

  reminder() {
    let dialog = this.popupService.openDialog(
      RemainderComponent, {
      // data: "data",
      width: '357px',
      height: '354px',
    }
    );
  }
}
