import { MatSnackBar } from '@angular/material/snack-bar';
import { DbStorageService } from './../../../../service/headers/db-storage/db-storage.service';
import { Router } from '@angular/router';
import { RightSlidingNavbarService } from './../right-sliding-navbar/right-sliding-navbar.service';
import {
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
  HostListener,
} from '@angular/core';
import { SigninSignupServicesService } from './../../../authentication/services/signin-signup-services.service';
import { PropertyDetailsService } from './../../../property-details/property-details.service';
import { TempEnqueryPopupComponent } from '../../../popup-components/popups/temp-enquery-popup/temp-enquery-popup.component';
import { count } from 'node:console';

@Component({
  selector: 'user-flow-top-navbar',
  templateUrl: './top-navbar.component.html',
  styleUrls: ['./top-navbar.component.scss', './mq-top-navbar.component.scss'],
})
export class TopNavbarComponent implements OnInit {
  home: any;
  navbarContet: any;
  constructor(
    private router: Router,
    private apiService: SigninSignupServicesService,
    private _dbStorage: DbStorageService,
    private propService: PropertyDetailsService,
    private rightSlideService: RightSlidingNavbarService,
    private matSnackBar: MatSnackBar
  ) {
    this.getScreenSize();
  }
  loader: boolean = false;
  getUserId: string = '';
  profileImg;
  name;
  noImg: './../../../../../assets/images/right-sliding-nav/defaultUser.png';

  // listMenu = [
  //   { name: 'Call: 8790858581', active: false, router: 'service' },
  // ];
  ngOnInit(): void {
    this.getUserId = this._dbStorage.getUserId();

    this.propService.home().subscribe((homeData: any) => {
      // console.log('navdata: ', homeData);

      this.home = homeData.data;
      this.navbarContet = this.home?.headerSection?.navbar.reverse();
      
    });
    if (this.getUserId) {
      let data = {
        id: this.getUserId,
      };
      this.rightSlideService.getUser(data.id).subscribe(
        (id: any) => {
          if (id.error) {
            // console.log('error', id.message);
          } else {
            // console.log('get user data', id);
            this.name = id.data.fullName;
            this.profileImg = id.data.profilePicture;
            // console.log("get name",this.user.name)
            // console.log("get profile pic",this.user.profileImg)
          }
        },
        (err) => {
          // console.log('error', err);
        }
      );
    }
    //get Profile

    //     if (this.router.url === '/') {

    // let count;
    //       if (count === 0) {
    //         setTimeout(() => {
    //           this.enquiryPopup();
    //         }, 5000);
    //       }
    //       count++;

    //     }
  }
  srcHeight;
  srcWidth;
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.srcHeight = window.innerHeight;
    this.srcWidth = window.innerWidth;
    // console.log(this.scrHeight, this.scrWidth);
  }

  // routeTo(path) {
  //   this.router.navigate([path]);
  // }

  enquiryPopup() {
    let enquiry = this.propService.enquiryDialog(TempEnqueryPopupComponent, {
      data: { dataFrom: 'topnavbar' },
    });
    enquiry.afterClosed().subscribe((data) => {
      // this.matSnackBar.open('Will Contact You Soon!!!', 'Close', {
      //   duration: 5000,
      // });
    });
  }
  routeTo(redirectUrl) {
    this.router.navigate([redirectUrl]);
  }
  // loginredirect(){
  //   this.router.navigate(['/signin']);
  // }
}
