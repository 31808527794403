<section>
  <div class="carousel" [ngStyle]="{ width: parentWidth + 'px' }">
    <div *ngFor="let map of offersData">
      <div class="carousel-main">
        <div class="image-section">
          <img
            [src]="map.imageUrl"
            [alt]="map.couponCode"
            class="offer-img"
            loading="lazy"
          />
          <div class="coupon-code">{{ map.offerCode }}</div>
        </div>
        <div class="text-section">
          <p class="offer-type">{{ map.title | uppercase }}</p>
          <p class="description">
            <span class="des-text"
              >{{map.subtitle}}</span
            >
            <!-- on booking fee +
            <span class="des-text">
              {{ map.description.monthlyType | titlecase }}
              {{ map.description.monthlyDiscount }}% OFF
            </span>
            on monthly rent -->
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="arrow" [ngStyle]="{ width: arrowWidth + 'px' }" *ngIf="offerDataCount !== '1'">
    <div class="prev cursor-pointer">
      <img
        width="15%"
        src="./../../../../../assets/images/offers/prev.png"
        alt="previous"
      />
    </div>
    <div class="next cursor-pointer">
      <img
        width="15%"
        src="./../../../../../assets/images/offers/next.png"
        alt="next"
      />
    </div>
  </div>
</section>
