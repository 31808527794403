import { PopupService } from './../../popup.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
  selector: 'user-flow-temp-enquery-popup',
  templateUrl: './temp-enquery-popup.component.html',
  styleUrls: ['./temp-enquery-popup.component.scss',
              './mq-temp-enquery-popup.component.scss',
],
})
export class TempEnqueryPopupComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<TempEnqueryPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private popupService: PopupService,
    private matSnackBar: MatSnackBar
  ) {}
  submitbtn = true;
  enqForm = {
    Name: '',
    PhoneNumber: null,
    EmailId: '',
    Status: 'New',
    EnquiryType: 'WEBSITE',
    Offers: null,
    city: '',
    CityMaster: { CityMaster_Id: 1 },
    LocationMaster: { LocationMaster_Id: 0 },
  };
  cityList = [
    { name: 'Hyderabad', value: 1 },
    { name: 'bangalore', value: 2 },
    { name: 'Gurgoan', value: 3 },
  ];
  ngOnInit(): void {
    console.log(this.data.data.dataFrom);
    console.log(this.data.data.property);
  }

  onNoClick(): void {
    this.dialogRef.close(this.data);
  }

  submitEnquiry() {
    if(this.data.data.dataFrom === 'topnavbar'){
      if(this.enqForm.CityMaster.CityMaster_Id === 1){
        this.enqForm.city = this.cityList[0].name;
      }
      else if(this.enqForm.CityMaster.CityMaster_Id === 2){
        this.enqForm.city = this.cityList[1].name;
      }
      else{
        this.enqForm.city = this.cityList[2].name;
      }
      this.submitbtn = false;
      this.enqForm.PhoneNumber = Number(this.enqForm.PhoneNumber);
      let data = {
        cityname: this.enqForm.city,
        countryCode: "91",
        emailId: this.enqForm.EmailId,
        istharacityId: this.enqForm.CityMaster.CityMaster_Id,
        mobileno: this.enqForm.PhoneNumber,
        name: this.enqForm.Name,
        sourceType: "WEBSITE",
        propertyId:null,
        istharaPropertyId: 0
      }
      // console.log(data);
      if (this.enqForm.PhoneNumber || this.enqForm.EmailId !== '') {
        this.popupService.enqueryNew(data).subscribe(
          (data) => {
            if (data) {
              this.onNoClick();
              this.matSnackBar.open('Thanks For The Enquiry!', 'Close', {
                duration: 3000,
              });
            }
            this.submitbtn = true;
          },
          (err) => {
            this.matSnackBar.open('Please Try Again!', 'Close', {
              duration: 3000,
            });
            this.submitbtn = true;
          }
        );
      } else {
        this.matSnackBar.open('Mobile or Email is Mandatory!', 'Close', {
          duration: 3000,
        });
      }
    }
    else{
      if(this.data.data.property.propertyAddress.city.toLowerCase() === 'hyderabad'){
        this.enqForm.CityMaster.CityMaster_Id = 1;
      }
      else if(this.data.data.property.propertyAddress.city.toLowerCase() === 'bengaluru' || 
      this.data.data.property.propertyAddress.city.toLowerCase() === 'bangalore'){
        this.enqForm.CityMaster.CityMaster_Id = 2;
      }
      else{
        this.enqForm.CityMaster.CityMaster_Id = 3;
      }
      this.submitbtn = false;
      this.enqForm.PhoneNumber = Number(this.enqForm.PhoneNumber);
      let data = {
        cityname: this.data.data.property.propertyAddress.city,
        countryCode: "91",
        emailId: this.enqForm.EmailId,
        istharacityId: this.enqForm.CityMaster.CityMaster_Id,
        mobileno: this.enqForm.PhoneNumber,
        name: this.enqForm.Name,
        sourceType: "WEBSITE",
        propertyId: this.data.data.property.propertyId,
        istharaPropertyId: this.data.data.property.istharaPropertyId
      }
      // console.log(data);
      if (this.enqForm.PhoneNumber || this.enqForm.EmailId !== '') {
        this.popupService.enqueryNew(data).subscribe(
          (data) => {
            if (data) {
              this.onNoClick();
              this.matSnackBar.open('Thanks For The Enquiry!', 'Close', {
                duration: 3000,
              });
            }
          },
          (err) => {
            this.matSnackBar.open('Please Try Again!', 'Close', {
              duration: 3000,
            });
          }
        );
      } else {
        this.matSnackBar.open('Mobile or Email is Mandatory!', 'Close', {
          duration: 3000,
        });
      }
    }
  }
}
