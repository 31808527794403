import { Router } from '@angular/router';
import {
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ElementRef,
  Inject,
  PLATFORM_ID,
} from '@angular/core';
import { HostListener } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'user-flow-property-list-zone',
  templateUrl: './property-list-zone.component.html',
  styleUrls: [
    './property-list-zone.component.scss',
    './mq-property-list-zone.component.scss',
  ],
})
export class PropertyListZoneComponent implements OnInit {
  @Input() home: any;
  @Input() location: any[];
  @Input() locality: any[];
  @ViewChild('scroll') scroll: ElementRef;
  @ViewChild('prev') prev: ElementRef;
  @ViewChild('next') next: ElementRef;
  ngOnChanges(changes: SimpleChanges): void {
    let city = changes.location.currentValue;
    for (let i = 0; i < city?.length; i++) {
      if (city[i].city == city[0].city) {
        this.city=city[0].city;
        city[i].active = true;
      }
    }
  }
  Locality = [];
  propertyList = [];

  addressSearch: any;

  constructor(
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      window.scroll(0, 0);
      this.getScreenSize();
    }
    
  }

  // changeList(list) {
  //   this.zoneDescription.zone.forEach((zone) => {
  //     if (zone.name === list.name) {
  //       zone.active = true;
  //       this.propertyList = list.propertyList;

  //     } else {
  //       zone.active = false;
  //     }
  //   });
  // }
  city: any;
  changeList(list) {
    this.location.forEach((locality) => {
      if (locality.city === list.city) {
        locality.active = true;
        this.locality = list.locality;
        let length = list.locality.length;
        // console.log("city of city", list.city)
        if (this.scrWidth > 475) {
          if (length < 6) {
            this.prev.nativeElement.style.visibility = 'hidden';
            this.next.nativeElement.style.visibility = 'hidden';
          } else {
            this.prev.nativeElement.style.visibility = 'visible';
            this.next.nativeElement.style.visibility = 'visible';
          }
        } else {
          this.prev.nativeElement.style.visibility = 'visible';
          this.next.nativeElement.style.visibility = 'visible';
        }
        this.city = list.city;
        // console.log("this.city", this.city)
        // console.log("locality of locality", list.locality)
      } else {
        locality.active = false;
      }
    });
  }
  selectLocality(item, i) {
    // console.log("locality",item);
    
    // console.log("locality of locality of locality", this.locality[i].name)
    this.addressSearch = {
      city: this.city,
      locality: this.locality[i].name.replaceAll(" ","-"),
    };
    this.router.navigate([`${this.city}`,`pg-hostel-in-${this.locality[i].name.replaceAll(" ","-")}`]
    // this.router.navigate([`pg-hostels-in-${this.locality[i].name}-${this.city}`]
    
    // , 
    // {
    //   queryParams: this.addressSearch,
    // }
    );
  }

  nextScroll() {
    this.sideScroll(this.scroll.nativeElement, 'right', 25, 98, 6);
  }
  previosScroll() {
    this.sideScroll(this.scroll.nativeElement, 'left', 25, 98, 6);
  }
  sideScroll(element, direction, speed, distance, step) {
    let scrollAmount = 0;
    var slideTimer = setInterval(function () {
      if (direction == 'left') {
        element.scrollLeft -= step;
      } else {
        element.scrollLeft += step;
      }
      scrollAmount += step;
      if (scrollAmount >= distance) {
        window.clearInterval(slideTimer);
      }
    }, speed);
  }

  scrHeight: any;
  scrWidth: any;

  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    // console.log(this.scrHeight, this.scrWidth);
  }
}
