<section>
  <div class="main-box">
    <div class="text-section">
      <div class="name" [innerHTML]="home?.title">
        {{ home?.title_1 | titlecase }}
      </div>
      <p class="description" [innerHTML]="home?.subtitle"></p>
    </div>

    <div class="property-list">
      <div class="zone-section">
        <ng-container *ngFor="let item of location">
          <div
            class="zone-name"
            (click)="changeList(item)"
            [ngClass]="{ 'zone-name-active': item?.active }"
          >
            {{ item?.city | titlecase }}
          </div>
        </ng-container>
      </div>
      <a class="arrow-left">
        <img
          src="./../../../../assets/images/visit/left.png"
          alt="left"
          (click)="previosScroll()"
          #prev
        />
      </a>
      <a class="arrow-right">
        <img
          src="./../../../../assets/images/visit/right.png"
          alt="right"
          (click)="nextScroll()"
          #next
        />
      </a>
      <div class="fixed-zone-section" #scroll>
        <div class="zone-list-section">
          <ng-container *ngFor="let item of locality; index as i">
            <div class="prop-box" (click)="selectLocality(item?.name, i)">
              <img [src]="item?.imageUrl" [alt]="item?.name" width="100%" *ngIf="item?.imageUrl"/>
              <img src="./../../../../assets/images/home/default-img.jpeg" alt="default-img" width="100%" *ngIf="!item?.imageUrl"/>
              <div class="prop-location">
                {{ item?.name | titlecase }}
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</section>
