import { ActivatedRoute, Router } from '@angular/router';
import { SearchPropertyBoardService } from './search-property-board.service';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { MapSearchLibService } from './../../../service/map-search/map-search-lib.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'user-flow-search-property-board',
  templateUrl: './search-property-board.component.html',
  styleUrls: [
    './search-property-board.component.scss',
    './mq-search-property-board.component.scss',
  ],
})
export class SearchPropertyBoardComponent implements OnInit {
  addressSearch: any;
  cordinatesValue: any = 'Locate';
  id: any;
  property: any;
  selectedNearby: any;
  city: '';
  locality: '';
  addCity: '';

  constructor(
    private searchBoard: SearchPropertyBoardService,
    private router: Router,
    private map: MapSearchLibService,
    private _route: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  ngOnInit(): void {
    this._route.queryParams.subscribe((params) => {
      if(params.city && params.locality){
        this.addCity = params.locality;
      }
      else{
  this.addCity=params.city;
      }
    });
    this._route.params.subscribe(
      (params)=>{
        if(params.city&&params.locality){
          this.addCity=params.locality.split("-")[3]
        }
        else if(params.city){
          this.addCity=params.city
        }
      }
    )
   
    // console.log(
    //   'ajlkfjsadk sakdf jklsa fj salkf ksalf jksaf jksal dfjlsf',
    //   this.addCity
    // );
    if (isPlatformBrowser(this.platformId)) {
      window.scroll(0, 0);
    }
  }
  getAddress(place: any) {
    this.addressSearch = place;
    // console.log(place);
    this.city = place.city;
    this.locality = place.locality;
    if (this.addressSearch) {
      this.router.navigate(['/list'], {
        queryParams: this.addressSearch,
      });
    }
  }

  search() {
    if (this.addressSearch) {
      this.router.navigate(['/list'], {
        queryParams: this.addressSearch,
      });
    }

    // else {
    //   this.addressSearch = {
    //     city: 'hyderabad',
    //     locality: this.addressSearch.locality,
    //     country: this.addressSearch.country,
    //     state: this.addressSearch.state,
    //   };
    //   this.router.navigate(['/list'], {
    //     queryParams: this.addressSearch,
    //   });
    // }
  }

  lat;
  lng;
  nearBy() {
    // if ('geolocation' in navigator) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        this.map.findAddressByCoordinates(
          (this.lat = position.coords.latitude),
          (this.lng = position.coords.longitude)
        );
        this.addressSearch = {
          lat: Number(this.lat),
          lng: Number(this.lng),
        };
        this.router.navigate([`/NearBy`,`${Number(this.lat)}`,`${Number(this.lng)}`]);
      },
      (err) => {
        // console.log('map error', err);
      }
    );
    // }
  }
}
