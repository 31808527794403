import { Component, OnInit } from '@angular/core';
import { ErrorPopsService } from 'projects/user-flow-property/src/app/service/error-pops/error-pops.service';
import { PartnershipService } from '../partnership.service';
@Component({
  selector: 'user-flow-register-your-service',
  templateUrl: './register-your-service.component.html',
  styleUrls: [
    './register-your-service.component.scss',
    './mq-register-your-service.component.scss',
  ],
})
export class RegisterYourServiceComponent implements OnInit {
  constructor(
    private apiService: PartnershipService,
    private errorPops: ErrorPopsService
  ) {}

  ngOnInit(): void {}

  cards = [
    {
      img: './../../../../../assets/images/list-your-property/note.png',
      text: 'Submit the details of your  products or services',
      border: '3px solid #FFEDF4',
      bigImg: './../../../../../assets/images/list-your-property/onedigit.png',
    },
    {
      img: './../../../../../assets/images/list-your-property/person.png',
      text: 'Our Product Manager will get in touch with you.',
      border: '3px solid #CEF9E8',
      bigImg:
        './../../../../../assets/images/list-your-property/seconddigit.png',
    },
    {
      img: './../../../../../assets/images/list-your-property/writing.png',
      text: 'Iron out the finer details and sign the agreement.',
      border: '3px solid #DBDFF2',
      bigImg:
        './../../../../../assets/images/list-your-property/threedigit.png',
    },
    {
      img: './../../../../../assets/images/list-your-property/startup.png',
      text: 'Watch your business reach new heights!',
      border: '3px solid #FAF0BF',
      bigImg:
        './../../../../../assets/images/list-your-property/fourthdigit.png',
    },
  ];

  about = [
    {
      img: './../../../../../assets/images/list-your-property/payment-method.png',
      text: 'Grow Your Brand With Us.',
    },
    {
      img: './../../../../../assets/images/list-your-property/paymentmethod.png',
      text: 'Timely and Transparent Payment Cycles.',
    },
    {
      img: './../../../../../assets/images/list-your-property/calander.png',
      text: 'A Convenient And Long-Term Partnership.',
    },
  ];

  Form = {
    details: {
      name: '',
      mobileNo: '',
      emailId: '',
      productName: '',
      descrpition: '',
      contactMethod: '',
    },
  };
  listComments = [
    {
      img: './../../../../../assets/images/carousel/chairaja.svg',
      comment:
        ' We are an upcoming young vibrant tea and snacks ADDA in Hyderabad and leveraging Isthara platform to grow our business in multiple places. Happy to partner with Isthara and thanks for supporting curated and localized brands as well.',
      name: 'Anthic Joseph',
      designation: 'Hyderabad | With us since 1 Year',
    },
    // {
    //   img: './../../../../../assets/images/carousel/random.gif',
    //   comment:
    //     ' I own a property in the heart of the city and was looking to rent it out for some easy side income. That’s when I learnt about Isthara and decided to get in touch with them immediately. I am so happy I did! They are managing my property really well and the payments are prompt, competitive and transparent.',
    //   name: 'Amali kamalini',
    //   designation: 'Lecturer, Hyderabad',
    // },
  ];
  submitdetails() {
    if (
      this.Form.details.name == '' ||
      this.Form.details.name == null ||
      this.Form.details.productName == '' ||
      this.Form.details.productName == null ||
      this.Form.details.mobileNo == '' ||
      this.Form.details.mobileNo == null ||
      this.Form.details.emailId == '' ||
      this.Form.details.emailId == null
    ) {
      this.errorPops.openSnackBarMessage(
        'Please Fill All Mandatory Fields',
        'OK'
      );
    } else {
      let listdata = {
        details: {
          emailId: this.Form.details.emailId,
          mobileNumber: this.Form.details.mobileNo,
          name: this.Form.details.name,
        },
        serviceName: this.Form.details.productName,
        description: this.Form.details.descrpition,
      };
      // console.log('gggg',listdata)
      this.apiService.productregistration(listdata).subscribe(
        (res: any) => {
          // console.log('successfully Registered', res.city);
          this.errorPops.openSnackBarMessage(
            ` Details submitted successfully`,
            'OK'
          );
          window.location.reload();
        },
        (err) => {
          // console.log("gettting error", err)
          this.errorPops.openSnackBarMessage(
            'Something Went Wrong try again',
            'Cancel'
          );
        }
      );
    }
  }
}
