<section *ngIf="loader; else loadingImg">
  <div *ngIf="propertyListView; else loader">
    <div *ngIf="properties?.length == 1; else part">
      <div class="properties-count">
        <span class="count">Showing {{ totalProperties }} Property</span>
      </div>
    </div>
    <ng-template #part>
      <div class="properties-count">
        <span class="count">Showing {{ totalProperties }} Properties</span>
      </div>
    </ng-template>
  </div>
  <ng-template #loader>
    <div class="no-property-text">There are No properties in this locality</div>
    <div class="no-property">
      <img src="./../../../../assets/images/Favourites/nofav.png" />
    </div>
  </ng-template>
</section>
<section>
  <div class="main-listing-box">
    <ng-container *ngFor="let prop of properties; index as i">
      <ng-container *ngIf="!prop?.add; else addvertiseBlock">
        <div class="prop-box" (click)="detailProperty(prop, prop.propertyId)">
          <div class="images">
            <user-flow-single-image-carousel
              [imageSet]="prop.images"
              [classId]="'image' + (i + 1)"
            >
            </user-flow-single-image-carousel>
            <div class="gender-box">
              <img
                [src]="
                  './../../../assets/images/property-list/' +
                  prop.propertyType +
                  '.png'
                "
                alt="gender"
                loading="lazy"
              />
              {{ prop.propertyType.replace("_", " ") | titlecase }}
            </div>
          </div>
          <div class="container-text">
            <div class="prop-heading">
              {{ prop.propertyName }}
            </div>
            <!-- <p class="prop-heading">{{ prop.propertyName }}</p> -->
            <div class="location-distance">
              <div class="location">
                <img
                  src="./../../../assets/images/property-list/location.png"
                  alt="location"
                />
                {{ prop.propertyAddress.locality }},
                {{ prop.propertyAddress.city }}
              </div>

              <div class="distance">
                <span *ngIf="distance != NaN">{{ prop.distance }} </span>
              </div>
            </div>
            <p class="starting-from">starting from</p>
            <div class="price-fav">
              <div class="price">
                &#x20B9; {{ getMinimum(prop?.roomType) }}/M
              </div>
              <div
                class="distance"
                *ngIf="userId !== 'null' && userId !== null"
              >
                <img
                  class="fav"
                  src=" {{
                    prop.isFav
                      ? './assets/images/property-list/fav.png'
                      : './assets/images/property-list/unfav.png'
                  }} "
                  type="button"
                  (click)="addFav(prop.propertyId, prop.isFav, $event)"
                  alt="fav"
                />
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-template #addvertiseBlock>
        <div class="add-blocks">
          <div class="advertise">
            <div
              class="img-section"
              [ngStyle]="{ 'background-color': prop.advertise.color }"
            >
              <div class="ad-img-box">
                <img [src]="prop?.advertise?.img" alt="adds" class="imge-ad" />
              </div>
              <div class="ad-heading-text">
                <div class="heading-ad">
                  {{ prop?.advertise?.heading | titlecase }}
                </div>
                <div class="text-ad">{{ prop?.advertise?.subText }}</div>
              </div>
            </div>
            <div class="text-btn-section">
              <div class="ad-description-text">
                {{ prop?.advertise?.description }}
              </div>
              <button
                class="add-btn-know"
                (click)="changePath(prop?.advertise?.buttonLink)"
              >
                Know More
              </button>
            </div>
          </div>
        </div>
      </ng-template>
    </ng-container>
  </div>
</section>
<ng-template #loadingImg>
  <img src="./../../../../assets/images/loader.gif" alt="" class="loadingImg" />
</ng-template>
