<section>
  <div class="top-navbar">
    <user-flow-top-navbar></user-flow-top-navbar>
  </div>
  <ng-container *ngIf="loader; else loadingImg">
    <div class="main-section" *ngIf="count > 0; else noCount">
      <div class="tabView-section">
        <div class="tab">
          <button
            class="tablinks"
            *ngFor="let tab of tabs; let i = index"
            (click)="openTab(tab, i)"
            [ngClass]="{ active: tab.active }"
          >
            {{ tab.name }}
          </button>
        </div>
        <div class="card-section">
          <div class="tabcontent" *ngIf="booking?.length > 0; else noCount">
            <ng-container *ngFor="let cd of booking; let i = index">
              <div
                class="booking-card"
                [ngStyle]="
                  cd?.active === true ? { border: '3px solid deeppink' } : {}
                "
                (click)="bookingData(cd, i)"
              >
                <div class="total-imagestop">
                  <div class="imagestop">
                    <img [src]="cd.property.images[0].imageUrl" alt="" />
                    <!-- <user-flow-single-image-carousel
                [imageSet]="prop.images"
                [classId]="'image' + (i + 1)"
              >
              </user-flow-single-image-carousel> -->
                    <!-- <img [src]="prop.images[0].imageUrl"> -->
                  </div>
                </div>
                <div class="gender-box">
                  <img
                    [src]="
                      './../../../assets/images/property-list/' +
                      cd.property.propertyType +
                      '.png'
                    "
                    alt="gender"
                    loading="lazy"
                  />
                  {{ cd.property.propertyType | titlecase }}
                </div>
                <div
                  class="cancelledImage"
                  *ngIf="cd.bookingStatus == 'Booking Cancelled'"
                >
                  <img
                    src="./../../../../assets/images/myBookings/cancelledBooking.png"
                    alt="booking Cancelled"
                    width="130%"
                  />
                </div>
                <div class="content">
                  <div class="inner-subcontent">
                    <div class="content-heading">
                      <div class="left">
                        {{ cd?.property?.propertyAddress?.city }}
                      </div>
                      <div class="right">
                        {{ cd.bookingDate | date }}
                      </div>
                    </div>
                    <hr />
                  </div>
                  <div class="inner-subcontent">
                    <div class="content-sub">
                      <div class="content-left">
                        {{ cd?.property?.propertyName }}
                      </div>
                      <div class="content-right">
                        &#8377;{{ cd.totalAmount }}
                      </div>
                    </div>
                  </div>
                  <div class="inner-subcontent">
                    <div class="checkin-data">
                      <img
                        src="./../../../../assets/images/visit/calendar.png"
                        alt=""
                        width="20px"
                      />
                      <div class="checkin-date">
                        {{ cd.expectedCheckInDate | date }}
                      </div>
                      <div class="in-not">IN</div>
                    </div>
                  </div>
                  <div class="inner-subcontent">
                    <!-- <button class="isthara-help">Isthara Help</button> -->
                    <span
                      class="paymentStatus"
                      [ngClass]="{
                        paymentSuccess: cd.bookingStatus == 'Paid',
                        paymentInitiated:
                          cd.bookingStatus === 'Booking Initiated',
                        paymentFailed:
                          cd.bookingStatus === 'Payment Failed' ||
                          cd.bookingStatus === 'Payment Cancelled' ||
                          cd.bookingStatus === 'Booking Cancelled'
                      }"
                      >{{ cd.bookingStatus }}</span
                    >
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="vl" *ngIf="booking?.length"></div>
      <div class="display-section" *ngIf="booking?.length">
        <!-- <div *ngIf="leadcreation?.text">{{ leadcreation.text }}</div> -->
        <ng-container>
          <div class="side-booking-card">
            <div class="image">
              <img
                src="./../../../../assets/images/visit/istharacoliving1.png"
                width="180px"
              />
            </div>
            <div class="content">
              <div class="inner-subcontent">
                <div class="content-heading">
                  <div class="left">Hyderabad</div>
                  <div class="right">
                    {{ leadcreation.bookingDate | date }}
                  </div>
                </div>
                <hr />
              </div>
              <div class="inner-subcontent">
                <div class="content-sub">
                  <div class="content-left">{{ leadcreation.name }}</div>
                  <div class="content-right">
                    &#8377;{{ leadcreation.price }}
                  </div>
                </div>
              </div>
              <div class="inner-subcontent">
                <div class="checkin-data">
                  <img
                    src="./../../../../assets/images/visit/calendar.png"
                    alt=""
                    width="20px"
                  />
                  <div class="checkin-date">
                    {{ leadcreation.expectedCheckInDate | date }}
                  </div>
                  <div class="in-not">IN</div>
                </div>
              </div>
              <div class="inner-rightcontent">
                <!-- <button class="isthara-help">Isthara Help</button> -->
                <span
                  class="paymentStatu"
                  [ngClass]="{
                    paymentSuccess: leadcreation.bookingStatus == 'Paid',
                    'paymentFailed-bottom':
                      leadcreation.bookingStatus == 'Booking Cancelled' ||
                      leadcreation.bookingStatus == 'Payment Cancelled',
                    paymentInitiated:
                      leadcreation.bookingStatus == 'Booking Initiated',
                    paymentFailed:
                      leadcreation.bookingStatus === 'Payment Failed' ||
                      leadcreation.bookingStatus == 'Payment Cancelled'
                  }"
                  >{{ leadcreation.bookingStatus }}</span
                >
              </div>
            </div>
          </div>
          <div class="right-line"><hr /></div>
        </ng-container>
        <div
          class="checkindate"
          *ngIf="
            leadcreation.bookingStatus == 'Paid' ||
            leadcreation.bookingStatus == 'Booking Cancelled'
          "
        >
          Checkin date
        </div>
        <div
          class="date-section"
          *ngIf="
            leadcreation.bookingStatus == 'Paid' ||
            leadcreation.bookingStatus == 'Booking Cancelled'
          "
        >
          <mat-form-field
            class="input-width"
            appearance="outline"
            *ngIf="leadcreation.bookingStatus == 'Paid'"
          >
            <mat-label>{{ leadcreation.expectedCheckInDate | date }}</mat-label>
            <input
              matInput
              [matDatepicker]="picker"
              [min]="minDate"
              [max]="checkin"
              [(ngModel)]="checkinDate"
              [matDatepickerFilter]="dateFilter"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
          <mat-form-field
            class="input-width"
            appearance="outline"
            *ngIf="leadcreation.bookingStatus == 'Booking Cancelled'"
          >
            <mat-label>Select checkIn Date</mat-label>
            <input
              matInput
              [matDatepicker]="picker"
              [min]="minBookingCancelled"
              [max]="maxBookingCancelled"
              [(ngModel)]="checkinDate"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="Pricing">Pricing</div>
        <div class="right-down-text">
          <div class="sharingroom">
            {{ leadcreation.roomSharing.replace("_", " ") }} (Monthly)
          </div>
          <div class="sharing-price">&#8377;{{ leadcreation.price }}</div>
        </div>
        <div class="right-down-text">
          <div class="room-selected">Payment Status</div>
          <div class="floor-no">
            <span
              class="paymentStatus-rightside"
              [ngClass]="{
                paymentSuccess: leadcreation.bookingStatus == 'Paid',
                paymentInitiated:
                  leadcreation.bookingStatus == 'Booking Initiated',
                paymentFailed:
                  leadcreation.bookingStatus === 'Payment Failed' ||
                  leadcreation.bookingStatus === 'Payment Cancelled' ||
                  leadcreation.bookingStatus == 'Booking Cancelled'
              }"
              >{{ leadcreation.bookingStatus }}</span
            >
          </div>
        </div>
        <div class="button-sec">
          <div class="first-div">
            <button
              class="first-bt"
              (click)="cancelBooking(leadcreation)"
              [disabled]="leadcreation.bookingStatus == 'Booking Cancelled'"
            >
              Cancel Booking
            </button>
          </div>
          <div class="sec-div" *ngIf="leadcreation.bookingStatus == 'Paid'">
            <button
              class="second-bt"
              [disabled]="checkinDate == null || checkinDate == ''"
              (click)="updateBooking()"
            >
              Save Changes
            </button>
          </div>
          <div
            class="sec-div"
            *ngIf="
              leadcreation.bookingStatus == 'Payment Cancelled' ||
              leadcreation.bookingStatus == 'Booking Initiated' ||
              leadcreation.bookingStatus == 'Payment Failed'
            "
          >
            <button class="second-bt" (click)="retryPayment()">
              <span *ngIf="!bookagainLoader">Retry Payment</span>
              <span *ngIf="bookagainLoader"><i class="fa fa-spinner fa-spin"></i> &nbsp;Loading</span>
            </button>
          </div>
          <div
            class="sec-div"
            *ngIf="leadcreation.bookingStatus == 'Booking Cancelled'"
          >
            <button
              class="second-bt"
              *ngIf="leadcreation.bookingStatus == 'Booking Cancelled'"
              (click)="bookAgain()"
              [disabled]="checkinDate == null || checkinDate == ''"
            >
              Book Again
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-template #noCount>
    <div class="noBooking-section">
      <!-- <div class="mainHeading">Bookings</div> -->
      <div class="noBookingImg">
        <img
          src="./../../../../assets/images/myBookings/noBookings.png"
          alt=""
          width="30%"
        />
      </div>
      <div class="textSection">
        <div class="headingSection">No Bookings</div>
        <div class="subSection">You have not listed any bookings</div>
      </div>
    </div>
  </ng-template>
  <ng-template #loadingImg>
    <img
      src="./../../../../assets/images/loader.gif"
      alt=""
      class="loadingImg"
    />
  </ng-template>

  <div class="footer">
    <app-footer></app-footer>
  </div>
</section>

<form
  id="redirectForm"
  method="post"
  action="https://test.cashfree.com/billpay/checkout/post/submit"
>
  <input id="appId" type="hidden" name="appId" [value]="formDataView.appId" />
  <input type="hidden" name="orderId" [value]="formDataView.orderId" />
  <input type="hidden" name="orderAmount" [value]="formDataView.orderAmount" />
  <input type="hidden" name="orderCurrency" [value]="'INR'" />
  <input type="hidden" name="orderNote" [value]="'Booking'" />
  <input
    type="hidden"
    name="customerName"
    [value]="formDataView.customerName"
  />
  <input
    type="hidden"
    name="customerEmail"
    [value]="formDataView.customerEmail"
  />
  <input
    type="hidden"
    name="customerPhone"
    [value]="formDataView.customerPhone"
  />
  <input type="hidden" name="returnUrl" [value]="formDataView.returnUrl" />
  <input type="hidden" name="notifyUrl" [value]="formDataView.notifyUrl" />
  <input type="hidden" name="signature" [value]="formDataView.signature" />
</form>
