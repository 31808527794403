import { ErrorPopsService } from './../../../service/error-pops/error-pops.service';
import { Router } from '@angular/router';
import { ScheduleVisitService } from './../schedule-visit.service';
import { DbStorageService } from './../../../service/headers/db-storage/db-storage.service';
import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PopupService } from '../../popup-components/popup.service';
import { ScheduleVisitBookedComponent } from '../../popup-components/popups/schedule-visit-booked/schedule-visit-booked.component';

declare let $: any;
@Component({
  selector: 'user-flow-visit-user-flow',
  templateUrl: './visit-user-flow.component.html',
  styleUrls: [
    './visit-user-flow.component.scss',
    './mq-visit-user-flow.component.scss',
  ],
})
export class VisitUserFlowComponent implements OnInit {
  // statusClass = 'rttt';
  viewMode = 'selectVisit';
  getProperty;
  userid;
  timeArr;
  selectedDate: any;
  videoBtn = { name: 'Video Visit', value: 'VIDEO_VISIT', active: true };
  physicalBtn = {
    name: 'Physical Visit',
    value: 'PHYSICAL_VISIT',
    active: false,
  };
  visitType;
  @ViewChild('scroll') scroll: ElementRef;
  @ViewChild('prev') prev: ElementRef;
  @ViewChild('next') next: ElementRef;

  constructor(
    public dialogRef: MatDialogRef<VisitUserFlowComponent>,
    private _dbStorage: DbStorageService,
    private router: Router,
    private svService: ScheduleVisitService,
    private errorPops: ErrorPopsService,
    private openPop: PopupService,
    @Inject(MAT_DIALOG_DATA) public data,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    dialogRef.disableClose = true;
  }
  date = new Date();
  step = 0;
  daysAvailable = this.array_range(this.date.getDate(), 6);

  monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'July',
    'Aug',
    'Sept',
    'Oct',
    'Nov',
    'Dec',
  ];
  days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  email;
  gender;
  mobile;
  selectedTime: any = { time: '', schedule: '' };
  month = this.monthNames[this.date.getMonth()];
  year = this.date.getFullYear();
  dayName = this.days[this.date.getDay()];
  times: any[];
  allTimes = [
    {
      time: '09:00',
      schedule: 'AM',
      isActive: false,
      hours: '9',
      minutes: '00',
    },
    {
      time: '09:30',
      schedule: 'AM',
      isActive: false,
      hours: '9',
      minutes: '30',
    },
    {
      time: '10:00',
      schedule: 'AM',
      isActive: false,
      hours: '10',
      minutes: '00',
    },
    {
      time: '10:30',
      schedule: 'AM',
      isActive: false,
      hours: '10',
      minutes: '30',
    },
    {
      time: '11:00',
      schedule: 'AM',
      isActive: false,
      hours: '11',
      minutes: '00',
    },
    {
      time: '11:30',
      schedule: 'AM',
      isActive: false,
      hours: '11',
      minutes: '30',
    },
    {
      time: '12:00',
      schedule: 'PM',
      isActive: false,
      hours: '12',
      minutes: '00',
    },
    {
      time: '12:30',
      schedule: 'PM',
      isActive: false,
      hours: '12',
      minutes: '30',
    },
    {
      time: '01:00',
      schedule: 'PM',
      isActive: false,
      hours: '13',
      minutes: '00',
    },
    {
      time: '01:30',
      schedule: 'PM',
      isActive: false,
      hours: '13',
      minutes: '30',
    },
    {
      time: '02:00',
      schedule: 'PM',
      isActive: false,
      hours: '14',
      minutes: '00',
    },
    {
      time: '02:30',
      schedule: 'PM',
      isActive: false,
      hours: '14',
      minutes: '30',
    },
    {
      time: '03:00',
      schedule: 'PM',
      isActive: false,
      hours: '15',
      minutes: '00',
    },
    {
      time: '03:30',
      schedule: 'PM',
      isActive: false,
      hours: '15',
      minutes: '30',
    },
    {
      time: '04:00',
      schedule: 'PM',
      isActive: false,
      hours: '16',
      minutes: '00',
    },
    {
      time: '04:30',
      schedule: 'PM',
      isActive: false,
      hours: '16',
      minutes: '30',
    },
    {
      time: '05:00',
      schedule: 'PM',
      isActive: false,
      hours: '17',
      minutes: '00',
    },
    {
      time: '05:30',
      schedule: 'PM',
      isActive: false,
      hours: '17',
      minutes: '30',
    },
    {
      time: '06:00',
      schedule: 'PM',
      isActive: false,
      hours: '18',
      minutes: '00',
    },
    {
      time: '06:30',
      schedule: 'PM',
      isActive: false,
      hours: '18',
      minutes: '30',
    },
    {
      time: '07:00',
      schedule: 'PM',
      isActive: false,
      hours: '19',
      minutes: '00',
    },
    {
      time: '07:30',
      schedule: 'PM',
      isActive: false,
      hours: '19',
      minutes: '30',
    },
    {
      time: '08:00',
      schedule: 'PM',
      isActive: false,
      hours: '20',
      minutes: '00',
    },
    {
      time: '08:30',
      schedule: 'PM',
      isActive: false,
      hours: '20',
      minutes: '30',
    },
    {
      time: '09:00',
      schedule: 'PM',
      isActive: false,
      hours: '21',
      minutes: '00',
    },
    {
      time: '09:30',
      schedule: 'PM',
      isActive: false,
      hours: '21',
      minutes: '30',
    },
    {
      time: '10:00',
      schedule: 'PM',
      isActive: false,
      hours: '22',
      minutes: '00',
    },
  ];

  async timeArray() {
    let hh = this.date.getHours();
    if (this.daysAvailable[0].isActive) {
      this.times = [];
      await this.allTimes.forEach((value, index) => {
        if (hh + 2 < Number(value.hours)) {
          this.times.push(value);
        }
      });
    } else {
      this.times = [];
      this.times.push(...this.allTimes);
    }
    this.timeArr = this.times.length;
    // console.log(this.timeArr);
  }
  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  array_range(start, len) {
    let arr = new Array(len);
    let updatedDate = this.date;
    for (var i = 0; i < len; i++) {
      arr[i] = {
        date: updatedDate.getDate(),
        isActive: false,
        month: updatedDate.getMonth(),
      };
      updatedDate.setDate(updatedDate.getDate() + 1);
    }
    return arr;
  }
  visitId: any;
  visitDateTime: any;
  ngOnInit(): void {
    // console.log(this.data, this.daysAvailable);
    this.timeArray();
    this.userid = this._dbStorage.getUserId();
    // console.log("property id for userid",this.userid)
    this.getProperty = this._dbStorage.getProperty();
    // console.log("get property fully",this.getProperty)
    // this.visitId = this.data.data.visitId;
    // console.log('getvistId', this.visitId);
  }
  closeBooking() {
    this.dialogRef.close(this.data);
  }

  nextScroll() {
    this.sideScroll(this.scroll.nativeElement, 'right', 25, 100, 10);
  }
  previosScroll() {
    this.sideScroll(this.scroll.nativeElement, 'left', 25, 100, 10);
  }
  sideScroll(element, direction, speed, distance, step) {
    let scrollAmount = 0;
    var slideTimer = setInterval(function () {
      if (direction == 'left') {
        element.scrollLeft -= step;
      } else {
        element.scrollLeft += step;
      }
      scrollAmount += step;
      if (scrollAmount >= distance) {
        window.clearInterval(slideTimer);
      }
    }, speed);
  }
  newTime: any;
  visiteTime: any;
  timeValue(time) {
    this.selectedTime.time = time.time;
    this.selectedTime.schedule = time.schedule;
    var timeSchedule = this.selectedTime.time + this.selectedTime.schedule;
    for (let i = 0; i < this.times.length; i++) {
      if (
        this.times[i].time == this.selectedTime.time &&
        this.times[i].schedule == this.selectedTime.schedule
      ) {
        this.times[i].isActive = true;
      } else {
        this.times[i].isActive = false;
      }
    }
    // console.log('time', this.selectedTime.time, this.selectedTime.schedule);
    var timenew = this.selectedTime.time.toString();
    var schedule = this.selectedTime.schedule.toString();
    this.newTime = timenew + ':00';
    // console.log('time', this.newTime);
    var visit = this.newDate + ' ' + this.newTime + ' ' + schedule;
    this.visiteTime = new Date(visit).toUTCString();
    // console.log('toutcstrin', this.visiteTime.toString());
  }
  newDate: any;
  dateTime;
  selectDate(date) {
    this.selectedDate = date;
    for (let index = 0; index < this.daysAvailable.length; index++) {
      if (this.daysAvailable[index] == this.selectedDate) {
        this.daysAvailable[index].isActive = true;
      } else {
        this.daysAvailable[index].isActive = false;
      }
    }
    // console.log("date",this.selectedDate)
    // console.log('selected Date', this.selectedDate, this.month, this.year);
    var Day = this.dayName.toString();
    var Date = this.selectedDate.date.toString();
    var month = this.month.toString();
    var year = this.year.toString();
    this.month = this.monthNames[date.month].toString();
    // this.newDate = Day+' '+month+' '+Date+' '+year
    this.newDate = year + '/' + month + '/' + Date;
    // console.log('concatinate', this.newDate);
    this.timeArray();
    if (this.selectedTime.time !== '') {
      this.timeValue({
        time: this.selectedTime.time,
        schedule: this.selectedTime.schedule,
      });
    }
  }
  closeDialog() {
    this.dialogRef.close();
  }
  verifyotp = {
    otp1: '',
    otp2: '',
    otp3: '',
    otp4: '',
    otp5: '',
    otp6: '',
  };
  move(fromnum, tonum) {
    var length = fromnum.length;
    var maxlength = fromnum.getAttribute(maxlength);
    if (length == maxlength) {
      tonum.focus();
    }
  }

  videoVisit() {
    this.visitType = this.videoBtn.value;
    // console.log(this.visitType);
    if (this.visitType == 'VIDEO_VISIT') {
      this.videoBtn.active = true;
      this.physicalBtn.active = false;
    }
    // console.log(this.videoBtn.active);
    // console.log(this.physicalBtn.active);
  }
  physicalVisit() {
    this.visitType = this.physicalBtn.value;
    // console.log(this.visitType);
    if (this.visitType == 'PHYSICAL_VISIT') {
      this.videoBtn.active = false;
      this.physicalBtn.active = true;
    }
    // console.log(this.videoBtn.active);
    // console.log(this.physicalBtn.active);
  }
  // setActiveClass(){
  //   this.statusClass = 'active';
  // }
  rescheduleBook() {
    this.openPop.openDialog(ScheduleVisitBookedComponent, {
      data: this.data,
      width: '372px',
      height: 'auto',
      maxWidth: '374px',
      maxHeight: '404px',
      bigtext: 'Successfully Visit Rescheduled!',
      subtext:
        'Your visit has been successfully rescheduled. We look forward to see you at visit time.',
      image: './../../../../assets/images/popup-images/reschedule.png',
    });
  }
  succesVisit() {
    this.openPop.openDialog(ScheduleVisitBookedComponent, {
      data: this.data,
      width: '394px',
      height: ' auto',
      bigtext: 'Successfully Visit Booked!',
      subtext:
        'Your visit has been successfully booked. We look forward to see you at visit time.',
      image: './../../../../assets/images/popup-images/reschedule.png',
    });
  }

  submitDetails() {
    let visitData = {
      city: this.getProperty.propertyAddress.city,
      locality: this.getProperty.propertyAddress.locality,
      propertyId: this.getProperty.propertyId,
      propertyName: this.getProperty.propertyName,
      status: 'null',
      userId: this.userid,
      visitType: this.visitType || 'VIDEO_VISIT',
      visitDateTime: this.visiteTime,
    };
    if (this.visiteTime == null) {
      this.errorPops.openSnackBarMessage('Plase Select Date & Time', 'OK');
    } else {
      this.svService.createVisit(visitData).subscribe(
        (res: any) => {
          // console.log('sucesss', res);
          // this.router.navigate(["viewMode='enterDetails'"])
          // this.viewMode = 'enterDetails'
          this.closeDialog();
          this.succesVisit();
        },
        (err: any) => {
          // console.log('error', err);
        }
      );
    }
  }

  reschedule() {
    let scheduleData = {
      visitId: this.data.data.visitId,
      visitDateTime: this.visiteTime,
      visitType: this.visitType || 'VIDEO_VISIT',
    };
    if (this.visiteTime == null) {
      this.errorPops.openSnackBarMessage('Plase Select Date & Time', 'OK');
    } else {
      this.svService.reScheduleVisit(scheduleData).subscribe(
        (res: any) => {
          // console.log('response', res);
          this.dialogRef.close();
          this.rescheduleBook();
        },
        (err) => {
          // console.log('gettting error', err);
          // this.errorPops.openSnackBarMessage(err.error.message, 'Cancel');
        }
      );
    }
  }
}
