import { CareersComponent } from './../components/shared/careers/careers.component';
import { ReferAndEarnComponent } from './../components/shared/refer-and-earn/refer-and-earn.component';
import { AddPlaceComponent } from './../components/popup-components/popups/add-place/add-place.component';
import { SavedPlaceComponent } from './../components/shared/saved-place/saved-place.component';
import { CountdownModule } from 'ngx-countdown';
import { NgOtpInputModule } from 'ng-otp-input';
import { PromisesTabsComponent } from './../components/home-page/promises-tabs-details/promises-tabs/promises-tabs.component';
import { BookingPopUserComponent } from './../components/booking/booking-pop-user/booking-pop-user.component';
import { MyBookingsComponent } from './../components/booking/my-bookings/my-bookings.component';
import { RoomListComponent } from './../components/rooms/room-list/room-list.component';
import { SmartStayApplicationComponent } from './../components/home-page/smart-stay-application/smart-stay-application.component';
import { OurServicesHomePageComponent } from './../components/home-page/our-services-home-page/our-services-home-page.component';
import { RightSlidingNavbarComponent } from './../components/shared/navbars/right-sliding-navbar/right-sliding-navbar.component';
import { TopNavbarComponent } from './../components/shared/navbars/top-navbar/top-navbar.component';
import { MaterialPropertyModule } from '../material-property/material-property.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxSpinnerModule } from 'ngx-spinner';

import { ListPropertyComponent } from '../components/list-properties/list-property/list-property.component';

import { SingleImageCarouselComponent } from '../components/shared/carousel/single-image-carousel/single-image-carousel.component';
import { SearchPropertyBoardComponent } from '../components/list-properties/search-property-board/search-property-board.component';
import { OfferingCarouselComponent } from '../components/shared/carousel/offering-carousel/offering-carousel.component';
import { MainPageComponent } from '../components/home-page/main-page/main-page.component';
import { PropertyListZoneComponent } from '../components/home-page/property-list-zone/property-list-zone.component';
import { ProductBenifitsComponent } from '../components/home-page/product-benifits/product-benifits.component';
import { ComunitySectionComponent } from '../components/home-page/comunity-section/comunity-section.component';
import { BlogPreviewSectionComponent } from '../components/home-page/blog-preview-section/blog-preview-section.component';
import { CustomerCarouselComponent } from '../components/shared/carousel/customer-carousel/customer-carousel.component';
import { FooterComponent } from '../components/shared/footer/footer/footer.component';
import { PropDetailsComponent } from '../components/property-details/prop-details/prop-details.component';
import { MapSearchComponent } from '../components/shared/map/map-search/map-search.component';
import { MapSpotCordinatesComponent } from '../components/shared/map/map-spot-cordinates/map-spot-cordinates.component';
import { AgmCoreModule } from '@agm/core';
import { FormsModule } from '@angular/forms';
import { ImageCarouselPropDetailComponent } from '../components/shared/carousel/image-carousel-prop-detail/image-carousel-prop-detail.component';
import { CommonAmenitiesComponent } from '../components/property-details/common-amenities/common-amenities.component';
import { VisitUserFlowComponent } from '../components/visit/visit-user-flow/visit-user-flow.component';
import { FavouriteComponent } from '../components/favourite/favourite/favourite.component';
import { AboutUsComponent } from '../components/static/about-us/about-us.component';
import { FaqsComponent } from '../components/static/faqs/faqs.component';
import { KycComponent } from '../components/payments/kyc/kyc.component';
import { CommunityComponent } from '../components/static/community/community.component';
import { ProfileUserComponent } from '../components/shared/profile/profile-user/profile-user.component';
import { ContactUsComponent } from '../components/shared/contactus/contact-us/contact-us.component';
import { PrivacyPolicyComponent } from '../components/static/privacy-policy/privacy-policy.component';
import { SigninSignupFlowComponent } from '../components/authentication/signin-signup-flow/signin-signup-flow.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { CancelVisitComponent } from '../components/popup-components/popups/cancel-visit/cancel-visit.component';
import { ChangeMobileNumberComponent } from '../components/popup-components/popups/change-mobile-number/change-mobile-number.component';
import { LogoutPopupComponent } from '../components/popup-components/popups/logout-popup/logout-popup.component';
import { ScheduleVisitBookedComponent } from '../components/popup-components/popups/schedule-visit-booked/schedule-visit-booked.component';
import { SearchBarComponent } from '../components/popup-components/popups/search-bar/search-bar.component';
import { OtpVerificationComponent } from '../components/popup-components/popups/otp-verification/otp-verification.component';
import { LoftComponent } from '../components/static/loft/loft.component';

import { MyVisitsComponent } from './../components/visit/my-visits/my-visits.component';
import { ListYourPropertyComponent } from '../components/shared/Partnership/list-your-property/list-your-property.component';
import { RegisterYourServiceComponent } from '../components/shared/Partnership/register-your-service/register-your-service.component';
import { ShowcaseYourTalentComponent } from '../components/shared/Partnership/showcase-your-talent/showcase-your-talent.component';
import { CorporateConnectComponent } from '../components/shared/Partnership/corporate-connect/corporate-connect.component';
import { BookingDetailsComponent } from '../components/booking/booking-details/booking-details.component';
import { TermsAndConditionsComponent } from '../components/static/terms-and-conditions/terms-and-conditions.component';
import { ApplyComponent } from '../components/popup-components/popups/apply/apply.component';
import { ReferAFriendComponent } from '../components/popup-components/popups/refer-a-friend/refer-a-friend.component';
import { RemainderComponent } from '../components/popup-components/popups/remainder/remainder.component';
import { PremiumpropertyListComponent } from '../components/home-page/premiumproperty-list/premiumproperty-list.component';
import { TempEnqueryPopupComponent } from '../components/popup-components/popups/temp-enquery-popup/temp-enquery-popup.component';
import { FoodCourtComponent } from '../components/static/food-court/food-court.component';
import { CarouselUserComponent } from '../components/shared/carousel/carousel-user/carousel-user.component';
import { LocalityAutoCompleteComponent } from '../components/shared/locality-auto-complete/locality-auto-complete.component';
import { SuggestBookingComponent } from '../components/booking/suggest-booking/suggest-booking.component';
import { AllJobsComponent } from './../components/shared/careers/all-jobs/all-jobs.component';
import { JobDetailsComponent } from '../components/shared/careers/job-details/job-details.component';
import { PropDetailsFromErpComponent } from './../components/property-details/prop-details-from-erp/prop-details-from-erp.component';
import { PrivacyPoliciesComponent } from '../components/static/privacy-policies/privacy-policies.component';

@NgModule({
  declarations: [
    ListPropertyComponent,
    SearchPropertyBoardComponent,
    MainPageComponent,
    OurServicesHomePageComponent,
    PropertyListZoneComponent,
    ProductBenifitsComponent,
    ComunitySectionComponent,
    BlogPreviewSectionComponent,
    PremiumpropertyListComponent,
    SmartStayApplicationComponent,
    PropDetailsComponent,
    CommonAmenitiesComponent,
    PropDetailsFromErpComponent,

    //footer
    FooterComponent,

    //room
    RoomListComponent,

    //navbars
    TopNavbarComponent,
    RightSlidingNavbarComponent,

    //carousel
    SingleImageCarouselComponent,
    OfferingCarouselComponent,
    CustomerCarouselComponent,
    ImageCarouselPropDetailComponent,
    CarouselUserComponent,

    //map
    MapSearchComponent,
    MapSpotCordinatesComponent,

    //booking
    BookingPopUserComponent,
    MyBookingsComponent,
    BookingDetailsComponent,
    SuggestBookingComponent,

    //visit
    VisitUserFlowComponent,
    MyVisitsComponent,

    //promises-tabs
    PromisesTabsComponent,

    //favourite
    FavouriteComponent,

    //static
    AboutUsComponent,
    FaqsComponent,
    CommunityComponent,
    TermsAndConditionsComponent,

    //payment
    KycComponent,

    //shared
    ProfileUserComponent,
    ContactUsComponent,
    PrivacyPolicyComponent,
    SigninSignupFlowComponent,
    ListYourPropertyComponent,
    RegisterYourServiceComponent,
    ShowcaseYourTalentComponent,
    CorporateConnectComponent,
    SavedPlaceComponent,
    ReferAndEarnComponent,
    CareersComponent,
    LocalityAutoCompleteComponent,
    AllJobsComponent,
    JobDetailsComponent,

    //PopUps
    CancelVisitComponent,
    ChangeMobileNumberComponent,
    LogoutPopupComponent,
    ScheduleVisitBookedComponent,
    SearchBarComponent,
    OtpVerificationComponent,
    ApplyComponent,
    ReferAFriendComponent,
    RemainderComponent,
    TempEnqueryPopupComponent,
    //loft
    LoftComponent,
    AddPlaceComponent,
    FoodCourtComponent,
    PrivacyPoliciesComponent,
  ],
  imports: [
    CommonModule,
    MaterialPropertyModule,
    MatExpansionModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDSmQTx4n1e1PMy95EaAnyNwNk0nsXcYoY',
      libraries: ['places', 'geometry'],
    }),
    FormsModule,
    CountdownModule,
    NgOtpInputModule,
    NgxSpinnerModule,
  ],
})
export class ModulesPropertyModule {}
