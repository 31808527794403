<div class="head-text">Add Place</div>
<div class="close-x" (click)="closeBtn()">
  <img src="./../../../../../assets/images/popup-images/cross-mark.png" />
</div>
<div class="searchPlace">
  <div class="searchflow">
    <div class="search-box">
      <div class="search-place">
        <div class="text-box">
          <user-flow-map-search
            #place
            (setAddress)="setAddress($event)"
            [placeHolderName]="'Find your property by locality or city'"
          >
          </user-flow-map-search>
        </div>
        <!-- <div class="search-location">
          <img src="./../../../../assets/images/popup-images/Search.png"/>
        </div> -->
      </div>
    </div>
    <div class="btn-section">
      <button
        class="btns"
        *ngFor="let item of btnSection; index as i"
        (click)="btnFunction(i)"
      >
        {{ item.name }}
      </button>
    </div>
    <div class="savebtn">
      <button class="svbtn" (click)="setDetails()">Save Place</button>
    </div>
  </div>
  <div class="mapflow">
    <div class="geo-view">
      <agm-map [latitude]="17.3" [longitude]="18.25" [zoom]="zoom">
        <agm-marker [latitude]="17.3" [longitude]="18.25" [iconUrl]="icon">
          >
        </agm-marker>
      </agm-map>
    </div>
  </div>
</div>
<!-- app.component.html -->
<!-- <div class="searchPlace">
  <div class="searchflow">
    <div class="search-box">
        <div class="text-box">
          <div class="form-group">
            <input type="text" class="form-control" (keydown.enter)="$event.preventDefault()" placeholder="Search Nearest Location" autocorrect="off" autocapitalize="off" spellcheck="off" type="text" #search>
          </div>
        </div>
    </div>
    <div class="btn-section">
      <button class="btns" *ngFor="let item of btnSection;index as i" (click)="btnFunction(i)">{{item.name}}</button>
    </div>
    <div class="savebtn">
      <button class="svbtn"  (click)="setDetails()">Save Place</button>
    </div>
  </div>
  <div class="mapflow">
    <div class="geo-view">
      <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="zoom">
        <agm-marker [latitude]="latitude" [longitude]="longitude" [markerDraggable]="true"
          (dragEnd)="markerDragEnd($event)"></agm-marker>
      </agm-map>
    </div>
  </div>
</div> -->

<h5>Address: {{ address }}</h5>
<div>Latitude: {{ latitude }}</div>
<div>Longitude: {{ longitude }}</div>
