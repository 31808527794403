<div class="section">
  <div class="top-nav">
    <user-flow-top-navbar></user-flow-top-navbar>
  </div>
  <div class="total">
    <div class="left">
      <div class="left-bg">
        <div class="left-heading">
          Join The Isthara Family - Opt For A Corporate Connect
        </div>
        <div class="sub-text">
          We know you value your employees, your truest treasure in the most
          uncertain times. We want your connection with them to last forever and
          only grow deeper in the times to come. Join hands with Isthara to
          provide a living experience like no other for your employees and let
          us help you create a safe haven for them.
        </div>
        <div class="talent-image">
          <img
            src="./../../../../../assets/images/list-your-property/corprate.png"
          />
        </div>
      </div>
      <div class="benifits">The Isthara</div>
      <div class="isthara-promise">Promise</div>
      <div>
        <div class="total-bottom-images">
          <div class="bottom-images" *ngFor="let im of about">
            <img src="{{ im.img }}" />

            <div class="bottom-images-text">{{ im.text | titlecase }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="right">
      <div class="right-form">
        <div class="form-heading">Connect With Us</div>
        <div class="share-detail">Share your details with us</div>
        <div class="fields">
          <div class="name">
            <mat-form-field class="input-width" appearance="outline">
              <mat-label>Name</mat-label>
              <input
                matInput
                [(ngModel)]="Form.details.name"
                #nameCheck="ngModel"
                pattern="[a-zA-Z\s]+"
                required
              />
            </mat-form-field>
            <mat-error
              *ngIf="!nameCheck.valid && (nameCheck.dirty || nameCheck.touched)"
            >
              Please enter your name
            </mat-error>
          </div>
          <div class="name">
            <mat-form-field class="input-width" appearance="outline">
              <mat-label>Mobile Number</mat-label>
              <input
                matInput
                [(ngModel)]="Form.details.mobileNo"
                #numberCheck="ngModel"
                pattern="^[6-9]\d{9}$|^[1-9]\d{9}$"
                required
              />
            </mat-form-field>
            <mat-error
              *ngIf="
                !numberCheck.valid && (numberCheck.dirty || numberCheck.touched)
              "
            >
              please provide your contact number
            </mat-error>
          </div>
          <div class="name">
            <mat-form-field class="input-width" appearance="outline">
              <mat-label>Email id</mat-label>
              <input
                matInput
                [(ngModel)]="Form.details.emailId"
                #emailCheck="ngModel"
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                required
              />
            </mat-form-field>
            <mat-error
              *ngIf="
                !emailCheck.valid && (emailCheck.dirty || emailCheck.touched)
              "
            >
              please provide your emailID
            </mat-error>
          </div>
          <div class="name">
            <mat-form-field class="input-width" appearance="outline">
              <mat-label>Company Name</mat-label>
              <input
                matInput
                [(ngModel)]="Form.details.companyName"
                #CompanyNameCheck="ngModel"
                required
              />
            </mat-form-field>
            <mat-error
              *ngIf="
                !CompanyNameCheck.valid &&
                (CompanyNameCheck.dirty || CompanyNameCheck.touched)
              "
            >
              please provide your companyName
            </mat-error>
          </div>
          <div class="name">
            <mat-form-field class="input-width" appearance="outline">
              <mat-label>location</mat-label>
              <input
                matInput
                [(ngModel)]="Form.details.location"
                #locationCheck="ngModel"
                required
              />
            </mat-form-field>
            <mat-error
              *ngIf="
                !locationCheck.valid &&
                (locationCheck.dirty || locationCheck.touched)
              "
            >
              Please enter your location
            </mat-error>
          </div>
          <div class="name">
            <mat-form-field class="input-width" appearance="outline">
              <mat-label>City</mat-label>
              <input
                matInput
                [(ngModel)]="Form.details.city"
                #cityCheck="ngModel"
                pattern="[a-zA-Z\s]+"
                required
              />
            </mat-form-field>
            <mat-error
              *ngIf="!cityCheck.valid && (cityCheck.dirty || cityCheck.touched)"
            >
              Please enter your city
            </mat-error>
          </div>
          <div class="prefer-method">
            <p>Preferred Contact Method</p>
            <mat-radio-group
              aria-label="Select an option"
              [(ngModel)]="Form.details.contactMethod"
            >
              <mat-radio-button [value]="'email'">Email</mat-radio-button>
              <mat-radio-button [value]="'mobile'">Mobile</mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="message">
            <mat-form-field class="input-width" appearance="outline">
              <mat-label>Message</mat-label>
              <textarea matInput></textarea>
            </mat-form-field>
          </div>
        </div>
        <div class="sec-div">
          <button
            class="second-bt"
            (click)="submitdetails()"
            [disabled]="
              !numberCheck.valid && (numberCheck.dirty || numberCheck.touched)
            "
          >
            Submit
          </button>
        </div>
        <div class="or">OR</div>
        <div class="contact-number"><span>Call us on</span> 8790858581</div>
      </div>
    </div>
  </div>

  <div class="companies">
    <div class="company-text">Our Corporates</div>
    <div class="companyimage-side">
      <div class="company-images" *ngFor="let cp of company">
        <img src="{{ cp.img }}" />
      </div>
    </div>
  </div>
</div>
<div class="footer">
  <app-footer></app-footer>
</div>
