<!-- <div class="close-x" (click)="closeBtn()">
  <img src="./../../../../assets/images/popup-images/cross-mark.png" />
</div> -->
<div class="total-popup">
  <div class="pop-heading">
    Oops ! 
  </div>
  <div class="sub">
    This Property is Only for {{data.data.property.propertyType | uppercase}} resident. You can't book this property. Please Try {{nextLevel | uppercase}} or Colving Property
  </div>
  <div class="proceed-buttons">
    <div class="otherProperty">
      <button (click)="searchBar()">Search Other Property</button>
    </div>
    <div class="continue">
      <button (click)="closeBtn()">Update Gender</button>
    </div>
  </div>
</div>