import { DbStorageService } from './../../../service/headers/db-storage/db-storage.service';
import {
  Component,
  Input,
  OnInit,
  OnChanges,
  Output,
  EventEmitter,
} from '@angular/core';


@Component({
  selector: 'user-flow-room-list',
  templateUrl: './room-list.component.html',
  styleUrls: ['./room-list.component.scss', 'mq-room-list.component.scss'],
})
export class RoomListComponent implements OnInit, OnChanges {
  @Input() roomList: any[];
  @Input() selection: boolean;
  @Output() roomSelected = new EventEmitter<any>();
  rooms: any[] = [0];
  constructor(
    private _dbStorage: DbStorageService
  ) { }
  ngOnChanges(changes: import('@angular/core').SimpleChanges) {
    // if (changes.roomList.currentValue) {
    //   this.rooms = changes.roomList.currentValue;
    // }
  }
  ngOnInit(): void {
    // console.log('roomlist: ', this.roomList);
    this.rooms = this.roomList.map((r) => {
      return { ...r, isSharingCount: false, isPrivateRoom: false }
    });
    // console.log("rooooooooooooo", this.rooms);

  }
  selectroom1 = { name: "Select Room", active: false }
  selectroom2 = { name: "Select Room", active: false }
  selectedRoom(room) {
    this.roomSelected.emit(room);
    for (let i = 0; i < this.rooms.length; i++) {
      if (this.rooms[i].roomTypeId == room.roomTypeId) {
        this.rooms[i].isSharingCount = true;
        this.rooms[i].isPrivateRoom = false
        break;
      }
    }
    for (let i = 0; i < this.rooms.length; i++) {
      if (this.rooms[i].roomTypeId != room.roomTypeId) {
        this.rooms[i].isSharingCount = false;
        this.rooms[i].isPrivateRoom = false;
      }
    }
    // console.log("new update", this.rooms);
    // if(this.selectroom1.active == true){
    //   this.selectroom1.active = true
    //   this.selectroom2.active = false
    // }
    // else{
    //   this.selectroom1.active = true
    //   this.selectroom2.active = false
    // }
  }
  selectOfferRoom(room) {
    this.roomSelected.emit(room)
    // console.log("this.rooms",this.rooms,room);
    for (let i = 0; i < this.rooms.length; i++) {
      if (this.rooms[i].roomTypeId == room.roomTypeId) {
        this.rooms[i].isSharingCount = false;
        this.rooms[i].isPrivateRoom = true;
        break;
      }
    }
    for (let i = 0; i < this.rooms.length; i++) {
      if (this.rooms[i].roomTypeId != room.roomTypeId) {
        this.rooms[i].isSharingCount = false;
        this.rooms[i].isPrivateRoom = false;
      }
    }
    // console.log("new update", this.rooms);

    // if(this.selectroom2.active == false){
    //   this.selectroom1.active = false
    //   this.selectroom2.active = true
    // }
    // else{
    //   this.selectroom1.active = false
    //   this.selectroom2.active = true
    // }
    // console.log("some thing",this.roomSelected.emit(room))
  }
}
