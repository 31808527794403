<section class="container-fluid">
  <nav class="navbar navbar-expand-lg navbar-light">
    <a class="navbar-brand image-logo cursor-pointer" href="">
      <div class="brand-section">
        <img [src]="home?.brandingLogo" alt="logo-ws" class="logo" loading="lazy" />
        <!-- <div class="brandName">{{ home?.brandName }}</div> -->
      </div>
      
    </a>
    <!-- <div class="profile-btn" (click)="loginredirect()"*ngIf="srcWidth < 475">Login</div> -->
    <!-- <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
      aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button> -->
    
    <div class="collapse navbar-collapse show" id="navbarNav">
      
      <ul class="navbar-nav justify-content-end size-ul">
        <ng-container *ngFor="let item of navbarContet">
          <li class="nav-item cursor-pointer li-size"*ngIf="srcWidth > 480 || item.title === 'Enquire Now'">
            <span (click)="routeTo(item.redirectUrl)">
              <ng-container *ngIf="item.title !== 'Enquire Now'">
                {{ item?.title }}
              </ng-container>
              
            </span>
            <ng-container *ngIf="item.title === 'Enquire Now'">
              <div *ngIf="item.title === 'Enquire Now'" class="enquire-now" (click)="enquiryPopup()">
                Enquire Now
              </div>
            </ng-container>
          </li>
          <!-- <li class="nav-item cursor-pointer book-btn" *ngIf="item?.srNo == '1'" >
            <span (click)="routeTo(item.path)" *ngIf="getUserId == 'null' || getUserId === null"><button>{{item?.title}}</button></span>
          </li> -->
        </ng-container>
        <!-- <li
          class="nav-item cursor-pointer li-sizes"
          *ngIf="getUserId != null && getUserId != 'null'"
        >
          <div class="notification-image">
            <img
              src="./../../../../../assets/images/top-nav/notificationIcon.png"
              alt=""
            />
          </div>
        </li>
        <li
          class="nav-item cursor-pointer li-sizes"
          *ngIf="getUserId != null && getUserId != 'null'"
        >
          <div class="img-section">
            <img
              [src]="profileImg"
              [alt]="name"
              loading="lazy"
              *ngIf="profileImg != null; else noImg"
            />
          </div>
          <ng-template class="img-section" #noImg>
            <img
              src="./../../../../../assets/images/right-sliding-nav/defaultUser.png"
              alt="user"
              loading="lazy"
            />
          </ng-template>
        </li> -->

        <li class="nav-item cursor-pointer rightsliding-bar">
          <user-flow-right-sliding-navbar [navbarText]="navbarContet"></user-flow-right-sliding-navbar>
        </li>
      </ul>
    </div>
  </nav>
</section>
