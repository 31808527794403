<section *ngIf="loader; else loadingImg">
  <div class="main-page">
    <div class="navbar-section">
      <user-flow-top-navbar></user-flow-top-navbar>
    </div>
    <div class="main-section">
      <div class="main-heading">
        <div class="heading" innerHTML="{{ carosouelSection?.title }}"></div>
      </div>
      <div class="search-box">
        <div class="search">
          <div class="text-box" (click)="openVisit5()">
            <user-flow-map-search
              #place
              (setAddress)="getAddress($event)"
              [placeHolderName]="'Find your property by locality or city'"
            >
            </user-flow-map-search>
          </div>
          <!-- <div class="search-img" (click)="openVisit5()">
            <img src="./../../../../assets/images/popup-images/Search.png" />
          </div> -->
          <div class="btn-box">
            <div class="theme-btn" (click)="nearBy()">
              <span class="search-icon">
                <img
                  src="./../../../../assets/images/home/nearby.svg"
                  alt="nearby"
                  class="nearby-btn-icon"
                />
              </span>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="carousel-offer">
        <user-flow-offering-carousel
          [offers]="home?.offersSection"
          [parentWidth]="offeringWidth"
        ></user-flow-offering-carousel>
      </div> -->
    </div>

    <div style="width: 50%"></div>
    <div class="our-promise">
      <user-flow-our-services-home-page
        [home]="home?.promiseSection"
        [cardList]="home?.promiseSection.promiseCard"
      ></user-flow-our-services-home-page>
    </div>
    <div class="zone-section">
      <user-flow-property-list-zone
        [home]="home?.locationsSection"
        [location]="home?.locationsSection.locations"
        [locality]="locality"
      ></user-flow-property-list-zone>
    </div>

    <div class="premium-property">
      <user-flow-premiumproperty-list
        [premiumProperty]="home.premiumPropertySection"
      ></user-flow-premiumproperty-list>
    </div>

    <div class="benifits">
      <user-flow-product-benifits
        [benifits]="home?.benifitsSection"
        [benifitCard]="home?.benifitsSection.benefitsCards"
      ></user-flow-product-benifits>
    </div>
    <div class="community">
      <user-flow-comunity-section
        [community]="home?.communitySection"
        [communityCard]="home?.communitySection.communityCards"
      ></user-flow-comunity-section>
    </div>
    <!-- <div class="blog-section">
      <user-flow-blog-preview-section
        [blog]="home?.blogSection"
        [blogCards]="home?.blogSection.blogCards"
      ></user-flow-blog-preview-section>
    </div> -->
    <div class="feedback">
      <div class="heading-firsr">Our</div>
      <div class="heading-secod">Success Stories</div>
      <div class="sub-text">See what people have to say about Isthara.</div>
      <user-flow-carousel-user></user-flow-carousel-user>

      <!-- <div class="carousel-img">
        <img
          src="./../../../../assets/images/carousel/carousel.png"
          class="img-box"
          alt=""
        />
      </div> -->

      <!-- <user-flow-customer-carousel
        [curstomerCarousel]="home?.reviewSection"
        [cusomerCards]="home?.reviewSection.reviewCards"
      ></user-flow-customer-carousel> -->
    </div>
    <div class="smart-stay">
      <user-flow-smart-stay-application
        [smartStay]="home?.downloadAppSection"
      ></user-flow-smart-stay-application>
    </div>
    <div class="footer">
      <app-footer></app-footer>
    </div>
  </div>
</section>
<ng-template #loadingImg>
  <div class="main-loader">
    <img src="./../../../../assets/images/loader.gif" alt="" class="loadingImg" />
  </div>
</ng-template>
