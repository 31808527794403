import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'user-flow-image-carousel-prop-detail',
  templateUrl: './image-carousel-prop-detail.component.html',
  styleUrls: ['./image-carousel-prop-detail.component.scss'],
})
export class ImageCarouselPropDetailComponent implements OnInit, OnChanges {
  @Input() imageList = [];
  @Input() srcWidth;
  imageToShow: string = '';
  images: any[] = [];
  imageIndex = 0;
  interval;
  overLay = false;
  constructor() {}
  ngOnChanges(changes: import('@angular/core').SimpleChanges) {
    if (changes.imageList.currentValue) {
      this.carousel(changes.imageList.currentValue);
    }
  }
  carousel(imageList) {
    if (imageList.length > 8) {
      if(this.srcWidth < 476){
        imageList.length = 4;  
      }
      else{
        imageList.length = 8;
      }
    }

    if (imageList) {
      this.images.push(...imageList);
      this.imageToShow = this.images[0].imageUrl;
    } else {
      this.images.push(...imageList);
      this.imageToShow = this.images[0].imageUrl;
    }
    this.interval = setInterval(() => {
      if (this.imageIndex < this.images.length - 1) {
        this.imageToShow = this.images[++this.imageIndex].imageUrl;
      } else {
        this.imageIndex = 0;
        this.imageToShow = this.images[this.imageIndex].imageUrl;
      }
      // console.log('running', this.imageIndex);
    }, 5 * 1000);
    // console.log(this.images);
  }

  changeImage(item, i) {
    this.imageIndex = i;
    this.imageToShow = this.images[i].imageUrl;
    // console.log(item, i);
  }

  ngOnInit(): void {}
  showImage() {
    this.overLay = true;
  }
  closeOverlay() {
    this.overLay = false;
  }

  previous() {
    this.imageIndex = this.imageIndex - 1;
    if (this.imageIndex < 0) {
      this.imageIndex = this.images.length - 1;
      this.imageToShow = this.images[this.imageIndex].imageUrl;
    } else {
      this.imageToShow = this.images[this.imageIndex].imageUrl;
    }
  }
  next() {
    this.imageIndex = this.imageIndex + 1;
    if (this.imageIndex < this.images.length - 1) {
      this.imageToShow = this.images[this.imageIndex].imageUrl;
    } else {
      this.imageIndex = 0;
      this.imageToShow = this.images[this.imageIndex].imageUrl;
    }
  }

  ngOnDestroy() {
    clearInterval(this.interval);
  }
}
