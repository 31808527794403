<section *ngIf="loader; else loadingImg">
  <div class="main-section">
    <div class="top-nav">
      <user-flow-top-navbar></user-flow-top-navbar>
    </div>
    <!-- <div class="search-bar">
      <div class="main-search">
        <div class="location-drop-down">
          <span class="icon">
            <user-flow-map-spot-cordinates
              (setAddress)="getCordinates($event)"
            ></user-flow-map-spot-cordinates>
          </span>
          <span class="location">{{ cordinatesValue | titlecase }}</span>
        </div>
        <div class="search-box">
          <user-flow-map-search
            #place
            (setAddress)="getAddress($event)"
            [placeHolderName]="'Search by locality or city'"
          >
          </user-flow-map-search>
        </div>
      </div>
    </div> -->
    <div class="kyc-section">
      <div class="big-text">KYC Details</div>
      <mat-expansion-panel
        class="background-heading"
        [expanded]="step === 0"
        (opened)="setStep(0)"
      >
        <mat-expansion-panel-header>
          <mat-panel-title
            ><div class="panel-heading">
              Personal Details
              <div
                *ngIf="
                  this.kycDetails.personalDetails.fullname == null ||
                    this.kycDetails.personalDetails.fullname == '' ||
                    this.kycDetails.personalDetails.dob == null ||
                    this.kycDetails.personalDetails.dob == null ||
                    this.kycDetails.personalDetails.gender == null ||
                    this.kycDetails.personalDetails.gender == null ||
                    this.kycDetails.personalDetails.paddress == null ||
                    this.kycDetails.personalDetails.paddress == '' ||
                    this.kycDetails.personalDetails.state == null ||
                    this.kycDetails.personalDetails.state == '' ||
                    this.kycDetails.personalDetails.city == null ||
                    this.kycDetails.personalDetails.city == '' ||
                    this.kycDetails.personalDetails.country == null ||
                    this.kycDetails.personalDetails.country == '';
                  else part
                "
              >
                <div class="pending">Pending!</div>
              </div>
              <ng-template #part>
                <img src="./../../../../assets/images/list/right.png" />
              </ng-template>
              <!-- <div *ngIf="showMe">
                <img src="./../../../../assets/images/list/right.png" />
              </div> -->
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <span class="logged-text">Please tell us about yourself</span>
        <div class="personal-info">
          <mat-form-field class="full-width">
            <mat-label>Full Name</mat-label>
            <!-- <input matInput /> -->
            <input
              matInput
              [(ngModel)]="kycDetails.personalDetails.fullname"
              #nameCheck="ngModel"
              pattern="[a-zA-Z\s]+"
              required
            />
            <mat-error
              *ngIf="!nameCheck.valid && (nameCheck.dirty || nameCheck.touched)"
            >
              Please enter fullname
            </mat-error>
          </mat-form-field>
          <div class="full-half-width">
            <mat-form-field class="half-width">
              <mat-label>Date of Birth</mat-label>
              <input
                matInput
                [matDatepicker]="picker"
                [(ngModel)]="kycDetails.personalDetails.dob"
                #dobCheck="ngModel"
                [max]="maxDate"
              />
              <mat-error
                *ngIf="!dobCheck.valid && (dobCheck.dirty || dobCheck.touched)"
              >
                please enter date of birth
              </mat-error>
              <mat-datepicker-toggle
                matSuffix
                [for]="picker"
              ></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <div class="gap-width"></div>
            <mat-form-field appearance="fill" class="half-width">
              <mat-label>Gender</mat-label>
              <mat-select
                [(ngModel)]="selectedValue"
                name="gender"
                [(ngModel)]="kycDetails.personalDetails.gender"
                #genderCheck="ngModel"
                required
              >
                <mat-option
                  *ngFor="let gender of genders"
                  [value]="gender.value"
                >
                  {{ gender.viewValue }}
                </mat-option>
              </mat-select>
              <mat-error
                *ngIf="
                  !genderCheck.valid &&
                  (genderCheck.dirty || genderCheck.touched)
                "
              >
                please select your gender
              </mat-error>
            </mat-form-field>
          </div>
          <div class="full-half-width">
            <mat-form-field class="half-width">
              <mat-label>Permanent Address</mat-label>
              <input
                matInput
                [(ngModel)]="kycDetails.personalDetails.paddress"
                #paddressCheck="ngModel"
                required
              />
              <mat-error
                *ngIf="
                  !paddressCheck.valid &&
                  (paddressCheck.dirty || paddressCheck.touched)
                "
              >
                please enter permanent address
              </mat-error>
            </mat-form-field>
            <div class="gap-width"></div>
            <mat-form-field class="half-width">
              <mat-label>City/Town</mat-label>
              <input
                matInput
                required
                [(ngModel)]="kycDetails.personalDetails.city"
                pattern="[a-zA-Z\s]+"
                #cityCheck="ngModel"
              />
              <mat-error
                *ngIf="
                  !cityCheck.valid && (cityCheck.dirty || cityCheck.touched)
                "
              >
                please enter your city
              </mat-error>
            </mat-form-field>
          </div>
          <div class="full-half-width">
            <mat-form-field class="half-width">
              <mat-label>State/Province</mat-label>
              <input
                matInput
                required
                [(ngModel)]="kycDetails.personalDetails.state"
                pattern="[a-zA-Z\s]+"
                #stateCheck="ngModel"
              />
              <mat-error
                *ngIf="
                  !stateCheck.valid && (stateCheck.dirty || stateCheck.touched)
                "
              >
                please enter the state
              </mat-error>
            </mat-form-field>
            <div class="gap-width"></div>
            <mat-form-field class="half-width">
              <mat-label>Country</mat-label>
              <input
                matInput
                required
                pattern="[a-zA-Z\s]+"
                [(ngModel)]="kycDetails.personalDetails.country"
              />
              <!-- <mat-error *ngIf="!countryCheck.valid && (countryCheck.dirty || countryCheck.touched)">
                                    please enter the country
                                </mat-error> -->
            </mat-form-field>
          </div>
        </div>
        <mat-action-row class="next-btn">
          <button class="continue-btn" (click)="nextStep()">
            Save & Proceed
          </button>
        </mat-action-row>
      </mat-expansion-panel>
      <mat-expansion-panel
        class="background-heading"
        [expanded]="step === 1"
        (opened)="setStep(1)"
      >
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="panel-heading">
              Professional Details
              <div
                *ngIf="
                  this.kycDetails.professionalDetails.cName == null ||
                    this.kycDetails.professionalDetails.cName == '' ||
                    this.kycDetails.professionalDetails.designation == null ||
                    this.kycDetails.professionalDetails.designation == '' ||
                    this.kycDetails.professionalDetails.caddress == null ||
                    this.kycDetails.professionalDetails.caddress == '' ||
                    this.kycDetails.professionalDetails.prcity == null ||
                    this.kycDetails.professionalDetails.prcity == '' ||
                    this.kycDetails.professionalDetails.prstate == null ||
                    this.kycDetails.professionalDetails.prstate == '' ||
                    this.kycDetails.professionalDetails.prcountry == null ||
                    this.kycDetails.professionalDetails.prcountry == '';
                  else Professional
                "
              >
                <div class="pending">Pending!</div>
              </div>
              <ng-template #Professional>
                <img src="./../../../../assets/images/list/right.png" />
              </ng-template>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <span class="logged-text">Let us know your professional details</span>
        <div class="professional-details">
          <div class="full-half-width">
            <mat-form-field class="half-width">
              <mat-label>Company / College Name</mat-label>
              <input
                matInput
                required
                [(ngModel)]="kycDetails.professionalDetails.cName"
                #cNameCheck="ngModel"
              />
              <mat-error
                *ngIf="
                  !cNameCheck.valid && (cNameCheck.dirty || cNameCheck.touched)
                "
              >
                please enter your Company/College name
              </mat-error>
            </mat-form-field>
            <div class="gap-width"></div>
            <mat-form-field class="half-width">
              <mat-label>Designation / Course</mat-label>
              <input
                matInput
                [(ngModel)]="kycDetails.professionalDetails.designation"
                #designationCheck="ngModel"
                required
              />
              <mat-error
                *ngIf="
                  !designationCheck.valid &&
                  (designationCheck.dirty || designationCheck.touched)
                "
              >
                please provide your designation
              </mat-error>
            </mat-form-field>
          </div>
          <div class="full-half-width">
            <mat-form-field class="half-width">
              <mat-label>Company / College Address</mat-label>
              <input
                matInput
                required
                [(ngModel)]="kycDetails.professionalDetails.caddress"
                #caddressCheck="ngModel"
              />
              <mat-error
                *ngIf="
                  !caddressCheck.valid &&
                  (caddressCheck.dirty || caddressCheck.touched)
                "
              >
                please enter your Company/College Address
              </mat-error>
            </mat-form-field>
            <div class="gap-width"></div>
            <mat-form-field class="half-width">
              <mat-label>City / Town</mat-label>
              <input
                matInput
                required
                [(ngModel)]="kycDetails.professionalDetails.prcity"
                #prcityCheck="ngModel"
              />
              <mat-error
                *ngIf="
                  !prcityCheck.valid &&
                  (prcityCheck.dirty || prcityCheck.touched)
                "
              >
                please enter your city/Town
              </mat-error>
            </mat-form-field>
          </div>
          <div class="full-half-width">
            <mat-form-field class="half-width">
              <mat-label>State / Province</mat-label>
              <input
                matInput
                required
                [(ngModel)]="kycDetails.professionalDetails.prstate"
                #prstateCheck="ngModel"
              />
              <mat-error
                *ngIf="
                  !prstateCheck.valid &&
                  (prstateCheck.dirty || prstateCheck.touched)
                "
              >
                please enter the state/Province
              </mat-error>
            </mat-form-field>
            <div class="gap-width"></div>
            <mat-form-field class="half-width">
              <mat-label>Country</mat-label>
              <input
                matInput
                required
                [(ngModel)]="kycDetails.professionalDetails.prcountry"
                #prcountryCheck="ngModel"
              />
              <mat-error
                *ngIf="
                  !prcountryCheck.valid &&
                  (prcountryCheck.dirty || prcountryCheck.touched)
                "
              >
                please provide country
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <mat-action-row class="next-btn">
          <button class="continue-btn" (click)="nextStep2()">
            Save & Proceed
          </button>
        </mat-action-row>
      </mat-expansion-panel>
      <mat-expansion-panel
        class="background-heading"
        [expanded]="step === 2"
        (opened)="setStep(2)"
      >
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="panel-heading">
              Emergency Details
              <div
                *ngIf="
                  this.kycDetails.emergencyDetails.cpName == null ||
                    this.kycDetails.emergencyDetails.cprel == '' ||
                    this.kycDetails.emergencyDetails.cpnumber == null ||
                    this.kycDetails.emergencyDetails.cpnumber == '' ||
                    this.kycDetails.emergencyDetails.cpaddress == null ||
                    this.kycDetails.emergencyDetails.cpaddress == '' ||
                    this.kycDetails.emergencyDetails.cpcity == null ||
                    this.kycDetails.emergencyDetails.cpcity == '' ||
                    this.kycDetails.emergencyDetails.cpstate == null ||
                    this.kycDetails.emergencyDetails.cpstate == '' ||
                    this.kycDetails.emergencyDetails.cpcountry == null ||
                    this.kycDetails.emergencyDetails.cpcountry == '';
                  else emergencyDetails
                "
              >
                <div class="pending">Pending!</div>
              </div>
              <ng-template #emergencyDetails>
                <img src="./../../../../assets/images/list/right.png" />
              </ng-template>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <span class="logged-text"
          >Let us know whom to contact incase of emergency</span
        >
        <div class="emergency-details">
          <div class="full-half-width">
            <mat-form-field class="half-width">
              <mat-label>Contact person Name</mat-label>
              <input
                matInput
                required
                [(ngModel)]="kycDetails.emergencyDetails.cpName"
                #cpnameCheck="ngModel"
              />
              <mat-error
                *ngIf="
                  !cpnameCheck.valid &&
                  (cpnameCheck.dirty || cpnameCheck.touched)
                "
              >
                please provide contanct person name
              </mat-error>
            </mat-form-field>
            <div class="gap-width"></div>
            <mat-form-field class="half-width">
              <mat-label>Relationship with Person</mat-label>
              <input
                matInput
                required
                [(ngModel)]="kycDetails.emergencyDetails.cprel"
                #cprelationCheck="ngModel"
              />
              <mat-error
                *ngIf="
                  !cprelationCheck.valid &&
                  (cprelationCheck.dirty || cprelationCheck.touched)
                "
              >
                please provide Relationship with person
              </mat-error>
            </mat-form-field>
          </div>
          <div class="full-half-width">
            <mat-form-field class="half-width">
              <mat-label>Contact Phone Number</mat-label>
              <input
                matInput
                required
                [(ngModel)]="kycDetails.emergencyDetails.cpnumber"
                #cpnumberCheck="ngModel"
                pattern="^[6-9]\d{9}$|^[1-9]\d{9}$"
              />
              <mat-error
                *ngIf="
                  !cpnumberCheck.valid &&
                  (cpnumberCheck.dirty || cpnumberCheck.touched)
                "
              >
                please provide contact person number
              </mat-error>
            </mat-form-field>
            <div class="gap-width"></div>
            <mat-form-field class="half-width">
              <mat-label>Contact Address</mat-label>
              <input
                matInput
                required
                [(ngModel)]="kycDetails.emergencyDetails.cpaddress"
                #cpaddressCheck="ngModel"
              />
              <mat-error
                *ngIf="
                  !cpaddressCheck.valid &&
                  (cpaddressCheck.dirty || cpaddressCheck.touched)
                "
              >
                please provide contact Address
              </mat-error>
            </mat-form-field>
          </div>
          <div class="full-half-width">
            <mat-form-field class="half-width">
              <mat-label>City / Town</mat-label>
              <input
                matInput
                required
                [(ngModel)]="kycDetails.emergencyDetails.cpcity"
                #cpcityCheck="ngModel"
              />
              <mat-error
                *ngIf="
                  !cpcityCheck.valid &&
                  (cpcityCheck.dirty || cpcityCheck.touched)
                "
              >
                please provide city/Town
              </mat-error>
            </mat-form-field>
            <div class="gap-width"></div>
            <mat-form-field class="half-width">
              <mat-label>State / Province</mat-label>
              <input
                matInput
                required
                [(ngModel)]="kycDetails.emergencyDetails.cpstate"
                #cpstateCheck="ngModel"
              />
              <mat-error
                *ngIf="
                  !cpstateCheck.valid &&
                  (cpstateCheck.dirty || cpstateCheck.touched)
                "
              >
                please provide State/Province
              </mat-error>
            </mat-form-field>
          </div>
          <div class="full-half-width">
            <mat-form-field class="half-width">
              <mat-label>Country</mat-label>
              <input
                matInput
                required
                [(ngModel)]="kycDetails.emergencyDetails.cpcountry"
                #cpcountryCheck="ngModel"
              />
              <mat-error
                *ngIf="
                  !cpcountryCheck.valid &&
                  (cpcountryCheck.dirty || cpcountryCheck.touched)
                "
              >
                please provide country
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <mat-action-row class="next-btn">
          <button class="continue-btn" (click)="nextStep3()">
            Save & Proceed
          </button>
        </mat-action-row>
      </mat-expansion-panel>
      <mat-expansion-panel
        class="background-heading"
        [expanded]="step === 3"
        (opened)="setStep(3)"
      >
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="panel-heading">
              Bank Details
              <div
                *ngIf="
                  this.kycDetails.bankDetails.bName == null ||
                    this.kycDetails.bankDetails.bName == '' ||
                    this.kycDetails.bankDetails.bAccountNumber == null ||
                    this.kycDetails.bankDetails.bAccountNumber == '' ||
                    this.kycDetails.bankDetails.bAccountHolder == null ||
                    this.kycDetails.bankDetails.bAccountHolder == '' ||
                    this.kycDetails.bankDetails.ifscCode == null ||
                    this.kycDetails.bankDetails.ifscCode == '';
                  else bank
                "
              >
                <div class="pending">Pending!</div>
              </div>
              <ng-template #bank>
                <img src="./../../../../assets/images/list/right.png" />
              </ng-template>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <span class="logged-text"
          >Your bank details is required to ensure quick refunds</span
        >
        <div class="bank-details">
          <div class="full-half-width">
            <mat-form-field class="half-width">
              <mat-label>Bank Name</mat-label>
              <input
                matInput
                required
                [(ngModel)]="kycDetails.bankDetails.bName"
                #bNameCheck="ngModel"
              />
              <mat-error
                *ngIf="
                  !bNameCheck.valid && (bNameCheck.dirty || bNameCheck.touched)
                "
              >
                please provide Bank Name
              </mat-error>
            </mat-form-field>
          </div>
          <div class="full-half-width">
            <mat-form-field class="half-width">
              <mat-label>Bank Account Number</mat-label>
              <input
                matInput
                required
                [(ngModel)]="kycDetails.bankDetails.bAccountNumber"
                #bAccountNumberCheck="ngModel"
                minlength="10"
              />
              <mat-error
                *ngIf="
                  !bAccountNumberCheck.valid &&
                  (bAccountNumberCheck.dirty || bAccountNumberCheck.touched)
                "
              >
                please provide Bank Account Number
              </mat-error>
            </mat-form-field>
          </div>
          <div class="full-half-width">
            <mat-form-field class="half-width">
              <mat-label>Bank Account Holder Name</mat-label>
              <input
                matInput
                required
                [(ngModel)]="kycDetails.bankDetails.bAccountHolder"
                #bAccountHolderCheck="ngModel"
              />
              <mat-error
                *ngIf="
                  !bAccountHolderCheck.valid &&
                  (bAccountHolderCheck.dirty || bAccountHolderCheck.touched)
                "
              >
                please provide Account Holder Name
              </mat-error>
            </mat-form-field>
          </div>
          <div class="full-half-width">
            <mat-form-field class="half-width">
              <mat-label>IFSC Code</mat-label>
              <input
                matInput
                required
                [(ngModel)]="kycDetails.bankDetails.ifscCode"
                #ifscCodeCheck="ngModel"
              />
              <mat-error
                *ngIf="
                  !ifscCodeCheck.valid &&
                  (ifscCodeCheck.dirty || ifscCodeCheck.touched)
                "
              >
                please provide IFSC Code
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <mat-action-row class="next-btn">
          <button class="continue-btn" (click)="nextStep4()">
            Save & Proceed
          </button>
        </mat-action-row>
      </mat-expansion-panel>
      <mat-expansion-panel
        class="background-heading"
        [expanded]="step === 4"
        (opened)="setStep(4)"
      >
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="panel-heading">
              Upload Documents
              <div
                *ngIf="
                  this.kycDetails.documents.identity == null ||
                    this.kycDetails.documents.addresss == null ||
                    this.kycDetails.documents.photo == null;
                  else bank
                "
              >
                <div class="pending">Pending!</div>
              </div>
              <ng-template #bank>
                <img src="./../../../../assets/images/list/right.png" />
              </ng-template>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <span class="logged-text">Please upload your documents</span>
        <div class="upload-documents">
          <mat-form-field class="full-width">
            <mat-label
              >Identity Proof
              <p>
                Voter Id / Aadhar Card / Passport / Driving License / Pan Card
              </p>
            </mat-label>
            <input matInput disabled />
            <div class="display-section">
              <img
                [src]="ds.bypassSecurityTrustUrl(kycDetails.documents.identity)"
                alt=""
                width="40"
                height="40"
                class="profile-image"
                *ngIf="kycDetails.documents.identity != null; else noImg"
              />
              <ng-template #noImg></ng-template>

              <div class="browse-section">
                <div class="myLabel">
                  <input
                    type="file"
                    (change)="fileChange($event)"
                    accept="image/x-png,image/gif,image/jpeg,image/jpg"
                  />
                  <span class="browse-btn">Browse</span>
                </div>
              </div>
            </div>
          </mat-form-field>
          <mat-form-field class="full-width">
            <mat-label
              >Address Proof
              <p>
                Voter Id / Aadhar Card / Passport / Driving License / Pan Card
              </p>
            </mat-label>
            <input matInput disabled />
            <div class="display-section">
              <img
                [src]="ds.bypassSecurityTrustUrl(kycDetails.documents.addresss)"
                alt=""
                width="40"
                height="40"
                class="profile-image"
                *ngIf="kycDetails.documents.addresss != null; else noImg"
              />
              <ng-template #noImg></ng-template>

              <div class="browse-section">
                <div class="myLabel">
                  <input
                    type="file"
                    (change)="addressChange($event)"
                    accept="image/x-png,image/gif,image/jpeg,image/jpg"
                  />
                  <span class="browse-btn">Browse</span>
                </div>
              </div>
            </div>
          </mat-form-field>
          <mat-form-field class="full-width">
            <mat-label>Passport Size Photo </mat-label>
            <input matInput disabled />
            <div class="display-section">
              <img
                [src]="ds.bypassSecurityTrustUrl(kycDetails.documents.photo)"
                alt=""
                width="40"
                height="40"
                class="profile-image"
                *ngIf="kycDetails.documents.photo != null; else noImg"
              />
              <ng-template #noImg></ng-template>

              <div class="browse-section">
                <div class="myLabel">
                  <input
                    type="file"
                    (change)="photoChange($event)"
                    accept="image/x-png,image/gif,image/jpeg,image/jpg"
                  />
                  <span class="browse-btn">Browse</span>
                </div>
              </div>
            </div>
          </mat-form-field>
        </div>
        <mat-action-row class="next-btn">
          <button class="continue-btn" (click)="nextStep5()">Save</button>
        </mat-action-row>
      </mat-expansion-panel>
    </div>
  </div>
  <div class="footer">
    <app-footer></app-footer>
  </div>
</section>
<ng-template #loadingImg>
  <img src="./../../../../assets/images/loader.gif" alt="" class="loadingImg" />
</ng-template>
