<div class="footer-color">
  <div class="text-center btn-pos">
    <!-- <button class="link-btn">
      USEFUL LINKS<i
        class="fa fa-caret-down"
        style="font-size: 20px; margin-left: 4px"
        area-hidden="true"
      ></i>
    </button> -->
  </div>
  <div class="color-secondary pt-4text-center">
    <ul
      class="nav nav-tabs tab-view"
      id="myTab"
      role="tablist"
      aria-label="document"
    >
      <!-- <li class="nav-item">
        <a
          class="nav-link active"
          id="boysHostel-tab"
          data-toggle="tab"
          href="#boysHostel"
          role="tab"
          aria-label="tab"
          aria-controls="boysHostel"
          aria-selected="true"
          >Hyderabad</a
        >
      </li> -->
      <li class="nav-item cursor-pointer" *ngFor="let item of cityList" (click)="citySelection(item)">
        <div class="nav-link" [ngClass]="item?.status ? 'active' : 'inactive'">{{item?.name}}</div>
      </li>
      <!-- <li class="nav-item">
        <a
          class="nav-link"
          id="girlsHostels-tab"
          data-toggle="tab"
          href="#girlsHostels"
          role="tab"
          aria-label="tab"
          aria-controls="girlsHostels"
          aria-selected="false"
          >Banglore</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          id="coliving-tab"
          data-toggle="tab"
          href="#coliving"
          role="tab"
          aria-label="tab"
          aria-controls="coliving"
          aria-selected="false"
          >NCR</a
        >
      </li> -->
    </ul>
    <div class="tab-content text-center" id="myTabContent" *ngIf="selectedCity === 'Hyderabad'">
      <div
        class="tab-pane fade show active"
        id="boysHostel"
        role="tabpanel"
        aria-labelledby="boysHostel-tab"
      >
        <div class="container-fluid">
          <div class="list-total">
            <div class="list-data">
              <p class="heading-list">Top Searches</p>
              <div *ngFor="let list of topSearch">
                <a rel="noopener noreferrer">
                  <div
                    class="cursor-pointer list-top-search"
                    (click)="filterList(list.city, list.propertyType,list)"
                  >
                    {{ list.name }}
                  </div>
                </a>
              </div>
            </div>

            <div class="list-data">
              <p class="heading-list">PG IN Hyderabad</p>
              <div *ngFor="let list of pgData">
                <a rel="noopener noreferrer">
                  <div
                    class="cursor-pointer"
                    (click)="pgsHyd(list.city, list.locality)"
                  >
                    {{ list.name }}
                  </div>
                </a>
              </div>
            </div>
            <div class="list-data">
              <p class="heading-list">Hostel In Hyderabad</p>
              <div *ngFor="let list of hostelsData">
                <a rel="noopener noreferrer">
                  <div
                    class="cursor-pointer"
                    (click)="pgsHyd(list.city, list.locality)"
                  >
                    {{ list.name }}
                  </div>
                </a>
              </div>
            </div>
            <div class="list-data">
              <p class="heading-list">Rooms for Rent in Hyderabad</p>
              <div *ngFor="let list of roomsData">
                <a rel="noopener noreferrer">
                  <div
                    class="cursor-pointer"
                    (click)="pgsHyd(list.city, list.locality)"
                  >
                    {{ list.name }}
                  </div>
                </a>
              </div>
            </div>
            <!-- <div class="list-data">
              <p class="heading-list">Premium Properties</p>
              <div *ngFor="let list of PremiumPropertiesData">
                <a rel="noopener noreferrer">
                  <div class="cursor-pointer">{{ list.name }}</div>
                </a>
              </div>
            </div> -->
          </div>
        </div>
      </div>
      <div
        class="tab-pane fade"
        id="girlsHostels"
        role="tabpanel"
        aria-labelledby="girlsHostels-tab"
      >
        <div class="container-fluid">
          <div class="list-total">
            <div class="list-data">
              <p class="heading-list">Top Searches</p>
              <div *ngFor="let list of topSearch; index as i">
                <a rel="noopener noreferrer">
                  <div class="cursor-pointer list-top-search"
                  (click)="filterList(list.city, list.propertyType,list)"
                  >
                    {{ list.name }}</div>
                </a>
              </div>
            </div>
            <div class="list-data">
              <p class="heading-list">PG IN Hyderabad</p>
              <div *ngFor="let list of pgData">
                <a rel="noopener noreferrer">
                  <div class="cursor-pointer">{{ list.name }}</div>
                </a>
              </div>
            </div>
            <div class="list-data">
              <p class="heading-list">Hostel In Hyderabad</p>
              <div *ngFor="let list of hostelsData">
                <a rel="noopener noreferrer">
                  <div class="cursor-pointer">{{ list.name }}</div>
                </a>
              </div>
            </div>
            <div class="list-data">
              <p class="heading-list">Rooms for Rent in Hyderabad</p>
              <div *ngFor="let list of roomsData">
                <a rel="noopener noreferrer">
                  <div class="cursor-pointer">{{ list.name }}</div>
                </a>
              </div>
            </div>
            <!-- <div class="list-data">
              <p class="heading-list">Premium Properties</p>
              <div *ngFor="let list of PremiumPropertiesData">
                <a rel="noopener noreferrer">
                  <div class="cursor-pointer">{{ list.name }}</div>
                </a>
              </div>
            </div> -->
          </div>
        </div>
      </div>
      <div
        class="tab-pane fade"
        id="coliving"
        role="tabpanel"
        aria-labelledby="coliving-tab"
      >
        <div class="container-fluid">
          <div class="list-total">
            <div class="list-data">
              <p class="heading-list">Top Searches</p>
              <div *ngFor="let list of topSearch">
                <a rel="noopener noreferrer">
                  <div class="cursor-pointer list-top-search"
                  (click)="filterList(list.city, list.propertyType,list)">
                  {{ list.name }}</div>
                </a>
              </div>
            </div>
            <div class="list-data">
              <p class="heading-list">PG IN Hyderabad</p>
              <div *ngFor="let list of pgData">
                <a rel="noopener noreferrer">
                  <div class="cursor-pointer">{{ list.name }}</div>
                </a>
              </div>
            </div>
            <div class="list-data">
              <p class="heading-list">Hostel In Hyderabad</p>
              <div *ngFor="let list of hostelsData">
                <a rel="noopener noreferrer">
                  <div class="cursor-pointer">{{ list.name }}</div>
                </a>
              </div>
            </div>
            <div class="list-data">
              <p class="heading-list">Rooms for Rent in Hyderabad</p>
              <div *ngFor="let list of roomsData">
                <a rel="noopener noreferrer">
                  <div class="cursor-pointer">{{ list.name }}</div>
                </a>
              </div>
            </div>
            <!-- <div class="list-data">
              <p class="heading-list">Premium Properties</p>
              <div *ngFor="let list of PremiumPropertiesData">
                <a rel="noopener noreferrer">
                  <div class="cursor-pointer">{{ list.name }}</div>
                </a>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="tab-content text-center" id="myTabContent" *ngIf="selectedCity === 'Bengaluru'">
      <div
        class="tab-pane fade show active"
        id="boysHostel"
        role="tabpanel"
        aria-labelledby="boysHostel-tab"
      >
        <div class="container-fluid">
          <div class="list-total">
            <div class="list-data">
              <p class="heading-list">Top Searches</p>
              <div *ngFor="let list of topSearchBenguluru">
                <a rel="noopener noreferrer">
                  <div
                    class="cursor-pointer list-top-search"
                    (click)="filterList(list.city, list.propertyType,list)"
                  >
                    {{ list.name }}
                  </div>
                </a>
              </div>
            </div>

            <div class="list-data">
              <p class="heading-list">PG IN Banglore</p>
              <div *ngFor="let list of pgDataBanglore">
                <a rel="noopener noreferrer">
                  <div
                    class="cursor-pointer"
                    (click)="pgsHyd(list.city, list.locality)"
                  >
                    {{ list.name }}
                  </div>
                </a>
              </div>
            </div>
            <div class="list-data">
              <p class="heading-list">Hostel In Banglore</p>
              <div *ngFor="let list of pgDataBanglore">
                <a rel="noopener noreferrer">
                  <div
                    class="cursor-pointer"
                    (click)="pgsHyd(list.city, list.locality)"
                  >
                    {{ list.name }}
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tab-content text-center" id="myTabContent" *ngIf="selectedCity === 'NCR'">
      <div
        class="tab-pane fade show active"
        id="boysHostel"
        role="tabpanel"
        aria-labelledby="boysHostel-tab"
      >
        <div class="container-fluid">
          <div class="list-total">
            <div class="list-data">
              <p class="heading-list">Top Searches</p>
              <div *ngFor="let list of topSearchncr">
                <a rel="noopener noreferrer">
                  <div
                    class="cursor-pointer list-top-search"
                    (click)="ncrList(list.locality,list)"
                  >
                    {{ list.name }}
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr />
  </div>

  <div class="custom-margin mb-0 text-white">
    <div class="container-fluid px-3 pt-5">
      <div class="row">
        <div class="col-sm-4 footer-logo">
          <img
            src="./../../../../../assets/images/top-nav/logowhite.svg"
            alt="logo"
            width="25%"
            class="footer-logo-main"
          />
        </div>
        <div class="col-sm-2 para-styling">
          <h5 class="sm-scr-heading">ABOUT</h5>
          <p class="sub-link-style"></p>
          <p
            class="mt-2 cursor-pointer list-text"
            (click)="routerPathRedirection('/aboutus')"
          >
            About Us
          </p>
          <p class="mt-2 cursor-pointer list-text">
            <a (click)="routerPathRedirection('/community')">Community</a>
          </p>

          <!-- <p class="mt-2 cursor-pointer list-text">Blog</p> -->
          <!-- <p class="mt-2 cursor-pointer list-text"><a (click)="routerPathRedirection('')""faq">FAQ's</a></p> -->
          <p class="mt-2 cursor-pointer list-text">
            <a (click)="routerPathRedirection('/contactus')">Contact Us</a>
          </p>
          <p class="mt-2 cursor-pointer list-text">
            <a (click)="routerPathRedirection('/careers')">Careers</a>
          </p>
        </div>

        <div class="col-sm-2 para-styling">
          <h5 class="sm-scr-heading">PARTNERSHIP</h5>
          <p class="sub-link-style"></p>
          <p class="mt-2 cursor-pointer list-text">
            <a (click)="routerPathRedirection('/listyourProperty')"
              >List Your Property</a
            >
          </p>
          <p class="mt-2 cursor-pointer list-text">
            <a (click)="routerPathRedirection('/registerYourService')">
              Register your Service / Products</a
            >
          </p>
          <p class="mt-2 cursor-pointer list-text">
            <a (click)="routerPathRedirection('/corporate-connect')"
              >Corporate Connect</a
            >
          </p>
          <p class="mt-2 cursor-pointer list-text">
            <a (click)="routerPathRedirection('/showcaseTalent')"
              >Showcase Your Talent</a
            >
          </p>
        </div>

        <div class="col-sm-2 para-styling">
          <h5 class="sm-scr-heading">Our Portfolio</h5>
          <p class="sub-link-style"></p>
          <p class="mt-2 cursor-pointer list-text">
            <a (click)="routerPathRedirection('/')">Co-Living</a>
          </p>
          <p class="mt-2 cursor-pointer list-text">
            <a (click)="routerPathRedirection('/student-living')"
              >Student Living</a
            >
          </p>
          <p class="mt-2 cursor-pointer list-text">
            <a (click)="routerPathRedirection('/food-court')">Food Court</a>
          </p>
        </div>

        <div class="col-sm-2">
          <div class="download">DOWNLOAD THE APP</div>
          <div class="download-btn">
            <div class="android btn-img">
              <a href="{{ footer?.downloadAppSection?.androidLink }}">
                <img
                  src="./../../../../../assets/images/about-us/google store.png"
                  alt="download"
                  width="90%"
              /></a>
            </div>
            <div class="ios btn-img">
              <a href="{{ footer?.downloadAppSection?.iOSLink }}">
                <img
                  src="./../../../../../assets/images/about-us/app store.png"
                  width="100%"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr />
    <!-- <div class="container-fluid">
      <div class="row copyright-row">
        <div class="col-sm-9">
          <div class="copy-right-text">©2020 Isthara. All Rights Reserved</div>
        </div>
        <div class="col-sm-3">
          <div class="copy-right-text">Social Media</div>
        </div>
      </div>
    </div> -->
    <div class="bottom_containt">
      <div class="bottomText">
        <div class="bottom-text">
          <a (click)="routerPathRedirection('/termsandconditions')"
            >Terms & Conditions</a
          >
        </div>
        <div class="bottom-text">
          <a (click)="routerPathRedirection('/privacy-policy')">
            Privacy Policy</a
          >
        </div>

        <div class="bottom-text">
          <a>
            {{ footer?.copyRight }}
          </a>
        </div>
      </div>
      <div class="social-media" align="right">
        <div class="follow">Follow us</div>
        <div class="botto-img">
          <a [href]="facebook" target="_blank">
            <img src="./../../../../../assets/images/home/facebook.png"
          /></a>
        </div>

        <div class="botto-img">
          <a [href]="twitter" target="_blank">
            <img src="./../../../../../assets/images/home/twitter.png"
          /></a>
        </div>
        <div class="botto-img">
          <a [href]="insta" target="_blank">
            <img src="./../../../../../assets/images/home/instagram.png" />
          </a>
        </div>
        <div class="botto-img">
          <a [href]="youtube" target="_blank">
            <img src="./../../../../../assets/images/home/youtube.png" />
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
