import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class ErrorPopsService {
  constructor(public dialog: MatDialog, private _snackBar: MatSnackBar) {}
  durationInSeconds = 5;

  openSnackBarMessage(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: this.durationInSeconds * 1000,
    });
  }
}
