import { Component, OnInit } from '@angular/core';
import { ErrorPopsService } from 'projects/user-flow-property/src/app/service/error-pops/error-pops.service';
import { PartnershipService } from '../partnership.service';
interface Skilltype {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'user-flow-showcase-your-talent',
  templateUrl: './showcase-your-talent.component.html',
  styleUrls: [
    './showcase-your-talent.component.scss',
    './mq-showcase-your-talent.component.scss',
  ],
})
export class ShowcaseYourTalentComponent implements OnInit {
  constructor(
    private apiService: PartnershipService,
    private errorPops: ErrorPopsService
  ) {}

  ngOnInit(): void {}

  about = [
    {
      img: './../../../../../assets/images/list-your-property/network.png',
      text: 'Better Networking',
    },
    {
      img: './../../../../../assets/images/list-your-property/sound.png',
      text: 'Increase Opportunities',
    },
    {
      img: './../../../../../assets/images/list-your-property/calander.png',
      text: 'A Fruitful And Long-Term Partnership',
    },
  ];
  Form = {
    details: {
      name: '',
      mobileNo: '',
      emailId: '',
      skillType: '',
      description: '',
      contactMethod: '',
    },
  };

  selectedValue: string;
  skilltype: Skilltype[] = [
    { value: 'Dance', viewValue: 'Dance' },
    { value: 'Musician ', viewValue: 'Musician' },
    { value: 'Singing', viewValue: 'Singing' },
    { value: 'Fittness', viewValue: 'Fittness' },
    { value: 'Artist ', viewValue: 'Artist ' },
  ];

  submitdetails() {
    if (
      this.Form.details.skillType == '' ||
      this.Form.details.skillType == null ||
      this.Form.details.name == '' ||
      this.Form.details.name == null ||
      this.Form.details.mobileNo == '' ||
      this.Form.details.mobileNo == null ||
      this.Form.details.emailId == '' ||
      this.Form.details.emailId == null
    ) {
      this.errorPops.openSnackBarMessage(
        'Please Fill All Mandatory Fields',
        'OK'
      );
    } else {
      let listdata = {
        details: {
          emailId: this.Form.details.emailId,
          mobileNumber: this.Form.details.mobileNo,
          name: this.Form.details.name,
        },
        skilltype: this.Form.details.skillType,
        description: this.Form.details.description,
      };
      // console.log('gggg',listdata)
      this.apiService.talentregistration(listdata).subscribe(
        (res: any) => {
          // console.log('successfully Registered', res.city);
          this.errorPops.openSnackBarMessage(
            ` Details submitted successfully`,
            'close'
          );
          // window.location.reload();
        },
        (err) => {
          // console.log("gettting error", err)
          // this.errorPops.openSnackBarMessage(err.error.message, 'Cancel');
        }
      );
    }
  }
}
