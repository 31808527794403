import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PartnershipService {
  constructor(private http: HttpClient) {}

  listyourproperty(listdata) {
    return this.http.post(
      environment.authenticationUrl +
        'property/partnership/propertyRegistration',
      listdata
    );
  }

  corporateregistration(corparatedata) {
    return this.http.post(
      environment.authenticationUrl +
        'property/partnership/corporateRegistration',
      corparatedata
    );
  }

  productregistration(registrationdata) {
    return this.http.post(
      environment.authenticationUrl +
        'property/partnership/productRegistration',
      registrationdata
    );
  }

  talentregistration(talentdata) {
    return this.http.post(
      environment.authenticationUrl + 'property/partnership/talentRegistration',
      talentdata
    );
  }
}
