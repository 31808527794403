import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MapSearchLibService } from './../../../../service/map-search/map-search-lib.service';
@Component({
  selector: 'user-flow-map-spot-cordinates',
  templateUrl: './map-spot-cordinates.component.html',
  styleUrls: ['./map-spot-cordinates.component.scss'],
})
export class MapSpotCordinatesComponent implements OnInit {
  constructor(private _geoLocation: MapSearchLibService) {}
  autocompleteInput: string = null;
  @Output() setAddress: EventEmitter<any> = new EventEmitter();

  ngOnInit(): void {}
  geoLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((p) => {
        const geodata = this._geoLocation
          .findAddressByCoordinates(p.coords.latitude, p.coords.longitude)
          .then((data: any) => {
            this.invokeEvent(data);
          })
          .catch((err) => {
            //console.log(err);
          });
        //console.log(geodata);
      });
    } else {
      // console.log('Geo Location Not Supported for browser');
    }
  }
  invokeEvent(place: Object) {
    this.setAddress.emit(place);
  }
}
