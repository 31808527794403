import { ActivatedRoute, ParamMap } from '@angular/router';
import { style } from '@angular/animations';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { PropertyDetailsService } from './../../../property-details/property-details.service';
import { SearchBarComponent } from '../../../popup-components/popups/search-bar/search-bar.component';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'user-flow-promises-tabs',
  templateUrl: './promises-tabs.component.html',
  styleUrls: [
    './promises-tabs.component.scss',
    './mq-promises-tabs.component.scss',
  ],
})
export class PromisesTabsComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private propertyDetailsService: PropertyDetailsService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  headingSection = [
    {
      img: './../../../../../assets/images/promises_tabs/Group.png',
      heading1: 'Keep safe, stay trendy',
      heading2: 'at Isthara',
      heading3: '',
      type: 'vsafe',
    },
    {
      img: './../../../../../assets/images/promises_tabs/veat.png',
      heading1: 'Only the healthiest and  ',
      heading2: 'the most delicious meals',
      heading3: 'for you.',
      type: 'veat',
    },
    {
      img: './../../../../../assets/images/promises_tabs/vwell.png',
      heading1: 'Wellness programs for a',
      heading2: ' healthier you',
      heading3: '',
      type: 'vwell',
    },
    {
      img: './../../../../../assets/images/promises_tabs/vvedio.png',
      heading1: 'Take a peek at your',
      heading2: 'future stay before you',
      heading3: 'move in',
      type: 'videovisit',
    },
  ];

  promisesTabs = {
    bigBox: [
      {
        title: 'The Isthara Fights Covid-19 Guidelines:',
        sub: 'Safety measures to keep our residents and visitors safe:',
        type: 'vsafe',
        content: [
          {
            img: './../../../../../assets/images/promises_tabs/safestaff.png',
            desc: 'Resident staff at all premises',
            type: 'vsafe',
          },
          {
            img: './../../../../../assets/images/promises_tabs/Traveldeclaration.png',
            desc: 'Travel declaration from all new residents prior to moving-in.',
            type: 'vsafe',
          },
          {
            img: './../../../../../assets/images/promises_tabs/Aarogya.png',
            desc: 'Mandatory Aarogya setu check for everyone entering   the premises',
            type: 'vsafe',
          },
          {
            img: './../../../../../assets/images/promises_tabs/no-touchdelivery.png',
            desc: 'Zero-contact deliveries for e-commerce and food parcels at the entrance of the premises.',
            type: 'vsafe',
          },
          {
            img: './../../../../../assets/images/promises_tabs/transactionenable.png',
            desc: 'All transactions enabled through resident app to avoid handling of paperwork',
            type: 'vsafe',
          },
          {
            img: './../../../../../assets/images/promises_tabs/cleanedrooms.png',
            desc: 'Fresh new linens and disinfected rooms for all new residents.',
            type: 'vsafe',
          },
          {
            img: './../../../../../assets/images/promises_tabs/temperaturcheck.png',
            desc: 'Regular temperature check of all staff members',
            type: 'vsafe',
          },
        ],
        style: {
          'background-color': 'white',
        },
      },
      {
        title: 'Training',
        type: 'vsafe',
        content: [
          {
            img: './../../../../../assets/images/promises_tabs/training-1.png',
            desc: 'Advanced housekeeping training for all staff',
            type: 'vsafe',
          },
          {
            img: './../../../../../assets/images/promises_tabs/training-2.png',
            desc: 'Dedicated customer support staff for medical emergencies',
            type: 'vsafe',
          },
        ],
        style: {
          'background-color': '#FAF0BF',
        },
      },
    ],
    smBox: [
      {
        title: 'Protective Equipment',
        type: 'vsafe',
        content: [
          {
            img: './../../../../../assets/images/promises_tabs/pe-1.png',
            desc: 'Facemask',
            type: 'vsafe',
          },
          {
            img: './../../../../../assets/images/promises_tabs/pe-2.png',
            desc: 'Medical graded gloves',
            type: 'vsafe',
          },
          {
            img: './../../../../../assets/images/promises_tabs/pe-3.png',
            desc: 'Aprons / medical grade PPE for housekeeping staff',
            type: 'vsafe',
          },
          {
            img: './../../../../../assets/images/promises_tabs/pe-4.png',
            desc: 'Sanitizers, masks and disposable gloves',
            type: 'vsafe',
          },
        ],
        style: {
          'background-color': '#CEF9E8',
        },
      },
    ],
    finalBox: [
      {
        title: 'Hygiene Tips',
        img: './../../../../../assets/images/promises_tabs/Group 13105.png',
        desc1:
          '• Follow the 20 second hand wash rule upon returning to the premises.  ',
        desc2:
          '• 	Use gloves or a fresh and clean handkerchief to operate the elevators.',
        desc3: '• Avoid crowding the community areas',
        desc4:
          '• Maintain minimum 6 feet distance from your neighbours when in common area',
        desc5:
          '• 	Use sanitiser before and after touching anything in the common area.',
        type: 'vsafe',
        style: {
          background: '#FFEDF4',
          'background-image':
            "url('./../../../../../assets/images/promises_tabs/Mask Group.png'),url('./../../../../../assets/images/promises_tabs/Mask Group-right-bottom.png')",
          'background-repeat': 'no-repeat,no-repeat',
          'background-position': 'top left,bottom right',
        },
      },
      {
        title: 'Hand Picked Menu',
        img: './../../../../../assets/images/promises_tabs/veat1.png',
        desc1: '•	We freshen up our food menu every week.',
        desc2:
          '• Our food menu items are carefully selected to ensure the highest nutrition for our residents.',
        desc3:
          '• Whatever be your preference – there is something for everyone.',
        desc4: '• Menu personalisation available',
        type: 'veat',
        style: {
          background: '#FFFFFF',
          'background-image':
            "url('./../../../../../assets/images/promises_tabs/Mask Group.png'),url('./../../../../../assets/images/promises_tabs/Mask Group-right-bottom.png')",
          'background-repeat': 'no-repeat,no-repeat',
          'background-position': 'top left,bottom right',
        },
      },
      {
        title: 'Food Quality',
        img: './../../../../../assets/images/promises_tabs/veat2.png',
        desc1:
          '• Food at Isthara is made using fresh and high-quality ingredients only – wholesome nourishment, guaranteed!',
        desc2:
          '• The food is prepared by famous chefs with years of industry experience.',
        desc3:
          '• Menu designed to meet the FSSAI standards, and monthly third-party quality checks in place ensure the quality and safety of the food served to the residents.',
        type: 'veat',
        style: {
          background: '#FFEDF4',
          'background-image':
            "url('./../../../../../assets/images/promises_tabs/Mask Group.png'),url('./../../../../../assets/images/promises_tabs/Mask Group-right-bottom.png')",
          'background-repeat': 'no-repeat,no-repeat',
          'background-position': 'top left,bottom right',
        },
      },
      {
        title: 'Hygiene First',
        img: './../../../../../assets/images/promises_tabs/veat3.png',
        desc1:
          '• 	Kitchen processes are subject to multiple  hygiene checks at regular intervals.',
        desc2: '•	 Routine hygiene checks in place for the food service area.',
        type: 'veat',
        style: {
          background: '#CEF9E8',
          'background-image':
            "url('./../../../../../assets/images/promises_tabs/Mask Group.png'),url('./../../../../../assets/images/promises_tabs/Mask Group-right-bottom.png')",
          'background-repeat': 'no-repeat,no-repeat',
          'background-position': 'top left,bottom right',
        },
      },
      {
        title: 'Food Saftey Tips',
        img: './../../../../../assets/images/promises_tabs/veat4.png',
        desc1:
          '•  We have a strict “No Food Wastage” policy and we encourage our residents to take only as much food as they can finish.',
        desc2:
          '•  In case the residents are not eating their meals at Isthara, to ensure that no food is wasted, we require our residents to inform the staff at least 6 hours in advance.',
        type: 'veat',
        style: {
          background: '#FAF0BF',
          'background-image':
            "url('./../../../../../assets/images/promises_tabs/Mask Group.png'),url('./../../../../../assets/images/promises_tabs/Mask Group-right-bottom.png')",
          'background-repeat': 'no-repeat,no-repeat',
          'background-position': 'top left,bottom right',
        },
      },
      {
        title: 'Physical Well being',
        img: './../../../../../assets/images/promises_tabs/vwell1.png',
        desc1:
          '• On-prem, well-equipped gym at Isthara to help you achieve your physical fitness goals.',
        desc2:
          '• Virtual Zumba and other fun activities to make your fitness journey enjoyable.',
        type: 'vwell',
        style: {
          background: '#FAFFFF',
          'background-image':
            "url('./../../../../../assets/images/promises_tabs/Mask Group.png'),url('./../../../../../assets/images/promises_tabs/Mask Group-right-bottom.png')",
          'background-repeat': 'no-repeat,no-repeat',
          'background-position': 'top left,bottom right',
        },
      },
      {
        title: 'Mental Wellness',
        img: './../../../../../assets/images/promises_tabs/vwell2.png',
        desc1:
          '• 	Regular meditation and yoga sessions to ensure mental and emotional wellbeing. ',
        type: 'vwell',
        style: {
          background: '#FFEDF4',
          'background-image':
            "url('./../../../../../assets/images/promises_tabs/Mask Group.png'),url('./../../../../../assets/images/promises_tabs/Mask Group-right-bottom.png')",
          'background-repeat': 'no-repeat,no-repeat',
          'background-position': 'top left,bottom right',
        },
      },
      {
        title: 'Health Checkup Camps',
        img: './../../../../../assets/images/promises_tabs/vwell3.png',
        desc1:
          '•	We organise routine health check-up camps for all our residents',
        type: 'vwell',
        style: {
          background: '#CEF9E8',
          'background-image':
            "url('./../../../../../assets/images/promises_tabs/Mask Group.png'),url('./../../../../../assets/images/promises_tabs/Mask Group-right-bottom.png')",
          'background-repeat': 'no-repeat,no-repeat',
          'background-position': 'top left,bottom right',
        },
      },
    ],
    videovisit: [
      {
        title: 'How It Works?',
        type: 'videovisit',
        style: {
          // 'text-align': 'center',
          width: '60%',
          margin: 'auto',
          'margin-top': '50px',
        },
        content: [
          {
            img: './../../../../../assets/images/promises_tabs/vvideo1.png',
            desc: 'Schedule a time slot with your details.',
            type: 'videovisit',
          },
          {
            img: './../../../../../assets/images/promises_tabs/vvideo2.png',
            desc: 'You will get a call on whatsapp or hangout call on scheduled time.',
            type: 'videovisit',
          },
          {
            img: './../../../../../assets/images/promises_tabs/vvideo3.png',
            desc: 'Our Relation ship manager will show you around the premises inside and out.',
            type: 'videovisit',
          },
          {
            img: './../../../../../assets/images/promises_tabs/vvideo4.png',
            desc: 'Ask any question you may have to our Relationship Manager.',
            type: 'videovisit',
          },
        ],
      },
    ],
  };
  finalData = [
    {
      fd: 'Under our Isthara fights Covid-19 guidelines, we are taking all reasonable safety precautions and following the health and safety protocols as prescribed by the Government of India. None of the safety measures taken by Isthara can ever replace or override the governmental guidelines. We urge all our residents to do their part and follow the safety guidelines sincerely. ',
      type: 'vsafe',
    },
    {
      fd: 'Isthara takes food quality and quantity very seriously. We strive to ensure that all our residents get the healthiest, and the most nutritious food that not only satiates the hunger but also nourishes your body, mind and soul.',
      type: 'veat',
    },
    {
      fd: 'At Isthara, we value the physical fitness and mental wellbeing of all our residents.',
      type: 'vwell',
    },
  ];
  style: {};
  path: any;
  show: true;
  ngOnInit(): void {
    this.route.params.subscribe((param) => {
      // console.log('success', param);
      this.path = param.type;
    });
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo(0, 0);
    }
  }
  property: any;
  openVisit() {
    let dialog = this.propertyDetailsService.openDialog(SearchBarComponent, {
      width: '92%',
      height: '700px',
    });
  }
}
