<div class="section">
  <div class="top-nav">
    <user-flow-top-navbar></user-flow-top-navbar>
  </div>
  <div class="total">
    <div class="left">
      <div class="left-bg">
        <div class="left-heading">
          Join the Isthara Family - Leverage Your Property with
          <span>ISTHARA!</span>
        </div>
        <div class="sub-text">
          We help manage and maintain your property and turn your asset to a
          growing, thriving business with Isthara in a few easy steps:
        </div>
        <div class="left-heading">How To Get Started In Few Steps?</div>
        <div class="total-top-images">
          <div
            class="top-images"
            [ngStyle]="{ border: ct.border }"
            *ngFor="let ct of cards"
          >
            <div class="background-number">
              <img src="{{ ct.bigImg }}" alt="" />
            </div>
            <div class="note">
              <img src="{{ ct.img }}" />
            </div>
            <div class="desc">{{ ct.text }}</div>
          </div>
        </div>
      </div>
      <div class="benifits">The Isthara</div>
      <div class="isthara-promise">Promise</div>
      <div>
        <div class="total-bottom-images">
          <div class="bottom-images" *ngFor="let im of about">
            <img src="{{ im.img }}" />
            <div class="bottom-images-text">{{ im.text }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="right">
      <div class="green-bg">
        <div class="right-form">
          <div class="form-heading">
            Interested in listing your property with us?
          </div>
          <div class="share-detail">Share your details with us</div>
          <div class="fields">
            <div class="name">
              <mat-form-field class="input-width" appearance="outline">
                <mat-label>Name</mat-label>
                <input
                  matInput
                  [(ngModel)]="Form.details.name"
                  #nameCheck="ngModel"
                  pattern="[a-zA-Z\s]+"
                  required
                />
                <mat-error
                  *ngIf="
                    !nameCheck.valid && (nameCheck.dirty || nameCheck.touched)
                  "
                >
                  Please enter your name
                </mat-error>
              </mat-form-field>
            </div>
            <div class="name">
              <mat-form-field class="input-width" appearance="outline">
                <mat-label>Mobile Number</mat-label>
                <input
                  matInput
                  [(ngModel)]="Form.details.mobileNo"
                  #numberCheck="ngModel"
                  pattern="^[6-9]\d{9}$|^[1-9]\d{9}$"
                  required
                />
                <mat-error
                  *ngIf="
                    !numberCheck.valid &&
                    (numberCheck.dirty || numberCheck.touched)
                  "
                >
                  please provide your contact number
                </mat-error>
              </mat-form-field>
            </div>
            <div class="name">
              <mat-form-field class="input-width" appearance="outline">
                <mat-label>Email id</mat-label>
                <input
                  matInput
                  [(ngModel)]="Form.details.emailId"
                  #emailCheck="ngModel"
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                  required
                />
                <mat-error
                  *ngIf="
                    !emailCheck.valid &&
                    (emailCheck.dirty || emailCheck.touched)
                  "
                >
                  please provide your emailID
                </mat-error>
              </mat-form-field>
            </div>
            <div class="name">
              <mat-form-field class="input-width" appearance="outline">
                <mat-label>Property Type</mat-label>
                <mat-select
                  [(ngModel)]="selectedValue"
                  name="property"
                  [(ngModel)]="Form.details.propertyType"
                  #propertyTypeCheck="ngModel"
                  disableOptionCentering
                  panelClass="panelClass"
                  required
                >
                  <mat-option
                    *ngFor="let property of property"
                    [value]="property.value"
                  >
                    {{ property.viewValue }}
                  </mat-option>
                  <!-- <mat-option> PG </mat-option>
                <mat-option> Hotel </mat-option>
                <mat-option> Apartment </mat-option>
                <mat-option> Individual House </mat-option> -->
                </mat-select>
                <mat-error
                  *ngIf="
                    !propertyTypeCheck.valid &&
                    (propertyTypeCheck.dirty || propertyTypeCheck.touched)
                  "
                >
                  please select a property type
                </mat-error>
              </mat-form-field>
            </div>
            <div class="name">
              <mat-form-field class="input-width" appearance="outline">
                <mat-label>City</mat-label>
                <input
                  matInput
                  [(ngModel)]="Form.details.city"
                  #cityCheck="ngModel"
                  pattern="[a-zA-Z\s]+"
                  required
                />
                <mat-error
                  *ngIf="
                    !cityCheck.valid && (cityCheck.dirty || cityCheck.touched)
                  "
                >
                  Please enter your city
                </mat-error>
              </mat-form-field>
            </div>
            <div class="name">
              <mat-form-field class="input-width" appearance="outline">
                <mat-label>Location</mat-label>
                <input
                  matInput
                  [(ngModel)]="Form.details.location"
                  #locationCheck="ngModel"
                  required
                />
                <mat-error
                  *ngIf="
                    !locationCheck.valid &&
                    (locationCheck.dirty || locationCheck.touched)
                  "
                >
                  Please enter your location
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="but-main">
            <div *ngFor="let item of array; let i = index" class="check-main">
              <mat-checkbox
                [checked]="item.isActive"
                (change)="asignUserType(item, i, $event)"
                [(ngModel)]="item.isActive"
                >{{ item.name }}</mat-checkbox
              >
            </div>
          </div>
          <div class="sec-div">
            <button class="second-bt" (click)="submitdetails()">Submit</button>
          </div>
          <div class="or">OR</div>
          <div class="contact-number">Call us on 8790858581</div>
        </div>
      </div>
    </div>
  </div>
  <div class="right-bg">
    <div>
      <div class="our">Our</div>
      <div class="success-stories">Owner Says</div>
      <div class="our-text">See what other owners have to say!</div>
      <div class="carsouel-sectionpart">
        <user-flow-customer-carousel
          [customerCards]="listComments"
        ></user-flow-customer-carousel>
      </div>
    </div>
  </div>
  <div class="number-Plus">
    <div class="number-gap">
      <div class="Number">100+</div>
      <div class="number-text">Happy Owners</div>
    </div>
    <div class="vl"></div>

    <div class="number-gap">
      <div class="Number">150+</div>
      <div class="number-text">Properties</div>
    </div>
    <div class="vl"></div>

    <div class="number-gap">
      <div class="Number">05</div>
      <div class="number-text">Cities in India</div>
    </div>
    <div class="vl"></div>

    <!-- <div class="number-gap">
      <div class="Number">1200+</div>
      <div class="number-text">Happy Residents</div>
    </div> -->
  </div>
  <div class="question">
    <div class="question-text">Still Have Questions?</div>
    <div class="get-div">
      <a href="/faq"><button class="get-bt">Get Answers</button></a>
    </div>
  </div>
</div>
<div class="footer">
  <app-footer></app-footer>
</div>
