<div class="section">
  <div class="top-nav">
    <user-flow-top-navbar></user-flow-top-navbar>
  </div>
  <div class="total">
    <div class="left">
      <div class="left-bg">
        <div class="left-heading">
          Join The Isthara Family - Showcase Your Talent
        </div>
        <div class="sub-text">
          At Isthara, we encourage festive celebrations, gaming nights, movie
          screenings and other fun events that are planned by the residents, for
          the residents. Partner with us by signing an Exclusive Contract with
          Isthara for an amazing opportunity to showcase your talents and ride
          the wave of success. It will open up several avenues for you and steer
          your career in performing arts in the direction of growth and
          prosperity.
        </div>
        <div class="talent-image">
          <img
            src="./../../../../../assets/images/list-your-property/talent.png"
          />
        </div>
      </div>
      <div class="benifits">The Isthara</div>
      <div class="isthara-promise">Promise</div>
      <div>
        <div class="total-bottom-images">
          <div class="bottom-images" *ngFor="let im of about">
            <img src="{{ im.img }}" />

            <div class="bottom-images-text">{{ im.text }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="right">
      <div class="right-form">
        <div class="form-heading">Register Your Self</div>
        <div class="share-detail">Share your details with us</div>
        <div class="fields">
          <div class="name">
            <mat-form-field class="input-width" appearance="outline">
              <mat-label>Name</mat-label>
              <input
                matInput
                [(ngModel)]="Form.details.name"
                #nameCheck="ngModel"
                pattern="[a-zA-Z\s]+"
                required
              />
              <mat-error
                *ngIf="
                  !nameCheck.valid && (nameCheck.dirty || nameCheck.touched)
                "
              >
                Please enter your name
              </mat-error>
            </mat-form-field>
          </div>
          <div class="name">
            <mat-form-field class="input-width" appearance="outline">
              <mat-label>Mobile Number</mat-label>
              <input
                matInput
                [(ngModel)]="Form.details.mobileNo"
                #numberCheck="ngModel"
                pattern="^[6-9]\d{9}$|^[1-9]\d{9}$"
                required
              />
              <mat-error
                *ngIf="
                  !numberCheck.valid &&
                  (numberCheck.dirty || numberCheck.touched)
                "
              >
                please provide your contact number
              </mat-error>
            </mat-form-field>
          </div>
          <div class="name">
            <mat-form-field class="input-width" appearance="outline">
              <mat-label>Email id</mat-label>
              <input
                matInput
                [(ngModel)]="Form.details.emailId"
                #emailCheck="ngModel"
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                required
              />
            </mat-form-field>
            <mat-error
              *ngIf="
                !emailCheck.valid && (emailCheck.dirty || emailCheck.touched)
              "
            >
              please provide your emailID
            </mat-error>
          </div>
          <div class="name">
            <mat-form-field class="input-width" appearance="outline">
              <mat-label>Skill Type</mat-label>
              <mat-select
                disableOptionCentering
                panelClass="panelClass"
                [(ngModel)]="this.Form.details.skillType"
              >
                <mat-option
                  *ngFor="let skilltype of skilltype"
                  [value]="skilltype.value"
                >
                  {{ skilltype.viewValue }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="prefer-method">
            <p>Preferred Contact Method</p>
            <mat-radio-group
              aria-label="Select an option"
              [(ngModel)]="Form.details.contactMethod"
            >
              <mat-radio-button [value]="'email'">Email</mat-radio-button>
              <mat-radio-button [value]="'mobile'">Mobile</mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="message">
            <mat-form-field class="input-width" appearance="outline">
              <mat-label>Message</mat-label>
              <textarea
                matInput
                [(ngModel)]="Form.details.description"
                #descriptionCheck="ngModel"
              ></textarea>
            </mat-form-field>
          </div>
        </div>
        <div class="sec-div">
          <button class="second-bt" (click)="submitdetails()">Submit</button>
        </div>
        <div class="or">OR</div>
        <div class="contact-number"><span>Call Us On</span> +91 8790858581</div>
      </div>
    </div>
  </div>

  <div class="gap"></div>
</div>
<div class="footer">
  <app-footer></app-footer>
</div>
