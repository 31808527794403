import { ErrorPopsService } from './../../../../service/error-pops/error-pops.service';
import { DbStorageService } from './../../../../service/headers/db-storage/db-storage.service';
import { RightSlidingNavbarService } from './right-sliding-navbar.service';
import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SigninSignupServicesService } from '../../../authentication/services/signin-signup-services.service';
import { PropertyDetailsService } from './../../../property-details/property-details.service';

@Component({
  selector: 'user-flow-right-sliding-navbar',
  templateUrl: './right-sliding-navbar.component.html',
  styleUrls: [
    './right-sliding-navbar.component.scss',
    './mq-right-sliding-navbar.component.scss',
  ],
})
export class RightSlidingNavbarComponent implements OnInit {
  home: any;
  sideNav: any;
  navbar: any;
  @ViewChild('mySidePanel') mySidePanel: ElementRef;
  @Input() navbarText;
  constructor(
    private rightSilidingService: RightSlidingNavbarService,
    private router: Router,
    private _dbStorage: DbStorageService,
    private apiService: SigninSignupServicesService,
    private errorPops: ErrorPopsService,
    private propService: PropertyDetailsService
  ) {
    this.getScreenSize() 
  }
  srcHeight;
  srcWidth;
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.srcHeight = window.innerHeight;
    this.srcWidth = window.innerWidth;
    // console.log(this.scrHeight, this.scrWidth);
  }
  logoutBtn: boolean;
  userid;
  token: string;
  user = {
    name: '',
    mobile: '',
    profileImg: '',
    age: '',
    mailId: '',
    noImg: './../../../../../assets/images/right-sliding-nav/defaultUser.png',
  };
  smallScreenChecks : boolean = false
  ngOnInit(): void {
    this.token = this._dbStorage.getToken();
    this.userid = this._dbStorage.getUserId();
    this.getHome();
    if(this.srcWidth < 475){
      this.smallScreenChecks = true;
    }
  }
  navMenuLoggedInUser = [
    { name: 'My Booking', imgName: 'booking.png', path: 'myBookings' },
    {
      name: 'Scheduled Visits',
      imgName: 'scheduleBooking.png',
      path: 'scheduleVisit',
    },
    { name: 'Favourites', imgName: 'fav.png', path: 'favourite' },
    { name: 'KYC Details', imgName: 'kyc.png', path: 'kyc-documentation' },
    // { name: 'Refer and Earn', imgName: 'refer.png', path: 'ReferNearn' },
    { name: 'Reviews', imgName: 'review.png', path: 'reviews' },
  ];
  nonLoggedInUser = [
    { name: 'About Us', imgName: 'aboutus.png', nonLpath: 'aboutus' },
    { name: 'Community', imgName: 'community.png', nonLpath: 'community' },
    { name: 'Blog', imgName: 'blog.png', nonLpath: 'Blog' },
    {
      name: 'Privacy Policy',
      imgName: 'privacy.png',
      nonLpath: 'privacy-policy',
    },
    {
      name: 'Tenancy Policy',
      imgName: 'tenantPolicy.png',
      nonLpath: 'tenancyPolicy',
    },
    { name: 'Help Desk', imgName: 'helpDesk.png', nonLpath: 'helpDesk' },
    { name: 'Contact us', imgName: 'contactus.png', nonLpath: 'contactus' },
    // { name: 'Logout', imgName: 'logout.png',path:'' },
  ];
  navbarArray : any[]=[];
  openNav() {
    this.navbarArray = [];
    this.mySidePanel.nativeElement.style.width = '320px';
    this.navbarText = this.navbarText.reverse();
    // this.navbarText.splice(0,2);
    // this.navbarText.pop();
    this.navbarText.forEach((element,i) => {
      if(element.redirectUrl === '/food-court' || element.redirectUrl === '/student-living'){
        this.navbarArray.push(element)
      }
    });
    console.log(this.sideNav, this.navbarText);
    // let userId = '9618474297';
    // this.rightSilidingService.checkProfile(userId).subscribe((data: any) => {
    //   let user = data.userList.find((d) => d.mobile === userId);
    //   if (user) {
    //     this.user.name = user.name;
    //     this.user.mobile = user.mobile;
    //     this.user.profileImg = user.profileImg;
    //     this.user.age = user.age;
    //     this.user.mailId = user.mailId;
    //   } else {
    //     this.user = this.user;
    //   }
    // });
    if (this.userid) {
      let data = {
        id: this.userid,
      };
      this.rightSilidingService.getUser(data.id).subscribe(
        (id: any) => {
          if (id.error) {
            // console.log('error', id.message);
          } else {
            // console.log('get user data', id);
            this.user.name = id.data.fullName;
            this.user.profileImg = id.data.profilePicture;
          }
        },
        (err) => {
          // console.log('error', err);
        }
      );
    } else {
    }

    // console.log("check for slide: ",this.mySidePanel.nativeElement.style);
  }

  /* Set the width of the sidebar to 0 (hide it) */
  closeNav() {
    this.mySidePanel.nativeElement.style.width = '0';
  }

  //path for right sliding navbar
  routeTo(redirectUrl) {
    this.router.navigate([redirectUrl]);
  }
  nonLogrouteTo(redirectUrl) {
    this.router.navigate([redirectUrl]);
  }

  //logout api
  logout() {
    // let data={
    //   deviceInfo: 'null',
    //   userId: this.userid
    // }
    // this.apiService.l
    this._dbStorage.setUserId('null');
    // if(this.userid == 'null'){
    // this.nonLogrouteTo
    localStorage.clear();
    // }
    // else{
    // this.routeTo
    // this.router.navigate([''])
    window.location.reload();
    if (this.userid == 'null') {
      this.nonLogrouteTo;
    } else {
      this.routeTo;
    }
    // }
  }

  // logout(){
  //   // this._dbStorage.setLoginType("isLoggedinFalse")
  //   // this._dbStorage.setUserId('null')
  //   let data = {
  //     deviceInfo: "null",
  //     userId : this.userid}
  //   console.log ("logout",data.userId)
  //   this.apiService.logout(data,data.userId).subscribe(
  //     (data:any) =>{
  //       console.log("successfully logout",data.data)
  //       if(this.userid == 'null'){
  //         this.nonLoggedInUser
  //       }
  //       else{
  //           this.router
  //           window.location.reload()
  //       }
  //       // if(this.userid == 'null'){
  //       //   this.nonLogrouteTo
  //       // }
  //       // else{
  //       //   this.routeTo
  //       //   this.router.navigate([''])
  //       //   // localStorage.clear();
  //       //   this.closeNav()
  //       //   window.location.reload()
  //       //   this.errorPops.openSnackBarMessage(data.data,"Cancel")}
  //     },
  //     (err)=>{
  //       console.log("Some Error",err.error.messasge)
  //     }
  //   )
  // }

  getHome() {
    this.propService.home().subscribe((homeData: any) => {
      this.home = homeData.data;
      this.sideNav = this.home?.headerSection?.sideNavBar;
      this.navbar = this.sideNav?.navBar;
    });
  }
 
}
