import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
declare let $: any;
@Component({
  selector: 'user-flow-offering-carousel',
  templateUrl: './offering-carousel.component.html',
  styleUrls: [
    './offering-carousel.component.scss',
    'mq-offering-carousel.component.scss',
  ],
})
export class OfferingCarouselComponent implements OnInit {
  arrowWidth: number;
  constructor(
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  @Input() offers: any = [];
  @Input() parentWidth;

  offersData = [];
  offerDataCount: any;
  ngOnChanges(changes: import('@angular/core').SimpleChanges) {
    this.offerDataCount = changes.offers.currentValue.length;
    if (this.offerDataCount !== 0) {
      this.offersData = changes.offers.currentValue;
      // if (changes.parentWidth.currentValue > 321) {
      //   this.arrowWidth = Number(this.parentWidth); //this.arrowWidth = Number(this.parentWidth) * (34 / 31);
      // } else {
      this.arrowWidth = 590;
      this.parentWidth = 550;
      // }
      this.carouselData();
    }
  }
  ngOnInit() {}
  carouselData() {
    this.offersData = this.offers;
    if (isPlatformBrowser(this.platformId)) {
      $(document).ready(function () {
        $('.carousel').slick({
          // rtl: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
          fade: false,
          // adaptiveHeight: true,
          infinite: true,
          useTransform: true,
          // speed: 400,
          // autoplay: true,
          // autoplaySpeed: 2000,
          cssEase: 'cubic-bezier(0.77, 0, 0.18, 1)',
          prevArrow: $('.prev'),
          nextArrow: $('.next'),
        });
      });
    }
  }

  changeRoute(code) {
    this.router.navigate([`offer/${code}`]);
  }
}
