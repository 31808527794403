import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { PropertyDetailsService } from '../../../property-details/property-details.service';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: [
    './footer.component.scss',
    './mq-footer.component.scss',
  ],
  // encapsulation: ViewEncapsulation.None
})
export class FooterComponent implements OnInit {
  isShowMore = false;
  safeSrc: SafeResourceUrl;
  facebook: any;
  twitter: any;
  insta: any;
  youtube: any;
  constructor(
    private router: Router,
    private sanitizer: DomSanitizer,
    private propService: PropertyDetailsService
  ) {
    this.safeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(
      'https://www.youtube.com/embed/W5eq-2z33n8'
    );
  }
  domainName: any;
  ngOnInit() {
    this.getFooter();
    // this.domainName = environment.domainName;
    this.selectedCity = 'Hyderabad'
  }
  cityList=[
    {name:'Hyderabad',status:true},
    {name:'Bengaluru',status:false},
    {name:'NCR',status:false},
  ]
  home: any;
  footer: any;
  getFooter() {
    this.propService.home().subscribe((homeData: any) => {
      this.home = homeData.data;
      this.footer = this.home?.footerSection;
      this.facebook = this.footer.socialLinks.fbLink;
      this.twitter = this.footer.socialLinks.twitterLink;
      this.insta = this.footer.socialLinks.instaLink;
      this.youtube = this.footer.socialLinks.youtubeLink;
      // console.log("footerdata",this.footer)
    });
  }
  showMore() {
    this.isShowMore ? (this.isShowMore = false) : (this.isShowMore = true);
    //console.log("seeee meore", this.isShowMore);
  }

  routeListing(city, locality) {
    this.router.navigate(['/property-list'], {
      queryParams: { city: city, locality: locality },
    });
    // .then(result => {
    //   window.open("_blank");
    // });
  }
  routerPathRedirection(path) {
    this.router.navigate([path]);
  }
  topSearch = [
    {
      name:'Queens Madrid Womens Property',
      city:'Hyderabad',
      propertyType:'WOMENS',
      routingUrl :'/hyderabad/Pg-hostel-in-narsingi/womens/Queens-Madrid'
    },
    {
      name:'HS Mansion Coliving Property',
      city:'Hyderabad',
      propertyType:'COLIVING',
      routingUrl :'/Hyderabad/Pg-hostel-in-Gowlidoddi/coliving/HS-Mansion'
    },
    {
      name:'Juventus Mens Living Property',
      city:'Hyderabad',
      propertyType:'MENS',
      routingUrl :'/Hyderabad/Pg-hostel-in-Nadergul/mens/Juventus-Boys-Living'
    },
    {
      name: 'PG For Womens In Hyderabad',
      city: 'Hyderabad',
      propertyType: 'WOMENS',
      routingUrl:''
    },
    {
      name: 'PG For Mens In Hyderabad',
      city: 'Hyderabad',
      propertyType: 'MENS',
      routingUrl:''
    },
    {
      name: "Coliving PG's In Hyderabad",
      city: 'Hyderabad',
      propertyType: 'COLIVING',
      routingUrl:''
    },
  ];
  topSearchBenguluru = [
    {
      name:'Isthara Mustang Coliving Property',
      city:'bengaluru',
      propertyType:'COLIVING',
      routingUrl :'/bengaluru/Pg-hostel-in-sg-pal/coliving/Isthara-Mustang'
    },
    {
      name:'West Ham Coliving Property',
      city:'bengaluru',
      propertyType:'COLIVING',
      routingUrl :'/bengaluru/Pg-hostel-in-hulimavu/coliving/West-Ham'
    },
    {
      name:'Crusader Coliving Property',
      city:'bengaluru',
      propertyType:'COLIVING',
      routingUrl :'/bengaluru/Pg-hostel-in-btm-/coliving/Crusaders'
    },
    {
      name: 'PG For Womens In Bengaluru',
      city: 'bengaluru',
      propertyType: 'WOMENS',
      routingUrl:''
    },
    {
      name: 'PG For Mens In Bengaluru',
      city: 'bengaluru',
      propertyType: 'MENS',
      routingUrl:''
    },
    {
      name: "Coliving PG's In Bengaluru",
      city: 'bengaluru',
      propertyType: 'COLIVING',
      routingUrl:''
    },
  ];
  topSearchncr = [
    {
      name:'CMC Residency Mens Property',
      city:'sector 24',
      propertyType:'MENS',
      routingUrl :'/Gurugram/Pg-hostel-in-Sector-24/mens/CMC-Residency'
    },
    {
      name:'Amirya Residency Coliving Property',
      city:'sector 48',
      propertyType:'Coliving',
      routingUrl :'/Gurugram/Pg-hostel-in-Sector-48/coliving/Amirya-Residency'
    },
    {
      name: 'PG in Sector 48',
      locality: 'sector 48',
      routingUrl:''
    },
    {
      name: 'PG in Sector 24',
      locality: 'sector 24',
      routingUrl:''
    }
  ];

  pgData = [
    {
      name: 'PG in KPHB',
      locality: 'kukatpally',
      city: 'Hyderabad',
    },
    {
      name: 'PG in Gowlidoddi',
      locality: 'Gowlidoddi',
      city: 'Hyderabad',
    },
    {
      name: 'PG in Financial District',
      locality: 'Financial District',
      city: 'Hyderabad',
    },
    {
      name: 'PG in Madhapur',
      locality: 'Madhapur',
      city: 'Hyderabad',
    },
  ];
  pgDataBanglore = [
    {
      name: 'PG in Marathahalli',
      locality: 'Marathahalli',
      city: 'bengaluru',
    },
    {
      name: 'PG in Bellandur',
      locality: 'Bellandur',
      city: 'bengaluru',
    },
    {
      name: 'PG in Whitefield',
      locality: 'Whitefield',
      city: 'bengaluru',
    },
    {
      name: 'PG in Sarjapur',
      locality: 'Sarjapur',
      city: 'bengaluru',
    },
  ];

  hostelsData = [
    {
      name: 'Hostels in KPHB',
      locality: 'kukatpally',
      city: 'Hyderabad',
    },
    {
      name: 'Hostels in Gowlidoddi',
      locality: 'Gowlidoddi',
      city: 'Hyderabad',
    },
    {
      name: 'Hostels in Financial Distict',
      locality: 'Financial District',
      city: 'Hyderabad',
    },
    {
      name: 'Hostels in Madhapur',
      locality: 'Madhapur',
      city: 'Hyderabad',
    },
  ];
  hostelDataBanglore = [
    {
      name: 'Hostels in Marathahalli',
      locality: 'Marathahalli',
      city: 'bengaluru',
    },
    {
      name: 'Hostels in Bellandur',
      locality: 'Bellandur',
      city: 'bengaluru',
    },
    {
      name: 'Hostels in Whitefield',
      locality: 'Whitefield',
      city: 'bengaluru',
    },
    {
      name: 'Hostels in Sarjapur',
      locality: 'Sarjapur',
      city: 'bengaluru',
    },
  ];

  roomsData = [
    {
      name: 'Room for rent in KPHB',
      city: 'kukatpally',
      locality: 'Hyderabad',
    },
    {
      name: 'Room for rent in Madhapur',
      locality: 'Madhapur',
      city: 'Hyderabad',
    },
    {
      name: 'Room for rent in Gowlidoddi',
      locality: 'Gowlidoddi',
      city: 'Hyderabad',
    },
    {
      name: 'Room for rent in Financial District',
      locality: 'Financial District',
      city: 'Hyderabad',
    },
  ];

  PremiumPropertiesData = [
    {
      name: 'KPHB - Isthara',
    },
    {
      name: 'Gowlidoddi - Isthara Fortuna',
    },
    {
      name: 'Financial District -Isthara Ripple',
    },
    {
      name: 'Madhapur - IstharaTalisman',
    },
    {
      name: 'Financial District - Isthara Golf Edge',
    },
    {
      name: 'KPHB - Isthara Midway',
    },
  ];
  // routeListing(city,propertyType){

  // }
  filterList(city, propertyType,list) {
    this.topSearchBenguluru.forEach(element => {
      if(element.routingUrl === 'undefined'){
        this.router.navigate([city,'Gender',propertyType]
        // , {
        //   queryParams: { city: city, propertyType: propertyType },
        // }
        ); 
      }
      else{
        this.router.navigate([`${list.routingUrl}`]);
      }
    });
    // console.log(city)
    // console.log(propertyType)
    // console.log("list",list);
    
  }
  pgsHyd(city, locality) {
    // this.router.navigate(['/list'], {
    //   queryParams: { city: city, locality: locality },
    // });
    this.router.navigate([`${city}`,`pg-hostel-in-${locality.replaceAll(" ","-")}`]);
  }
  ncrList(locality,list){
    // this.router.navigate(['/list'],{
    //   queryParams:{locality:locality}
    // })
    this.topSearchncr.forEach(element => {
      if(element.routingUrl != ''){
        let city='Ncr'
        this.router.navigate([`${city}`,`pg-hostel-in-${locality.replaceAll(" ","-")}`]);
      }
      else{
        this.router.navigate([`${list.routingUrl}`]);
      }
    });
    
  }
  selectedCity = '';
  citySelection(item){
    // console.log(item)
    this.cityList.forEach(element => {
      if(element.name === item.name){
        element.status = true;
        this.selectedCity = element.name
      }
      else{
        element.status = false;
      }
    });
  }
}
