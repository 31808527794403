<section>
  <div class="main-block">
    <div class="align-block">
      <div class="text-section">
        <p class="company-name" [innerHTML]="blog?.title">
          <!-- {{ blog?.title_1 | titlecase }} -->
        </p>
        <!-- <p class="subtext">
        {{ blog?.title_2 | titlecase }}
      </p> -->
        <p class="description">
          {{ blog?.description }}
        </p>
      </div>
      <div class="service-section">
        <div class="services-box">
          <ng-container *ngFor="let item of blogCards">
            <div class="box-service">
              <div class="service-img">
                <img [src]="item?.iconUrl" [alt]="item.name" />
              </div>
              <div class="item-desc">{{ item?.title }}</div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</section>
