<!-- 
<div  class="otp-section">
    <div class="big-text">OTP Verification</div>
    <div class="sub-text">
         OTP has been sent to  mobile number 
    </div>
    <div class="number-text">
9956789878 <div class="mobile-text"> Change</div>
    </div>
    <div class="otp-field">
        <input id="codeBox1" #num1 maxlength="1" (keyup)="move(num1,num2)" [(ngModel)]="verifyotp.otp1">
<input id="codeBox2" #num2 maxlength="1" (keyup)="move(num2,num3)" [(ngModel)]="verifyotp.otp2">
<input id="codeBox3" #num3 maxlength="1" (keyup)="move(num3,num4)" [(ngModel)]="verifyotp.otp3">
        <input id="codeBox4" #num4 maxlength="1" (keyup)="move(num4,num5)" [(ngModel)]="verifyotp.otp4">
        <input id="codeBox5" #num5 maxlength="1" (keyup)="move(num5,num6)" [(ngModel)]="verifyotp.otp5">
        <input id="codeBox6" #num6 maxlength="1"  [(ngModel)]="verifyotp.otp6">
    </div>                    
    <div class="resend-otp">
        <div class="sub-text"></div><span class="otp-class">
            Resend OTP</span>
    </div>
    <div class="v-btn">
        <button class="verify-btn" >Sumbit</button>
    </div>

  </div> -->

<!-- <div class="close-x" (click)="closeBtn()">X</div>
<div class="main-sec">
<div class="heading">
    Change Mobile
</div>
<div class="sub-heading">
    Enter your new mobile number
</div>
<div>
    <mat-form-field appearance="outline">
        <mat-label>Outline form field</mat-label>
        <input matInput placeholder="Mobile Number">
         <mat-icon matSuffix>sentiment_very_satisfied</mat-icon> -->
<!-- </mat-form-field>
</div>
<button class="bt" >Submit</button>
</div>  -->
<div>
  <div class="big-text">
    {{ data.bigtext }}
  </div>
  <div class="img-part">
    <img src="{{ data.image }}" />
  </div>
  <div class="sub-text">
    <p>{{ data.subtext }}</p>
  </div>
  <div class="bt">
  <button class="second-bt" (click)="closeBtn()">DONE</button>
</div>
</div>
