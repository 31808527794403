import { environment } from './../../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class CareersService {

  constructor(
    private http: HttpClient
  ) { }
  getAllJobs(boolean){
    return this.http.get(
      environment.authenticationUrl+`user/miscellaneous/ist/job?active=${boolean}`
    )
  }
  filter(data){
    return this.http.post(
      environment.authenticationUrl+`user/miscellaneous/ist/job/search`,data
    )
  }
  jobBydetails(jobId){
    return this.http.get(
      environment.authenticationUrl+`user/miscellaneous/ist/job/${jobId}`
    )
  }
}
