import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'user-flow-apply',
  templateUrl: './apply.component.html',
  styleUrls: ['./apply.component.scss','mq-apply.component.scss']
})
export class ApplyComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ApplyComponent>,
    // @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
  }
  closeBtn() {
    this.dialogRef.close();
  }
formDetails={
  personalDetail: {
    firstName: '',
    lastName: '',
    email:'',
    number: '',
    
  }
}

}
