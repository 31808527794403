import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ErrorPopsService } from 'projects/user-flow-property/src/app/service/error-pops/error-pops.service';
import { DbStorageService } from 'projects/user-flow-property/src/app/service/headers/db-storage/db-storage.service';
import { ScheduleVisitService } from '../../../visit/schedule-visit.service';
import { BookingService } from './../../../booking/booking.service';
@Component({
  selector: 'user-flow-cancel-visit',
  templateUrl: './cancel-visit.component.html',
  styleUrls: [
    './cancel-visit.component.scss',
    './mq-cancel-visit.component.scss',
  ],
})
export class CancelVisitComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<CancelVisitComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiService: ScheduleVisitService,
    private _dbStorage: DbStorageService,
    private bookingService: BookingService,
    private errorPops: ErrorPopsService
  ) {
    {
      dialogRef.disableClose = true;
    }
  }
  visitId: any;
  cancelReason: '';
  cancelBookingloader : boolean = false;
  ngOnInit(): void {
    if (this.data.data.type == 'visit') {
      this.visitId = this.data.data.popData.visitId;
    }
    if (this.data.data.type == 'book') {
      this.visitId = this.data.data.popData.bookingId;
    }
    // console.log(this.visitId, this.data);
  }
  closeBtn() {
    this.dialogRef.close();
  }
  getCancel() {
    let data = {
      id: this.visitId,
    };
    this.apiService.getCancelvisitId(data.id).subscribe(
      (res: any) => {
        // console.log('vgfgf', res);
        window.location.reload();
      },
      (err) => {
        // console.log('error', err);
      }
    );
  }

  cancelBooing() {
    this.cancelBookingloader = true;
    let data = {
      bookingId: this.visitId,
      reason: this.cancelReason,
    };
    // console.log('reason', data.reason);
    this.bookingService.cancelBooking(data).subscribe(
      (res: any) => {
        this.errorPops.openSnackBarMessage(
          'Successfully Cancelled Your Booking',
          'OK'
        );
        this.cancelBookingloader = false;
        window.location.reload();
      },
      (err: any) => {
        this.cancelBookingloader = false;
        this.errorPops.openSnackBarMessage(err.message, 'OK');
      }
    );
  }
}
