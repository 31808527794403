import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'user-flow-community',
  templateUrl: './community.component.html',
  styleUrls: ['./community.component.scss', 'mq-community.component.scss'],
})
export class CommunityComponent implements OnInit {
  constructor(private router: Router,
    private metaService: Meta,
    private titleService: Title) {}
  cordinatesValue: any = 'Locate';
  addressSearch: any;
  showMe: boolean = false;

  stayEasy = [
    {
      img: './../../../../assets/images/community-right-panel/group-one.png',
      heading: 'Isthara Sports League',
      subText:
        'Refreshing sports leagues that bring the game to you in a revitalizing form. Enjoy the spirit of the game and the charm of your community. ',
    },
    {
      img: './../../../../assets/images/community-right-panel/group-event.png',
      heading: 'Fun Events',
      subText:
        'Lively experiences that give you the chance to break out of your comfort zone and embrace the community around you. Participate in comedy nights, fashion shows, and trekking, movie nights, and more.',
    },
    {
      img: './../../../../assets/images/community-right-panel/premises.png',
      heading: 'On-Premises',
      subText:
        'Leave the wear and tear of the day at bay with our on-premises recreational activities. Enjoy the gym, game center, etc. in a completely hassle-free experience.',
    },
    {
      img: './../../../../assets/images/community-right-panel/cooking.png',
      heading: 'Food Festivals',
      subText:
        'Unique food programs that introduce and immerse you with the culture and heritage of regional cuisines.',
    },
  ];
  ngOnInit(): void {
    this.metaService.updateTag({
      name:'description',
      content:'A catalyst of comminity living that nurtures the spirit of a group with growth and a sense of belonging.'});
    this.titleService.setTitle('A community of like-minded people at Isthara')
  }
  getCordinates(place: any) {
    this.cordinatesValue = place.city;
  }
  getAddress(place: any) {
    this.addressSearch = place;
    // console.log(place);
  }

  showvideo() {
    this.showMe = !this.showMe;
  }
  closeBtn() {
    // this.router.navigate(['/community'])
    this.showMe = !this.showMe;
  }
}
