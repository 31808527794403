import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { timeStamp } from 'node:console';
import { ErrorPopsService } from '../../../service/error-pops/error-pops.service';
import { DbStorageService } from '../../../service/headers/db-storage/db-storage.service';
import { PopupService } from '../../popup-components/popup.service';
import { CancelVisitComponent } from '../../popup-components/popups/cancel-visit/cancel-visit.component';
import { PropertyDetailsService } from '../../property-details/property-details.service';
import { ScheduleVisitService } from '../schedule-visit.service';
import { VisitUserFlowComponent } from '../visit-user-flow/visit-user-flow.component';

@Component({
  selector: 'user-flow-my-visits',
  templateUrl: './my-visits.component.html',
  styleUrls: ['./my-visits.component.scss', './mq-my-visits.component.scss'],
})
export class MyVisitsComponent implements OnInit {
  showMe: boolean = false;
  userid: any;
  date: any;
  day: any;
  loader: boolean = false;
  visitDateTime: any;
  viewMode = 'visits';
  tabs = [
    { name: 'All', active: false },
    { name: 'Upcoming', active: true },
    { name: 'Completed', active: false },
    { name: 'Cancelled', active: false },
  ];
  constructor(
    private propertyDetailsService: PropertyDetailsService,
    private apiService: ScheduleVisitService,
    private _dbStorage: DbStorageService,
    private errorPops: ErrorPopsService,
    private popupService: PopupService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.userid = this._dbStorage.getUserId();
    // this.getVisitByUser();
    if (this.userid == 'null' || this.userid == null) {
      this.router.navigate(['/']);
    } else {
    }

    this.openTab(this.tabs[0], 0);
  }
  cards = [
    {
      cardHeading: 'Isthara Coliving',
      locateImg: './../../../../assets/images/visit/locate.png',
      areaText: 'Madhapur,Hyderabad',
      lowerText: '14th oct,Wednesday',
      calanderImg: './../../../../assets/images/visit/clock.png',
    },
  ];
  nextStep2() {
    this.showMe = !this.showMe;
  }
  visits: any = [];
  data: any = [];
  some;
  count;

  getVisitDetailedList(value) {
    this.loader = false;
    let data = {
      userId: this.userid,
      visitStatus: value.name.toUpperCase(),
    };
    this.apiService.visitslist(data).subscribe(
      (id: any) => {
        if (id.error) {
          // console.log('error', id.message);
        } else {
          // console.log('response: ', id.data.reverse());
          this.visits = id.data;
          this.getVisits(this.visits[0], 0);
        }
      },
      (err) => {
        // console.log('error', err);
      },
      () => {
        if (this.visits) { this.loader = true; }

      }
    );
  }

  visitDetails: any;

  getVisits(visit, i) {
    // console.log('visitid', visit);
    // console.log('i', i);
    this.visitDetails = visit;
    this.visits.forEach((person, index) => {
      if (i === index) {
        person.active = true;
      } else {
        person.active = false;
      }
    });
    // this.apiService.getvisitByvisitId(visitId).subscribe(
    //   (res: any) => {
    //     console.log('sucess', res.data);
    //     this.visitDetails = res.data;

    //     this.visits.forEach((person, index) => {
    //       if (i === index) {
    //         person.active = true;
    //       } else {
    //         person.active = false;
    //       }
    //     });

    //     // this._dbStorage.setvisitId(this.visitDetails.visitId)

    // this._dbStorage.setvisitId(this.visitDetails.visitId);
    //   },
    //   (err: any) => {
    //     console.log('error', err);
    //   }
    // );
  }

  openVisit(visitData) {
    let dialog = this.popupService.openDialog(CancelVisitComponent, {
      heading: 'Cancel visit',
      sub: 'Are you Sure to cancel your Visit?',
      btnName: 'Yes,Cancel',
      btnStatus: 'fromVisit',
      subSub: 'If you are busy you can reschedule the visit.',
      width: '346px',
      // height: '53%',
      // maxHeight: '40vh',
      maxWidth: '349px',
      data: { popData: visitData, type: "visit" },
    });
  }

  reschedule(visitData) {
    this.propertyDetailsService.openDialog(VisitUserFlowComponent, {
      // user: { name: 'Prateek', mobile: '9618474297' },
      buttonName: 'Reschedule',
      data: visitData,
    });
    // dialog.afterClosed().subscribe((result) => {
    //   console.log('The visit was closed: ', result);
    // });
  }

  openTab(tab, tabI) {
    this.tabs.forEach((value, index) => {
      if (index === tabI) {
        value.active = true;
      } else {
        value.active = false;
      }
    });
    this.getVisitDetailedList(tab);
  }
  mapCoordinates(deatils) {
    window.open(
      `https://www.google.com/maps?z=12&t=m&q=loc:${deatils.properties[0].propertyAddress.geoLocation.latitude}+${deatils.properties[0].propertyAddress.geoLocation.longitude}`
    );
  }
}
