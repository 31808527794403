import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'user-flow-privacy-policies',
  templateUrl: './privacy-policies.component.html',
  styleUrls: ['./privacy-policies.component.scss']
})
export class PrivacyPoliciesComponent implements OnInit {
  addressSearch: any;
  cordinatesValue: any = 'Locate';
  constructor() { }

  ngOnInit(): void {
  }
  getCordinates(place: any) {
    this.cordinatesValue = place.city;
  }
  getAddress(place: any) {
    this.addressSearch = place;
    // console.log('dddddddddddd', place);
  }
}
