<section>
  <div class="community-details">
    <user-flow-top-navbar></user-flow-top-navbar>
  </div>

  <div class="main-section">
    <div class="community-top-sec">
      <div class="student-living">
        <div class="text-section">
          <p class="sub-class1">The Isthara Community</p>
          <p class="sub-text">Your Tribe</p>
          <p class="text-descrpition">
            The Isthara nurtures the spirit of community and a sense of
            belongingness with the people that you share your living space with.
            These connections enrich your co-living experience and hold the
            potential to change your life – forever!
          </p>
          <div class="video" (click)="showvideo()">
            <span class="img"
              ><img
                src="./../../../../assets/images/community-right-panel/videoicon.png"
                alt=""
            /></span>
            <span class="line">Watch Video</span>
          </div>
        </div>
      </div>
      <div class="img-sec-community">
        <img
          src="./../../../../assets/images/community-right-panel/community.png"
          alt=""
          class="community-img"
        />
      </div>
    </div>

    <div class="picture">
      <div class="pic-text" *ngFor="let se of stayEasy">
        <div class="inpicture">
          <img src="{{ se.img }}" alt="" />
        </div>
        <div class="text1">
          <p class="food">{{ se.heading }}</p>
          <p class="sub-text1">{{ se.subText }}</p>
        </div>
      </div>
    </div>

    <div class="creating">
      <div class="text-section4">
        <p class="sub-text4">The Isthara Touch</p>
        <p class="create">
          Isthara is a pioneer in transforming the campus experience with a fun,
          hassle-free, and memorable community.
        </p>
        <p class="sub-text4">Community Development</p>
        <p class="create">
          Social events planned by the residents, for the residents nurture the
          sense of community and belonging.
        </p>
      </div>
      <div class="mem-img" align="right">
        <img
          src="./../../../../assets/images/community-right-panel/community-group.png"
          alt=""
          width="80%"
        />
      </div>
    </div>
    <div class="help">
      <div class="text-section5">
        <p class="sub-text5">Create Memorable Experiences</p>
        <p class="pink-text">Add The Isthara Charm</p>
        <p class="create1">
          Experience the Isthara Community at your facility. Whether it’s
          co-living or student living, the community will always have something
          meaningful to offer.
        </p>
        <p class="create1">
          Get in touch with us to know more about how you can bring the Isthara
          magic to your space!
        </p>
        <!-- <div class="add-box">
          <div class="add-search">
            <div class="text-box">
              
              <div class="input-bar">
                <input type="text" placeholder="Add Contact Details" />
              </div>
            </div>
            <div class="btn-box">
              <button class="theme-btn">Send</button>
            </div>
          </div>
        </div> -->
      </div>

      <div class="sub5" align="right">
        <img
          src="./../../../../assets/images/community-right-panel/anime.png"
          alt=""
        />
      </div>
    </div>
  </div>

  <!-- <div class="image-text">
  <div class="image">
    <img src="./../../../../assets/images/community from right panel/Rectangle 3332.png" alt="">
  </div>
  <div class="text">
    <p>Nutricious Food</p>
    <p>Menu designed as per nutriotn requirements, FFSAI standards and Third party quality checks</p>
  </div>
</div> -->
  <div *ngIf="showMe" id="div22" class="video-popup">
    <div (click)="closeBtn()" class="closebtn">X</div>
    <iframe
      width="860"
      height="515"
      src="https://www.youtube.com/embed/77b_5vfletY"
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
  </div>
  <div>
    <app-footer></app-footer>
  </div>
</section>
