import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router, ActivatedRoute, ParamMap, Routes } from '@angular/router';
import { PropertyDetailsService } from '../../property-details/property-details.service';

@Component({
  selector: 'user-flow-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss', './mq-about-us.component.scss'],
})
export class AboutUsComponent implements OnInit {
  addressSearch: any;
  cordinatesValue: any = 'Locate';
  selectedNearby: any;
  viewMode = 'profilephoto';
  isActive: boolean = false;
  showMe: boolean = false;

  constructor(
    private propertyDetailsService: PropertyDetailsService,
    private _route: ActivatedRoute,
    private metaService: Meta,
    private router: Router,
    private titleService: Title
  ) {}
  changePath(path) {
    this.router.navigate([path]);
  }
  ngOnInit(): void {
    this.metaService.updateTag({
      name:'description',
      content:'Get to know about your new home with everything you need in one place | PGs in Hyderabad | PGs in Bangalore'});
    this.titleService.setTitle('Coliving space you can call Home')
  }
}
