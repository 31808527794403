import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'user-flow-comunity-section',
  templateUrl: './comunity-section.component.html',
  styleUrls: ['./comunity-section.component.scss','./mq-comunity-section.component.scss'],
})
export class ComunitySectionComponent implements OnInit {
  @Input() community : any
  @Input() communityCard: any[]
  showMe: boolean = false;

  serviceBox = {
    name: 'our',
    subText: 'community',
    subDescription: 'Student Living',
    description:
      'Students from around the world want to be in the right place, in good quality accommodation with facilities and communities that add value to that experience.',
    services: [
      {
        text: './../../../../assets/images/community/community1.png',
        cols: 3,
        rows: 3,
        color: 'transparent',
      },
      {
        text: './../../../../assets/images/community/community2.png',
        cols: 1,
        rows: 2,
        color: 'transparent',
      },

      {
        text: './../../../../assets/images/community/community3.png',
        cols: 1,
        rows: 1,
        color: 'transparent',
      },
    ],
  };
  constructor() {}

  ngOnInit(): void {
    this.serviceBox = this.serviceBox;
  }

  showvideo() {
    this.showMe=!this.showMe
  }
  closeBtn(){
  // this.router.navigate(['/community'])
  this.showMe=!this.showMe
  }
}
