import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SigninSignupServicesService {
  constructor(private http: HttpClient) {}

  //signUp
  signUp(signUpdata) {
    return this.http.post(
      environment.authenticationUrl + 'user/userdetails/ist/signup',
      signUpdata
    );
  }
  //signin
  signIn(signIndata) {
    return this.http.post(
      environment.authenticationUrl + 'user/userdetails/ist/login',
      signIndata
    );
  }
  //verifyotp
  verifyNow(verifyOtp) {
    return this.http.post(
      environment.authenticationUrl + 'user/userdetails/ist/validate',
      verifyOtp
    );
  }
  //Resend OTP
  resendOtp(resendotp) {
    return this.http.post(
      environment.authenticationUrl + 'user/userdetails/ist/resendotp',
      resendotp
    );
  }

  //logout
  logout(data, userId) {
    return this.http.put(
      environment.authenticationUrl + `user/user/${userId}/logout`,
      data
    );
  }
}
