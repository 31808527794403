<div class="close-x" (click)="closeBtn()">
    <div class="close-image">
        <div class="product-analyst">Product Analyst</div>
        <img src="./../../../../../assets/images/popup-images/cross-mark.png" />
    </div>
</div>



<div>
    <div class="lead-heading">First Name<sup>*</sup></div>
    <input class="form" matInput [(ngModel)]="formDetails.personalDetail.firstName" #nameCheck="ngModel"
        pattern="[a-zA-Z\s]+" required />
    <mat-error *ngIf="!nameCheck.valid && (nameCheck.dirty || nameCheck.touched)">Please Enter
        First Name
    </mat-error>
</div>
<div>
    <div class="lead-heading">Last Name<sup>*</sup></div>
    <input class="form" matInput [(ngModel)]="formDetails.personalDetail.lastName" #nameCheck="ngModel"
        pattern="[a-zA-Z\s]+" required />
    <mat-error *ngIf="!nameCheck.valid && (nameCheck.dirty || nameCheck.touched)">Please Enter
        Last Name
    </mat-error>
</div>
<div>
    <div class="lead-heading">Email<sup>*</sup></div>
    <input class="form" matInput [(ngModel)]="formDetails.personalDetail.email" #nameCheck="ngModel"
        pattern="[a-zA-Z\s]+" required />
    <mat-error *ngIf="!nameCheck.valid && (nameCheck.dirty || nameCheck.touched)">Please Enter
        Email
    </mat-error>
</div>
<div>
    <div class="lead-heading">Phone number<sup>*</sup></div>
    <input class="form" matInput [(ngModel)]="formDetails.personalDetail.number" #nameCheck="ngModel"
        pattern="[a-zA-Z\s]+" required />
    <mat-error *ngIf="!nameCheck.valid && (nameCheck.dirty || nameCheck.touched)">Please Enter
        Phone Number
    </mat-error>
</div>
<div class="box">
    <input id="file" type="file" style="display: none;">
    <img src="./../../../../../assets/images/contactus/file-upload.png" alt="">
    <div class="file-upload">Upload Resume</div>


</div>

<button class="upload-btn">Submit</button>