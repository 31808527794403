import { ErrorPopsService } from './../../../service/error-pops/error-pops.service';
import { Router } from '@angular/router';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DbStorageService } from '../../../service/headers/db-storage/db-storage.service';
import { PopupService } from '../../popup-components/popup.service';
import { CancelVisitComponent } from '../../popup-components/popups/cancel-visit/cancel-visit.component';
import { PropertyDetailsService } from '../../property-details/property-details.service';
import { BookingService } from '../booking.service';
declare let CashFree: any;
declare let $: any;


@Component({
  selector: 'user-flow-my-bookings',
  templateUrl: './my-bookings.component.html',
  styleUrls: ['./my-bookings.component.scss', './mq-my-bookings.component.scss']
})

export class MyBookingsComponent implements OnInit {
  loader: boolean = false;
  @ViewChild("tabcontent") tabContent: ElementRef;
  @ViewChild("tabLinks") tabLinks: ElementRef
  userid;
  constructor(
    private popupService: PopupService,
    private _dbStorage: DbStorageService,
    private apiService: BookingService,
    private propService: PropertyDetailsService,
    private router: Router,
    private errorPops: ErrorPopsService
  ) { }

  booking: any = []
  checkinDate;
  count;
  paymentObj: any = {}
  formDataView: any = {};
  bookingDetails;
  bookagainLoader : boolean = false;
  tabs = [
    { name: 'All', active: false },
    { name: 'Upcoming', active: true },
    { name: 'Completed', active: false },
    { name: 'Cancelled', active: false }
  ]
  ngOnInit(): void {
    this.userid = this._dbStorage.getUserId()
    if (this.userid == 'null' || this.userid == null) {
      this.router.navigate(['/'])
    }
    else { }
    this.openTab(this.tabs[0], 0);
    let data = {
      bookedById: this._dbStorage.getUserId(),
      status: "ALL"
    };
    this.apiService.bookingData(data).subscribe(
      (id: any) => {
        if (id.error) {
          // //console.log('error', id.message);
        } else {
          // //console.log("response", id.data)
          this.booking = id.data;
          this.count = id.data.length;
          for (let i = 0; i < id.data.length; i++) {
            if (id.data[0]) {
              id.data[0].active = true
            }
            else {
              id.data[i].active = false
            }
            this.bookingData(this.booking[0], 0)
          }
          // this.propService
          // .propertyDetails(this.booking.propertyId)
          // .subscribe(
          //   (res: any) => {
          //     //console.log("gfhgf",res)
          //   });
        }
      },
      (err) => {
        // //console.log('error', err);
      },
      () => {
        if (this.booking) { this.loader = true; }

      }
    );
    //console.log("bikkibvbdata",this.booking)
  }

  cards = [
    {
      img: './../../../../assets/images/visit/istharacoliving1.png',
      city: 'hyderabad',
      bookdate: 'Booked On 1 Feb',
      name: 'Isthara Coliving',
      price: 'Rs 4500',
      date: '20 Mar 2021',
      in: 'in',
      istharahelp: 'Isthara Help',
      active: true,

    },
    {
      img: './../../../../assets/images/visit/istharacoliving1.png',
      city: 'hyderabad',
      bookdate: 'Booked On 1 Feb',
      name: 'Isthara',
      price: 'Rs 4500',
      date: '20 Mar 2021',
      in: 'in',
      istharahelp: 'Isthara Help',
      active: false,
    }
  ]

  bookingData(item, i) {
    this.booking.forEach((person, index) => {
      if (i === index) {
        person.active = true;
        this.updateValuesinRight(item);
        // update Value
      } else {
        person.active = false;
      }
    });
  }
  checkin;
  dateFilter
  updateValuesinRight(item) {
    this.leadcreation.img = item?.property?.imges;
    this.leadcreation.city = item?.property?.city;
    this.leadcreation.bookingDate = item.bookingDate;
    this.leadcreation.name = item?.property?.propertyName;
    this.leadcreation.price = item.totalAmount;
    this.leadcreation.expectedCheckInDate = item.expectedCheckInDate;
    this.leadcreation.in = item.in;
    this.leadcreation.roomSharing = item.roomSharing
    this.leadcreation.istharahelp = item.istharahelp;
    this.leadcreation.bookingStatus = item.bookingStatus;
    this.leadcreation.bookingId = item.bookingId;
    this.leadcreation.agreedRent = item.agreedRent;
    this.leadcreation.rentDiscount = item.rentDiscount;
    this.leadcreation.bookedById = item.bookedById;
    this.leadcreation.bookingAmount = item.bookingAmount;
    this.leadcreation.bookingType = item.bookingType;
    this.leadcreation.discountAmount = item.discountAmount;
    this.leadcreation.propertyId = item.propertyId;
    this.leadcreation.roomType = item.roomType;
    this.leadcreation.totalAmount = item.totalAmount;
    this.leadcreation.userEmail = item.userEmail;
    this.leadcreation.userMobile = item.userMobile;
    this.leadcreation.userName = item.userName;
    this._dbStorage.setvisitId(item.bookingId)
    let some = new Date(item.createdDateTimeStamp).toDateString();
    this.checkin = new Date(new Date(some).setDate(new Date(some).getDate() + 30))
    let checkinDate = new Date(item.expectedCheckInDate).toDateString();

    this.dateFilter = (d: Date) => {
      return [new Date(checkinDate).getDate()].indexOf(+d.getDate());
    };
  }
  leadcreation = {
    img: '',
    city: 'Hyderabad',
    bookingDate: '',
    name: '',
    price: '',
    expectedCheckInDate: '',
    in: '',
    istharahelp: '',
    roomSharing: '',
    bookingStatus: '',
    bookingId: '',
    agreedRent: '',
    rentDiscount: '',
    bookedById: '',
    bookingAmount: '',
    bookingType: '',
    discountAmount: '',
    propertyId: '',
    roomType: '',
    totalAmount: '',
    userEmail: '',
    userMobile: '',
    userName: '',
  }
  myDate = new Date();
  minDate = new Date(this.myDate);
  minBookingCancelled = new Date();
  maxBookingCancelled = new Date(new Date(this.minBookingCancelled).setDate(new Date(this.minBookingCancelled).getDate() + 30))

  getBookingDetailsList(value) {
    let data = {
      bookedById: this._dbStorage.getUserId(),
      status: value.name.toUpperCase()
    };
    this.apiService.bookingData(data).subscribe(
      (id: any) => {
        if (id.error) {
          // //console.log('error', id.message);
        } else {
          // //console.log("response", id.data)
          this.booking = id.data
          //console.log("bikkibvbdata",this.booking)
          for (let i = 0; i < id.data.length; i++) {
            if (id.data[0]) {
              id.data[0].active = true
            }
            else {
              id.data[i].active = false
            }
            this.bookingData(this.booking[0], 0)
          }
        }
      },
      (err) => {
        // //console.log('error', err);
      }
    );
  }
  //   
  cancelBooking(bookingData) {
    this.popupService.openDialog(
      CancelVisitComponent, {
      width:'400px',
      heading: 'Cancel Booking',
      sub: 'Are you Sure to cancel your Booking?',
      btnName: 'Yes, Cancel',
      btnStatus: 'fromBooking',
      data: { popData: bookingData, type: "book" },
    }
    )
  }
  changeCheckinDate;
  updateBooking() {
    if(this.checkinDate == null || this.checkinDate == ''){
      //console.log("please select date")
    }
    else{
    this.changeCheckinDate = new Date(this.checkinDate).toISOString();
    let data = {
      bookingID: this.leadcreation.bookingId,
      expectedCheckInDate: this.changeCheckinDate
    }
    this.apiService.updateBooking(data).subscribe(
      (res: any) => {
        // //console.log("successfully UPdated",res);
        window.location.reload();
      },
      (err: any) => {
        // //console.log("GEtting Somenthing error",err);

      }
    )
    }
  }
  openTab(tab, tabI) {
    this.tabs.forEach((value, index) => {
      if (index === tabI) {
        value.active = true;
      } else {
        value.active = false;
      }
    });
    this.getBookingDetailsList(tab);
  }
  bookAgain() {
    let bookingData = {
      agreedRent: this.leadcreation.agreedRent,
      rentDiscount: this.leadcreation.rentDiscount,
      bookedById: this.userid,
      bookingAmount: Number(this.leadcreation.bookingAmount),
      bookingType: "PROPERTY_BOOKING",
      countryCode: "91",
      discountAmount: Number(this.leadcreation.discountAmount),
      expectedCheckInDate: this.checkinDate,
      offerApplied: true,
      propertyId: this.leadcreation.propertyId,
      // propertyName: this.data.property.propertyName,
      roomSharing: this.leadcreation.roomSharing,
      roomType: this.leadcreation.roomType,
      totalAmount: Number(this.leadcreation.totalAmount),
      userEmail: this.leadcreation.userEmail,
      // userGender: this.userDetails.gender,
      userMobile: this.leadcreation.userMobile,
      userName: this.leadcreation.userName
    }
    this.apiService.createBooking(bookingData).subscribe(
      (res: any) => {
        // //console.log("this.is the responce from booking service", res)
        this.bookingDetails = res.data
        this.paymentMethod()
      },
      (err: any) => {
        // //console.log("this is the error from booking service", err)
      }
    )
  }
  paymentMethod() {
    this.paymentObj = {
      bookingId: this.bookingDetails.bookingId,
      orderAmount: (this.bookingDetails.totalAmount).toFixed(2)
    }
    this.paymentCall()
  }

  retryPayment() {
    this.bookagainLoader = true;
    this.paymentObj = {
      bookingId: this.leadcreation.bookingId,
      orderAmount: Number(this.leadcreation.totalAmount).toFixed(2)
    }
    this.paymentCall();
    this.bookagainLoader = false;
  }
  cfInitialized = false;
  paymentCall() {
    this.apiService.merchantToken(this.paymentObj).subscribe(
      (pay: any) => {
        this.formDataView.orderId = pay.data.orderId;
        this.formDataView.orderAmount = pay.data.orderAmount.toFixed(1);
        this.formDataView.customerName = pay.data.payeeName;
        this.formDataView.customerPhone = pay.data.payeeMobile;
        this.formDataView.customerEmail = pay.data.payeeEmail;
        this.formDataView.returnUrl = pay.data.returnUrl; //http://localhost:4200/#/amount/${this.paymentObj.bookingId}
        this.formDataView.notifyUrl = pay.data.notifyUrl;
        this.formDataView.appId = pay.data.appId;
        this.formDataView.signature = pay.data.orderSignature; //paymentToken
        this.formDataView.orderCurrency = pay.data.paymentCurrency;
        this.formDataView.orderNote = 'Booking';



        setTimeout(() => {
          $('#redirectForm').submit();
        }
          , 100);
      },
      (err: any) => {
        // //console.log("error from cashfree payment gateway", err)
      }
    )
  }
}
