import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DbStorageService } from '../../service/headers/db-storage/db-storage.service';
import { environment } from './../../../environments/environment';



@Injectable({
  providedIn: 'root'
})
export class ScheduleVisitService {

  constructor(
    private http: HttpClient,
    private _dbStorage: DbStorageService
  ) { }
  createVisit(visitData){
    return this.http.post(
      environment.authenticationUrl+'property/visit/createvisit/',visitData
    )
  }
  visitslist(visitlist){
    return this.http.post(
      environment.authenticationUrl+`property/visit/visitsearch/`,visitlist
    )
  }
  getvisitByvisitId(id) {
    return this.http.get(
      environment.authenticationUrl+`property/visit/visitdetails/${id}`
    )
  }
  getCancelvisitId(id) {
    return this.http.get(
      environment.authenticationUrl+`property/visit/cancelvisit/${id}`
    )
  }
  reScheduleVisit(scheduleData) {
    return this.http.post(
      environment.authenticationUrl+'property/visit/reschedule/',scheduleData
    )
  }
}
