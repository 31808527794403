<section class="career-section">
    <div class="top-nav">
        <user-flow-top-navbar></user-flow-top-navbar>
    </div>
    <div class="main-section">
        <div class="filter-job">
            <div class="filter">
                <div class="filter-card">
                    <div class="field-location">
                        <div class="lead-heading">Location</div>
                        <form [formGroup]="cityGroup">
                            <select class="form-select shadow-none leftselect-width" (change)="selectlocation($event.target.value)"
                            formControlName="city">
                                <option selected disabled value="null">-Select-</option>
                                <option *ngFor="let loc of location" [value]="loc.value" ngDefaultControl>
                                    {{ loc.name | titlecase }}</option>
                            </select>
                        </form>
                    </div>
                    <div class="field-department">
                        <div class="lead-heading">Department</div>
                        <form [formGroup]="departmentGroup">
                            <select class="form-select shadow-none leftselect-width" (change)="selectDepartment($event.target.value)"
                            formControlName="department">
                                <option selected value="null">-Select-</option>
                                <option *ngFor="let loc of Department" [value]="loc.value" ngDefaultControl>
                                    {{ loc.name}}</option>
                            </select>
                        </form>
                    </div>
                    <div class="field-check">
                        <div class="lead-heading">Job Type</div>
                        <div class="group-checkbox" *ngFor="let item of jobType; let i = index">
                            <mat-checkbox
                                (change)="selectjobType(item.value,item.status)"
                                class="text-color"
                                [color] = "primary"
                                [ngClass]="item.status == true ? 'text-bold' : 'text-color'"
                                [checked]="item.status == true"
                            >
                                {{ item.name | titlecase }}
                            </mat-checkbox>
                        </div>
                    </div>
                    <div class="btn-group">
                        <div class="submit-btn">
                            <button (click)="submitSearch()" *ngIf="!submitLoading; else loading">Submit</button>
                            <ng-template #loading >
                                <div class="submit-btn">
                                    <button>
                                        <i class="fa fa-spinner fa-spin"></i> Loading...
                                    </button>
                                </div>
                              </ng-template>
                        </div>
                        <div class="reset-btn">
                            <button (click)="resetSearch()" *ngIf="!resetLoading;else reLoading">Reset Search</button>
                            <ng-template #reLoading >
                                <div class="submit-btn">
                                    <button class="active-load">
                                        <i class="fa fa-spinner fa-spin"></i>&nbsp; Loading...
                                    </button>
                                </div>
                              </ng-template>
                        </div>
                    </div>
                </div>
            </div>
            <div class="career-cards" *ngIf="careers.length > 0; else nojobs">
                <ng-container *ngFor="let item of careers">
                    <div class="job-card">
                        <div (click)="viewMore(item?.jobId)">
                            <div class="job-head">{{item.jobName}}</div>
                            <div class="loc-exp">
                                    <img src="./../../../../assets/images/career/location.svg" alt="">
                                    <div class="loc" *ngFor="let loc of item?.locationDetails">{{loc.city | titlecase}}</div>
                                    <img src="./../../../../assets/images/career/exp.svg" alt="" class="exp-img">
                                    <div class="exp">{{item.experience}}</div>
                            </div>
                            <div class="desc">
                                {{(showMore) ? item.jobDescription : item.jobDescription | slice:0:270}}<span>..</span> 
                                <span class="viewmore">
                                    <button>View More</button>
                                </span>
                            </div>
                        </div>
                        <div class="type-apply">
                            <div class="job-type">
                                <!-- <div class="company">Isthara</div>
                                <div class="vlcompany"></div> -->
                                <div class="type">
                                    {{item?.jobType.join(', ') | titlecase}}</div>
                            </div>
                            <div class="apply-btn">
                                    <button (click)="mailTo(item.jobName,item.jobCode)">Apply</button>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
            <ng-template #nojobs>
                <div class="career-card">
                    <div>
                        <img src="./../../../../../assets/images/career/noJobs.svg" alt="">
                        <div class="no-job">
                            Oops! No Jobs Here...
                        </div>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
    <div class="footer">
        <app-footer></app-footer>
    </div>
</section>