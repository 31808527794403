<section>
  <div class="top-nav">
    <user-flow-top-navbar></user-flow-top-navbar>
  </div>
  <div class="total" *ngIf="loader; else loadingImg">
    <div class="total-content">
      <div class="left-content">
        <div class="top-heading">Scheduled Visit</div>
        <div class="tab">
          <button
            class="tablinks"
            *ngFor="let tab of tabs; let i = index"
            (click)="openTab(tab, i)"
            [ngClass]="{ active: tab.active }"
          >
            {{ tab.name }}
          </button>
        </div>
        <!-- <div >aaaaaa</div> -->
        <div class="tabcontent" *ngIf="visits?.length > 0; else noCount">
          <div *ngFor="let visit of visits; let i = index">
            <div
              class="card-section"
              (click)="getVisits(visit, i)"
              [ngStyle]="
                visit?.status == 'Visit Planned'
                  ? { opacity: '100%' }
                  : { opacity: '50%' }
              "
              [ngClass]="{ active: visit?.active == true }"
            >
              <div class="image">
                <img
                  src="./../../../../assets/images/visit/istharacoliving1.png"
                  width="190px"
                />
              </div>
              <div class="container-text">
                <div class="heading-total">
                  <div class="card-heading">
                    {{ visit?.propertyName }}
                  </div>
                  <div class="gap">
                    <div
                      class="top-card"
                      [ngStyle]="
                        visit?.status == 'Visit Planned'
                          ? { 'background-color': '#ff1872' }
                          : { 'background-color': 'black' }
                      "
                    >
                      <span *ngIf="visit?.visitType == 'VIDEO_VISIT'"
                        >Video Visit</span
                      >
                      <span *ngIf="visit?.visitType == 'PHYSICAL_VISIT'"
                        >Physical Visit</span
                      >
                    </div>
                  </div>
                </div>
                <div class="sub-div">
                  <div class="locate-img">
                    <img src="./../../../../assets/images/visit/locate.png" />
                  </div>
                  <div class="area-text">
                    {{ visit?.locality }},{{ visit?.city }}
                  </div>
                </div>
                <div>
                  <div class="sub-div">
                    <div class="locate-img">
                      <img
                        src="./../../../../assets/images/visit/calendar.png"
                      />
                    </div>
                    <div class="date-area">
                      {{ visit?.visitDateTime | date: "fullDate" }}
                    </div>
                    <div class="lower-subtext">
                      <img src="./../../../../assets/images/visit/clock.png" />
                      <div class="time-clock">
                        {{ visit?.visitDateTime | date: "h:mm a" }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ng-template #noCount>
          <div class="no-visits">
            <div class="main">
              <div class="noImg">
                <img
                  src="./../../../../assets/images/visit/no-visits.png"
                  alt=""
                  width="25%"
                />
              </div>
              <div class="noVisit-head">No Visits</div>
              <div class="sub-content">
                You can check and scheduled a visit in our properties
              </div>
            </div>
          </div>
        </ng-template>
      </div>
      <div class="vl" *ngIf="visits?.length"></div>
      <div class="side-total-area" *ngIf="visits?.length">
        <div class="side-area">
          <div class="side-img">
            <img
              src="./../../../../assets/images/visit/istharacoliving1.png"
              width="190px"
            />
          </div>
          <div class="side-container-text">
            <div class="left-card-heading">
              {{ visitDetails?.propertyName }}
            </div>
            <div class="sub-div">
              <div class="locate-img">
                <img src="./../../../../assets/images/visit/locate.png" />
              </div>
              <div class="area-text">
                {{ visitDetails?.locality }},{{ visitDetails?.city }}
              </div>
            </div>
            <div class="map-button">
              <button (click)="mapCoordinates(visitDetails)">
                <img src="./../../../../assets/images/visit/locateblack.png" />
                Google Map
              </button>
            </div>
          </div>
        </div>
        <div class="line">
          <img src="./../../../../assets/images/visit/Line.png" />
        </div>
        <div>
          <div class="profile">
            <div class="profilePhoto">
              <img src="./../../../../assets/images/visit/profilePhoto.png" />
            </div>
            <div class="side-profile-text">
              <div class="profile-text">
                <div class="name-profile">
                  Rajesh Kumar <span>(Visit Captain)</span>
                </div>
                <div class="visit-type">Visit Type</div>
              </div>
              <div class="contact">
                <img src="./../../../../assets/images/visit/call.png" />
                <div class="number">9654857985</div>
                <div class="video-visit">
                  <span *ngIf="visitDetails?.visitType == 'VIDEO_VISIT'"
                    >Video Visit</span
                  >
                  <span *ngIf="visitDetails?.visitType == 'PHYSICAL_VISIT'"
                    >Physical Visit</span
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="timing-details-visit">
            <div class="visiting">
              <div class="Visit-Date">Visit Date</div>
              <div class="Visit-Dat">
                {{ visitDetails?.visitDateTime | date: "fullDate" }}
              </div>
            </div>
            <div class="total-visttimings">
              <div class="visiting">
                <div class="Visit-Timings">Visit Timings</div>
                <div class="side-Time">
                  {{ visitDetails?.visitDateTime | date: "h:mm a" }}
                </div>
              </div>
            </div>
          </div>

          <div class="visit-tips">
            <div class="visit-heading">Visit Tips</div>
            <div class="visit-list">
              <li>
                Take a peak at your isthara stay before you move in with Virtual
                Property tours.
              </li>
              <li>
                Our Relationship Manager will get in touch with you via whatsapp
                at the allotted time
              </li>
              <li>Look around your new Isthara</li>
              <li>Ask all your queries, safety and comfortably</li>
              <li>Know exactly what to expect when you enter your Isthara</li>
              <li>Share your feedback on your video tour with us later.</li>
            </div>
          </div>
          <div class="button-sec">
            <div class="first-div">
              <button
                class="first-bt"
                [disabled]="visitDetails?.status == 'Visit Cancelled'"
                [ngStyle]="
                  visitDetails?.status == 'Visit Planned'
                    ? { 'background-color': '#fff' }
                    : {
                        'background-color': '#777a79',
                        border: '#777a79',
                        color: '#fff'
                      }
                "
                (click)="openVisit(visitDetails)"
              >
                Cancel Visit
              </button>
            </div>
            <div class="sec-div">
              <button
                class="second-bt"
                [disabled]="visitDetails?.status == 'Visit Cancelled'"
                [class.grey]="visitDetails?.status == 'Visit Cancelled'"
                (click)="reschedule(visitDetails)"
              >
                Reschedule
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-gap"></div>
  </div>
  <ng-template #loadingImg>
    <img
      src="./../../../../assets/images/loader.gif"
      alt=""
      class="loadingImg"
    />
  </ng-template>
  <div class="footer">
    <app-footer></app-footer>
  </div>
</section>
