import {
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'user-flow-product-benifits',
  templateUrl: './product-benifits.component.html',
  styleUrls: [
    './product-benifits.component.scss',
    './mq-product-benifits.component.scss',
  ],
})
export class ProductBenifitsComponent implements OnInit {
  @Input() benifits: any;
  @Input() benifitCard: any[];

  serviceBox = {
    name: 'Isthara',
    subText: 'Benefits',
    description:
      'Isthara, led by a team of health experts, has implemented every measure to create a safe haven for you. In these uncertain times, you can count on us to take care of your needs.',
    services: [
      {
        imgType: './../../../../assets/images/benifits/save-money.png',
        description: 'Just 1 Month Security Deposit',
      },
      {
        imgType: './../../../../assets/images/benifits/security.png',
        description: 'No Lock-in Period',
      },
      {
        imgType: './../../../../assets/images/benifits/cheap.png',
        description: 'No Brokerage',
      },
    ],
  };

  detailImg = [
    {
      img: './../../../../assets/images/benifits/salad.png',
      heading: 'Wholesome Nourishment',
      des: 'The food menu at Isthara is designed to meet FSSAI standards and the nutritional requirements of students. It is also subject to regular third-party quality checks.',
    },
    {
      img: './../../../../assets/images/benifits/cleaning.png',
      heading: 'International Standards',
      des: 'Our trained staff implements streamlined housekeeping processes at Isthara, that meet the International Standard for Housekeeping, Maintenance and Operations.',
    },
    {
      img: './../../../../assets/images/benifits/mobile.png',
      heading: 'Cutting Edge Technology',
      des: 'Integrated technology and mobile app driven approach to complaint management and service request system for all residents.',
    },
    {
      img: './../../../../assets/images/benifits/community.png',
      heading: 'Community Development',
      des: 'Social events planned by the residents, for the residents nurture the sense of community and belonging',
    },
  ];

  constructor() {}

  ngOnInit(): void {
    this.serviceBox = this.serviceBox;
  }
}
