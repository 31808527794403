<section>
    <div class="top-nav">
        <user-flow-top-navbar></user-flow-top-navbar>
    </div>
    <div class="main-section">
        <div class="join-team">
            <div class="join-text">
                <div class="text-section">
                    <div class="light-text">Join Our Team At</div>
                    <div class="dark-text">Isthara</div>
                    <div class="sub-text">Work at the most dynamic agency and unlock your true potential</div>
                    <div class="more-btn">
                        <button (click)="openJobs()">View Openings</button>
                    </div>
                </div>
            </div>
            <div class="join-image">
                <img src="./../../../../assets/images/career/top-sectionImage.svg" alt="group-image" loading="lazy">
            </div>
        </div>
        <div class="isthara-life">
            <div class="box-section">
                <div class="office">
                    <div class="office-box" *ngFor="let item of office">
                        <img [src]="item.img" alt="">
                        <div class="bigText">{{item.bigText}}</div>
                        <div class="smallText">{{item.smallText}}</div>
                    </div>
                </div>
                <div class="job">
                    <div class="office-box" *ngFor="let item of job">
                        <img [src]="item.img" alt="">
                        <div class="bigText">{{item.bigText}}</div>
                        <div class="smallText">{{item.smallText}}</div>
                    </div>
                </div>
            </div>
            <div class="isthara-text">
                <div class="text-section">
                    <div class="light-text">Your Life At</div>
                    <div class="dark-text">Isthara</div>
                    <div class="isthara-sub-text">At Isthara we believe in working together and working hard. we are looking for dynamic and creative individuals who are willing to dedicate themselves to providing innovative products and services for our clients</div>
                    <div class="isthara-sub-text-1">Besides getting the oppurtunity to unlock your true potential, at isthara you can also network with the some of the most talented people in the industry, go on annual picnics outside and enjoy many other benifits by working with us</div>

                </div>
            </div>
        </div>
    </div>
    <div class="image-section">
        <img src="./../../../../assets/images/career/imageGroup.svg" alt="group-image" loading="lazy">
    </div>
    <div class="join-now">
        <div>
            <span class="areyou">Are you ready to</span>
            <span class="join">&nbsp; Join Our Team?</span>
        </div>
        <div class="join-btn">
            <button (click)="openJobs()">Join Now</button>
        </div>
    </div>
    <div class="product-section">
        <div class="team-member">
            <div class="count">750+</div>
            <div class="text">Isthara Heros</div>
        </div>
        <div class="vl"></div>
        <div class="products">
            <div class="count">6+</div>
            <div class="text">Cities</div>
        </div>
        <div class="vl"></div>
        <div class="users">
            <div class="count">100000+</div>
            <div class="text">Happy Customers</div>
        </div>
    </div>
    <div class="footer">
        <app-footer></app-footer>
    </div>
</section>
