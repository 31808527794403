<section>
  <div class="main-block">
    <div class="align-block">
      <div class="text-section">
        <p class="company-name" [innerHTML]="home?.title">
          {{ home?.title_1 | titlecase }}
        </p>
        <!-- <p class="subtext">
        {{ home?.title_2 | titlecase }}
      </p> -->
        <p class="description">
          {{ home?.subtitle }}
        </p>
      </div>
      <div class="service-section">
        <div class="services-box">
          <ng-container *ngFor="let item of cardList; indes as i">
            <div
              class="box-service"
              align="center"
              [ngStyle]="{ 'background-color': getColor(item?.title) }"
            >
              <img
                class="service-img"
                [src]="item.iconUrl"
                [alt]="item.name"
                width="35%"
              />
              <p class="item-name">{{ item?.title | titlecase }}</p>
              <p class="item-desc">{{ item?.subtitle }}</p>
              <button
                class="btn-know-more"
                (click)="routeTo(item?.redirectUrl)"
              >
                Know More
              </button>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</section>
