<div class="close-x" (click)="closeBtn()">
  <div class="referafriend">Reminder</div>
  <div class="close-image">
    <img src="./../../../../../assets/images/popup-images/cross-mark.png" />
  </div>
</div>
<hr />
<div class="resident">Resident</div>
<div class="name">Name</div>
<div class="name-tag">Connie Richards</div>
<div class="message">Message</div>
<div class="message-box">
  <mat-form-field class="input-width" appearance="outline">
    <mat-label>Message</mat-label>
    <textarea matInput></textarea>
  </mat-form-field>
</div>
<div class="buttons">
  <div class="cancel"><button>Cancel</button></div>
  <div class="send"><button>send</button></div>
</div>
