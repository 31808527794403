<section style="width: 100%">
  <div class="slider" [class]="classId">
    <ng-container *ngFor="let item of images; let i = index">
      <div>
        <img
          width="100%"
          height="144"
          class="check-img-fit-box"
          [src]="item.imageUrl"
          [alt]="item.altText"
          [ngClass]="{ 'group-height': group }"
        />
      </div>
    </ng-container>
  </div>
  <!-- <div class="arrow">
    <div class="prevtrendprop cursor-pointer">
      <img
        width="25%"
        src="./../../../../../assets/images/prev.png"
        alt="previous"
      />
    </div>
    <div class="nexttrendprop cursor-pointer">
      <img
        width="25%"
        src="./../../../../../assets/images/next.png"
        alt="next"
      />
    </div>
  </div> -->
</section>
