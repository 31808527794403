<div class="close-x" (click)="closeBtn()">
  <div class="referafriend">Refer a Friend</div>
  <div class="close-image">
    <img src="./../../../../../assets/images/popup-images/cross-mark.png" />
  </div>
</div>
<div class="tab">
  <button
    class="tablinks"
    *ngFor="let tab of tabs; let i = index"
    (click)="openTab(tab, i)"
    [ngClass]="{ active: tab.active }"
  >
    {{ tab.name }}
  </button>
</div>

<div [ngSwitch]="viewMode">
  <div *ngSwitchCase="'link'">
    <div class="link-container">
      <div class="refer-box">
        <div class="refer-type">Referal Type</div>
        <input type="text" />
      </div>
      <div class="invite">
        <button>COPY LINK</button>
      </div>
    </div>
  </div>
  <div *ngSwitchCase="'socialmedia'">
    <div class="social-btn">
      <button>Invite Via Facebook</button>
    </div>
  </div>
  <div *ngSwitchCase="'direct'">
    <div class="direct">
      <div class="direct-text">
        <input type="text" placeholder="Name" />
        <select>
          <option>Referal Type</option>
          <option value="one">one</option>
          <option value="two">two</option>
          <option value="three">three</option>
        </select>
      </div>
      <div class="email">
        <mat-form-field class="input-width" appearance="outline">
          <mat-label>Email</mat-label>
          <input
            matInput
            placeholder="abc@example.com"
            pattern="^([_a-z0-9]+(.[_a-z0-9]+)*@[a-z0-9-]+(.[a-z0-9-]+)*(.[a-z]{2,5}))|(d+$)$"
            required
          />
          <mat-error> Please Enter Valid mail address </mat-error>
        </mat-form-field>
      </div>
      <div class="invite">
        <button>INVITE</button>
      </div>
    </div>
    <div class="invite-more"><button>Invite more</button></div>
  </div>
</div>
