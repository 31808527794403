<div class="main-sec" *ngIf="data.btnStatus == 'fromVisit'">
  <div class="heading">{{data.heading}}</div>
  <div class="sub-heading">{{data.sub}}</div>
  <div class="sub">{{data.subSub}}</div>
  <div class="button-sec">
    <div class="first-div">
      <button class="first-bt" (click)="closeBtn()">No,Dont Cancel</button>
    </div>
    <div class="sec-div">
      <button class="second-bt" (click)="getCancel()">Yes,Cancel</button>
      <!-- <button class="second-bt" *ngIf="data.btnStatus == 'fromBooking'" (click)="cancelBooing()">{{data.btnName}}</button> -->
    </div>
  </div>
</div>
<div class="main-sec" *ngIf="data.btnStatus == 'fromBooking'">
  <div class="heading">{{data.heading}}</div>
  <div class="sub-heading">{{data.sub}}</div>
  <div class="sub">{{data.subSub}}</div>
  <div class="message">
    <mat-form-field class="input-width" appearance="outline">
      <mat-label>Reason for cancel</mat-label>
      <textarea
        matInput
        [(ngModel)]="cancelReason"
        #cancelReasonCheck="ngModel"
        cols="60"
      ></textarea>
    </mat-form-field>
  </div>
  <div class="button-sec">
    <div class="first-div">
      <button class="first-bt" (click)="closeBtn()">No,Dont Cancel</button>
    </div>
    <div class="sec-div">
      <button class="second-bt" (click)="cancelBooing()">
        <span *ngIf="!cancelBookingloader">{{data.btnName}}</span>
        <span *ngIf="cancelBookingloader"><i class="fa fa-spinner fa-spin"></i> &nbsp;Loading</span>
      </button>
    </div>
  </div>
</div>
