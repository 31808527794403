<section>
  <div class="list-data">
    <ng-container *ngFor="let item of commonAmenitiesList">
      <div
        class="amenities-box"
        [ngStyle]="{
          width: diamentions.width,
          height: diamentions.height
        }"
        *ngIf="
          commonAmenitiesList == property?.commonAmenties ||
          commonAmenitiesList == property?.commonServices
        "
      >
        <img src="{{ item.icon }}" alt="ca" class="amenity-icon" />
        <p class="name" [ngStyle]="{ 'font-size': diamentions.fontSize }">
          <!-- [ngStyle]="{font-size: diamentions.font_size}" -->
          {{ item.name }}
        </p>
      </div>
      <div
        class="amenities-box"
        [ngStyle]="{
          width: diamentions.width,
          height: diamentions.height
        }"
        *ngIf="commonAmenitiesList == property?.foodService?.weekdaysMeals"
      >
        <img src="{{ item.mealIcon }}" alt="ca" />
        <p class="name" [ngStyle]="{ 'font-size': diamentions.fontSize }">
          <!-- [ngStyle]="{font-size: diamentions.font_size}" -->
          {{ item.mealName }}
        </p>
      </div>
      <div
        class="amenities-box"
        [ngStyle]="{
          width: diamentions.width,
          height: diamentions.height
        }"
        *ngIf="commonAmenitiesList == property?.foodService?.weekendsMeals"
      >
        <img src="{{ item.mealIcon }}" alt="ca" />
        <p class="name" [ngStyle]="{ 'font-size': diamentions.fontSize }">
          <!-- [ngStyle]="{font-size: diamentions.font_size}" -->
          {{ item.mealName }}
        </p>
      </div>
    </ng-container>
  </div>
</section>
