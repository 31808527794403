import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'user-flow-food-court',
  templateUrl: './food-court.component.html',
  styleUrls: ['./food-court.component.scss', './mq-food-court.comonent.scss'],
})
export class FoodCourtComponent implements OnInit {
  constructor(private router: Router,
    private metaService: Meta,
    private titleService: Title) {}
  cordinatesValue: any = 'Locate';
  addressSearch: any;
  showMe: boolean = false;

  studentSection = [
    {
      heading: 'Reimagine Food Court,',
      headingTwo: 'The Isthara Way',
      desc: 'The Isthara Food Court offers a community of entrepreneurs who are bringing quality and hygiene into the college canteen and cafeteria. With a paradigm shift of packaging food with easily identifiable external labeling, Isthara provides students with the most hygienic, safe, and tasty food options across various colleges in Bengaluru and Hyderabad.',
      icon: './../../../../assets/images/loft/video.png',
      desc1: 'watch video',
      img: './../../../../assets/images/food/food-main.png',
    },
  ];
  transformedLiving = [
    {
      text: 'Top Quality and Cost-effective',
      heading: 'Culinary Ecosystem',
      desc: 'Isthara food courts has a unique student-centric approach wherein we ensure a healthy ecosystem of the college canteen.',
      desc1:
        ' By delivering the right product at the right time to the right people, we believe in building long term relationships that spread beyond the mere transaction of service offerings.',
      desc2:
        "With an adept software platform, Isthara looks into the entire cafeteria management with the aim of providing a better food experience for students. The solution provides a self-explanatory interface for vendor's interface, stock management, menu management, delivery management, staff attendance tracking, machine monitoring, etc.",
      img: './../../../../assets/images/food/food-second.png',
    },
  ];
  question = [
    {
      text: 'The Isthara',
      heading: 'Amenities',
      desc: 'The needs of students for a well-rounded academic, professional, physical and mental growth are huge. The state of the art amenities offered at Isthara are designed with these growing needs in mind.',
      img: '',
      img1: '',
      img2: '',
      img3: '',
    },
  ];
  amenities = [
    {
      text: 'Isthara',
      heading: 'Amenities',
      desc: 'Today the needs of the students for their physical, professional and academic and mental growth are huge. Keeping these in mind the amenities in Isthara are designed around these factors.',
      firstRowImages: {
        name: '1stRow',
        images: [
          {
            img: './../../../../assets/images/loft/food.png',
            activeimg: './assets/images/loft/activefood.png',
            src: '',
          },
          {
            img: './../../../../assets/images/loft/hot-water.png',
            activeimg: './assets/images/loft/activehot-water.png',
            src: '',
          },
          {
            img: './../../../../assets/images/loft/cloth.png',
            activeimg: './assets/images/loft/activelaundry.png',
            src: '',
          },
          {
            img: './../../../../assets/images/loft/oven.png',
            activeimg: './assets/images/loft/activeoven.png',
            src: '',
          },
          {
            img: './../../../../assets/images/loft/ro-water.png',
            activeimg: './assets/images/loft/activero-water.png',
            src: '',
          },

          {
            img: './../../../../assets/images/loft/coffee.png',
            activeimg: './assets/images/loft/active-coffee.png',
            src: '',
          },
          {
            img: './../../../../assets/images/loft/tv.png',
            activeimg: './assets/images/loft/activetv.png',
            src: '',
          },
          {
            img: './../../../../assets/images/loft/wifi.png',
            activeimg: './assets/images/loft/activewifi.png',
            src: '',
          },
        ],
      },
      secondRowImages: {
        name: '2nd Row',
        images: [
          {
            img: './../../../../assets/images/loft/ro-water.png',
            activeimg: './assets/images/loft/activero-water.png',
            src: '',
          },

          {
            img: './../../../../assets/images/loft/coffee.png',
            activeimg: './assets/images/loft/active-coffee.png',
            src: '',
          },
          {
            img: './../../../../assets/images/loft/tv.png',
            activeimg: './assets/images/loft/activetv.png',
            src: '',
          },
          {
            img: './../../../../assets/images/loft/wifi.png',
            activeimg: './assets/images/loft/activewifi.png',
            src: '',
          },
        ],
      },

      thirdRowImages: {
        name: '3rd Row',
        images: [
          {
            img: './../../../assets/images/loft/ac.png',
            activeimg: './assets/images/loft/activeac.png',
            src: '',
          },
          {
            img: './../../../assets/images/loft/clean.png',
            activeimg: './assets/images/loft/activecleaning.png',
            src: '',
          },
          {
            img: './../../../assets/images/loft/gym.png',
            activeimg: './assets/images/loft/activegym.png',
            src: '',
          },
          {
            img: './../../../assets/images/loft/fridge.png',
            activeimg: './assets/images/loft/activefridge.png',
            src: '',
          },
        ],
      },
    },
  ];
  loftData = [
    {
      img: './../../../../assets/images/loft/nutricious-food.png',
      heading: 'Nutricious Food',
      subText:
        'Menu designed as per nutriotn requirements, FFSAI standards and Third party quality checks',
    },
    {
      img: './../../../../assets/images/loft/international-standard.png',
      heading: 'International Standards',
      subText:
        'Processes, chemicals, staff training and audit as per International Standards for House Keeping, Maintenance and Operations ',
    },
    {
      img: './../../../../assets/images/loft/technology.png',
      heading: 'Cutting Edge Technology',
      subText:
        'Technology at your hand, Mobile app driven approach for ticketing, Events, and any requests to college.',
    },
    {
      img: './../../../../assets/images/loft/development.png',
      heading: 'Community Development',
      subText:
        'What is better than friends and community. Events planned for you and planned by you. ',
    },
  ];

  blog = [
    {
      img: './../../../../assets/images/food/multi-vendor.svg',
      subHead: 'Multi Vendor Set-Up',
      desc: 'We specialize in bringing together multi culinary curated vendors and take pride in their excellent customer service, highly trained staff, and quick response time. ',
      desc1: '',
      desc2: '',
    },
    {
      img: './../../../../assets/images/food/face-business.svg',
      subHead: 'Ease of Business',
      desc: 'All of our Food Courts enjoy an advanced Point of Sale system and that enables businesses to optimize sales and make the entire experience seamless and hassle-free.',
      desc1: '',
      desc2: '',
    },
    {
      img: './../../../../assets/images/food/quality.svg',
      subHead: 'Quality Control',
      desc: 'Before the Food Court comes into service, the Quality Team analyses the functioning and food daily to ensure that the quality is not compromised in any regard.',
      desc1: '',
      desc2: '',
    },
    {
      img: './../../../../assets/images/food/better-student.png',
      subHead: 'Better Student Experience',
      desc: 'Our vision is to not just provide delectable food but also make the entire cafeteria experience memorable. We keep customer satisfaction at the forefront and ensure that all students have a refreshing experience. ',
      desc1: '',
      desc2: '',
    },
  ];
  experience = [
    {
      text: 'Inviting',
      heading: 'Food Entrepreneur',
      desc: 'Join us in our journey to revolutionize food courts for universites and colleges. We are sure together we can.',
      img: './../../../../assets/images/food/foodcourt.png',
    },
  ];
  imageFood = [
    { name: './../../../../assets/images/food/food-varity.svg' },
    { name: './../../../../assets/images/food/hygiene.svg' },
    { name: './../../../../assets/images/food/fssai.svg' },
    { name: './../../../../assets/images/food/pricing.svg' },
    // { name: './../../../../assets/images/food/practical.svg' },
  ];

  ngOnInit(): void {
    this.metaService.updateTag({
      name:'description',
      content:'We, at Isthara have redefined Food court that ensures nutritious meals prepared in the best hygienic conditions.'});
    this.titleService.setTitle('Food court, the Isthara way')
  }
  showvideo() {
    this.showMe = !this.showMe;
  }
  closeBtn() {
    // this.router.navigate(['/community'])
    this.showMe = !this.showMe;
  }
  changePath(path) {
    this.router.navigate([path]);
  }
}
