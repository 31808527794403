<div class="text-box">
  <!-- <user-flow-map-search
      #place
      (setAddress)="getAddress($event)"
      [placeHolderName]="'Find your property by locality or city'"
    >
    </user-flow-map-search> -->
  <div class="ng-autocomplete">
    <ng-autocomplete
      [data]="suggest"
      [searchKeyword]="keyword"
      placeholder="Find your property by locality or city"
      (selected)="selectEvent($event)"
      (inputChanged)="onChangeSearch($event)"
      (inputFocused)="onFocused($event)"
      historyIdentifier="suggest"
      [itemTemplate]="itemTemplate"
      [notFoundTemplate]="notFoundTemplate"
    >
    </ng-autocomplete>

    <ng-template #itemTemplate let-item>
      <div class="property-location">
        <div class="suggest-display" *ngIf="item.responseType == 'city'">
          <div class="location-image">
            <img src="./../../../../assets/images/home/location.svg" />
          </div>
          <div class="locality-name">
            <a [innerHTML]="item.name"></a
            ><span *ngIf="item?.name">,</span>&nbsp;<a
              [innerHTML]="item.state"
            ></a
            ><span *ngIf="item?.state">,</span>&nbsp;
            <a [innerHTML]="item.country"></a>&nbsp;-&nbsp;
            <div [innerHTML]="item.count"></div>
            &nbsp;
            <div>Properties avaliable</div>
          </div>
        </div>
        <!-- <div class="response-define" *ngIf="item.responseType=='property'">{{item.responseType}}</div> -->
      </div>
      <div class="property-location">
        <div class="suggest-display" *ngIf="item.responseType == 'locality'">
          <div class="location-image">
            <img src="./../../../../assets/images/home/location.svg" />
          </div>
          <div class="locality-name">
            <a [innerHTML]="item.name"></a>,&nbsp;<a [innerHTML]="item.city"></a
            >,&nbsp;<a [innerHTML]="item.state"></a>,&nbsp;<a
              [innerHTML]="item.country"
            ></a
            >&nbsp;-&nbsp;
            <div [innerHTML]="item.count"></div>
            &nbsp;
            <div>Properties avaliable</div>
          </div>
        </div>
        <!-- <div class="response-define" *ngIf="item.responseType=='property'">{{item.responseType}}</div> -->
      </div>

      <div class="property-location">
        <div class="suggest-display" *ngIf="item.responseType == 'landmark'">
          <div class="location-image">
            <img src="./../../../../assets/images/home/location.svg" />
          </div>
          <div class="locality-name">
            <a [innerHTML]="item.name"></a>,&nbsp;<a [innerHTML]="item.city"></a
            ><span *ngIf="item?.city">,</span>&nbsp;<a
              [innerHTML]="item.locality"
            ></a
            ><span *ngIf="item?.locality">,</span>&nbsp;<a
              [innerHTML]="item.state"
            ></a
            ><span *ngIf="item?.state">,</span>&nbsp;<a
              [innerHTML]="item.country"
            ></a>
          </div>
        </div>
        <!-- <div class="response-define" *ngIf="item.responseType=='property'">{{item.responseType}}</div> -->
      </div>

      <div *ngIf="item.responseType == 'property'" class="property-type">
        <div class="suggest-displayProperty">
          <div class="location-image">
            <img src="./../../../../assets/images/home/property.svg" />
          </div>
          <div class="localityProperty-name">
            <a [innerHTML]="item.name"></a>,&nbsp;<a [innerHTML]="item.city"></a
            >,&nbsp;<a [innerHTML]="item.state"></a>,&nbsp;<a
              [innerHTML]="item.country"
            ></a>
          </div>
        </div>
        <div class="response-define" *ngIf="item.responseType == 'property'">
          {{ item.responseType | titlecase }}
          <span class="dot-symbol">&#x2022;</span
          >{{ item.locality | titlecase }}
        </div>
      </div>
    </ng-template>

    <ng-template #notFoundTemplate let-notFound>
      <div [innerHTML]="notFound"></div>
    </ng-template>
  </div>
</div>
