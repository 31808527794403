import { Component, OnInit ,Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'user-flow-logout-popup',
  templateUrl: './logout-popup.component.html',
  styleUrls: ['./logout-popup.component.scss','./mq-logout-popup.component.scss']
})
export class LogoutPopupComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<LogoutPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any) {
      {
        dialogRef.disableClose = true;
      }
     }

  ngOnInit(): void {
  }
  closeBtn(){
    this.dialogRef.close();
  }
}
