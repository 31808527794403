<section>
  <div class="main-block">
    <div class="text-section">
      <p class="company-name" [innerHTML]="benifits?.title">
        <!-- {{ benifits?.title_1 | titlecase }} -->
      </p>

      <p class="description">
        {{ benifits?.description }}
      </p>
    </div>
    <div class="service-section">
      <div class="services-box">
        <ng-container *ngFor="let item of benifitCard; indes as i">
          <div class="box-service" align="center">
            <img
              class="service-img"
              [src]="item?.iconUrl"
              [alt]="item.name"
              width="30%"
            />
            <p class="no">No</p>
            <p class="item-desc">{{ item?.description }}</p>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="image-details">
    <div class="list-img-section">
      <ng-container *ngFor="let imgCheck of detailImg">
        <div class="box-details">
          <img [src]="imgCheck.img" alt="benefits" class="benifits-img" />
          <div class="detail-section">
            <div class="ben-heading">{{ imgCheck.heading | titlecase }}</div>
            <div class="ben-subdetail">{{ imgCheck.des }}</div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</section>
