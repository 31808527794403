import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';


@Component({
  selector: 'user-flow-schedule-visit-booked',
  templateUrl: './schedule-visit-booked.component.html',
  styleUrls: ['./schedule-visit-booked.component.scss', './mq-schedule-visit-booked.component.scss']
})
export class ScheduleVisitBookedComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ScheduleVisitBookedComponent>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    {
      dialogRef.disableClose = true;
    }
  }

  ngOnInit(): void {
  }
  closeBtn() {
    this.dialogRef.close();
    if (this.data.bigtext == "Successfully Visit Rescheduled!") {
      this.router.navigate(["myVisits"]);
      window.location.reload();
    }
    else {
      this.router.navigate(["myVisits"]);
    }
  }
}
