import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'user-flow-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: [
    './privacy-policy.component.scss',
    './mq-privacy-policy.component.scss',
  ],
})
export class PrivacyPolicyComponent implements OnInit {
  addressSearch: any;
  cordinatesValue: any = 'Locate';

  // privacyPolicy: any = [
  //   {
  //     heading: 'What information does Isthara collect from its users?',
  //     bullet1:
  //       'Some information you provide to us automatically, and other information you provide in order to utilize certain features of our website. Any information that you provide to us we may collect. For example, we might collect information about the type of Accommodation that interests you.',
  //     bullet2:
  //       'Automatic Information: When you visit the website, you disclose your IP address and certain information about your computing, such as the type of browser and operating system you are using and the page that referred you to our site.',
  //     bullet3:
  //       'Mobile Information: When you use Isthara’s mobile site, you have the option to disclose your location to Isthara.',
  //     bullet4:
  //       'Information You Provide To Isthara: To leverage certain functionalities offered by the website, you may need to supply additional information. For example, For Isthara Team to reach you about your accomocation requirement, you must share your e-mail address / mobile number.',
  //   },
  //   {
  //     heading: 'What information does Isthara collect from its users?',
  //     bullet1:
  //       'Some information you provide to us automatically, and other information you provide in order to utilize certain features of our website. Any information that you provide to us we may collect. For example, we might collect information about the type of Accommodation that interests you.',
  //     bullet2:
  //       'Automatic Information: When you visit the website, you disclose your IP address and certain information about your computing, such as the type of browser and operating system you are using and the page that referred you to our site.',
  //     bullet3:
  //       'Mobile Information: When you use Isthara’s mobile site, you have the option to disclose your location to Isthara.',
  //     bullet4:
  //       'Information You Provide To Isthara: To leverage certain functionalities offered by the website, you may need to supply additional information. For example, For Isthara Team to reach you about your accomocation requirement, you must share your e-mail address / mobile number.',
  //   },
  // ];

  // privacyPolicies: any = [
  //   {
  //     question: 'Privacy Policy',
  //     answers: [
  //       {
  //         ans: 'Some information you provide to us automatically, and other information you provide in order to utilize certain features of our website. Any information that you provide to us we may collect. For example, we might collect information about the type of Accommodation that interests you.',
  //       },
  //       {
  //         ans: 'Automatic Information: When you visit the website, you disclose your IP address and certain information about your computing, such as the type of browser and operating system you are using and the page that referred you to our site.',
  //       },
  //       {
  //         ans: 'Mobile Information: When you use Isthara’s mobile site, you have the option to disclose your location to Isthara.',
  //       },
  //       {
  //         ans: 'Information You Provide To Isthara: To leverage certain functionalities offered by the website, you may need to supply additional information. For example, For Isthara Team to reach you about your accomocation requirement, you must share your e-mail address / mobile number.',
  //       },
  //     ],
  //   },
  //   {
  //     question: 'Does Isthara Share the Information it receives?',
  //     answers: [
  //       {
  //         ans: 'Isthara does not sell any of the information that it receives from its users. Isthara shares information unless it is directed by you to do so. For example, if you use a Isthara web form to share a listing to your friend as a referral.',
  //       },
  //     ],
  //   },
  //   {
  //     question: 'How does Isthara use the information it collects?',
  //     answers: [
  //       {
  //         ans: 'Any time you supply an e-mail address (or a friend’s e-mail address), Isthara may use it to communicate with you about matters believed to be of interest to you. Should Isthara ever do so, it will provide you the opportunity to opt out of future communications. Isthara may also use your e-mail address to communicate with you regarding specific transactions you engage in on the site. For example: Whenever you establish an account, Isthara will send you an e-mail confirming that the account has been created.',
  //       },
  //     ],
  //   },
  //   {
  //     question: 'Cookies and Google Analytics',
  //     answers: [
  //       {
  //         ans: 'A HTTP cookie is an identifier that a website transfers to the user’s computer or mobile device and that is stored in the user’s web browser. Isthara uses cookies to identify the user and enhance the user experience, and also to understand the way in which the website is used. If you object to isthara’s use of cookies, look to your browser’s help feature for information about how to disable or clear cookies.',
  //       },
  //     ],
  //   },
  //   {
  //     question: 'Security',
  //     answers: [
  //       {
  //         ans: 'E-mail addresses, passwords, credit card information, messages transmitted in web forms, and other user-generated content of a confidential nature in Isthara’s possession will be accessible only to authorized personnel at Isthara.',
  //       },
  //       {
  //         ans: 'The Isthara website is hosted at AWS (Amazon Web Services) and Isthara cannot guarantee that information will not be intercepted from the website or that security breaches will not occur. This Privacy Statement applies solely to your dealings with Isthara.',
  //       },
  //       {
  //         ans: 'Once you leave the Isthara domain, your online actions will be subject to the privacy policy, if any, of the new website you enter, even if you reached that website by following a link from Isthara. This Privacy Statement may be updated from time-to-time, and Isthara recommends that you periodically revisit this page to keep yourself apprised of any changes.',
  //       },
  //     ],
  //   },
  // ];

  constructor() {}

  ngOnInit(): void {}
  getCordinates(place: any) {
    this.cordinatesValue = place.city;
  }
  getAddress(place: any) {
    this.addressSearch = place;
    // console.log('dddddddddddd', place);
  }
}
