import { Component, OnInit } from '@angular/core';
import { ErrorPopsService } from 'projects/user-flow-property/src/app/service/error-pops/error-pops.service';
import { PartnershipService } from '../partnership.service';

@Component({
  selector: 'user-flow-corporate-connect',
  templateUrl: './corporate-connect.component.html',
  styleUrls: [
    './corporate-connect.component.scss',
    './mq-corporate-connect.component.scss',
  ],
})
export class CorporateConnectComponent implements OnInit {
  constructor(
    private apiService: PartnershipService,
    private errorPops: ErrorPopsService
  ) {}

  ngOnInit(): void {}

  about = [
    {
      img: './../../../../../assets/images/list-your-property/service.png',
      text: 'Tailor-made services just for you.',
    },
    {
      img: './../../../../../assets/images/list-your-property/hygiene.png',
      text: 'Highest hygiene standards',
    },
    {
      img: './../../../../../assets/images/list-your-property/building.png',
      text: 'Customized premises',
    },
    {
      img: './../../../../../assets/images/list-your-property/calander.png',
      text: 'A practical and long-term partnership',
    },
  ];

  company = [
    {
      img: './../../../../../assets/images/list-your-property/microsoft.png',
    },
    {
      img: './../../../../../assets/images/list-your-property/Deloitte.png',
    },
    {
      img: './../../../../../assets/images/list-your-property/AIG.png',
    },
  ];

  Form = {
    details: {
      name: '',
      mobileNo: '',
      emailId: '',
      companyName: '',
      city: '',
      location: '',
      contactMethod: '',
    },
  };

  submitdetails() {
    if (
      this.Form.details.name == '' ||
      this.Form.details.name == null ||
      this.Form.details.mobileNo == '' ||
      this.Form.details.mobileNo == null ||
      this.Form.details.location == '' ||
      this.Form.details.location == null ||
      this.Form.details.emailId == '' ||
      this.Form.details.emailId == null ||
      this.Form.details.companyName == '' ||
      this.Form.details.companyName == null ||
      this.Form.details.city == '' ||
      this.Form.details.city == null
    ) {
      this.errorPops.openSnackBarMessage(
        'Please Fill All Mandatory Fields',
        'OK'
      );
    } else {
      let listdata = {
        city: this.Form.details.city,
        details: {
          active: true,
          emailId: this.Form.details.emailId,
          mobileNumber: this.Form.details.mobileNo,
          name: this.Form.details.name,
        },
        location: this.Form.details.location,
        companyName: this.Form.details.companyName,
      };
      // console.log('gggg',listdata)
      this.apiService.corporateregistration(listdata).subscribe(
        (res: any) => {
          if (res.error) {
            // console.log(res.message, 'error occurs');
            this.errorPops.openSnackBarMessage(res.message, 'OK');
          } else {
            // console.log('successfully Registered', res.city);
            this.errorPops.openSnackBarMessage(
              ` details submited successfully`,
              'close'
            );
            window.location.reload();
          }
        },
        (err) => {
          // console.log("gettting error", err)
          this.errorPops.openSnackBarMessage(err.error.message, 'Cancel');
        }
      );
    }
  }
}
