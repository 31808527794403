<section>
  <div class="img-lg-box">
    <img
      class="img-box main-img-box"
      [src]="imageToShow"
      alt="imageToShow"
      (click)="showImage()"
      onerror="this.onerror=null;this.src='./../../../../../assets/images/property-list/no-img.svg'"
    />
  </div>
  <div class="img-list">
    <ng-container *ngFor="let detailItem of images; index as i">
      <div class="img-sm-box">
        <img
          class="img-box"
          [src]="detailItem.imageUrl"
          [alt]="detailItem.altText"
          (click)="changeImage(detailItem, i)"
          [ngClass]="{ 'img-box-active': i === imageIndex }"
        />
      </div>
    </ng-container>
  </div>
</section>

<section class="overlay" [ngClass]="{ 'overlay-visible': overLay }">
  <div class="img-overlay-box">
    <div class="close-overlay-btn" (click)="closeOverlay()">X</div>
    <img class="img-box-overlay" [src]="imageToShow" [alt]="imageToShow" />
    <div class="direction-btn">
      <div class="previous dir-btn" (click)="previous()">Previous</div>
      <div class="next dir-btn" (click)="next()">Next</div>
    </div>
  </div>
</section>
