<section>
  <div class="main-section">
    <div class="top-nav">
      <user-flow-top-navbar></user-flow-top-navbar>
    </div>
    <!-- <div class="search-bar">
      <div class="main-search">
        <div class="location-drop-down">
          <span class="icon">
            <user-flow-map-spot-cordinates
              (setAddress)="getCordinates($event)"
            ></user-flow-map-spot-cordinates>
          </span>
          <span class="location">{{ cordinatesValue | titlecase }}</span>
        </div>
        <div class="search-box">
          <user-flow-map-search
            #place
            (setAddress)="getAddress($event)"
            [placeHolderName]="'Search by locality or city'"
          >
          </user-flow-map-search>
        </div>
      </div>
    </div> -->
    <div class="view-profile" *ngIf="!show" >
      <div class="heading-text">My Profile</div>
      <div class="profile-details" *ngIf="loader; else loadingImg">
        <div class="profile-image" *ngIf="user.profileImg != null; else noImg">
          <img
            [src]="user.profileImg"
            alt=""
            width="200"
            height="150"
            class="profile-image"
          />
        </div>
        <ng-template #noImg>
          <div class="profile-image">
            <img
              [src]="user.noImg"
              alt=""
              width="200"
              height="150"
              class="profile-image"
            />
          </div>
        </ng-template>
        <div class="details">
          <div class="name">
            {{ user.name }}
            <div class="edit">
              <button class="edit-btn" (click)="toggle()">
                <img
                  src="./../../../../../assets/images/profile/edit.png"
                  alt="edit"
                />
              </button>
            </div>
          </div>
          <div class="phone">
            <img
              src="./../../../../../assets/images/profile/phone.png"
              alt="phone"
            />
            <div class="number">
              {{ user.mobile }}
            </div>
            <div class="verified-icon" *ngIf="user.mobile == user.mobile">
              <img
                src="./../../../../../assets/images/profile/verified.png"
                alt="verified"
              />
            </div>
            <!-- <div class="verified-icon" *ngIf="user.mobile !== user.mobile">
                            <img src="{{user.noImg}}" alt="">
                        </div> -->
          </div>
          <div class="phone">
            <img
              src="./../../../../../assets/images/profile/mail.png"
              alt="phone"
            />
            <div class="number">
              {{ user.mailId }}
            </div>
            <div class="verified-icon" *ngIf="user.mailId == user.mailId">
              <img
                src="./../../../../../assets/images/profile/verified.png"
                alt="verified"
              />
            </div>
            <div class="verified-icon" *ngIf="user.mailId !== user.mailId">
              verify
            </div>
          </div>
        </div>
      </div>
    </div>
    <ng-template #loadingImg>
      <img src="./../../../../assets/images/loader.gif" alt="" class="loadingImg" />
    </ng-template>
    <div class="update-profile" *ngIf="show">
      <div class="updateLoader" *ngIf="updateLoader; else loadingImg">
      <div class="heading-text">My Profile</div>
      <div class="sub-text" *ngIf="user.mailId !== user.mailId">
        Email Verification Pending !
      </div>
      <div class="profile-details">
        <div class="profile-image">
          <img
            [src]="ds.bypassSecurityTrustUrl(user.profileImg)"
            alt=""
            width="200"
            height="150"
            class="profile-image"
            *ngIf="user.profileImg != null; else noImg"
          />
          <ng-template #noImg>
            <img
              [src]="ds.bypassSecurityTrustUrl(user.noImg)"
              alt=""
              width="200"
              height="150"
              class="profile-image"
            />
          </ng-template>
          <div class="myLabel">
            <input
              type="file"
              (change)="fileChange($event)"
              accept="image/x-png,image/gif,image/jpeg,image/jpg"
            />
            <span
              ><img
                src="./../../../../../assets/images/profile/camera.png"
                alt="camera"
                style="cursor: pointer"
            /></span>
          </div>
        </div>
        <div class="details">
          <div class="details-heading">
            <div class="left-section">Update your account information</div>
            <div class="right-section" align="right">
              <button class="edit-btn" (click)="toggle()">
                <span class="back-profile">Back to Profile</span>
              </button>
            </div>
          </div>
          <div class="update-details">
            <mat-form-field class="full-width">
              <mat-label>User Name</mat-label>
              <input matInput value="{{ user.name }}" [(ngModel)]="user.name" />
            </mat-form-field>
          </div>
          <div class="gender">
            <div class="gender-heading">Gender</div>
            <div class="radio-group">
              <mat-radio-group [(ngModel)]="user.gender">
                <mat-radio-button value="MALE">Male</mat-radio-button>
                <mat-radio-button value="FEMALE">Female</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
          <div class="update-contact">
            <div class="contact-heading">Your contact information</div>
            <div class="update-details">
              <!-- <mat-form-field class="full-width">
                                <mat-label>Mobile Number</mat-label>
                                <input matInput value="{{user.mobile}}"/>
                            </mat-form-field> -->
              {{ user.mobile }}
            </div>
            <div class="update-details">
              <!-- <mat-form-field class="full-width">
                                <mat-label>Email Id</mat-label>
                                <input matInput value="{{user.mailId}}"/>
                            </mat-form-field> -->
              {{ user.mailId }}
              <!-- <div class="verified-icon">
                                <span>verify</span>
                            </div> -->
            </div>
            <div class="update-details">
              <button class="submit-btn" (click)="updateDetails()">
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-template #loadingImg>
    <img src="./../../../../assets/images/loader.gif" alt="" class="loadingImg" />
  </ng-template>
  </div>
  <div class="footer">
    <app-footer></app-footer>
  </div>
</section>