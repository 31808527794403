<section>
  <div class="text-section">
    <!-- <div class="text-heading-main" [innerHTML]="curstomerCarousel?.title"></div> -->
    <!-- <div class="sub-heading">Success Stories</div> -->
  </div>

  <div class="one-time">
    <div *ngFor="let item of listComments" class="bg-img">
      <div class="align-box">
        <div class="image-box">
          <ng-container *ngIf="item.img !== ''">
            <img class="img-check" [src]="item.img" alt="person" />
          </ng-container>
        </div>

        <div class="invered-comma">“</div>
        <div class="text-box-carousel">
          <div class="basic-text">
            {{ item.comment }}
          </div>
          <div class="name-heading">{{ item.name }}</div>
          <div class="sub-text">{{ item.designation }}</div>
        </div>
      </div>
    </div>
  </div>
</section>
