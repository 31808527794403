<section>
  <div class="heading">Schedule Visit</div>
  <div class="close-x" (click)="closeDialog()">
    <img src="./../../../../assets/images/popup-images/cross-mark.png" />
  </div>
  <br />
  <div class="visit-main">
    <div [ngSwitch]="viewMode" class="select-visit">
      <div *ngSwitchCase="'selectVisit'">
        <div class="box-howits">
          <div class="logging-section">
            <div class="sline">
              <div class="firstbt">
                <button
                  value="{{ videoBtn.value }}"
                  (click)="videoVisit()"
                  [ngStyle]="
                    videoBtn.active == true
                      ? { 'background-color': '#ff1872', color: 'white' }
                      : {
                          'background-color': 'white',
                          color: 'black'
                        }
                  "
                >
                  {{ videoBtn.name }}
                </button>
              </div>
              <div class="secondbt">
                <button
                  value="{{ physicalBtn.value }}"
                  (click)="physicalVisit()"
                  [ngStyle]="
                    physicalBtn.active == true
                      ? { 'background-color': '#ff1872' }
                      : {
                          'background-color': 'white',
                          color: 'black'
                        }
                  "
                >
                  {{ physicalBtn.name }}
                </button>
              </div>
            </div>
            <p class="heading-visit">When would you like to visit ?</p>

            <p class="logged-msg">Pick a time for a FREE assisted visit</p>
            <p class="logged-msg">You can reschedule later if you are busy</p>
            <br />
            <p class="logged-data">Pick Date</p>
            <p class="logged-data">{{ month | titlecase }}</p>
            <div class="date-select">
              <ng-container *ngFor="let date of daysAvailable; index as i">
                <button
                  class="day-box"
                  (click)="selectDate(date)"
                  [ngClass]="{ active: date.isActive == true }"
                >
                  {{ date.date }}
                </button>
              </ng-container>
            </div>

            <div class="logged-data">Please Select a time Slot</div>
            <div class="time-select">
              <div class="box-outer">
                <a class="arrow-left prev" [ngClass]="{showHide: timeArr == 0}">
                  <img
                    src="./../../../../assets/images/visit/left.png"
                    alt="left"
                    (click)="previosScroll()"
                  />
                </a>
                <a class="arrow-right next" [ngClass]="{showHide: timeArr == 0}">
                  <img
                    src="./../../../../assets/images/visit/right.png"
                    alt="right"
                    (click)="nextScroll()"
                  />
                </a>
                <div class="box-inner" #scroll>
                  <div class="showText" *ngIf="timeArr == 0 ; else showArr">
                    Today Slots closed after 8 PM
                  </div>
                  <ng-container *ngFor="let item of times; index as i" #showArr>
                    <button
                      class="thumb"
                      (click)="timeValue(item)"
                      [ngClass]="{ active: item?.isActive == true }"
                    >
                      <p>{{ item?.time }}</p>
                      {{ item?.schedule }}
                    </button>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
          <div class="buttonn" *ngIf="data.buttonName == 'SUBMIT'">
            <button class="continue-btn" (click)="submitDetails()">
              {{ data.buttonName }}
            </button>
          </div>
          <div class="buttonn" *ngIf="data.buttonName == 'Reschedule'">
            <button class="continue-btn" (click)="reschedule()">
              {{ data.buttonName }}
            </button>
          </div>
        </div>
      </div>
      <!-- <div class="box-howits">
      <div *ngSwitchCase="'enterDetails'">
       
        <div class="visit-data mt-3">
          <div class="visit-main">
          <p class="heading-visit">Your Visit time</p>
          <p class="heading-text">Change</p>
        </div>
          <p class="logged-data">
            {{ selectedDate }} {{ dayName | titlecase }},
            {{ month | titlecase }}
          </p>
          <p class="logged-data">
            {{ selectedTime.time }} {{ selectedTime.schedule }}
          </p>

          <div class="personal-info">
            <mat-form-field appearance="legacy">
              <mat-label>Full Name</mat-label>
              <input matInput />
            </mat-form-field>
            <br />
            <mat-form-field appearance="legacy">
              <mat-label>Email</mat-label>
              <input
                type="text"
                placeholder="pat@example.com"
                name="email"
                matInput
                pattern="^([_a-z0-9]+(.[_a-z0-9]+)*@[a-z0-9-]+(.[a-z0-9-]+)*(.[a-z]{2,5}))|(d+$)$"
                [(ngModel)]="email"
                #emailCheck="ngModel"
                required
              />
              <mat-error
                *ngIf="
                  !emailCheck.valid && (emailCheck.dirty || emailCheck.touched)
                "
              >
                Please enter a valid email address
              </mat-error>
            </mat-form-field>
            <br />
            <div class="mobile">
              <mat-form-field class="form-fields" appearance="legacy">
                <mat-label>Phone Number</mat-label>
                <input
                  type="text"
                  matInput
                  [(ngModel)]="mobile"
                  name="mobile"
                  pattern="^[6-9]\d{9}$|^[1-9]\d{9}$"
                  #mobileCheck="ngModel"
                  required
                />

                <mat-error
                  *ngIf="
                    !mobileCheck.valid &&
                    (mobileCheck.dirty || mobileCheck.touched)
                  "
                >
                  Please enter a valid Phone Number
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <p class="logged-msg">
            We’ll send you the text verification code. SMS will be sent with
            exact direction to the stay.
          </p>
        </div>
       <div class="submit-bt">
        <button class="continue-bt" (click)="viewMode='otpVerification'">Sumbit</button>
      </div>
      </div>
    </div> -->
      <!-- <div class="box-howits">
      <div *ngSwitchCase="'otpVerification'" class="otp-section">
        <div class="big-text">OTP Verification</div>
        <div class="sub-text">
             OTP has been sent to  mobile number 
        </div>
        <div class="number-text">
9956789878 <div class="mobile-text"> Change</div>
        </div>
        <div class="otp-field">
            <input id="codeBox1" #num1 maxlength="1" (keyup)="move(num1,num2)" [(ngModel)]="verifyotp.otp1">
<input id="codeBox2" #num2 maxlength="1" (keyup)="move(num2,num3)" [(ngModel)]="verifyotp.otp2">
<input id="codeBox3" #num3 maxlength="1" (keyup)="move(num3,num4)" [(ngModel)]="verifyotp.otp3">
            <input id="codeBox4" #num4 maxlength="1" (keyup)="move(num4,num5)" [(ngModel)]="verifyotp.otp4">
            <input id="codeBox5" #num5 maxlength="1" (keyup)="move(num5,num6)" [(ngModel)]="verifyotp.otp5">
            <input id="codeBox6" #num6 maxlength="1"  [(ngModel)]="verifyotp.otp6">
        </div>                    
        <div class="resend-otp">
            <div class="sub-text"></div><span class="otp-class">
                Resend OTP</span>
        </div>
        <div class="v-btn">
            <button class="verify-btn" (click)="viewMode='otpVerification'">Sumbit</button>
        </div>

      </div>
    </div> -->
    </div>
    <div class="gap"></div>

    <div class="static-section">
      <div class="static-texts">
        <p class="heading-static">How it works ?</p>
        <ul class="list-static">
          <li>Schedule a time slot with your details.</li>
          <li>
            You will get a call on whatsapp or hangout call on scheduled time.
          </li>
          <li>
            Our Relation ship manager will show you around the premises inside
            and out.
          </li>
          <li>Ask any question you may have to our Relationship Manager.</li>
        </ul>
      </div>
    </div>
  </div>
  <!-- <mat-accordion class="mt-5"> </mat-accordion> -->
</section>
