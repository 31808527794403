import { Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MapsAPILoader } from '@agm/core';

declare var google: any;

@Injectable({
  providedIn: 'root',
})
export class MapSearchLibService {
  constructor(private _map: MapsAPILoader, private zone: NgZone) {
    this._map.load().then(() => {
      this.geocoder = new google.maps.Geocoder();
    });
  }
  geocoder;
  location = {
    lat: 0,
    lng: 0,
    viewport: '',
    zoom: '',
    address_level_1: '',
    address_level_2: '',
    address_country: '',
    address_zip: '',
    address_state: '',
    marker: '',
    locality: '',
    city: '',
    country: '',
    state: '',
    pincode: '',
  };

  reverseGeocodding(lat, lng) {
    const promise = new Promise((res, rej) => {
      this._map.load().then(() => {
        const latlng = new google.maps.LatLng(lat, lng);
        // This is making the Geocode request
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({ latLng: latlng }, function (results, status) {
          if (status !== google.maps.GeocoderStatus.OK) {
            rej(status);
          }
          // This is checking to see if the Geoeode Status is OK before proceeding
          if (status === google.maps.GeocoderStatus.OK) {
            res(results[0]);
          }
        });
      });
    });
    return promise;
  }

  autoComplete(addresstextNative, addressType) {
    const promise = new Promise((res, rej) => {
      this._map.load().then(() => {
        const autocomplete = new google.maps.places.Autocomplete(
          addresstextNative,
          {
            componentRestrictions: { country: 'IN' },
            types: [...addressType], // 'establishment' / 'address' / 'geocode' this.addressType
          }
        );
        google.maps.event.addListener(autocomplete, 'place_changed', () => {
          const place = autocomplete.getPlace();
          //console.log("Places From Autocomplete: ", place);
          res(
            this.decomposeAddressComponents([
              { address_components: place.address_components },
            ])
          );
        });
      });
    });
    return promise;
  }

  findAddressByCoordinates(lat, lng) {
    const promise = new Promise((res, rej) => {
      this._map.load().then(() => {
        this.geocoder = new google.maps.Geocoder().geocode(
          {
            location: {
              lat: lat,
              lng: lng,
            },
          },
          (results) => {
            res(this.decomposeAddressComponents(results));
          }
        );
      });
    });
    return promise;
  }
  decomposeAddressComponents(addressArray) {
    if (addressArray.length == 0) return false;
    let address = addressArray[0].address_components;
    for (let element of address) {
      if (element.length == 0 && !element['types']) {
        continue;
      }

      if (element['types'].indexOf('street_number') > -1) {
        this.location.address_level_1 = element['long_name'];
        continue;
      }
      if (element['types'].indexOf('route') > -1) {
        this.location.address_level_1 += ', ' + element['long_name'];
        continue;
      }
      //sublocality_level_1
      if (element['types'].indexOf('sublocality_level_1') > -1) {
        // this.location.locality = element['long_name'];

        this.location.locality = element['long_name'];
      }
      if (element['types'].indexOf('locality') > -1) {
        this.location.address_level_1 += ', ' + element['long_name'];
        this.location.city = element['long_name'];
        continue;
      }
      if (element['types'].indexOf('administrative_area_level_1') > -1) {
        this.location.address_level_1 += ', ' + element['long_name'];
        this.location.state = element['long_name'];
        continue;
      }
      if (element['types'].indexOf('country') > -1) {
        this.location.address_level_1 += ', ' + element['long_name'];
        this.location.country = element['long_name'];
        continue;
      }
      if (element['types'].indexOf('postal_code') > -1) {
        this.location.address_level_1 += ', ' + element['long_name'];
        this.location.pincode = element['long_name'];
        continue;
      }
    }
    //console.log(this.location);

    return {
      city: this.location.city, //this.location.city
      locality: this.location.locality,
      country: this.location.country,
      state: this.location.state,
      pincode: this.location.pincode,
      vicinity: this.location.locality,
      check: Math.random(),
    };
  }
}
