import { environment } from './../../../../environments/environment';
import { Injectable } from '@angular/core';
import * as AWS from 'aws-sdk';

@Injectable({
  providedIn: 'root',
})
export class KycDocsService {
  constructor() {}
  imageObject;
  async uploadProfileImage(element) {
    const AWSService = AWS;
    const imageEnvCognito =
      environment.image.CredentialsProvider.CognitoIdentity.Default;
    const imageEnvUtility = environment.image.S3TransferUtility.Default;
    const region = imageEnvUtility.Region;
    const bucketName = imageEnvUtility.Bucket;
    const IdentityPoolId = imageEnvCognito.PoolId;
    // ? Configures the AWS service and initial authorization
    AWSService.config.update({
      region: region,
      credentials: new AWSService.CognitoIdentityCredentials({
        IdentityPoolId: IdentityPoolId,
      }),
    });
    // ? adds the S3 service, make sure the api version and bucket are correct
    const s3 = new AWSService.S3({
      apiVersion: '2012-07-10',
      params: {
        Bucket: bucketName,
      },
    });

    // const element = e.srcElement.files[0];

    let imgPromise = new Promise((res, rej) => {
      if (element) {
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          s3.upload(
            {
              Key:
                'kycdocs/' +
                Math.floor(100000 + Math.random() * 900000) +
                'c' +
                new Date().getTime() +
                element.name,
              Bucket: bucketName,
              Body: element,
              ACL: 'public-read',
            },
            function (err: any, data: any) {
              if (err) {
                rej(err);
              } else {
                res(data);
              }
            }
          );
        };
      }
    });

    return imgPromise
      .then((data) => {
        this.imageObject = data;
        // console.log('awsResult', data);
        return data;
      })
      .catch((err) => {
        // console.log(err)
        return err;
      });
  }
}
