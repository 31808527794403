import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  HostListener,
  Input,
  Inject,
  OnInit,
  PLATFORM_ID,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
declare var $: any;
@Component({
  selector: 'user-flow-customer-carousel',
  templateUrl: './customer-carousel.component.html',
  styleUrls: [
    './customer-carousel.component.scss',
    './mq-customer-carousel.component.scss',
  ],
})
export class CustomerCarouselComponent implements OnInit, OnChanges {
  // @Input() curstomerCarousel: any;
  @Input() customerCards;
  scrHeight: number;
  scrWidth: number;
  customerCardsCount: number;
  arrowWidth: number;
  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    // this.getScreenSize();
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    // console.log(this.scrHeight, this.scrWidth);
  }
  listComments = [];

  ngOnChanges(changes: SimpleChanges) {
    this.customerCardsCount = changes.customerCards.currentValue.length;
    if (this.customerCardsCount !== 0) {
      // this.listComments = changes.customerCards.currentValue;
      this.listComments = changes.customerCards.currentValue;
      this.carousel();
    }
  }
  ngOnInit(): void {
    // console.log(this.customerCards);
  }
  carousel() {
    if (isPlatformBrowser(this.platformId)) {
      $(document).ready(function () {
        if (this.scrWidth < 476) {
          $('.one-time').slick({
            dots: false,
            infinite: true,
            speed: 300,
            slidesToShow: 1,
            slidesToScroll: 1,
            adaptiveHeight: false,
            arrows: false,
            fade: false,
            cssEase: 'cubic-bezier(0.77, 0, 0.18, 1)',
            nextArrow:
              '<div class="slick-next" style="right:0px !important"></div>',
          });
        } else {
          $('.one-time').slick({
            dots: true,
            infinite: true,
            arrows: false,
            speed: 300,
            slidesToShow: 1,
            adaptiveHeight: true,
            fade: false,
            slidesToScroll: 1,
            dotsClass: 'dots-ws',
            cssEase: 'cubic-bezier(0.77, 0, 0.18, 1)',
          });
        }
      });
    }
  }
}
