import { ErrorPopsService } from './../../../service/error-pops/error-pops.service';
import { DbStorageService } from './../../../service/headers/db-storage/db-storage.service';
import { ListPropertyService } from './../../list-properties/list-property/list-property.service';
import { Router, ActivatedRoute } from '@angular/router';
import { PropertyDetailsService } from './../property-details.service';
import { Component, OnInit, HostListener } from '@angular/core';
import { BookingPopUserComponent } from '../../booking/booking-pop-user/booking-pop-user.component';
import { VisitUserFlowComponent } from '../../visit/visit-user-flow/visit-user-flow.component';
import { Meta, MetaDefinition } from '@angular/platform-browser';
import { MapSearchLibService } from './../../../service/map-search/map-search-lib.service';
import { AgmCoreModule, MapsAPILoader } from '@agm/core';
import { TempEnqueryPopupComponent } from '../../popup-components/popups/temp-enquery-popup/temp-enquery-popup.component';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'user-flow-prop-details-from-erp',
  templateUrl: './prop-details-from-erp.component.html',
  styleUrls: ['./prop-details-from-erp.component.scss','./mq-prop-details-from-erp.component.scss']
})
export class PropDetailsFromErpComponent implements OnInit {
  fifthSector: any = { in_stock: 0 };
  // addressSearch: any;
  cordinatesValue: any = 'Locate';
  propDetail: any;
  id: any;
  property: any;
  nearBy: any;
  selectedNearby: any;
  placeInfoList: any;
  minPriceMonthly: any;
  nearbyList: any;
  loader: boolean = false;
  scrHeight: any;
  scrWidth: any;
  offeringWidth = '35';
  constructor(
    private propertyDetailsService: PropertyDetailsService,
    private _route: ActivatedRoute,
    private metaService: Meta,
    private listService: ListPropertyService,
    private _dbStorage: DbStorageService,
    private router: Router,
    private map: MapSearchLibService,
    private mapsApiLoader: MapsAPILoader,
    private errorPops: ErrorPopsService,
    private matSnackBar: MatSnackBar
  ) {
    this.getScreenSize();
  }
  zoom = 18;
  icon = {
    url: './assets/images/map/map-pin.png',
    scaledSize: { height: 45, width: 30 },
  };
  propid;
  userid;
  currentLat;
  currentLng;
  placeList;
  ngOnInit() {
    this.metaService.getTag("name='viewport'");
    // this._route.params.subscribe((data: any) => {
    //   this.id = data.propId;
    //   this.propertyDetail(this.id);
    //   // console.log('propertyId', this.id);
    //   this._dbStorage.setPropertyId(this.id);
    // });
    this.userid = this._dbStorage.getUserId();
    // console.log('user did', this.userid);

    //favlist
    this.getFavUid(this.userid);

    // checking from Erp
    let currentUrl = window.location.href.split("/"); 
    let isErp: boolean = false;
    let propId = currentUrl[currentUrl.length-1];
    currentUrl.forEach((element) => {
      if(element === "fromErp"){
        isErp = true;
        this.propertyDetail(isErp,propId);
      }
    });
  }
  propertyDetail(isErp,propId) {
    this.loader = false;
    this.propertyDetailsService.checkFromErp(isErp,propId).subscribe(
      (res: any) => {
        if (res.data.propertyId) {
          this.property = res.data;
          this._dbStorage.setProperty(this.property);
          console.log('res.data', res.data);
          // console.log('proerty', this.property);
          this.nearBy = this.property.nearByPlaces;
          if(this.nearBy){
            this.selectedNearby = this.nearBy[0];
            this.placeInfoList = this.selectedNearby.placeList[0];
            this.nearbyList = this.property.nearByPlaces.map((listData) => {
              let newList = Object.assign({}, listData);
              newList.active = false;
              return newList;
            });
            this.nearbyList.forEach((item) => {
              item.placeList.forEach((list) => {
                list.active = false;
                // if(item.active === true){
                //   list[0].active = true
                // }
                // console.log(list);
              });
            });
            this.nearbyList[0].active = true;
            if(this.nearbyList[0].active === true){
              // console.log("durga",this.nearbyList[0].placeList[0])
              this.nearbyList[0].placeList[0].active = true;
            }
          }
          this.minPriceMonthly = this.minRoomRentValue(this.property.roomType);
          // console.log(this.nearbyList);

          this.loader = true;
        }
        // this.property = propDetail.data[propId];

        // console.log("some",this.selectedNearby.placeList)
        // this.metaService.removeTag("name='description'");
        // this.metaService.addTag({
        //   name: 'description',
        //   content: this.property.overview,
        // });
        // this.metaService.addTag({
        //   name: 'keywords',
        //   content: "'Best', 'Isthara', 'Hostel'",
        // });
      },
      (err) => {
        // console.log(err);
      },
      () => {
        if ('geolocation' in navigator) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              this.map
                .findAddressByCoordinates(
                  position.coords.latitude,
                  position.coords.longitude
                )

                .then((d) => {
                  // console.log('location data :', d);
                });
              this.currentLat = position.coords.latitude;
              this.currentLng = position.coords.longitude;
              this.distanceFromSpecificLocation(
                this.property.propertyAddress.geoLocation
              );
              // console.log("lat", this.currentLat);
              // console.log("lng", this.currentLng);
            },
            (err) => {
              // console.log('map error', err);
            }
          );
        }
      }
    );
  }
  // getAddress(place: any) {
  //   this.addressSearch = place;
  //   // console.log(place);
  // }
  getCordinates(place: any) {
    this.cordinatesValue = place.city;
  }

  nearbyDetails(item, i) {
    // console.log(item, i);
    this.nearbyList.forEach((listele, index) => {
      if (i === index) {
        listele.active = true;
        this.selectedNearby = item;
        this.placeInfoList = item.placeList[0];
      } else {
        listele.active = false;
      }
    });
    this.selectedNearby = item;
    this.placeInfoList.placeInfoList.placeName = item;
    this.details(this.selectedNearby.placeList[0], 0);
  }

  details(item, nbi) {
    this.placeInfoList = item;
    // console.log("item",item);
    this.nearbyList.forEach((near) => {
      near.placeList.forEach((list) => {
        if(list.subcategoryName === item.subcategoryName){
          list.active = true;
        }
        else{
          list.active = false;
        }
      });
      // console.log("some thins wenasdklf",near);
    });
  }
  openBooking() {
    // console.log(this.scrHeight,this.scrWidth)
    this.matSnackBar.open(
      "This is just a preview","OK",
      {
        duration: 3000,
      }
    )
  }

  openVisit() {
    this.matSnackBar.open(
      "This is just a preview","OK",
      {
        duration: 3000,
      }
    )
  }

  minRoomRentValue(roomList) {
    let a = [];
    a = roomList.map((r) => {
      return r.roomPrice.monthly;
    });

    return Math.min.apply(Math, a);
  }
  distance;
  distanceFromSpecificLocation(propLocation) {
    this.distance = '';
    if (propLocation) {
      this.mapsApiLoader.load().then(() => {
        const center = new google.maps.LatLng(this.currentLat, this.currentLng);
        const place = new google.maps.LatLng(
          propLocation.latitude,
          propLocation.longitude
        );
        let distance = google.maps.geometry.spherical.computeDistanceBetween(
          center,
          place
        );
        this.distance = Math.ceil(distance / 1000) + `km` || '';
        // console.log("this.distance", this.distance);
      });
    } else {
      // this.ErrorPopsService.openSnackBarMessage(
      //   "No Coordinates for Property !!",
      //   "Close"
      // );
      // console.log("welcome to isthara");
    }
  }
  getFavList;
  getFavUid(userid) {
    this.listService.getFavourites(userid).subscribe(
      (res: any) => {
        this.getFavList = res.data;
        for (let i = 0; i < this.getFavList.length; i++) {
          if (this.property.propertyId === this.getFavList[i].propertyId) {
            this.property.isFav = true;
            break;
          } else {
            this.property.isFav = false;
          }
        }
        // console.log("fav List", this.getFavList)
      },
      (err: any) => {
        // console.log('get fav error', err);
      }
    );
  }
  addFav(propId, flag, event) {
    event.stopPropagation();
    flag = flag ? false : true;
    let favData = {
      favourite: flag,
      propertyId: propId,
      userId: this.userid,
    };
    // console.log(favData);
    this.listService.addFavourites(favData).subscribe(
      (res: any) => {
        if (propId === this.property.propertyId) {
          this.property.isFav = flag;
          if (flag == true) {
            this.errorPops.openSnackBarMessage('Added to Favourites', 'OK');
          } else {
            this.errorPops.openSnackBarMessage('Removed from Favourites', 'OK');
          }
        }
        // console.log('success', res);
      },
      (err: any) => {
        // console.log('error', err);
      }
    );
  }
  share() {
    this.errorPops.openSnackBarMessage('Comming Soon', 'OK');
  }

  EnquiryTemp() {
    let enquiry = this.propertyDetailsService.enquiryDialog(
      TempEnqueryPopupComponent,
      { property: this.property }
    );
    enquiry.afterClosed().subscribe((data) => {
      // this.matSnackBar.open('Will Contact You Soon!!!', 'Close', {
      //   duration: 5000,
      // });
      // console.log('Property enquiry: ', data);
    });
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    this.offeringWidth = String(Number(this.scrWidth) * 0.31);
    // console.log(this.scrHeight, this.scrWidth);
  }

}
