<div class="head-text">Search</div>
<div class="close-x" (click)="closeBtn()">
  <img src="./../../../../../assets/images/popup-images/cross-mark.png" />
</div>
<div class="search-box">
  <div class="search">
    <user-flow-locality-auto-complete
      (newItemEvent)="closePopup($event)"
      class="search-box-auto"
    ></user-flow-locality-auto-complete>
    <!-- <div class="search-img">
      <img
        src="./../../../../assets/images/popup-images/Search.png"
        (click)="search()"
      />
    </div> -->
    <div class="btn-box">
      <div class="theme-btn" (click)="nearBy()">
        <span class="search-icon">
          <img
            src="./../../../../../assets/images/home/nearby.svg"
            alt="nearby"
          />
        </span>
      </div>
    </div>
  </div>
</div>
<div class="sub-textt" *ngIf="userId !== 'null' && userId !== null">
  <p>Saved Places({{ svPlaceCount }})</p>
  <div
    class="savePlace"
    *ngFor="let item of svPlaces; index as i"
    (click)="searchBySvp(item?.addressDetails, i)"
  >
    <div class="placeCard">
      <div>
        <img
          src="./../../../../../assets/images/popup-images/homeIcon.png"
          width="50%"
          alt=""
        />
      </div>
      <div>{{ item?.addressType }}</div>
    </div>
    <div class="locationDetails">
      {{ item?.addressDetails.locality }},{{ item?.addressDetails.city }},{{
        item?.addressDetails.state
      }},{{ item?.addressDetails.country }}
    </div>
  </div>
  <div class="savedPlace" (click)="addPlace()">+Add a Place</div>
</div>
<div class="sub-textt">
  <p>Previous Searches</p>
</div>
<div class="list-display">
  <div *ngFor="let item of previousSearch; index as i">{{ item }}</div>
  <!-- <p>Madhapur</p>
      <p>Gachibowli</p>
      <p>Hymayatnagar</p>
      <p>Korenti</p>
      <p>Panjagutta</p> -->
</div>
